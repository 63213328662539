import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import { ActionResponse } from 'services/types'
import i18n from 'utils/i18n'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  EventsResponse,
  EventRequest,
  EventResponse,
  EventHistoriesResponse,
  EventHistoriesRequest,
  ActionResolvedRequest,
  ActionResolvingRequest,
  ActionCreateATaskRequest,
  ActionNoActionRequest,
  ActionNoteRequest,
  EventsReportRequest,
  EventDetailRequest,
  ActionMoveAssetRequest,
} from './types'
import { providesList } from 'services/util'

const PREFIX_BULK = 'pages.event_management.bulk_actions.'
const PREFIX_MODAL = 'pages.event_management.modals.'

export const eventApi = createApi({
  reducerPath: 'eventApi',
  tagTypes: ['Events'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getEvents: builder.query<EventsResponse, EventRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/event_management/get_events/`,
        method: 'POST',
        data: {
          ...data,
        },
      }),
      providesTags: (result) => providesList(result?.data, 'Events'),
    }),
    getEvent: builder.query<EventResponse, EventDetailRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/event_management/get_event_by_id/`,
        method: 'POST',
        data,
      }),
      providesTags: (_result, _error, { eventId }) => [{ type: 'Events', id: eventId }],
    }),
    getEventHistories: builder.query<EventHistoriesResponse, EventHistoriesRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/event_management/get_event_histories_by_id/`,
        method: 'POST',
        data,
      }),
    }),
    updateEventsReport: builder.mutation<
      ActionResponse,
      EventsReportRequest & { clientId: number }
    >({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/event_management/create_event_management_report/`,
          method: 'POST',
          data,
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_MODAL}report.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateEventResolved: builder.mutation<
      ActionResponse,
      ActionResolvedRequest & { clientId: number }
    >({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/event_management/update_action_resolved/`,
          method: 'POST',
          data,
        }
      },
      // In this case, `getEvent` will be re-run. `getEvents` *might*  rerun, if this id was under it's results.
      invalidatesTags: (_result, _error, args) => [
        ...args.eventId.map((id) => ({ type: 'Events', id }) as const),
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_BULK}mark_as_resolved.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateEventResolving: builder.mutation<
      ActionResponse,
      ActionResolvingRequest & { clientId: number }
    >({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/event_management/update_action_resolving/`,
          method: 'POST',
          data,
        }
      },
      // In this case, `getEvent` will be re-run. `getEvents` *might*  rerun, if this id was under it's results.
      invalidatesTags: (_result, _error, args) => [
        ...args.eventId.map((id) => ({ type: 'Events', id }) as const),
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_BULK}resolving.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateEventNoAction: builder.mutation<
      ActionResponse,
      ActionNoActionRequest & { clientId: number }
    >({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/event_management/update_action_no_action/`,
          method: 'POST',
          data,
        }
      },
      // In this case, `getEvent` will be re-run. `getEvents` *might*  rerun, if this id was under it's results.
      invalidatesTags: (_result, _error, args) => [
        ...args.eventId.map((id) => ({ type: 'Events', id }) as const),
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_BULK}no_action_required.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateEventAddNote: builder.mutation<ActionResponse, ActionNoteRequest & { clientId: number }>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/event_management/update_action_add_note/`,
          method: 'POST',
          data,
        }
      },
      // In this case, `getEvent` will be re-run. `getEvents` *might*  rerun, if this id was under it's results.
      invalidatesTags: (_result, _error, args) => [
        ...args.eventId.map((id) => ({ type: 'Events', id }) as const),
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_BULK}add_note.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateEventCreateATask: builder.mutation<
      ActionResponse,
      ActionCreateATaskRequest & { clientId: number }
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/event_management/update_create_a_task/`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Events'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_BULK}create_task.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateMoveAsset: builder.mutation<
      ActionResponse,
      ActionMoveAssetRequest & { clientId: number }
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/event_management/update_move_asset/`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['Events'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: i18n.t(`${PREFIX_BULK}move_asset.success`),
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const {
  useGetEventHistoriesQuery,
  useGetEventQuery,
  useGetEventsQuery,
  useUpdateEventAddNoteMutation,
  useUpdateEventCreateATaskMutation,
  useUpdateEventNoActionMutation,
  useUpdateEventResolvedMutation,
  useUpdateEventResolvingMutation,
  useUpdateEventsReportMutation,
  useUpdateMoveAssetMutation,
} = eventApi
