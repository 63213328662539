import styled from 'styled-components'
import { Flex } from 'components'
import { color, mediaQuery } from '@evrekadev/evreka-ui-components'
import { SIDEBAR_SIZES } from 'containers/Sidebar/Sidebar.style'

type ContentProps = {
  collapsed: boolean
}

export const ContentStyled = styled.div`
  /* padding: 1rem; */
  padding-top: 0;
  flex: 1;
`
export const ContainerStyled = styled(Flex)`
  height: 100vh;
  background-color: ${color('grey100')};
`

export const LayoutWrapperStyled = styled.div<ContentProps>`
  margin-left: ${(props) => (props.collapsed ? SIDEBAR_SIZES.collapsed : SIDEBAR_SIZES.expanded)};
  transition: margin-left 0.3s ease-in-out;
  flex: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
`
