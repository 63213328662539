import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  RegionsServicePointRequest,
  RegionsServicePointResponse,
  StaticTourDeleteRequest,
  StaticTourDeleteResponse,
  StaticTourEditDetailRequest,
  StaticTourEditDetailResponse,
  StaticToursRequest,
  StaticToursResponse,
  StaticTourUpsertRequest,
  StaticTourUpsertResponse,
  StaticTourDetailRequest,
  StaticTourDetailResponse,
  KMLImportResponse,
  KMLImportRequest,
  SpItineraryListResponse,
  SpItineraryListRequest,
  MutationResponse,
  ImportSpItineraryListRequest,
  ImportSpItineraryListResponse,
  CalculateEtaRequest,
  CalculateEtaResponse,
  OptimizeServiceRequest,
  OptimizeServiceResponse,
  DrawPathRequest,
  DrawPathResponse,
  SpItineraryItemEditRequest,
  StaticTourAllKmlListRequest,
  StaticTourAllKmlListResponse,
} from './types'
import { sendNotification } from 'components'

export const staticTourApi = createApi({
  reducerPath: 'staticTourApi',
  tagTypes: ['StaticTours', 'StaticTourDetail', 'SpItineraryList', 'KmlList'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getStaticTours: builder.query<StaticToursResponse, StaticToursRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/static_tour/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['StaticTours'],
    }),
    getRegionsServicePoints: builder.mutation<
      RegionsServicePointResponse,
      RegionsServicePointRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/static_tour/get_service_point_list_by_region/`,
        method: 'POST',
        data,
      }),
    }),
    getEditStaticTourDetail: builder.query<
      StaticTourEditDetailResponse,
      StaticTourEditDetailRequest
    >({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/edit_static_tour_detail/${id}/`,
        method: 'GET',
      }),
      providesTags: ['StaticTourDetail'],
    }),
    addStaticTour: builder.mutation<StaticTourUpsertResponse, StaticTourUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/create_static_tour/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['StaticTours'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editStaticTour: builder.mutation<StaticTourUpsertResponse, StaticTourUpsertRequest>({
      query({ clientId, id, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/edit_static_tour/${id}/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['StaticTours', 'StaticTourDetail'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteStaticTour: builder.mutation<StaticTourDeleteResponse, StaticTourDeleteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/static_tour/`,
          method: 'DELETE',
          data,
        }
      },
      invalidatesTags: ['StaticTours'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getStaticTour: builder.query<StaticTourDetailResponse, StaticTourDetailRequest>({
      query: ({ clientId, ...data }) => ({
        url:
          `clients/${clientId}/ops_management/static_tour/get_static_tour_detail/?static_tour_id=` +
          data.static_tour_id,
        method: 'GET',
      }),
      providesTags: ['StaticTourDetail'],
    }),
    importKml: builder.mutation<KMLImportResponse, KMLImportRequest>({
      query: ({ clientId, id, body }) => ({
        url: `clients/${clientId}/ops_management/static_tour/${id}/upload_kml/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['StaticTourDetail'],
    }),
    calculateEta: builder.mutation<CalculateEtaResponse, CalculateEtaRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/static_tour/${id}/calculate_eta/`,
        method: 'GET',
      }),
      invalidatesTags: ['SpItineraryList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    optimizeService: builder.mutation<OptimizeServiceResponse, OptimizeServiceRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/static_tour/${id}/optimize_route/`,
        method: 'GET',
      }),
      invalidatesTags: ['SpItineraryList', 'StaticTourDetail'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    drawPathService: builder.mutation<DrawPathResponse, DrawPathRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/static_tour/${id}/draw_path/`,
        method: 'GET',
      }),
      invalidatesTags: ['StaticTourDetail', 'KmlList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getItineraryList: builder.query<SpItineraryListResponse, SpItineraryListRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/static_tour/itinerary_list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['SpItineraryList'],
    }),
    importSpItineraryList: builder.mutation<
      ImportSpItineraryListResponse,
      ImportSpItineraryListRequest
    >({
      query: ({ clientId, id, body }) => ({
        url: `clients/${clientId}/import/static_tour_itinerary_list/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['SpItineraryList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editSpItineraryItem: builder.mutation<MutationResponse, SpItineraryItemEditRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/static_tour/edit_static_tour_itinerary/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['SpItineraryList', 'StaticTourDetail'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getAllKMLs: builder.query<StaticTourAllKmlListResponse, StaticTourAllKmlListRequest>({
      query: ({ clientId }) => ({
        url: `clients/${clientId}/ops_management/static_tour/kml_files/`,
        method: 'GET',
      }),
      providesTags: ['KmlList'],
    }),
  }),
})

export const {
  useGetStaticToursQuery,
  useGetRegionsServicePointsMutation,
  useGetEditStaticTourDetailQuery,
  useAddStaticTourMutation,
  useDeleteStaticTourMutation,
  useEditStaticTourMutation,
  useGetStaticTourQuery,
  useImportKmlMutation,
  useGetItineraryListQuery,
  useImportSpItineraryListMutation,
  useEditSpItineraryItemMutation,
  useCalculateEtaMutation,
  useOptimizeServiceMutation,
  useDrawPathServiceMutation,
  useGetAllKMLsQuery,
} = staticTourApi
