import { UilAngleLeft, UilAngleRight } from '@iconscout/react-unicons'
import { Icon } from 'components'
import { ModuleArrowGradientWrapper, RtlWrapper } from 'containers/ModuleSlider/ModuleSlider.styled'
import React from 'react'
import { useRtl } from 'utils/useRtl'

export type ModuleSliderArrowProps = {
  direction: 'left' | 'right'
  visible: boolean
  onClick: () => void
  isRtl?: boolean
}

export const ModuleSliderArrow: React.FC<React.PropsWithChildren<ModuleSliderArrowProps>> = ({
  direction,
  visible,
  onClick,
}) => {
  const isRtl = useRtl()
  return (
    <RtlWrapper isRtl={isRtl}>
      <ModuleArrowGradientWrapper
        isRtl={isRtl}
        direction={direction}
        visible={visible}
        onClick={onClick}
        className={direction}
      >
        <Icon
          icon={direction === 'left' ? UilAngleLeft : UilAngleRight}
          size={36}
          color={'darkblue400'}
        ></Icon>
      </ModuleArrowGradientWrapper>
    </RtlWrapper>
  )
}
