import { MultiValue, SingleValue } from '@evrekadev/evreka-ui-components'
import { Dropdown } from 'components'
import { ColumnExtended, OptionType } from '@evrekadev/evreka-ui-components'

type DropdownFilterProps = {
  column: ColumnExtended<any>
  filterValue: string
  onChange: (id: string, value: string | string[]) => void
  type: string
}

export function DropdownColumnFilter({
  column: { id, filterOptions },
  onChange,
  filterValue,
}: DropdownFilterProps) {
  const dropdownValue: OptionType | undefined =
    filterOptions && filterValue
      ? filterOptions.find(
          (filterOption: OptionType) =>
            filterOption.value.toString() === filterValue ||
            filterOption.value === Number(filterValue),
        )
      : undefined

  return (
    <Dropdown
      onChange={(e: MultiValue<OptionType> | SingleValue<OptionType>) => {
        if (e === null) {
          onChange(id, '')
        } else {
          onChange(id, (e as OptionType).value.toString())
        }
      }}
      isClearable
      options={filterOptions}
      value={dropdownValue || null}
      componentSize="small"
      name={'filter-field-' + id}
      menuPortalTarget={document.body}
    />
  )
}
