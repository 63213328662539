import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

type SquareSkeletonProps = IContentLoaderProps & {
  width?: number | string
  height?: number | string
  borderRadius?: number
}

export const SquareSkeleton: React.FC<SquareSkeletonProps> = ({
  height = 64,
  width = 64,
  borderRadius = 4,
  ...props
}) => (
  <ContentLoader
    height={height}
    width={width}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect width={width} height={height} rx={borderRadius} ry={borderRadius} />
  </ContentLoader>
)
