import { NavigationCommandType } from 'containers/Command/types'

const moduleId = 'engagement'

enum EngagementSubmodule {
  Entities = 'entities',
  ServicePoints = 'service_points',
  Orders = 'orders',
  Pricing = 'pricing',
  Case = 'case',
  Contacts = 'contacts',
}

const defaultProps = {
  moduleId,
  type: 'navigation',
} as const

export const engagementRoutes: Array<NavigationCommandType> = [
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Entities,
    commandId: 'list-page',
    to: '/entities',
    label: 'pages.engagement.entity_management.title',
  },
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Entities,
    commandId: 'add-page',
    to: '/entities/add_entity',
    label: 'pages.engagement.entity_management.add.title',
  },
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.ServicePoints,
    commandId: 'list-page',
    to: '/service_points',
    label: 'pages.engagement.service_point_management.title',
  },

  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.ServicePoints,
    commandId: 'add-page',
    to: '/service_points/add_service_point',
    label: 'pages.engagement.service_point_management.add.title',
  },

  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Orders,
    commandId: 'list-page',
    to: '/orders',
    label: 'pages.engagement.order_management.title',
  },

  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Orders,
    commandId: 'add-page',
    to: '/orders/add_ad_hoc_order',
    label: 'pages.engagement.order_management.orders.add.title',
  },
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Orders,
    commandId: 'add-order-plan-page',
    to: '/order_plans/add_order_plan',
    label: 'pages.engagement.order_management.order_plans.add.title',
  },

  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Orders,
    commandId: 'service-availability-page',
    to: '/orders/service_availability',
    label: 'pages.engagement.order_management.service_availability.title',
  },
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Pricing,
    commandId: 'list-page',
    to: '/financial_details',
    label: 'pages.engagement.pricing_engine.title',
  },
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Case,
    commandId: 'list-page',
    to: '/cases',
    label: 'pages.engagement.case_management.title',
  },
  {
    ...defaultProps,
    submoduleId: EngagementSubmodule.Contacts,
    commandId: 'list-page',
    to: '/contacts',
    label: 'pages.engagement.contact_management.title',
  },
]
