import {
  borderRadius,
  color,
  fontSize,
  spacing,
  ThemeColors,
} from '@evrekadev/evreka-ui-components'
import styled, { css } from 'styled-components'
import { PhoneInputProps } from './PhoneInput'

export const StyledPhoneInput = styled.div<Omit<PhoneInputProps, 'name' | 'value'>>`
  width: 100%;
  #phone-input {
    width: 100%;
    ${borderRadius(8)};
    ${(props) =>
      props.componentSize === 'small'
        ? css`
            height: ${spacing(32)};
            ${fontSize('text_sm')};
          `
        : css`
            height: ${spacing(40)};
            ${fontSize('text_md')};
          `}
    line-height: 1.4;

    ${(props) =>
      !props.error
        ? css`
            &:focus {
              border: 1px solid ${ThemeColors.green500};
              color: ${ThemeColors.darkblue900};
            }
          `
        : css`
            border: 1px solid ${ThemeColors.red500};
            color: ${ThemeColors.red500};
            &:focus {
              border: 1px solid ${ThemeColors.red500};
              color: ${ThemeColors.red500};
            }
          `}

    color: ${(props) => (props.disabled ? color('grey400') : color('grey900'))};

    ${(props) =>
      props.disabled
        ? css`
            background-color: ${ThemeColors.grey100};
            border: 1px solid ${ThemeColors.grey300};
            color: ${ThemeColors.darkblue500};
            &:focus {
              border: 1px solid ${ThemeColors.grey300};
              color: ${ThemeColors.darkblue500};
            }
          `
        : null}
  }

  .flag-dropdown.open {
    ${borderRadius(8)};
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    .selected-flag.open {
      background: transparent;
    }
  }
  .country-list {
    position: fixed;
  }
  .selected-flag {
    ${borderRadius(8)};
  }

  .flag-dropdown {
    ${borderRadius(8)};
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    ${(props) =>
      props.error
        ? css`
            border: 1px solid ${ThemeColors.red500};
            color: ${ThemeColors.red500};
          `
        : props.isFocused &&
          css`
            border: 1px solid ${ThemeColors.green500};
            color: ${ThemeColors.darkblue900};
          `}
  }
`
