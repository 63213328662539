import React from 'react'
import { ColumnExtended, FilterTypes } from '@evrekadev/evreka-ui-components'
import { DefaultColumnFilter } from './filters/DefaultColumnFilter'
import { MultipleDropdownColumnFilter } from './filters/MultipleDropdownColumnFilter'
import { NumberColumnFilter } from './filters/NumberColumnFilter'
import { DatepickerColumnFilter } from './filters/DatepickerColumnFilter'
import { DateRangeSelectorColumnFilter } from './filters/DateRangeSelectorColumnFilter'
import { DropdownColumnFilter } from './filters/DropdownColumnFilter'
import { RangeColumnFilter } from './filters/RangeColumnFilter'

type FilterProps = {
  column: ColumnExtended<any>
  filterValue: string | string[] | [string, string]
  onChange: (id: string, value: string | string[]) => void
  type: string

  cellType?: string
  timezone?: string
}

export function Filter(props: FilterProps) {
  const { filterValue, type, cellType, timezone } = props

  switch (type) {
    case FilterTypes.NUMBER:
      return <NumberColumnFilter {...props} />
    case FilterTypes.DROPDOWN:
      return <DropdownColumnFilter {...props} filterValue={filterValue as string} />
    case FilterTypes.MULTIPLE_DROPDOWN:
      return <MultipleDropdownColumnFilter {...props} />
    case FilterTypes.DATE:
      return <DatepickerColumnFilter {...props} filterValue={filterValue as string} />
    case FilterTypes.DATE_RANGE:
      return (
        <DateRangeSelectorColumnFilter
          {...props}
          timezone={cellType === 'DATETIME_ISO' ? timezone : undefined}
          filterValue={filterValue as [string, string]}
        />
      )
    case FilterTypes.RANGE:
      return <RangeColumnFilter {...props} filterValue={filterValue as [string, string]} />
    default:
      return <DefaultColumnFilter {...props} filterValue={filterValue as string} />
  }
}
