import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LegendType } from './types'
import { Flex, Icon, Typography } from 'components'
import { UilAngleRight, UilAngleDown } from '@iconscout/react-unicons'
import styled, { css } from 'styled-components'
import { borderRadius, color, padding, shadow, SpacingV2 } from '@evrekadev/evreka-ui-components'
import L from 'leaflet'
import { useMap } from 'react-leaflet'

export type LegendProps = {
  legendItems: LegendType | LegendType[]
  position?: 'top' | 'bottom'
  isRtl?: boolean
  legendExtraSpace?: boolean
}

const LegendComponent = ({ legendItems, isRtl, legendExtraSpace, position }: LegendProps) => {
  const legends = Array.isArray(legendItems) ? legendItems : [legendItems]
  const [open, setOpen] = useState<Array<boolean>>(
    legends?.map((legendItem) => legendItem.isOpen ?? true),
  )
  const legendComponentRef = useRef<HTMLDivElement>(null)
  const currentMap = useMap()

  const onToggle = (event: React.MouseEvent<HTMLDetailsElement, MouseEvent>, index: number) => {
    event.preventDefault()
    setOpen((prevOpen) => {
      let newOpen = [...prevOpen]
      newOpen[index] = !newOpen[index]
      return newOpen
    })
  }

  useEffect(() => {
    if (legendComponentRef.current) {
      L.DomEvent.disableScrollPropagation(legendComponentRef.current)
      L.DomEvent.disableClickPropagation(legendComponentRef.current)
      if (legendComponentRef.current) {
        if (legendComponentRef.current) {
          legendComponentRef.current.addEventListener('mouseenter', () => {
            currentMap.dragging.disable()
            currentMap.doubleClickZoom.disable()
            currentMap.scrollWheelZoom.disable()
          })
          legendComponentRef.current.addEventListener('mouseleave', () => {
            currentMap.dragging.enable()
            currentMap.doubleClickZoom.enable()
            currentMap.scrollWheelZoom.enable()
          })
        }
      }
    }
  }, [legendComponentRef])

  return (
    <div ref={legendComponentRef}>
      <Wrapper
        legendExtraSpace={legendExtraSpace}
        position={position ?? 'bottom'}
        multiple={legends.length > 1}
        directionColumn="zero"
      >
        {legends.map((legendData, index) => (
          <Legend
            legend={legendData}
            onToggle={onToggle}
            open={open}
            isRtl={isRtl}
            key={index}
            index={index}
          />
        ))}
      </Wrapper>
    </div>
  )
}

const Legend: React.FC<{
  legend: LegendType
  onToggle: (event: React.MouseEvent<HTMLDetailsElement, MouseEvent>, index: number) => void
  open: boolean[]
  isRtl?: boolean
  index: number
}> = ({ legend, onToggle, open, isRtl, index }) => {
  const { header, isLoading, isOpen, items } = legend
  if (isLoading) {
    return null
  }

  if (header) {
    return (
      <div dir={isRtl ? 'rtl' : 'ltr'}>
        <details onClick={(event) => onToggle(event, index)} open={open[index]}>
          <summary>
            <Typography
              fontSize="text_xs"
              fontWeight="semibold"
              color="grey600"
              fontStyle="normal"
              variant="span"
            >
              {header}
            </Typography>
            <SpacingV2 mr={4} />
            <Icon icon={open[index] ? UilAngleRight : UilAngleDown} />
          </summary>
          <LegendContent items={items} />
        </details>
      </div>
    )
  }

  return (
    <div dir={isRtl ? 'rtl' : 'ltr'}>
      <LegendContent items={items} />
    </div>
  )
}

const LegendContent: React.FC<{ items: LegendType['items'] }> = ({ items }) => {
  return (
    <main onClick={(e) => e.stopPropagation()}>
      <Flex directionColumn="zero" gap={0.25}>
        {items?.map(({ text, icon }, i) => (
          <Flex key={`${i}-${text}`} middle="zero" gap={0.5}>
            {typeof icon === 'string' ? <Typography fontSize="text_xs">{icon}</Typography> : icon}

            <Typography fontSize="text_xs">{text}</Typography>
          </Flex>
        ))}
      </Flex>
    </main>
  )
}

export default LegendComponent

const Wrapper = styled(Flex)<{
  position?: 'top' | 'bottom'
  multiple: boolean
  legendExtraSpace?: boolean
}>`
  position: absolute;
  z-index: 500;
  background-color: ${color('white')};
  left: unset;
  right: ${(props) => (props.legendExtraSpace ? '1.8rem' : '0.625rem')};
  ${borderRadius(8)};
  ${shadow('xs')}
  background-color: ${color('white')};
  cursor: default;
  z-index: 500;
  overflow: hidden;

  ${(props) =>
    props.multiple
      ? css`
          bottom: 0.75rem;
        `
      : css`
          top: ${!props.position ? '0.75rem' : 'unset'};
          bottom: ${props.position === 'bottom' ? '0.75rem' : 'unset'};
        `}

  > * {
    details {
      summary {
        cursor: pointer;
        background: ${color('grey50')};
        display: flex;
        justify-content: space-between;
        align-items: center;
        ${padding([4, 8])}
        border-bottom: 1px solid ${color('grey200')};
      }

      &[open] {
        max-height: 37.5rem;
      }

      max-height: 31px;
      transition: max-height 0.4s ease-in-out;
      overflow: hidden;
    }

    main {
      ${padding([8])}
    }
  }
`
