import { Icon, InputText, Typography, Flex } from 'components'
import {
  ActionMenuItem,
  ControlledMenu,
  GetActionMenuProps,
  borderRadius,
  color,
  padding,
  shadow,
  useMenuState,
} from '@evrekadev/evreka-ui-components'
import { UilDirection } from '@iconscout/react-unicons'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientId, selectCurrentTimezone, selectCurrentUser } from 'services/slices/auth'
import { changeClientId } from 'services/slices/ui'
import { useNavigate } from 'react-router'
import moment from 'moment-timezone'

export const ClientSelection: React.FC = () => {
  const user = useSelector(selectCurrentUser)
  const clientId = useSelector(selectClientId)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const timezone = useSelector(selectCurrentTimezone)

  const buttonAnchor = useRef<HTMLButtonElement>(null)
  const [{ state }, open] = useMenuState({ unmountOnClose: true })
  const handleOnClick = () => {
    if (state === 'open' || state === 'opening') {
      //   open(false)
    } else {
      open(true)
    }
  }
  const handleOnClose = () => {
    open(false)
  }

  const [input, setInput] = useState('')

  const selectedClient = user?.clients.find(
    ({ value }) => value.toString() === clientId?.toString(),
  )

  const onChangeUser = (val: string) => {
    dispatch(changeClientId(val))
    // navigate to homepage when client changes
    navigate(`/`, { replace: true })
    window.location.reload()
  }

  const actions =
    user?.clients.map(({ label, value, timezone }) => ({
      label,
      onClick: () => onChangeUser(value.toString()),
      description: `${timezone} (${moment.tz(timezone).format('Z')})`,
    })) ?? []

  const filteredActions = actions?.filter((action) =>
    (action as any).label.toLowerCase().includes(input.toLowerCase()),
  )

  const logo = user?.partner_logo
  return (
    <>
      <ClientSelectorStyled
        isOpen={state !== 'closed'}
        ref={buttonAnchor}
        onClick={handleOnClick}
        data-testid="client-select"
      >
        <Flex gap={0.5} middle="zero">
          {logo && <ClientLogo src={logo} />}
          <Typography
            fontSize="text_sm"
            fontWeight="regular"
            color={selectedClient ? 'grey900' : 'grey400'}
          >
            {selectedClient?.label ?? 'Select client'}
          </Typography>
          {timezone && (
            <Typography
              style={{ margin: 0 }}
              fontSize="text_xs"
              fontWeight="regular"
              color={'grey400'}
            >
              ({moment.tz(timezone).format('Z')})
            </Typography>
          )}
        </Flex>
        <Icon icon={UilDirection} color="grey500" size={20} />
      </ClientSelectorStyled>

      <ControlledMenu
        menuStyle={{
          width: buttonAnchor.current?.offsetWidth,
          maxHeight: 600,
          overflow: 'auto',
        }}
        gap={4}
        anchorRef={buttonAnchor}
        onClose={handleOnClose}
        state={state}
      >
        <div style={{ padding: '0.25rem 0.5rem' }}>
          <InputText
            value={input}
            onChange={(e) => setInput(e.target.value)}
            autoFocus
            componentSize={'sm'}
            placeholder="Search..."
          />
        </div>
        <ActionMenuItem separator />
        {filteredActions?.map((action, i) => (
          <ActionMenuItem key={i} dataTestId={`client-option-${i}`} {...action} />
        ))}
      </ControlledMenu>
    </>
  )
}

const ClientSelectorStyled = styled.button<{ isOpen: boolean }>`
  all: unset;
  transition: all 0.3s;
  ${padding([6, 8])}
  ${borderRadius(8)}
  ${shadow('xs')}
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid ${color('grey300')};
  background-color: ${color('white')};
  min-width: 180px;
  cursor: pointer;
  ${({ isOpen }) => isOpen && `border: 1px solid ${color('green500')};`}
  &:hover {
    border: 1px solid ${color('green500')};
  }
`

const ClientLogo = styled.img<{ small?: boolean }>`
  /* width: 24px; */
  height: 22px;
  object-fit: cover;
  border-radius: 4px;
`
