import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { sendNotification } from 'components'
import {
  AddCaseRequest,
  CaseCustomFieldsRequest,
  CaseDetailRequest,
  CaseDetailResponse,
  CaseListRequest,
  CaseListResponse,
  CommentListRequest,
  CommentListResponse,
  CustomFieldEngResponse,
  DeleteCommentRequest,
  ExportRequest,
  MutationResponse,
  TableResponseWithTimezone,
  UpdateStatusRequest,
} from './types'
import { getExportHandler, getImportHandler } from 'utils'
import { TableRequestV2 } from 'services/types'

export const caseApi = createApi({
  reducerPath: 'caseApi',
  tagTypes: ['Cases', 'Comments', 'CaseDetail', 'CaseHistory'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/engagement/'}`,
  }),
  endpoints: (builder) => ({
    getCases: builder.query<CaseListResponse, CaseListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/case/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Cases'],
    }),
    getComments: builder.query<CommentListResponse, CommentListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/comment/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Comments'],
    }),
    getCaseDetail: builder.query<CaseDetailResponse, CaseDetailRequest>({
      query: ({ client_id, case_id }) => ({
        url: `clients/${client_id}/case/?case_id=${case_id}`,
        method: 'GET',
      }),
      providesTags: ['CaseDetail'],
    }),
    getCaseDynamicFields: builder.query<CustomFieldEngResponse, CaseCustomFieldsRequest>({
      query: ({ client_id, case_type_id }) => ({
        url: `clients/${client_id}/case_dynamic_fields/?case_type_id=${case_type_id}`,
        method: 'GET',
      }),
    }),
    addCase: builder.mutation<MutationResponse, AddCaseRequest>({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/case/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Cases', 'CaseHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editCase: builder.mutation<MutationResponse, AddCaseRequest & { case_id: string }>({
      query: ({ client_id, body, case_id }) => ({
        url: `clients/${client_id}/case/${case_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Cases', 'CaseDetail', 'CaseHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportCase: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/case/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    addComment: builder.mutation<MutationResponse, AddCaseRequest>({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/comment/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Comments'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editComment: builder.mutation<MutationResponse, AddCaseRequest & { comment_id: string }>({
      query: ({ client_id, body, comment_id }) => ({
        url: `clients/${client_id}/comment/${comment_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Comments'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteComment: builder.mutation<MutationResponse, DeleteCommentRequest>({
      query: ({ client_id, comment_id }) => ({
        url: `clients/${client_id}/comment/${comment_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Comments', 'CaseDetail'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    importCases: builder.mutation<MutationResponse, { clientId: string; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/case/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Cases'],
      onQueryStarted: getImportHandler,
    }),
    exportCases: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/case/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    caseHistory: builder.query<
      TableResponseWithTimezone<{}>,
      TableRequestV2 & {
        case_id: string
      }
    >({
      query: ({ client_id, case_id, ...data }) => ({
        url: `clients/${client_id}/case/${case_id}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['CaseHistory'],
    }),
    updateCaseStatus: builder.mutation<MutationResponse, UpdateStatusRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/status/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Cases', 'CaseDetail', 'CaseHistory'],
    }),
    deleteCase: builder.mutation<
      MutationResponse,
      {
        client_id: string
        case_id: string
      }
    >({
      query: ({ client_id, case_id }) => ({
        url: `clients/${client_id}/case/${case_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Cases'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const {
  useGetCasesQuery,
  useGetCommentsQuery,
  useGetCaseDetailQuery,
  useGetCaseDynamicFieldsQuery,
  useAddCaseMutation,
  useEditCaseMutation,
  useAddCommentMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useImportCasesMutation,
  useExportCasesMutation,
  useCaseHistoryQuery,
  useUpdateCaseStatusMutation,
  useDeleteCaseMutation,
} = caseApi
