import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  ServicePointEditResponse,
  AssetsRequest,
  AssetsResponse,
  AssigneesRequest,
  AssigneesResponse,
  OperationResponse,
  ServicePointDetailResponse,
  ShiftsResponse,
  TaskOrdersRequest,
  TaskOrdersResponse,
  TaskTemplatesRequest,
  TaskTemplatesResponse,
  ServicePointDetailRequest,
  AssetTypeResponse,
  AssetTypeRequest,
  SearchAssetResponse,
  SearchAssetRequest,
  ServicePointViewResponse,
  TaskTemplateStepsRequest,
  TaskTemplateStepsResponse,
  TaskOrderDeleteRequest,
  TaskOrderDeleteResponse,
  OperationItemTypeRequest,
  OperationItemTypeResponse,
  DeleteServicePointResponse,
  DeleteServicePointRequest,
  ServicePointLocationResponse,
  ServicePointLocationRequest,
  MutationResponse,
  ExportRequest,
  ServicePointUpsertRequest,
  GeomapsResponse,
  GeomapsRequest,
  DumpLocationsRequest,
  DumpLocationsResponse,
  OperationRequest,
  ServicePointCalendarDetailRequest,
  ActionNoteRequest,
  ServicePointMapPopupDetailsRequest,
  ServicePointMapPopupDetailsResponse,
  SPMapSettingsResponse,
  SearchSPByParamsResponse,
  SearchSPByParamsRequest,
  DetachAssetFromSPRequest,
  GetServicePointOperationsResponse,
  GetServicePointOperationsRequest,
  MandatoryAssetSummaryRequest,
  MandatoryAssetSummaryResponse,
  CalendarResponse,
  CalendarDetailResponse,
  CalendarDetailTransformedResponse,
  ServicepointCalendarListRequest,
} from './types'

import {
  ActionResponse,
  TableRequest,
  TableResponse,
  MapPointsRequest,
  MapPointsResponse,
  ContentFieldsResponse,
  ContentFieldsRequest,
  ServicePointMapPopupDetailResponse,
  TableResponseV2,
  TableRequestV2,
} from 'services/types'
import { providesList } from 'services/util'
import { getExportHandler, getImportHandler } from 'utils'
import { staticTourApi } from './staticTours'
import { opsGeneralApi } from './opsGeneral'
import { AssetChangeStatusResponse } from 'services/AssetManagement/types'
import { assetApi } from 'services/AssetManagement'
import { EventsCellType } from '@evrekadev/evreka-ui-components'
import { servicePointApi as engServicePointApi } from 'services/Engagement'
import { taskApi } from './tasks'

export type CategoriesResponse = Array<{
  id: string
  label: string
  totalEventsNumber: number
}>

const PREFIX = 'pages.ops_management.toast.'

export type AddServicePointRequest = {
  name: string
  latitude: number
  longitude: number
  note: string
  operations: Array<number>
  region: number
}

//TODO: update types
export const servicePointApi = createApi({
  reducerPath: 'servicePointApi',
  tagTypes: ['ServicePoints', 'ServicePointDetail', 'Assets', 'TaskPlans', 'History', 'Shifts'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getServicePoint: builder.query<ServicePointDetailResponse, ServicePointDetailRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_service_point_by_id/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ServicePointDetail'],
    }),
    getAssets: builder.query<AssetsResponse, AssetsRequest>({
      query: ({ clientId, service_point, route_id, ...data }) => ({
        url: `clients/${clientId}/asset_management/list_asset/`,
        method: 'POST',
        data,
        params: {
          service_point: service_point,
          route_id: route_id,
        },
      }),
      providesTags: ['Assets'],
    }),
    detachAssetFromSP: builder.mutation<AssetChangeStatusResponse, DetachAssetFromSPRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/asset_management/change_status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Assets'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          !data.error_list?.length &&
            sendNotification({
              type: 'success',
              toastContent: data.detail.message,
            })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getMandatoryAssetSummary: builder.query<
      MandatoryAssetSummaryResponse,
      MandatoryAssetSummaryRequest
    >({
      query: ({ clientId, route_id }) => ({
        url: `clients/${clientId}/ops_management/route/get_route_assets_info/?route_id=${route_id}`,
        method: 'GET',
      }),
      providesTags: ['Assets'],
    }),
    getOperations: builder.query<OperationResponse, number>({
      query: (clientId) => ({
        url: `clients/${clientId}/ops_management/get_operations/`,
        method: 'GET',
      }),
    }),
    getOperationsBySpId: builder.query<OperationResponse, OperationRequest>({
      query: ({ clientId, service_points }) => {
        let queryStr = ''
        if (service_points) {
          const queryParamValue =
            service_points instanceof Array ? `[${service_points}]` : service_points
          queryStr += `?service_points=${queryParamValue}`
        }
        return {
          url: `clients/${clientId}/ops_management/get_operations/${queryStr}`,
          method: 'GET',
        }
      },
    }),
    getOperationsWithFilter: builder.query<OperationResponse, OperationRequest>({
      query: ({ clientId, filter }) => ({
        url: `clients/${clientId}/ops_management/get_operations/?${filter}`,
        method: 'GET',
      }),
    }),
    getContentFields: builder.query<ContentFieldsResponse, ContentFieldsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_service_point_content_fields/`,
        method: 'POST',
        data: {
          ...data,
        },
      }),
    }),
    getTaskTemplates: builder.query<TaskTemplatesResponse, TaskTemplatesRequest>({
      query: ({ clientId, operationId, isAdHoc }) => {
        const queryParam = isAdHoc ? `&location=adhoc` : ``
        return {
          url: `clients/${clientId}/ops_management/get_task_templates/?operation=${operationId}${queryParam}`,
          method: 'GET',
        }
      },
    }),
    getShifts: builder.query<ShiftsResponse, number>({
      query: (clientId) => ({
        url: `clients/${clientId}/ops_management/get_shifts/`,
        method: 'GET',
      }),
      providesTags: ['Shifts'],
    }),
    getGeomaps: builder.query<GeomapsResponse, GeomapsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/geomap/search/`,
        method: 'POST',
        data: {
          ...data,
          query_param: '',
        },
      }),
    }),
    getDumpLocations: builder.query<DumpLocationsResponse, DumpLocationsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/dump_location/search/`,
        method: 'POST',
        data,
      }),
    }),
    getServicePoints: builder.query<TableResponse<EventsCellType>, TableRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_service_points/`,
        method: 'POST',
        data,
      }),
      providesTags: (result) => providesList(result?.data, 'ServicePoints'),
    }),
    addServicePoint: builder.mutation<ActionResponse, ServicePointUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/create_service_point/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['ServicePoints'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editServicePoint: builder.mutation<ServicePointEditResponse, ServicePointUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/edit_service_point/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['ServicePoints', 'ServicePointDetail', 'History'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(
            engServicePointApi.util.invalidateTags([
              'ServicePoints',
              'ServicePointDetail',
              'ServicePointHistory',
            ]),
          )
          dispatch(
            staticTourApi.util.invalidateTags([
              'StaticTourDetail',
              'SpItineraryList',
              'KmlList',
              'StaticTours',
            ]),
          )
          dispatch(
            taskApi.util.invalidateTags(['TaskTransitionDetailPage', 'TaskTransitionDetails']),
          )
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),

    getMapPoints: builder.query<ServicePointMapPopupDetailResponse, MapPointsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_service_points_for_map/`,
        method: 'POST',
        data,
      }),
      providesTags: (result) => providesList(result, 'ServicePoints'),
    }),
    getAssignees: builder.query<AssigneesResponse, AssigneesRequest>({
      query: ({ clientId, route_plan, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_assignees_by_operation/${
          route_plan ? '?route_plan=' + route_plan : ''
        }`,
        method: 'POST',
        data,
      }),
    }),
    getTaskTemplateSteps: builder.query<TaskTemplateStepsResponse, TaskTemplateStepsRequest>({
      query: ({ clientId, task_template_id }) => ({
        url: `clients/${clientId}/ops_management/get_task_template_steps/?task_template=${task_template_id}`,
        method: 'GET',
      }),
    }),
    getOperationItemTypes: builder.query<OperationItemTypeResponse, OperationItemTypeRequest>({
      query: ({ clientId, operation_id, operation_id_list }) => ({
        url: `clients/${clientId}/ops_management/get_operation_item_types/`,
        method: 'GET',
        params: {
          operation: operation_id,
          operation_id_list: operation_id_list ? JSON.stringify(operation_id_list) : undefined,
        },
      }),
    }),
    getAssetTypes: builder.query<AssetTypeResponse, AssetTypeRequest>({
      query: ({ clientId }) => ({
        url: `clients/${clientId}/asset_management/get_asset_types/`,
        method: 'GET',
      }),
    }),
    searchAsset: builder.mutation<SearchAssetResponse, SearchAssetRequest>({
      // FIXME: this request should be removed and all of its use places should be updated accordingly!
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/asset_management/search_asset/`,
        method: 'POST',
        data,
      }),
    }),
    getServicePointOperations: builder.query<
      GetServicePointOperationsResponse,
      GetServicePointOperationsRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_operations_by_service_points/`,
        method: 'POST',
        data,
      }),
    }),
    getServicePointView: builder.query<ServicePointViewResponse, ServicePointDetailRequest>({
      query: ({ clientId, servicePointId }) => ({
        url: `clients/${clientId}/ops_management/get_edit_service_point_fields/?service_point=${servicePointId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { servicePointId }) => [
        { type: 'ServicePoints', id: servicePointId },
      ],
    }),
    deleteServicePoint: builder.mutation<DeleteServicePointResponse, DeleteServicePointRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/delete_service_point/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['ServicePoints', 'ServicePointDetail'],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          dispatch(staticTourApi.util.invalidateTags(['StaticTourDetail', 'SpItineraryList']))
          dispatch(
            engServicePointApi.util.invalidateTags([
              'ServicePoints',
              'ServicePointDetail',
              'ServicePointHistory',
            ]),
          )
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getServicePointLocations: builder.query<
      ServicePointLocationResponse,
      ServicePointLocationRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_service_point_locations/`,
        method: 'POST',
        data,
      }),
    }),
    exportServicePoint: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/export/service_point/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    importServicePoint: builder.mutation<MutationResponse, { client_id: string; body: FormData }>({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/import/service_point/`,
        method: 'POST',
        data: body,
      }),
      onQueryStarted: getImportHandler,
    }),
    getServicePointCalendar: builder.query<CalendarResponse, ServicepointCalendarListRequest>({
      query: ({ client_id, servicePointId, start_date, end_date }) => ({
        url: `clients/${client_id}/ops_management/service_point/calendar/list/?service_point_id=${servicePointId}&start_date=${start_date}&end_date=${end_date}`,
        method: 'GET',
      }),
    }),
    getServicePointCalendarDetails: builder.query<
      CalendarDetailTransformedResponse,
      ServicePointCalendarDetailRequest
    >({
      query: ({ client_id, servicePointId, date }) => ({
        url: `clients/${client_id}/ops_management/service_point/calendar/date_detail/?service_point_id=${servicePointId}&date=${date}`,
        method: 'GET',
      }),
      transformResponse(res: CalendarDetailResponse) {
        return {
          data: res.data.map((item) => ({
            name: item.operation_name,
            type: item.operation_type.toString(),
          })),
        }
      },
    }),
    getServicePointMapSettings: builder.query<SPMapSettingsResponse, number | undefined>({
      query: (client_id) => ({
        url: `clients/${client_id}/ops_management/get_service_points_map/settings/`,
        method: 'GET',
      }),
    }),
    getServicePointMapPopupDetails: builder.query<
      ServicePointMapPopupDetailsResponse,
      ServicePointMapPopupDetailsRequest
    >({
      query: ({ clientId, servicePointId, fields }) => ({
        url: `clients/${clientId}/ops_management/get_service_points_map/detail/${servicePointId}/${
          fields ? `?fields=${fields.join(',')}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { servicePointId }) => [
        { type: 'ServicePointDetail', id: servicePointId },
      ],
    }),
    searchServicePointsByParams: builder.mutation<
      SearchSPByParamsResponse,
      SearchSPByParamsRequest
    >({
      queryFn: async (arg, api) => {
        try {
          const res = await api.dispatch(
            opsGeneralApi.endpoints.genericSearchByParams.initiate({
              clientId: arg.clientId,
              model_name: 'servicepoint',
              requested_values: [
                'name',
                'latitude',
                'longitude',
                'is_active',
                'operations__id',
                'type',
              ],
              search_fields: [...(arg.operations?.length ? ['operations__id'] : []), 'name'],
              search_queries: [...(arg.operations?.length ? [arg.operations] : []), arg.name || ''],
              filter_expressions: [
                ...(arg.operations?.length ? ['in'] : []),
                arg.exact ? 'exact' : 'icontains',
              ],

              order_by: 'id',
              requested_labels: ['name'],
            }),
          )

          return res
        } catch (err) {}

        return { data: [] }
      },
      onQueryStarted: getImportHandler,
    }),
    getMandatoryItemsOfServicePoints: builder.query<
      {
        data: Array<{
          service_point_id: number
          default_items: Array<{
            operation_id: number
            label: string
            value: number
          }>
        }>
      },
      { clientId: number; service_points: Array<number> }
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_default_items_by_service_points/`,
        method: 'POST',
        data,
      }),
    }),
    getOpsServicePointHistory: builder.query<
      TableResponseV2<{}>,
      TableRequestV2<number> & { service_point_id: number }
    >({
      query: ({ client_id, service_point_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/service_point_history/`,
        method: 'POST',
        data: {
          service_point_id,
          ...data,
        },
      }),
      providesTags: ['History'],
    }),
  }),
})

export const {
  useAddServicePointMutation,
  useEditServicePointMutation,
  useGetContentFieldsQuery,
  useGetServicePointQuery,
  useGetAssetsQuery,
  useGetOperationsQuery,
  useGetOperationsBySpIdQuery,
  useGetOperationsWithFilterQuery,
  useGetTaskTemplatesQuery,
  useGetShiftsQuery,
  useGetGeomapsQuery,
  useGetDumpLocationsQuery,
  useGetServicePointsQuery,
  useGetMapPointsQuery,
  useDetachAssetFromSPMutation,
  useSearchAssetMutation,
  useGetServicePointOperationsQuery,
  useGetServicePointViewQuery,
  useGetAssigneesQuery,
  useGetTaskTemplateStepsQuery,
  useGetOperationItemTypesQuery,
  useGetAssetTypesQuery,
  useDeleteServicePointMutation,
  useGetServicePointLocationsQuery,
  useGetServicePointCalendarQuery,
  useGetServicePointCalendarDetailsQuery,
  useGetServicePointMapPopupDetailsQuery,
  useGetServicePointMapSettingsQuery,
  useSearchServicePointsByParamsMutation,
  useGetMandatoryAssetSummaryQuery,
  useGetMandatoryItemsOfServicePointsQuery,
  useGetOpsServicePointHistoryQuery,
} = servicePointApi
