import React, { useState } from 'react'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  ThemeColorKeys,
  SizeKeys,
  TopSection,
  BottomSection,
} from '@evrekadev/evreka-ui-components'
import { StyledPhoneInput } from './PhoneInput.style'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'utils/useTranslation'

export type PhoneInputValue = { value: string; country_format_len?: number }

export type PhoneInputProps = {
  labelRequired?: boolean
  label?: string
  error?: boolean
  componentSize?: SizeKeys
  errorText?: string
  helperText?: string
  disabled?: boolean
  helperTextColor?: ThemeColorKeys
  isFocused?: boolean
  countryCode?: string
  icon?: React.ReactNode
  handleChange?: (value: string, country: any, e: any, formattedValue: string | any[]) => void
  countryDropdownPositionAbsolute?: boolean
  name: string
  value?: string
}

const PhoneInputWrapper: React.FC<PhoneInputProps> = ({
  labelRequired,
  label,
  error,
  componentSize = 'large',
  errorText,
  helperText,
  disabled,
  countryCode,
  countryDropdownPositionAbsolute,
  name,
  value,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const { t } = useTranslation('placeholder.default')
  const { t: tPhone } = useTranslation(
    'pages.engagement.entity_management.details.contacts_tab.form',
  )

  const { trigger, setValue } = useFormContext()

  return (
    <>
      <TopSection label={label} required={labelRequired}></TopSection>
      <StyledPhoneInput
        componentSize={componentSize}
        errorText={errorText}
        helperText={helperText}
        error={error}
        isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        <PhoneInput
          enableSearch
          disableSearchIcon
          inputProps={{
            id: 'phone-input',
          }}
          disabled={disabled}
          country={countryCode || undefined}
          value={value}
          onChange={(
            value: string,
            country: CountryData,
            e: React.ChangeEvent<HTMLInputElement>,
            formattedValue: string,
          ) => {
            const countryCodeLen = country?.format?.slice(1)?.length ?? 0
            setValue(name, {
              value: formattedValue.slice(1) as string,
              country_format_len: countryCodeLen,
            })
            trigger(name)
          }}
          searchStyle={{ width: '95%' }}
          searchPlaceholder={t('search')}
          dropdownStyle={countryDropdownPositionAbsolute ? { position: 'absolute' } : undefined}
        />
      </StyledPhoneInput>
      <BottomSection helperText={helperText} errorText={error ? errorText : undefined} />
    </>
  )
}

export default PhoneInputWrapper
