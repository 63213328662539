import { Flex, Grid, GridCell, Typography } from 'components'
import { WelcomeBannerStyled } from 'containers/WelcomeBanner/WelcomeBanner.styled'
import React from 'react'
import cityIllustration from 'assets/png/illustrations.png'
import { useTranslation } from 'utils/useTranslation'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'services/slices/auth'

type WelcomeBannerProps = {}

export const WelcomeBanner: React.FC<React.PropsWithChildren<WelcomeBannerProps>> = () => {
  const { t } = useTranslation('pages.home')
  const user = useSelector(selectCurrentUser)

  return (
    <WelcomeBannerStyled>
      <Grid columns={2}>
        <GridCell>
          <div style={{ paddingLeft: 48, position: 'absolute', bottom: 48 }}>
            <Typography fontSize="header_xs" fontWeight="bold">
              {user && t('welcome_back', { id: user.username })}
            </Typography>
            <Typography variant="span">{t('have_a_nice_day')}</Typography>
          </div>
        </GridCell>
        <GridCell>
          <Flex right="zero">
            <img src={cityIllustration} />
          </Flex>
        </GridCell>
      </Grid>
    </WelcomeBannerStyled>
  )
}
