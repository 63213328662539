import React, { ComponentPropsWithoutRef, useContext, useEffect, useState } from 'react'
import { SidebarStyled, SidebarInnerStyled, SidebarLayoutStyled } from './Sidebar.style'
import { ToggleButton } from './ToggleButton'
import { useDispatch, useSelector } from 'react-redux'
import { selectUi, toggleSidebarCollapsed } from 'services/slices/ui'
import { selectCurrentUser } from 'services/slices/auth'

export type SidebarColorTheme = 'dark' | 'light'
export type SidebarContextProps = {
  collapsed: boolean
  toggleCollapse: () => void
  rtl?: boolean
}

export type SidebarBaseProps = {
  sidebarTheme: SidebarColorTheme
  sidebarCollapsed: boolean
}

export type SidebarProps = ComponentPropsWithoutRef<'div'>

export const Sidebar = React.forwardRef<unknown, SidebarProps>(({ children, ...rest }, ref) => {
  const { sidebarTheme, sidebarCollapsed } = useSelector(selectUi)
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user?.customer_portal_credentials != null) {
      dispatch(toggleSidebarCollapsed(true))
    }
  }, [user])

  const sidebarRef: React.RefObject<HTMLDivElement> =
    (ref as any) || React.createRef<HTMLDivElement>()

  return (
    <>
      {user?.customer_portal_credentials == null && <ToggleButton />}
      <SidebarStyled
        {...rest}
        ref={sidebarRef}
        sidebarCollapsed={sidebarCollapsed}
        role="sidebar"
        sidebarTheme={sidebarTheme}
      >
        <SidebarInnerStyled sidebarCollapsed={sidebarCollapsed} sidebarTheme={sidebarTheme}>
          {children}
        </SidebarInnerStyled>
      </SidebarStyled>
    </>
  )
})
