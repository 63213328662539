import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const BreadcrumbSkeleton = (props: IContentLoaderProps) => (
  <ContentLoader
    viewBox="0 0 85 22"
    height={22}
    width={85}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="85" height="22" />
  </ContentLoader>
)
