import React, { useState } from 'react'
import { InputV2, InputPropsV2 } from '@evrekadev/evreka-ui-components'
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons'

const InputPassword = React.forwardRef<HTMLInputElement, InputPropsV2>((props, ref) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <InputV2
      type={isVisible ? 'text' : 'password'}
      extraButtons={[
        {
          onClick: () => setIsVisible(!isVisible),
          iconRight: isVisible ? UilEyeSlash : UilEye,
          onlyIcon: true,
        },
      ]}
      ref={ref}
      {...props}
    />
  )
})

export default InputPassword
