import React, { useEffect } from 'react'
import { useTranslation } from 'utils/useTranslation'
import { Grid, GridCell, InputText, Modal } from 'components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'
import { ModalSkeleton } from 'components/Skeletons'
import { PhoneInput } from 'containers/PhoneInputContainer'
import {
  useCheckCustomerPortalProfile,
  useCheckCustomerPortalProfilePhoneValidity,
} from 'utils/useCheckCustomerPortalInfo'
import { PhoneInputValue } from 'containers/PhoneInputContainer/PhoneInput'

type UpdateProfileModalProps = {
  closeModal: () => void
  isOpen: boolean
}
type FormInputs = {
  name: string
  surname: string
  phone?: PhoneInputValue
}
const schema = yup.object().shape({
  name: yup.string().required('required.name'),
  surname: yup.string().required('required.surname'),
  phone: yup.object().test('phone', 'invalid.phone', (value) => {
    if (!value?.value || value.country_format_len === 0) return true
    if ((value.value as string)?.length !== value.country_format_len) {
      return false
    }

    return true
  }),
})
export const CustomerPortalUpdateProfileModal: React.FC<
  React.PropsWithChildren<UpdateProfileModalProps>
> = ({ closeModal, isOpen }) => {
  const { t: tGlobal } = useTranslation()
  const { t: tCustomerPortal } = useTranslation(
    'pages.engagement.entity_management.details.customer_portal',
  )
  const { t } = useTranslation('pages.engagement.contact_management.form')

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = methods

  const { client_id, contact_id } = useCheckCustomerPortalProfile()
  const { editContactService, env, contactDetails, isPhoneValid, isPhoneValidationLoading } =
    useCheckCustomerPortalProfilePhoneValidity({
      client_id,
      contact_id,
    })

  useEffect(() => {
    if (contactDetails.data) {
      const { name, surname, phone } = contactDetails.data
      reset({
        name: name.value,
        surname: surname.value,
        phone: { value: phone.value },
      })
    }
  }, [contactDetails.data])

  const onSubmit = (data: FormInputs) => {
    if (!client_id || !contact_id) {
      return
    }
    let body = {
      name: data.name,
      surname: data.surname,
      phone: data.phone?.value,
    }
    editContactService({
      clientId: client_id,
      body,
      contact_id,
    })
      .unwrap()
      .then(closeModal)
  }

  useEffect(() => {
    !isOpen && reset()
  }, [isOpen])

  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        heading={tCustomerPortal('update_profile')}
        formProps={{ onSubmit: handleSubmit(onSubmit) }}
        isPrimaryActionDisabled={!isPhoneValid}
        componentSize="small"
      >
        {contactDetails.isLoading || env.isLoading ? (
          <ModalSkeleton count={8} />
        ) : (
          <Grid gap={1} columns={1}>
            <GridCell width={1}>
              <Controller
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('name')}
                    error={!!errors.name}
                    errorText={errors.name && t((errors.name as FieldError).message ?? '')}
                    labelRequired
                  />
                )}
                control={control}
                name="name"
              />
            </GridCell>
            <GridCell width={1}>
              <Controller
                render={({ field }) => (
                  <InputText
                    {...field}
                    label={t('surname')}
                    error={!!errors.surname}
                    errorText={errors.surname && t((errors.surname as FieldError).message ?? '')}
                    labelRequired
                  />
                )}
                control={control}
                name="surname"
              />
            </GridCell>

            <GridCell width={1}>
              <Controller
                render={({ field }) => (
                  <PhoneInput
                    label={t('phone')}
                    name={field.name}
                    value={field.value?.value}
                    error={!!errors.phone}
                    componentSize="medium"
                  />
                )}
                control={control}
                name="phone"
              />
            </GridCell>
          </Grid>
        )}
      </Modal>
    </FormProvider>
  )
}
