import { BoundFilter } from 'components/Map/utils'
import {
  AttachmentType,
  FilterRequestList,
  MandateAndPick,
  SortRequest,
  TableSettingsRequest,
} from '@evrekadev/evreka-ui-components'
import { GenericResponse, TableRequest, TableRequestV3, TableResponse } from 'services/types'
import { MapSettingsOption } from 'components/Map/MapSettingsModal/types'
import { DropdownOptionTemplate } from 'types/common'

export type MapSettingsLegendType = 'status' | 'last_data_time' | 'speed'

export type FleetFilter =
  | {
      vehicle_id?: number
      source_type?: number
      recorded_at?: {
        start: string
        end: string
      }
    }
  | undefined

export type FleetFilterV2 =
  | {
      vehicle_id?: string
      source_type?: number
      recorded_at?: {
        start: string
        end: string
      }
    }
  | undefined

export type FleetRequest = {
  clientId?: number
  filter: FleetFilter
  map?: BoundFilter
}

export type FleetRequestV2 = {
  fleetClientId?: string
  filter: FleetFilterV2
  map?: BoundFilter
  legendBy?: MapSettingsLegendType
}

export enum CarStatus {
  'ON_THE_MOVE',
  'STEADY',
  'NO_DATA_LT_24_HOURS',
  'NO_DATA_GT_24_HOURS',
}

export enum CarIcons {
  'REAR_LOADER' = 1,
  'FRONT_LOADER' = 2,
  'SIDE_LOADER' = 3,
  'ROLL_OFF' = 4,
  'COMPACTOR' = 5,
  'CRANE_TRUCK' = 6,
  'EMPLOYEE' = 7,
  'BIN' = 8,
}

export type FleetResponse = Array<{
  record_id: string
  latitude: number
  longitude: number
  speed: number
  accuracy: number
  vehicle: {
    relational_id: number
    customer_vehicle_id: number
    plate: string
  }
  source: {
    relational_id: number
    type: number
    name: string
  }
  status: CarStatus
  recorded_at: string
}>

export type FleetMarkerResponse = {
  record_id: string
  latitude: number
  longitude: number
  speed: number
  accuracy: number
  vehicle: {
    relational_id: string
    customer_vehicle_id: number
    plate: string
    icon: number
    status: {
      color: string
      name: string
    }
  }
  source: {
    relational_id: number
    type: number
    name: string
  }
  recorded_at: string
}

export type FleetResponseV2 = Array<FleetMarkerResponse & { legend: { color: string } }>

export type VehicleRequest = {
  clientId?: number | string
  vehicle: string
}

export type VehicleResponse = Array<{
  value: string
  label: string
}>

export type ExportVehicleDevicesRequest = {
  clientId: string
  vehicleId: string
  filters: FilterRequestList
  sort: SortRequest
  columns?: TableSettingsRequest
}

export type InfoRequest = {
  clientId: number
}

export type InfoResponse = {
  client_id: string
  vehicle_limit: number
  vehicle_count: number
  features: {
    [key: string]: any
  }
}

export type SnapshotByPlateRequest = {
  fleetClientId: string
  plate: string
  fields?: string[]
}

type SnapshotByPlateDetails = {
  label: string | null
  value: string | null
  type: string
}

export type SnapshotByPlateResponse = {
  details: SnapshotByPlateDetails[]
  status: {
    label: string | null
    value: number | null
    type: string
  }
  plate: {
    label: string | null
    value: string | null
    type: string
  }
  last_update: {
    label: string | null
    value: string | null
    type: string
  }
  vehicle_id: {
    label: string | null
    value: string | null
    type: string
  }
}

export type SnapshotMapSettingsRequest = {
  fleetClientId: string
}

export type VehicleTypesRequest = {
  fleetClientId: string
}

export type VehicleTypesResponse = Array<{
  value: string
  label: string
}>

export type VehicleStatusRequest = {
  fleetClientId: string
  statusId?: string
  isEditMode?: boolean
}

export type VehicleStatusResponse = Array<{
  value: string
  label: string
}>

export type VehicleChangeStatusRequest = {
  fleetClientId: string
  vehicle_status_id: string
  vehicle_id: string
  note: string
}

export type VehicleChangeStatusResponse = GenericResponse

export type VehicleEditDetailResponse = {
  fingerprint: string
  attachments: Array<AttachmentType>
  plate: string
  vehicle_type: string
  status: string
  brand: string
  model: string
  chasis_number: string
  last_hourmeter: string
  supplier: string
  next_maintenance_km: string
  engine_number: string
  last_odometer: string
  next_maintenance_date: string
  date_of_purchase: string
  last_maintenance_date: string
  dynamic: Object
}

export type VehicleDetailRequest = {
  fleetClientId: string
  vehicleId: string
}

export type VehicleAddRequest = {
  fleetClientId: string
  body: FormData
}

export type VehicleAddResponse = {
  detail: { message: string }
  success: boolean
}

export type VehicleEditRequest = {
  fleetClientId: string
  body: FormData
}

export type VehicleEditResponse = {
  detail: { message: string }
  success: boolean
}

export type VehicleDeleteRequest = {
  fleetClientId: string
  vehicleId: string
}

export type VehicleDeleteResponse = {
  detail: { message: string }
  success: boolean
}

export type VehicleExportResponse = {
  detail: { message: string }
  success: boolean
}

export type VehicleExportRequest = {
  clientId: string
  filters: FilterRequestList
  sort: SortRequest
  columns?: TableSettingsRequest
}

export type ActivitiesExportResponse = {
  detail: { message: string }
  success: boolean
}

export type ActivitiesExportRequest = {
  clientId: string
  type: string
  filters: FilterRequestList
  sort: SortRequest
  columns?: TableSettingsRequest
}

export type DeviceLogsExportRequest = {
  fleetClientId: string
  device_fingerprint: string
  start_date: string
  end_date: string
}

export type VehicleDetailResponse = {
  details: Array<{
    key: string
    label: string
    value: any
    type: string
  }>
}

export type VehicleEditDetailRequest = {
  fleetClientId: string
  vehicleId: string | undefined
}

export type UpsertDeviceRequest = {
  fleetClientId: string
  device_id: string
  vehicle_id: string
  use_types: Array<{
    value: number
    is_checked: boolean
    is_primary: boolean
  }>
}

export type GetVehicleDeviceDetailsRequest = {
  fleetClientId: string
  vehicleId: string
  deviceId: string
}
export type GetVehicleDeviceDetailsResponse = {
  device_type: {
    value: number
    label: string
  }
  device_name: {
    value: string
    label: string
  }
  use_fors: Array<{
    id: number
    is_checked: boolean
    is_primary: boolean
    label: string
  }>
}

export type DeleteDeviceRequest = {
  fleetClientId: string
  vehicleId: string
  device_id: string
}

export type GenericSuccessResponse = {
  detail: { message: string }
  success: boolean
}

export type DevicesRequest = {
  clientId: string
  device_type: number
}

export type DevicesResponse = Array<{
  fingerprint: string
  id: string
  name: string
  device_type: number
  use_types: Array<{
    name: string
    use_type: { id: number; name: string }
  }>
}>

export type DevicesByIdRequest = Omit<TableRequest, 'clientId'> & {
  fleetClientId: string
  vehicleId: string
}

export type DevicesByIdResponse = TableResponse<{}>

export type VehicleDeviceTypesRequest = {}
export type VehicleDeviceTypesResponse = DropdownOptionTemplate

export type VehicleHistoriesRequest = Omit<TableRequest, 'clientId'> & {
  fleetClientId: string
  vehicle_id: string
}

export type VehicleHistoriesResponse = TableResponse<{}>

export type TrackHistoryRequest = {
  fleetClientId: string
  recorded_at: {
    start: string
    end: string
  }
  vehicle_id: string
}

export type TrackHistoryResponse = {
  data: Array<FleetMarkerResponse & { status: CarStatus }>
  total_distance: number | null
}

export type TrackHistoryDetailRequest = {
  fleetClientId: string
  id: string
}

export type TrackHistoryDetailResponse = {
  record_id: string
  latitude: number
  longitude: number
  speed: number
  accuracy: number
  vehicle: {
    relational_id: number
    customer_vehicle_id: number
    plate: string
  }
  source: {
    relational_id: number
    type: number
    name: string
  }
  status: CarStatus
  recorded_at: string
}

export type ActivitiesRequest = TableRequestV3 & { type: string }

export type ActivityTypesRequest = {
  fleetClientId: string
}

export type ActivityTypesResponse = Array<{ value: string; label: string }>

export type RFIDTagsRequest = Omit<TableRequest, 'clientId'> & {
  fleetClientId: string
}
export type RFIDTagsResponse = TableResponse<{}>

export type ExportRFIDRequest = {
  fleetClientId: string
  filters: FilterRequestList
  sort: SortRequest
  columns?: TableSettingsRequest
}

export type ExportRFIDResponse = {
  detail: { message: string }
  success: boolean
}

export type TagsByVehicleRequest = Omit<TableRequest, 'clientId'> & {
  fleetClientId: string
  vehicleId: string
  type: number
}
export type TagsByVehicleResponse = TableResponse<{}>

export type DetachTagsRequest = {
  clientId: string
  type: number
  vehicles: string[]
  tags: string[]
}

export type ActivityType = 'bin_lifting' | 'rfid' | 'moba' | 'weighing'

export type TrackHistoryActivitiesRequest = {
  fleetClientId: string
  filter: {
    recorded_at: {
      start: string
      end: string
    }
    vehicle_id: string
    type?: ActivityType | Array<ActivityType>
  }
}
export type TrackHistoryActivitiesResponse = Array<{
  activity_id: string
  type: ActivityType
  longitude: number
  latitude: number
  recorded_at: string
}>

export type TrackHistoryActivityDetailsRequest = {
  fleetClientId: string
  activityId: string
}
export type TrackHistoryActivityDetailsResponse = {
  data: Array<{
    label: string
    value: string
    type: string
  }>
  type: {
    name: string
  }
}

export type TrackHistoryActivityTypesRequest = {
  fleetClientId: string
}
export type TrackHistoryActivityTypesResponse = Array<{
  label: string
  value: string
}>

export type VehicleActivitiesRequest = Omit<TableRequest, 'clientId'> & {
  fleetClientId: string
  vehicleId: string
}
export type VehicleActivitiesResponse = TableResponse<{}>

export type BulkActionResponse = {
  successful_list: Array<{ id: string }>
  failure_list: Array<{ id: string; description: string }>
  detail: { message: string }
}

export type BulkDeleteRFIDRequest = {
  fleetClientId: string
  tags: Array<string>
}

export type AddRFIDTagRequest = {
  fleetClientId: string
  body: FormData
}

export type SearchRFIDResponse = DropdownOptionTemplate

export type SearchRFIDRequest = {
  fleetClientId: string
  query_param: string
}

export type BlackreasonsListResponse = DropdownOptionTemplate

export type VehicleListByListTypeResponse = Array<{
  value: number
  label: string
  in_list: boolean
}>
export type VehicleListByListTypeRequest = {
  tags: Array<string>
  type: number
  fleetClientId: string
}

export type AttachRFIDTagsResponse = {
  detail: { message: string }
  success: boolean
}

export type AttachRFIDTagsRequest = {
  fleetClientId: string
  tags: Array<{ rfid_tag_id: string; list_type: number; reason?: number }>
  vehicles: Array<string>
}

export type DetachRFIDTagsRequest = {
  fleetClientId: string
  tags: Array<string>
  vehicles: Array<string>
}

export type AttachTagsRequest = {
  clientId: string
  type: number
  vehicles: string[]
  tags: Array<{
    rfid_tag_id: string
    list_type: number
    reason?: number
  }>
}

export type FleetMapSettingsResponse = {
  legends: FleetSettingsModalLegend[]
  popup: {
    fields: MapSettingsOption[]
  }
}

export type FleetSettingsModalLegend = {
  label: string
  value: string
  data: Array<{
    label: string
    id: string
    color: string
  }>
}
