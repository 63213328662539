import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Button, Heading, Grid, GridCell, Flex, FlexCell } from 'components'
import {
  ColumnExtended,
  FilterRequestList,
  FilterTypes,
  generateUrlFilterParams,
  FilterObjectType,
  padding,
  TopSection,
} from '@evrekadev/evreka-ui-components'
import { Filter } from './Filter'
import { getRequestFilters } from './utils'
import { useTranslation } from 'utils/useTranslation'
import { URLMapParams } from 'utils/useInternalMapParams'

const StyledFilterSection = styled.div`
  ${padding([10])}
  height: calc(100% - 2rem);
  overflow: auto;
`

export type FilterSectionProps = {
  filters: FilterObjectType
  onFilterChange: (id: string, value: string | string[], type: string) => void | undefined
  setFilters: React.Dispatch<React.SetStateAction<FilterObjectType>>
  setSearchParams: ({ filterBy }: URLMapParams) => void
  onFetch: ((filter: FilterRequestList) => void) | undefined
  columns?: ColumnExtended<any>[]
  timezone?: string
}

export const FilterSection: React.FC<React.PropsWithChildren<FilterSectionProps>> = ({
  filters,
  onFilterChange,
  setFilters,
  setSearchParams,
  onFetch,
  columns,
  timezone,
}) => {
  const { t } = useTranslation('components.map')

  if (!columns) return null

  const filterArray = columns.map((column) => {
    const columnId = column.id
    const isFilterTypeDefined = column.filterType

    if (column.disableFilters) {
      return null
    }

    return (
      isFilterTypeDefined &&
      columnId && (
        <Fragment key={columnId}>
          <TopSection label={column.Header as string} />
          <Filter
            key={columnId}
            column={column}
            filterValue={filters[columnId]}
            onChange={(id: string, value: string | string[]) =>
              onFilterChange(id, value, isFilterTypeDefined || FilterTypes.TEXT)
            }
            type={isFilterTypeDefined || FilterTypes.TEXT}
            cellType={column?.cellType}
            timezone={timezone}
          />
        </Fragment>
      )
    )
  })

  return (
    <>
      <StyledFilterSection>
        <Grid gap={1}>
          <Heading variant="h2">{t('filters')}</Heading>
          {filterArray.map((el) => el && <GridCell key={el.key}>{el}</GridCell>)}
        </Grid>
      </StyledFilterSection>

      <Flex gap={1} center="zero">
        <FlexCell>
          <Button
            variant="outline"
            color="secondary"
            data-testid="map_clear_filters"
            onClick={() => {
              setFilters({})

              setSearchParams({
                filterBy: generateUrlFilterParams({}),
              })

              if (onFetch) {
                onFetch([])
              }
            }}
          >
            {t('clear_filters')}
          </Button>
        </FlexCell>

        <FlexCell>
          <Button
            onClick={() => {
              const requestFilters: FilterRequestList = getRequestFilters(columns, filters)

              setSearchParams({
                filterBy: generateUrlFilterParams(filters),
              })

              if (onFetch) {
                onFetch(requestFilters)
              }
            }}
            data-testid="map_apply_filters"
          >
            {t('apply_filters')}
          </Button>
        </FlexCell>
      </Flex>
    </>
  )
}
