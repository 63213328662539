import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  GeomapAllListRequest,
  GeomapAllListResponse,
  GeomapDeleteRequest,
  GeomapDeleteResponse,
  GeomapListRequest,
  GeomapListResponse,
  GeomapUpsertRequest,
  GeomapUpsertResponse,
} from './types'

import { sendNotification } from 'components'

export const geomapApi = createApi({
  reducerPath: 'geomapApi',
  tagTypes: ['Geomaps'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getGeomaps: builder.query<GeomapListResponse, GeomapListRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/geomap/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Geomaps'],
    }),
    addGeomap: builder.mutation<GeomapUpsertResponse, GeomapUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/geomap/add/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Geomaps'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editGeomap: builder.mutation<GeomapUpsertResponse, GeomapUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/geomap/edit/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Geomaps'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteGeomap: builder.mutation<GeomapDeleteResponse, GeomapDeleteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/geomap/delete/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Geomaps'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getAllGeomaps: builder.query<GeomapAllListResponse, GeomapAllListRequest>({
      query({ clientId }) {
        return {
          url: `clients/${clientId}/ops_management/geomap/map_list/`,
          method: 'GET',
        }
      },
      providesTags: ['Geomaps'],
      transformResponse(data: any) {
        return data.geomap_list
      },
    }),
  }),
})

export const {
  useGetGeomapsQuery,
  useGetAllGeomapsQuery,
  useAddGeomapMutation,
  useEditGeomapMutation,
  useDeleteGeomapMutation,
} = geomapApi
