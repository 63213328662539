import { Breadcrumb, BreadcrumbItem } from 'components'
import React from 'react'
import { Link } from 'react-router-dom'

export type BreadCrumbType = {
  text: string
  location?: string
}
export const useBreadCrumb = (breadcrumbs: BreadCrumbType[]) => {
  if (breadcrumbs.length === 0) {
    return null
  }
  return (
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, i) =>
        breadcrumb.location ? (
          <BreadcrumbItem key={i}>
            <Link to={breadcrumb.location}>{breadcrumb.text}</Link>
          </BreadcrumbItem>
        ) : (
          <BreadcrumbItem key={i}>{breadcrumb.text}</BreadcrumbItem>
        ),
      )}
    </Breadcrumb>
  )
}
