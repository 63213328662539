import React, { ReactChild, ReactChildren, useEffect } from 'react'
import * as L from 'leaflet'
import { useLeafletContext } from '@react-leaflet/core'
import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'

type DrawControlProps = {
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[]
  options: L.PM.ToolbarOptions
  pathOptions?: L.PathOptions
} & L.ControlOptions

export const DrawControl: React.FC<React.PropsWithChildren<DrawControlProps>> = (props) => {
  const { children, pathOptions, options } = props
  const { map } = useLeafletContext()

  useEffect(() => {
    map.pm.addControls({
      ...options,
    })
  }, [map, options])

  useEffect(() => {
    if (pathOptions) {
      map.pm.setPathOptions(pathOptions)
    }
  }, [map, pathOptions])

  useEffect(() => {
    map.pm.setGlobalOptions({
      ...map.pm.getGlobalOptions(),
      allowSelfIntersection: true,
    })
  }, [])

  return <>{children}</>
}
