import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const RVMIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <path
        d="M15.7876 10.0859C15.2353 10.0859 14.7876 10.5337 14.7876 11.0859V29.0859C14.7876 29.6382 15.2353 30.0859 15.7876 30.0859H25.7876C26.3399 30.0859 26.7876 29.6382 26.7876 29.0859V11.0859C26.7876 10.5337 26.3399 10.0859 25.7876 10.0859H15.7876ZM20.7876 19.5898C22.5825 19.5898 24.0376 18.1348 24.0376 16.3398C24.0376 14.5449 22.5825 13.0898 20.7876 13.0898C18.9927 13.0898 17.5376 14.5449 17.5376 16.3398C17.5376 18.1348 18.9927 19.5898 20.7876 19.5898ZM20.7876 18.0907C19.8206 18.0907 19.0367 17.3068 19.0367 16.3398C19.0367 15.3729 19.8206 14.589 20.7876 14.589C21.7546 14.589 22.5385 15.3729 22.5385 16.3398C22.5385 17.3068 21.7546 18.0907 20.7876 18.0907Z"
        fill={isSelected ? '#fff' : color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M17.911 30.2158V28.7158H17.363H16.8151V30.2158H17.363H17.911Z"
        stroke={isSelected ? '#fff' : color}
        strokeWidth="1.09583"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.7596 30.2158V28.7158H24.2117H23.6638V30.2158H24.2117H24.7596Z"
        stroke={isSelected ? '#fff' : color}
        strokeWidth="1.09583"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </AssetIconWrapperStyled>
  )
}

export default RVMIcon
