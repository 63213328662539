import { Icon, ThemeColorKeys, color } from '@evrekadev/evreka-ui-components'
import {
  UilBackspace,
  UilCheckCircle,
  UilEnter,
  UilExclamationOctagon,
  UilExclamationTriangle,
  UilInfoCircle,
  UilQuestionCircle,
} from '@iconscout/react-unicons'
import { Button, Flex, Typography } from 'components'
import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'utils/useTranslation'

type CommandMessageVariants = 'success' | 'error' | 'warning' | 'info' | 'empty'

export type CommandMessageProps = {
  variant: CommandMessageVariants
  description?: string
  noIcon?: boolean
}

export const CommandMessage: React.FC<CommandMessageProps> = ({ variant, noIcon, description }) => {
  const { t } = useTranslation('components.command')
  return (
    <Flex directionColumn="zero" gap={1} middle="zero" center="zero" style={{ maxWidth: '60%' }}>
      {!noIcon && <CommandMessageIcon variant={variant} />}
      <CommandMessageText variant={variant} description={description} />
      {/* <CommandActions /> */}
    </Flex>
  )
}

const CommandMessageIcon: React.FC<{ variant: CommandMessageVariants }> = ({ variant }) => {
  const { icon } = getCommandMessageColors(variant)
  let variantIcon = UilCheckCircle

  switch (variant) {
    case 'success':
    default:
      break
    case 'error':
      variantIcon = UilExclamationOctagon
      break
    case 'warning':
      variantIcon = UilExclamationTriangle
      break
    case 'info':
      variantIcon = UilInfoCircle
      break
    case 'empty':
      variantIcon = UilQuestionCircle
      break
  }

  return (
    <CommandMessageIconWrapper variant={variant}>
      <Icon icon={variantIcon} color={icon} size={30} />
    </CommandMessageIconWrapper>
  )
}

const CommandMessageText: React.FC<{ variant: CommandMessageVariants; description?: string }> = ({
  variant,
  description,
}) => {
  const { t } = useTranslation('components.command')
  switch (variant) {
    case 'empty':
      return (
        <Typography fontSize="text_md" fontWeight="semibold" color="grey500">
          {t('no_actions_found')}
        </Typography>
      )
    default:
      return (
        <Flex directionColumn="zero" middle="zero">
          <Typography fontSize="text_md" fontWeight="semibold" color="grey900">
            {t(`${variant}.title`)}
          </Typography>
          {description && (
            <Typography
              style={{ margin: 0 }}
              align="center"
              fontSize="text_sm"
              fontWeight="regular"
              color="grey600"
            >
              {description}
            </Typography>
          )}
        </Flex>
      )
  }
}

const CommandActions: React.FC = () => {
  return (
    <Flex gap={0.5}>
      <Button variant="outline" color="secondary" iconRight={UilBackspace}>
        Cancel
      </Button>
      <Button variant="filled" color="primary" iconRight={UilEnter}>
        Submit
      </Button>
    </Flex>
  )
}

const CommandMessageIconWrapper = styled.div<{ variant: CommandMessageVariants }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: ${({ variant }) => color(getCommandMessageColors(variant).background)};
`

const getCommandMessageColors = (
  variant: CommandMessageVariants,
): {
  background: ThemeColorKeys
  icon: ThemeColorKeys
} => {
  switch (variant) {
    case 'success':
    default:
      return {
        background: 'green25',
        icon: 'green500',
      }
    case 'error':
      return {
        background: 'red25',
        icon: 'red500',
      }
    case 'warning':
      return {
        background: 'yellow25',
        icon: 'yellow500',
      }
    case 'info':
      return {
        background: 'blue25',
        icon: 'blue500',
      }
    case 'empty':
      return {
        background: 'grey200',
        icon: 'grey500',
      }
  }
}
