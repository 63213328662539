import { createApi } from '@reduxjs/toolkit/query/react'
import {
  ServicePointDetailRequest,
  ServicePointDetailResponse,
  ServicePointListRequest,
  ServicePointListResponse,
  MutationResponse,
  AddServicePointRequest,
  EditServicePointRequest,
  ServicePointLocationsResponse,
  ServicePointLocationsTransformedResponse,
  ServicePointLocationRequest,
  SearchServicePointRequest,
  SearchServicePointResponse,
  ExportRequest,
  ServicePointDetailTransformedResponse,
  CustomFieldEngResponse,
  CustomFieldsRequest,
  LinkAssetRequest,
  ServicePointMappingResponse,
  ServicePointMappingRequest,
  UpdateStatusRequest,
  TableResponseWithTimezone,
} from './types'
import { MAP_SHAPES, Coordinate } from 'components/Map/types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { sendNotification } from 'components'
import { getExportHandler, getImportHandler } from 'utils'
import {
  MapPointsRequest,
  ServicePointMapPopupDetailResponse,
  TableRequestV2,
} from 'services/types'
import i18n from 'utils/i18n'
import {
  ServicePointMapPopupDetailsRequest,
  ServicePointMapPopupDetailsResponse,
  SPMapSettingsResponse,
} from 'services/OpsManagement/types'
import { entityApi } from './entity'
import { assetApi } from 'services/AssetManagement/asset'
import { orderApi } from './order'

export const servicePointApi = createApi({
  reducerPath: 'engagementServicePointApi',
  tagTypes: [
    'ServicePoints',
    'ServicePointLocations',
    'ServicePointDetail',
    'ServicePointHistory',
    'ServicePointMapPoints',
    'ServicePointPopupDetails',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    getServicePointList: builder.query<ServicePointListResponse, ServicePointListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ServicePoints'],
    }),
    getServicePointLocations: builder.query<
      ServicePointLocationsTransformedResponse,
      ServicePointLocationRequest
    >({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/location/list/`,
        method: 'POST',
        data,
      }),
      transformResponse: ({ data }: ServicePointLocationsResponse) => {
        return data.map(({ location: { address, coordinates, type }, ...rest }) => ({
          location: {
            coordinates: coordinates as Coordinate[],
            address: address as string,
            type: type.toLowerCase() as (typeof MAP_SHAPES)['single'],
          },
          ...rest,
        }))
      },
      providesTags: ['ServicePointLocations'],
    }),
    getServicePointDetails: builder.query<
      ServicePointDetailTransformedResponse,
      ServicePointDetailRequest
    >({
      query: ({ client_id, service_point_id }) => ({
        url: `engagement/clients/${client_id}/service_point/?service_point_id=${service_point_id}`,
        method: 'GET',
      }),
      transformResponse: ({ location, ...rest }: ServicePointDetailResponse) => {
        return {
          location: {
            coordinates: location.coordinates as Coordinate[],
            address: location.address as string,
            type: location.type.toLowerCase() as (typeof MAP_SHAPES)['single'],
          },
          ...rest,
        }
      },
      providesTags: ['ServicePointDetail'],
    }),
    getServicePointsCustomFields: builder.query<CustomFieldEngResponse, CustomFieldsRequest>({
      query: ({ clientId, type_id, ...data }) => ({
        url: `engagement/clients/${clientId}/service_point_dynamic_fields/?service_point_type_id=${type_id}`,
        method: 'GET',
        data,
      }),
    }),
    addServicePoint: builder.mutation<
      MutationResponse & { service_point_id: string },
      AddServicePointRequest
    >({
      query: ({ client_id, body }) => ({
        url: `engagement/clients/${client_id}/service_point/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [
        'ServicePoints',
        'ServicePointDetail',
        'ServicePointHistory',
        'ServicePointMapPoints',
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/service_points/detail/${data.service_point_id}`,
            },
          })
          dispatch(entityApi.util.invalidateTags(['Entities', 'EntityDetail']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editServicePoint: builder.mutation<
      MutationResponse & { service_point_id: string },
      EditServicePointRequest
    >({
      query: ({ client_id, service_point_id, body }) => ({
        url: `engagement/clients/${client_id}/service_point/${service_point_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [
        'ServicePoints',
        'ServicePointDetail',
        'ServicePointHistory',
        'ServicePointPopupDetails',
        'ServicePointLocations',
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(entityApi.util.invalidateTags(['Entities', 'EntityDetail']))
          dispatch(
            orderApi.util.invalidateTags(['OrderPlanDetails', 'OrderPlans', 'OrderSettings']),
          )
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/service_points/detail/${data.service_point_id}`,
            },
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchServicePoint: builder.mutation<SearchServicePointResponse, SearchServicePointRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/search/`,
        method: 'POST',
        data,
      }),
    }),
    exportServicePoint: builder.mutation<
      MutationResponse,
      ExportRequest & { service_point_type_id?: string }
    >({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    linkAssetToServicePoint: builder.mutation<MutationResponse, LinkAssetRequest>({
      query: ({ client_id, servicePointId, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/${servicePointId}/link_asset/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(assetApi.util.invalidateTags(['Assets']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    unlinkAssetToServicePoint: builder.mutation<MutationResponse, LinkAssetRequest>({
      query: ({ client_id, servicePointId, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/${servicePointId}/unlink_asset/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(assetApi.util.invalidateTags(['Assets']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getServicePointMapping: builder.mutation<
      ServicePointMappingResponse,
      ServicePointMappingRequest
    >({
      query: ({ clientId, service_point_id }) => ({
        url: `engagement/external/clients/${clientId}/service_point_mapping/search/${service_point_id}/`,
        method: 'GET',
        errorOverride: true,
      }),
    }),
    importServicePoints: builder.mutation<MutationResponse, { clientId: string; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `engagement/clients/${clientId}/service_point/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['ServicePoints', 'ServicePointMapPoints'],
      onQueryStarted: getImportHandler,
    }),
    servicePointHistory: builder.query<
      TableResponseWithTimezone<{}>,
      TableRequestV2 & {
        service_point_id: string
      }
    >({
      query: ({ client_id, service_point_id, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/${service_point_id}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ServicePointHistory'],
    }),
    updateSpStatus: builder.mutation<MutationResponse, UpdateStatusRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/status/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'ServicePoints',
        'ServicePointDetail',
        'ServicePointHistory',
        'ServicePointMapPoints',
      ],
    }),
    deleteServicePoint: builder.mutation<
      MutationResponse,
      {
        client_id: string
        service_point_id: string
        should_delete_linked_cases: boolean
        should_delete_linked_orders: boolean
      }
    >({
      query: ({ client_id, service_point_id, ...data }) => ({
        url: `engagement/clients/${client_id}/service_point/${service_point_id}/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['ServicePoints', 'ServicePointMapPoints'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getMapPoints: builder.query<ServicePointMapPopupDetailResponse, MapPointsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `engagement/clients/${clientId}/service_point/get_service_points_map/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ServicePointMapPoints'],
    }),
    getMapSettings: builder.query<SPMapSettingsResponse, { client_id: string }>({
      query: ({ client_id }) => ({
        url: `engagement/clients/${client_id}/service_point/get_service_points_map/settings/`,
        method: 'GET',
      }),
    }),
    getServicePointMapPopupDetails: builder.query<
      ServicePointMapPopupDetailsResponse,
      ServicePointMapPopupDetailsRequest
    >({
      query: ({ clientId, servicePointId, fields }) => ({
        url: `engagement/clients/${clientId}/service_point/get_service_points_map/detail/${servicePointId}/${
          fields ? `?fields=${fields.join(',')}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: ['ServicePointPopupDetails'],
    }),
  }),
})

export const {
  useGetServicePointListQuery,
  useGetServicePointDetailsQuery,
  useGetServicePointsCustomFieldsQuery,
  useAddServicePointMutation,
  useEditServicePointMutation,
  useGetServicePointLocationsQuery,
  useLazyGetServicePointLocationsQuery,
  useSearchServicePointMutation,
  useExportServicePointMutation,
  useLinkAssetToServicePointMutation,
  useGetServicePointMappingMutation,
  useImportServicePointsMutation,
  useUnlinkAssetToServicePointMutation,
  useServicePointHistoryQuery,
  useUpdateSpStatusMutation,
  useDeleteServicePointMutation,
  useGetMapPointsQuery,
  useGetMapSettingsQuery,
  useGetServicePointMapPopupDetailsQuery,
} = servicePointApi
