import { useContext, useEffect, useRef, useState } from 'react'
import L from 'leaflet'
import { MarkerProps, useMap, useMapEvents } from 'react-leaflet'
import { setSerializableMapBounds } from './utils'
import { MapContextType } from './types'
import { MapContext } from 'context'

export function getFeatureGroup(markers: MarkerProps[]) {
  const featureGroup = L.featureGroup([])
  markers.forEach((marker) => {
    const markerLayer = new L.Marker(marker.position)
    markerLayer.addTo(featureGroup)
  })
  return featureGroup
}

//TODO: extend for all shapes
export const BoundControl = () => {
  const map = useMap()
  const autopanstarted = useRef(false)
  const {
    bounds,
    onBoundChange: onChange,
    markers,
    renderMapSearch,
  } = useContext<MapContextType>(MapContext)

  const [markersAreCentered, setMarkersAreCentered] = useState(false)

  useMapEvents({
    autopanstart(_e) {
      autopanstarted.current = true
    },
    moveend(_e) {
      if (!autopanstarted.current && onChange) {
        onChange(setSerializableMapBounds(map.getBounds()))
      }

      autopanstarted.current = false
    },
    dragend(_e) {
      if (onChange) {
        onChange(setSerializableMapBounds(map.getBounds()))
      }
    },
  })

  useEffect(() => {
    if (
      !bounds &&
      !markersAreCentered &&
      !renderMapSearch &&
      onChange === undefined &&
      markers &&
      markers.length > 0
    ) {
      const featureGroup = getFeatureGroup(markers)
      map.fitBounds(featureGroup.getBounds().pad(0.5))
      setMarkersAreCentered(true)
    }
  }, [markers])

  // useEffect(() => {
  //   if (circles && circles.length > 0) {
  //     circles.forEach((circle) => {
  //       if (extendedBoundRef.current.isValid()) {
  //         if (circle.radius) {
  //           extendedBoundRef.current.extend(L.latLng(circle.center).toBounds(circle.radius * 2))
  //         } else {
  //           extendedBoundRef.current.extend(circle.center)
  //         }
  //       } else {
  //         extendedBoundRef.current = L.latLng(circle.center).toBounds(
  //           circle.radius ? circle.radius * 2 : 500,
  //         )
  //       }
  //     })
  //   }
  // }, [circles])

  // useEffect(() => {
  //   if (polylines && polylines.length > 0) {
  //     polylines.forEach((polyline) => {
  //       const polylinebounds = L.polyline(polyline.positions).getBounds()
  //       if (extendedBoundRef.current.isValid()) {
  //         extendedBoundRef.current.extend(polylinebounds)
  //       } else {
  //         extendedBoundRef.current = polylinebounds
  //       }
  //     })
  //   }
  // }, [polylines])

  // useEffect(() => {
  //   if (polygons && polygons.length > 0) {
  //     polygons.forEach((polygon) => {
  //       const polygonbounds = L.polygon(polygon.positions).getBounds()
  //       if (extendedBoundRef.current.isValid()) {
  //         extendedBoundRef.current.extend(polygonbounds)
  //       } else {
  //         extendedBoundRef.current = polygonbounds
  //       }
  //     })
  //   }
  // }, [polygons])
  return null
}
