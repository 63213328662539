import { Breadcrumb, BreadcrumbItem, Grid, GridCell, Spacing } from 'components'
import { InputSkeleton } from 'components/Skeletons/InputSkeleton'
import { MapSkeleton } from 'components/Skeletons/MapSkeleton'
import { BreadcrumbSkeleton } from 'components/Skeletons/BreadCrumbSkeleton'
import { LayoutForm } from 'containers/Layout'
import React from 'react'

type FormSkeletonProps = {
  isMap?: boolean
}

const array = [1, 1, 1, 1, 1, 1, 1, 1, 1]

export const FormSkeleton: React.FC<React.PropsWithChildren<FormSkeletonProps>> = ({ isMap }) => {
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbSkeleton />
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbSkeleton />
        </BreadcrumbItem>
      </Breadcrumb>
      <LayoutForm>
        <LayoutForm.LeftColumn>
          <Grid gap={4} columns={2}>
            {array.map((_, idx) => (
              <GridCell key={idx}>
                <InputSkeleton />
              </GridCell>
            ))}
          </Grid>
        </LayoutForm.LeftColumn>
        <LayoutForm.RightColumn>
          {isMap ? (
            <MapSkeleton />
          ) : (
            <Grid gap={4} columns={2}>
              {array.map((_, idx) => (
                <GridCell key={idx}>
                  <InputSkeleton />
                </GridCell>
              ))}
            </Grid>
          )}
        </LayoutForm.RightColumn>
      </LayoutForm>
    </>
  )
}
