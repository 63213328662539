import { useContext } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'utils/useTranslation'
import { Infobox, Typography, Icon } from 'components'
import { padding, color, shadow, borderRadius, margin } from '@evrekadev/evreka-ui-components'
import { UilMapMarker, UilTimes } from '@iconscout/react-unicons'
import { MapContextType } from '../types'
import { MapContext } from 'context'

const SelectedMarkers = () => {
  const { t } = useTranslation('components.map')

  const { selectedMarkers: markers, onMarkerSelect: onMarkerRemove } =
    useContext<MapContextType>(MapContext)

  return (
    <Container style={ContainerStyle}>
      <TitleContainer fontSize="text_sm" fontWeight="semibold">
        {t('selected_markers')} ({markers?.length})
      </TitleContainer>
      <MarkersContainer>
        {!markers?.length && (
          <div className="info-box">
            <Infobox text={t('draw_an_area')} width="%100" showIcon={false} variant="warning" />
          </div>
        )}
        {markers?.map((marker, idx) => {
          return (
            <div key={marker.id || idx} className="marker-item">
              <div className="title">
                <Icon icon={UilMapMarker} color="green500" />
                <Typography>{marker.title || marker.name}</Typography>
              </div>
              <div onClick={() => onMarkerRemove?.(marker)}>
                <TimesIconContainer className="remove_marker" icon={UilTimes} color="darkblue500" />
              </div>
            </div>
          )
        })}
      </MarkersContainer>
    </Container>
  )
}

const Container = styled.div`
  ${padding([12])}
  ${shadow('xs')}
  ${borderRadius(12)}
  position: absolute;
  z-index: 1000;
  top: 10px;
  width: 224px;
  height: 188px;
  background: ${color('white')};
  border-bottom: 1px solid ${color('grey300')};

  p {
    margin-left: 0 !important;
  }
`

const TitleContainer = styled(Typography)``

const MarkersContainer = styled.div`
  height: 136px;
  overflow-y: auto;
  margin-top: 5px;

  & > .marker-item {
    ${padding([2])}
    ${margin({ b: 4 })}
    display: flex;
    justify-content: space-between;

    .title {
      display: flex;
      flex-shrink: 0;

      p {
        width: 141px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .remove_marker {
      display: none;
    }

    &:hover {
      ${borderRadius(4)}
      background: ${color('grey50')};

      .remove_marker {
        display: block;
      }
    }
  }
`

const TimesIconContainer = styled(Icon)`
  cursor: pointer;
`

const ContainerStyle: any = {
  textAlign: 'left',
  right: 10,
}

export default SelectedMarkers
