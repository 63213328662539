import { ThemeColors, ThemeColorKeys } from '@evrekadev/evreka-ui-components'

/**
 * this utility function is used to extract the corresponding color string
 * from ThemeColors enum
 */
const getThemeColorName = (color?: ThemeColors | string) => {
  const indexOfColor = Object.values(ThemeColors).findIndex((c) => c === color)
  if (indexOfColor === -1) {
    return 'green500'
  }
  const colorName = Object.keys(ThemeColors)[indexOfColor]
  return colorName as ThemeColorKeys
}

export default getThemeColorName
