import { LatLngTuple, LatLngBounds, LatLngBoundsExpression, LatLngBoundsLiteral } from 'leaflet'
import { i18n } from 'i18next'
import { Coordinate, MapLocation, MAP_SHAPES } from './types'
import { CircleMarker, CircleMarkerProps, Polyline, PolylineProps } from 'react-leaflet'
import { ShapeWithoutColor } from './types'
import {
  ColumnExtended,
  ExtraColors,
  FilterRequest,
  FilterRequestList,
  FilterTypes,
  FilterObjectType,
  parseNonLatinNumber,
  ThemeColors,
} from '@evrekadev/evreka-ui-components'
import { LANGUAGES } from 'utils/languages'
import { CustomMarkerProps } from './MapMarkers'

export const renderCircles = (circles?: ShapeWithoutColor<CircleMarkerProps>[]) => {
  return (
    circles &&
    circles.length > 0 &&
    circles.map((circle, i) => (
      <CircleMarker
        {...circle}
        key={i}
        radius={circle.radius ?? undefined}
        color={(circle.color && ThemeColors[circle.color]) || ExtraColors.circle}
        fillColor={(circle.fillColor && ThemeColors[circle.fillColor]) || ThemeColors.green25}
      />
    ))
  )
}

export const renderPolyLines = (polylines?: ShapeWithoutColor<PolylineProps>[]) => {
  return (
    polylines &&
    polylines.length > 0 &&
    polylines.map((polyline, i) => {
      return (
        <Polyline
          {...polyline}
          key={i}
          color={(polyline.color && ThemeColors[polyline.color]) || ExtraColors.polyline}
          fillColor={
            (polyline.fillColor && ThemeColors[polyline.fillColor]) || ThemeColors.green600
          }
        />
      )
    })
  )
}

export const getLatLong = (location: Coordinate) => {
  return [location.latitude, location.longitude] as LatLngTuple
}

export const getMarkers = (location: MapLocation): CustomMarkerProps[] | undefined => {
  switch (location.type) {
    case MAP_SHAPES.multiple:
    case MAP_SHAPES.single: {
      return location.coordinates.map(({ latitude, longitude }) => ({
        position: [latitude, longitude],
      })) as unknown as CustomMarkerProps[]
    }
    case MAP_SHAPES.start_finish: {
      return [
        {
          position: getLatLong(location.start_location),
        },
        {
          position: getLatLong(location.finish_location),
        },
      ] as CustomMarkerProps[]
    }
    case MAP_SHAPES.geofence: {
      return [
        {
          position: getLatLong(location.location),
        },
      ] as CustomMarkerProps[]
    }
    default: {
      return undefined
    }
  }
}

export const getPolylines = (location: MapLocation) => {
  switch (location.type) {
    case MAP_SHAPES.start_finish: {
      return [
        {
          color: 'orange500',
          positions: [getLatLong(location.start_location), getLatLong(location.finish_location)],
        },
      ] as ShapeWithoutColor<PolylineProps>[]
    }
    default:
      return undefined
  }
}

export const getPolygons = (location: MapLocation) => {
  switch (location.type) {
    case MAP_SHAPES.geofence: {
      return [
        {
          positions: [
            location.locations.map(
              ({ latitude, longitude }) => [latitude, longitude] as LatLngTuple,
            ),
          ],
        },
      ]
    }
    default:
      return undefined
  }
}

//TODO: add translation
export const getLegendItems = (location: MapLocation) => {
  switch (location.type) {
    case MAP_SHAPES.start_finish: {
      return [
        {
          text: 'Start Location',
        },
        {
          text: 'Finish Location',
        },
      ]
    }
    case MAP_SHAPES.geofence: {
      return [
        {
          text: 'Finish Location',
        },
        {
          text: 'Depot Area',
        },
      ]
    }
    default:
      return undefined
  }
}

export type BoundFilter = {
  _northEast: {
    lat: number
    lng: number
  }
  _southWest: {
    lat: number
    lng: number
  }
}

// return serializable map bounds of leaflet bound instance
export const setSerializableMapBounds = (bounds: LatLngBounds): BoundFilter => {
  return {
    _northEast: {
      lat: bounds.getNorthEast().lat,
      lng: bounds.getNorthEast().lng,
    },
    _southWest: {
      lat: bounds.getSouthWest().lat,
      lng: bounds.getSouthWest().lng,
    },
  }
}

export const isPointInsideShape = (point: LatLngTuple, polygon: [number, number][]) => {
  // ray-casting algorithm based on
  // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html

  let x = point[0],
    y = point[1]

  let inside = false
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    let xi = polygon[i][0],
      yi = polygon[i][1]
    let xj = polygon[j][0],
      yj = polygon[j][1]

    let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
    if (intersect) inside = !inside
  }

  return inside
}

export const isBoundsLiteral = (val: LatLngBoundsExpression): val is LatLngBoundsLiteral => {
  return (val as LatLngBoundsLiteral)[0] !== undefined
}

export const parseFilters = (
  prev: FilterObjectType,
  i18n: i18n,
  id: string,
  value: string | string[],
  type: string,
) => {
  //TODO remove key value pair when value null, undefined or empty
  let updatedValue: { [key: string]: string | string[] } = {}
  let parsedFilter = prev
  if (value === '' || !value.length) {
    delete parsedFilter[id]
  } else if (type === FilterTypes.DATE && i18n.language === LANGUAGES.ARABIC) {
    const latinValue = parseNonLatinNumber(value as string)
    updatedValue[id] = latinValue
  } else if (type === FilterTypes.DATE_RANGE && i18n.language === LANGUAGES.ARABIC) {
    const dates = (value as string).split(',')
    const latinStartValue = parseNonLatinNumber(dates[0])
    const latinEndValue = parseNonLatinNumber(dates[1])
    updatedValue[id] = [latinStartValue, latinEndValue]
  } else {
    updatedValue[id] = value
  }
  const updateFilter: FilterObjectType = updatedValue

  return { ...parsedFilter, ...updateFilter }
}

export const getRequestFilters = (columns: ColumnExtended<any>[], filters: FilterObjectType) => {
  let requestFilters: FilterRequestList = []

  for (const [key, value] of Object.entries(filters)) {
    for (let index = 0; index < columns.length; index++) {
      const element = columns[index]
      if (element.id === key) {
        const aRequest: FilterRequest = {
          type: element.filterType ?? 'TEXT',
          key: key,
          value,
        }
        requestFilters = [...requestFilters, aRequest]
      }
    }
  }

  return requestFilters
}

export const mirrorFetch = async (url: string) => {
  const body = new FormData()
  body.append('url', url)
  const res = await fetch(`${process.env.REACT_APP_BASE_URL + '/api/mirror/'}`, {
    method: 'POST',
    body,
  })
  const json = await res.json()
  return json
}
