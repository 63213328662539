import { MapContext } from 'context'
import { LeafletEventHandlerFnMap } from 'leaflet'
import { useContext } from 'react'
import { useMapEvents } from 'react-leaflet'
import { MapContextType } from './types'

const MapEventsEmitter = () => {
  const { events } = useContext<MapContextType>(MapContext)

  useMapEvents(events ?? {})

  return null
}

export default MapEventsEmitter
