import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const EventSettingsSkeleton = (props: IContentLoaderProps) => {
  return (
    <ContentLoader viewBox="0 0 1300 400" height={400} width={1300} {...props}>
      <rect height="30" rx="1" ry="1" width="300" x="10" y="25" />
      <rect height="24" rx="0" ry="0" width="24" x="400" y="25" />
      <rect height="24" rx="0" ry="0" width="24" x="600" y="25" />
      <rect height="24" rx="0" ry="0" width="24" x="800" y="25" />
      <rect height="24" rx="0" ry="0" width="24" x="1000" y="25" />
      <rect height="24" rx="0" ry="0" width="24" x="1200" y="25" />

      <rect height="30" rx="1" ry="1" width="300" x="10" y="95" />
      <rect height="24" rx="0" ry="0" width="24" x="400" y="95" />
      <rect height="24" rx="0" ry="0" width="24" x="600" y="95" />
      <rect height="24" rx="0" ry="0" width="24" x="800" y="95" />
      <rect height="24" rx="0" ry="0" width="24" x="1000" y="95" />
      <rect height="24" rx="0" ry="0" width="24" x="1200" y="95" />

      <rect height="30" rx="1" ry="1" width="300" x="10" y="165" />
      <rect height="24" rx="0" ry="0" width="24" x="400" y="165" />
      <rect height="24" rx="0" ry="0" width="24" x="600" y="165" />
      <rect height="24" rx="0" ry="0" width="24" x="800" y="165" />
      <rect height="24" rx="0" ry="0" width="24" x="1000" y="165" />
      <rect height="24" rx="0" ry="0" width="24" x="1200" y="165" />

      <rect height="30" rx="1" ry="1" width="300" x="10" y="235" />
      <rect height="24" rx="0" ry="0" width="24" x="400" y="235" />
      <rect height="24" rx="0" ry="0" width="24" x="600" y="235" />
      <rect height="24" rx="0" ry="0" width="24" x="800" y="235" />
      <rect height="24" rx="0" ry="0" width="24" x="1000" y="235" />
      <rect height="24" rx="0" ry="0" width="24" x="1200" y="235" />

      <rect height="30" rx="1" ry="1" width="300" x="10" y="305" />
      <rect height="24" rx="0" ry="0" width="24" x="400" y="305" />
      <rect height="24" rx="0" ry="0" width="24" x="600" y="305" />
      <rect height="24" rx="0" ry="0" width="24" x="800" y="305" />
      <rect height="24" rx="0" ry="0" width="24" x="1000" y="305" />
      <rect height="24" rx="0" ry="0" width="24" x="1200" y="305" />

      <rect height="30" rx="1" ry="1" width="300" x="10" y="375" />
      <rect height="24" rx="0" ry="0" width="24" x="400" y="375" />
      <rect height="24" rx="0" ry="0" width="24" x="600" y="375" />
      <rect height="24" rx="0" ry="0" width="24" x="800" y="375" />
      <rect height="24" rx="0" ry="0" width="24" x="1000" y="375" />
      <rect height="24" rx="0" ry="0" width="24" x="1200" y="375" />
    </ContentLoader>
  )
}
