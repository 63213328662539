import { Table } from 'components'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const TableSkeleton = (props: IContentLoaderProps) => (
  <ContentLoader
    width={1920}
    height={400}
    // viewBox="0 0 1920 400"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="27" y="19" rx="4" ry="4" width="20" height="20" />
    <rect x="67" y="20" rx="10" ry="10" width="85" height="19" />
    <rect x="188" y="21" rx="10" ry="10" width="169" height="19" />
    <rect x="402" y="20" rx="10" ry="10" width="85" height="19" />
    <rect x="523" y="21" rx="10" ry="10" width="169" height="19" />
    <rect x="731" y="19" rx="10" ry="10" width="85" height="19" />
    <rect x="852" y="18" rx="10" ry="10" width="169" height="19" />

    <rect x="27" y="69" rx="4" ry="4" width="20" height="20" />
    <rect x="67" y="70" rx="10" ry="10" width="85" height="19" />
    <rect x="188" y="71" rx="10" ry="10" width="169" height="19" />
    <rect x="402" y="70" rx="10" ry="10" width="85" height="19" />
    <rect x="523" y="71" rx="10" ry="10" width="169" height="19" />
    <rect x="731" y="69" rx="10" ry="10" width="85" height="19" />
    <rect x="852" y="68" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="126" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="127" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="128" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="127" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="128" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="126" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="125" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="188" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="189" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="190" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="189" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="190" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="188" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="187" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="246" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="247" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="248" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="247" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="248" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="246" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="245" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="309" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="310" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="311" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="310" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="311" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="309" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="308" rx="10" ry="10" width="169" height="19" />

    <rect x="26" y="369" rx="4" ry="4" width="20" height="20" />
    <rect x="66" y="370" rx="10" ry="10" width="85" height="19" />
    <rect x="187" y="371" rx="10" ry="10" width="169" height="19" />
    <rect x="401" y="370" rx="10" ry="10" width="85" height="19" />
    <rect x="522" y="371" rx="10" ry="10" width="169" height="19" />
    <rect x="730" y="369" rx="10" ry="10" width="85" height="19" />
    <rect x="851" y="368" rx="10" ry="10" width="169" height="19" />

    <rect x="1076" y="19" rx="10" ry="10" width="169" height="19" />
    <rect x="1076" y="69" rx="10" ry="10" width="169" height="19" />
    <rect x="1075" y="126" rx="10" ry="10" width="169" height="19" />
    <rect x="1075" y="188" rx="10" ry="10" width="169" height="19" />
    <rect x="1075" y="246" rx="10" ry="10" width="169" height="19" />
    <rect x="1075" y="309" rx="10" ry="10" width="169" height="19" />
    <rect x="1075" y="369" rx="10" ry="10" width="169" height="19" />

    <rect x="1301" y="19" rx="10" ry="10" width="169" height="19" />
    <rect x="1301" y="69" rx="10" ry="10" width="169" height="19" />
    <rect x="1300" y="126" rx="10" ry="10" width="169" height="19" />
    <rect x="1300" y="188" rx="10" ry="10" width="169" height="19" />
    <rect x="1300" y="246" rx="10" ry="10" width="169" height="19" />
    <rect x="1300" y="309" rx="10" ry="10" width="169" height="19" />
    <rect x="1300" y="369" rx="10" ry="10" width="169" height="19" />

    <rect x="1526" y="19" rx="10" ry="10" width="169" height="19" />
    <rect x="1526" y="69" rx="10" ry="10" width="169" height="19" />
    <rect x="1525" y="126" rx="10" ry="10" width="169" height="19" />
    <rect x="1525" y="188" rx="10" ry="10" width="169" height="19" />
    <rect x="1525" y="246" rx="10" ry="10" width="169" height="19" />
    <rect x="1525" y="309" rx="10" ry="10" width="169" height="19" />
    <rect x="1525" y="369" rx="10" ry="10" width="169" height="19" />
  </ContentLoader>
)

export const TableSkeletonWithBorder: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Table
    data={[]}
    columns={[]}
    emptyLabel=""
    totalCount={0}
    loading
    name={'table-skeleton-with-border'}
  />
)
