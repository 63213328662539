import { Loading, Modal } from 'components'
import React from 'react'

type OverlayLoaderProps = {
  isOpen: boolean
}

export const OverlayLoader: React.FC<React.PropsWithChildren<OverlayLoaderProps>> = ({
  isOpen,
}) => {
  return (
    <Modal isOpen={isOpen} isEmpty>
      <Loading type="spinner" />
    </Modal>
  )
}
