import React, { useRef } from 'react'
import { useMap, Tooltip } from 'react-leaflet'
import {
  BadgeProps,
  BaseColorType,
  DATE_TIME_FORMAT,
  Grid,
  GridCell,
  IconProps,
  LinkCell,
  Separator,
  Tag,
  ThemeColorType,
  ThemeColors,
  TooltipV2,
  dateFormat,
  usePopperProps,
} from '@evrekadev/evreka-ui-components'
import { Flex, Icon, Button, Typography, Badge } from 'components'
import { PointExpression, Popup as LeafletPopup } from 'leaflet'
import { MarkerPopupContent, MarkerPopupHeader, MarkerPopupStyled } from './MarkerPopup.style'
import { UilCalender, UilTimes } from '@iconscout/react-unicons'
import { TypographySkeleton } from 'components/Skeletons/TypographySkeleton'
import ReactDOM from 'react-dom'
import _ from 'lodash'

export type PopupProps = {
  heading?: string
  headingExtra?: React.ReactElement
  supportingText?: string
  headingIcon?: IconProps['icon']
  headingIconColor?: IconProps['color']
  tooltip?: {
    icon: IconProps['icon']
    content: string
  }
  extraButtons?: Array<{
    icon: IconProps['icon']
    onClick: () => void
    dataTestId?: string
  }>
  ActionMenu?: React.FC
  size?: 'small' | 'medium'
  // eskiler
  content: React.ReactElement | null
  isLoading: boolean
  autoPan?: boolean
  offset?: PointExpression
}

const MarkerPopup: React.FC<PopupProps> = ({
  content,
  isLoading,
  ActionMenu,
  autoPan,
  extraButtons,
  heading,
  headingExtra,
  headingIcon,
  headingIconColor,
  offset,
  size = 'medium',
  supportingText,
  tooltip,
}) => {
  const popupRef = useRef<LeafletPopup>(null)
  const { closePopup } = useMap()

  const handleClosePopup = () => {
    popupRef.current && closePopup(popupRef.current)
  }

  const {
    attributes,
    isOpen,
    popperElement,
    referenceElement,
    setIsOpen,
    setPopperElement,
    setReferenceElement,
    styles,
  } = usePopperProps('top')

  return (
    <MarkerPopupStyled
      // TODO: autopan is buggy, try to change z-index
      autoPan={autoPan || false}
      autoPanPadding={[0, 50]}
      minWidth={size === 'medium' ? 390 : 320}
      closeOnEscapeKey
      closeButton={false}
      offset={offset || [-4, 20]}
      className="marker-popup-wrapper"
      ref={popupRef}
      size={size}
    >
      <MarkerPopupHeader data-testid="popup-header" gap={0.5} between="zero">
        <Flex data-testid="popup-header-left" directionColumn="zero" gap={0.25}>
          <Flex data-testid="popup-header-text" directionColumn="zero">
            <Flex gap={0.25} middle="zero">
              {headingIcon && (
                <Icon icon={headingIcon} size={20} color={headingIconColor ?? 'grey600'} />
              )}
              <Typography fontSize="text_md" fontWeight="medium" color="grey900">
                {heading}
              </Typography>
              {tooltip && (
                <div
                  ref={(ref) => setReferenceElement(ref)}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Icon
                    icon={tooltip.icon}
                    color="grey400"
                    size={16}
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                  />

                  {isOpen &&
                    ReactDOM.createPortal(
                      <div
                        ref={setPopperElement}
                        style={{
                          ...styles.popper,
                          zIndex: 99999999999,
                          padding: '0.25rem 0.5rem',
                          background: ThemeColors['darkblue700'],
                          borderRadius: '0.25rem',
                        }}
                        {...attributes.popper}
                      >
                        <Typography fontSize="text_sm" color="white">
                          {tooltip.content}
                        </Typography>
                      </div>,
                      document.body,
                    )}
                </div>
                // <TooltipV2
                //   element={<Icon icon={tooltip.icon} color="grey400" size={16} />}
                //   content={tooltip.content}
                //   placement={'bottom' as any}
                //   strategy={'absolute'}
                //   modifiers={[{
                //     name: ""
                //   }]}

                // />
              )}
            </Flex>
            <Typography
              data-testid="popup-header-supporting"
              fontSize="text_sm"
              fontWeight="regular"
              color="grey600"
            >
              {supportingText}
            </Typography>
          </Flex>
          {headingExtra}
        </Flex>
        <Flex data-testid="popup-header-right" gap={0.25}>
          {extraButtons?.map(({ icon, onClick, dataTestId }, i) => (
            <Button
              key={i}
              onlyIcon
              iconLeft={icon}
              variant="text"
              color="secondary"
              size="small"
              onClick={onClick}
              data-testid={dataTestId ?? `map-extra-button-${i}`}
            />
          ))}
          {ActionMenu && <ActionMenu />}
          <Button
            onlyIcon
            iconLeft={UilTimes}
            onClick={handleClosePopup}
            variant="text"
            color="secondary"
            size="small"
            data-testid="close-popup"
          />
        </Flex>
      </MarkerPopupHeader>
      <Separator />
      <MarkerPopupContent data-testid="popup-content">
        {isLoading ? <MarkerPopupLoading /> : content}
      </MarkerPopupContent>
    </MarkerPopupStyled>
  )
}

const MarkerPopupLoading: React.FC = () => {
  return (
    <Flex directionColumn="zero" fullHeight gap={0.25}>
      <TypographySkeleton words={5} separate />
      <TypographySkeleton words={4} />
      <TypographySkeleton words={3} />
      <TypographySkeleton words={5} />
      <TypographySkeleton words={2} separate />
      <TypographySkeleton words={4} />
      <TypographySkeleton words={5} />
    </Flex>
  )
}

type MarkerPopupDetailItemProps = {
  value?: string | JSX.Element | ObjectValue | number
  label: string
  type?: string
  isAllBold?: boolean
  isAllLight?: boolean
  timezone?: string
  cellType?: string
}

type ObjectValue = {
  value: string
  label?: string
  color?: BadgeProps['color']
}

const getValue = (
  value: MarkerPopupDetailItemProps['value'],
  type: MarkerPopupDetailItemProps['type'],
  timezone: MarkerPopupDetailItemProps['timezone'],
  cellType: MarkerPopupDetailItemProps['cellType'],
  label: string,
) => {
  const shouldLog = false
  let returnValue = undefined
  shouldLog && console.log({ message: type })
  shouldLog && console.log({ message: value })

  if (value === 0) {
    returnValue = '0'
  }

  if (value == null) {
    shouldLog && console.log({ message: shouldLog && 'null' })
    return '-'
  }

  if (type === 'LINK' && value !== '-' && value != null) {
    shouldLog && console.log({ message: shouldLog && 'link' })
    const data = { value } as any

    if ((value as any)?.value == null) {
      return '-'
    }

    return <LinkCell {...data} />
  } else if (React.isValidElement(value)) {
    shouldLog && console.log({ message: shouldLog && 'valid' })
    returnValue = value
  } else if (cellType === 'DATETIME_ISO' || type === 'DATE_RANGE') {
    shouldLog && console.log({ message: shouldLog && 'date_time and range' })
    returnValue = (
      <Flex middle="zero" gap={0.25} flexWrap>
        <Icon icon={UilCalender} color="grey500" size={18} />
        <Typography fontSize="text_sm" fontWeight="regular" color="grey700">
          {dateFormat(value as string, DATE_TIME_FORMAT, timezone)}
        </Typography>
      </Flex>
    )
  } else if (type === 'DATE') {
    shouldLog && console.log({ message: shouldLog && 'date' })
    returnValue = (
      <Flex middle="zero" gap={0.25} flexWrap>
        <Icon icon={UilCalender} color="grey500" size={18} />
        <Typography fontSize="text_sm" fontWeight="regular" color="grey700">
          {dateFormat(value as string, DATE_TIME_FORMAT)}
        </Typography>
      </Flex>
    )
  } else if (
    typeof value === 'object' &&
    ('value' in value || 'label' in value)
    // ((value as ObjectValue)?.value || (value as ObjectValue)?.label)
  ) {
    const newVal = value as ObjectValue
    shouldLog && console.log({ message: shouldLog && 'status' })
    returnValue = (
      <Badge color={newVal?.color ?? 'grey'} size="medium">
        {newVal.value?.toString() ?? newVal.label?.toString()}
      </Badge>
    )
  } else if (type === 'MULTIPLE_DROPDOWN') {
    const items = value
      ?.toString()
      .split(',')
      .filter((i) => i !== '' && i !== '-')
    returnValue =
      items.length > 0 ? (
        <Flex gap={0.25} flexWrap>
          {value
            ?.toString()
            .split(',')
            .map((item, i) => <Tag key={i} text={item} />)}
        </Flex>
      ) : (
        '-'
      )
  } else if (typeof value === 'string') {
    shouldLog && console.log({ message: shouldLog && 'string' })
    returnValue = <Typography>{_.truncate(value, { length: 25 })}</Typography>
  } else {
    returnValue = value
  }

  return returnValue || '-'
}

export const MarkerPopupDetailItem: React.FC<MarkerPopupDetailItemProps> = ({
  label,
  value,
  cellType,
  isAllBold,
  isAllLight,
  timezone,
  type,
}) => (
  <Grid columns={5} fullWidth gap={0.25}>
    <GridCell width={2}>
      <Typography wordBreak="break-word" fontSize="text_sm" fontWeight="medium" color="grey900">
        {label}
      </Typography>
    </GridCell>
    <GridCell width={3}>
      {/* <Typography fontSize="text_sm" fontWeight="regular"> */}
      {getValue(value, type, timezone, cellType, label)}
      {/* </Typography> */}
    </GridCell>
  </Grid>
)

export default MarkerPopup
