import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const MapSkeleton = (props: IContentLoaderProps) => (
  <ContentLoader
    speed={2}
    height={'100%'}
    width={'100%'}
    preserveAspectRatio="none"
    viewBox="0 0 100 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#dfdfdf"
    {...props}
  >
    <rect x="0%" width="100%" rx={2} ry={2} height="100%" />
  </ContentLoader>
)
