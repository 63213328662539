import { NavigationCommandType } from 'containers/Command/types'
const moduleId = 'mrf'

export enum MRFSubmodule {
  Allocations = 'allocations',
  Parcels = 'parcels',
  Inbounds = 'inbounds',
  InboundsOutbounds = 'inbounds_outbounds',
  Inventory = 'inventory',
  Materials = 'materials',
  Processes = 'processes',
}

const defaultProps = {
  moduleId,
  type: 'navigation',
} as const

export const mrfRoutes: Array<NavigationCommandType> = [
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Allocations,
    commandId: 'list-page',
    to: '/allocations/',
    label: 'pages.mrf_management.allocations.title',
    labelKey: 'consignment',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Allocations,
    commandId: 'add-page',
    to: '/allocations/add_allocation',
    label: 'pages.mrf_management.allocations.add_allocation.title',
    labelKey: 'consignment',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Parcels,
    commandId: 'list-page',
    to: '/parcels',
    label: 'pages.mrf_management.parcels.title',
    labelKey: 'parcel',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Parcels,
    commandId: 'add-page',
    to: '/parcels/add_parcel',
    label: 'pages.mrf_management.parcels.add_parcel.title',
    labelKey: 'parcel',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Inventory,
    commandId: 'list-page',
    to: '/inventory',
    label: 'pages.mrf_management.inventory.title',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Inventory,
    commandId: 'add-page',
    to: '/inventory/add_inventory_record',
    label: 'pages.mrf_management.inventory.add_inventory_record.title',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Inbounds,
    commandId: 'list-page',
    to: '/inbounds',
    label: 'pages.mrf_management.inbound.title',
    labelKey: 'inbound',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Inbounds,
    commandId: 'add-page',
    labelKey: 'inbound',
    to: '/inbounds/add_inbound',
    label: 'pages.mrf_management.inbound.add_inbound.title',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Processes,
    commandId: 'list-page',
    labelKey: 'process',
    to: '/processes',
    label: 'pages.mrf_management.process.title',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.Materials,
    commandId: 'list-page',
    to: '/materials',
    label: 'pages.mrf_management.materials.title',
  },
  {
    ...defaultProps,
    submoduleId: MRFSubmodule.InboundsOutbounds,
    commandId: 'list-page',
    to: '/inbounds_outbounds',
    label: 'pages.mrf_management.inbound.bulk_title',
    labelKey: 'inbounds_outbounds',
  },
]
