import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  ParcelListResponse,
  ParcelListRequest,
  ExportResponse,
  ExportRequest,
  ParcelDetailRequest,
  ParcelDetailResponse,
  SearchAssetRequest,
  SearchAssetResponse,
  EditCarrierResponse,
  EditCarrierRequest,
  ParcelHistoryResponse,
  ParcelHistoryRequest,
  UpdateParcelResponse,
  UpdateParcelRequest,
  ValidateUsabilityRequest,
  ValidateUsabilityResponse,
  BulkMutationResponse,
  BulkDeleteRequest,
  ParcelListByParentIdResponse,
  ParcelListByParentIdRequest,
  ParcelListFromIDListResponse,
  ParcelListFromIDListRequest,
  SearchParcelAttachRequest,
  SearchParcelAttachResponse,
  MutationResponse,
  AddParcelRequest,
  LinkTaskRequest,
  ParcelSearchResponse,
  SearchRequest,
  ChangeParcelStatusRequest,
  UpdateParcelWeightRequest,
  BulkImportResponse,
  BulkImportRequest,
} from './types'
import { getExportHandler, getImportHandler } from 'utils'
import { TableRequestV2External, TableResponseV2 } from 'services/types'

export const parcelApi = createApi({
  reducerPath: 'parcelApi',
  tagTypes: ['Parcels', 'Parcel', 'WorkOrderListByOrderId'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/MRF/'}`,
  }),
  endpoints: (builder) => ({
    getParcelList: builder.query<ParcelListResponse, ParcelListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `v2/parcel/clients/${client_id}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Parcels'],
    }),
    exportParcelList: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/export/parcel/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    getParcelDetails: builder.query<ParcelDetailResponse, ParcelDetailRequest>({
      query: ({ client_id, parcel_id }) => ({
        url: `v2/parcel/clients/${client_id}/?parcel_id=${parcel_id}`,
        method: 'GET',
      }),
      providesTags: ['Parcel'],
    }),
    searchCarrier: builder.mutation<SearchAssetResponse, SearchAssetRequest>({
      query: ({ client_id, carrier_type_id, query_param }) => ({
        url: `clients/${client_id}/asset/search_asset/?query_param=${query_param}&carrier_type_id=${carrier_type_id}`,
        method: 'GET',
      }),
    }),
    editCarrier: builder.mutation<EditCarrierResponse, EditCarrierRequest>({
      query: ({ client_id, parcel_id, ...data }) => ({
        url: `clients/${client_id}/parcel/${parcel_id}/carrier/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels', 'Parcel'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getParcelHistory: builder.query<ParcelHistoryResponse, ParcelHistoryRequest>({
      query: ({ client_id, parcel_id, ...data }) => ({
        url: `clients/${client_id}/parcel/${parcel_id}/history/`,
        method: 'POST',
        data,
      }),
    }),
    updateParcel: builder.mutation<UpdateParcelResponse, UpdateParcelRequest>({
      query: ({ client_id, parcel_id, ...data }) => ({
        url: `v2/parcel/clients/${client_id}/update/${parcel_id}/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels', 'Parcel'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    validateUsability: builder.mutation<ValidateUsabilityResponse, ValidateUsabilityRequest>({
      query: ({ external_client_id, content_type, ...data }) => ({
        url: `external/${external_client_id}/validate/${content_type}/`,
        method: 'POST',
        data,
      }),
    }),
    deleteParcel: builder.mutation<BulkMutationResponse, BulkDeleteRequest<number, string>>({
      query: ({ clientId, deleteIdArray: parcel_id_list }) => ({
        url: `clients/${clientId}/parcel/bulk_delete/`,
        method: 'DELETE',
        data: { parcel_id_list },
        errorOverride: true,
      }),
      invalidatesTags: ['Parcels'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
          sendNotification({
            type: 'error',
            toastContent: (err as any).error.data.failure_list?.[0]?.description,
          })
        }
      },
    }),
    getParcelByParentId: builder.query<ParcelListByParentIdResponse, ParcelListByParentIdRequest>({
      query: ({ client_external_id, model_name, object_id }) => ({
        url: `external/${client_external_id}/${model_name}/${object_id}/list/parcels/`,
        method: 'GET',
      }),
    }),
    getParcelListFromIDList: builder.query<
      ParcelListFromIDListResponse,
      ParcelListFromIDListRequest
    >({
      query: ({ client_external_id, parcel_id_list }) => ({
        url: `external/${client_external_id}/dropdown_list/parcels/`,
        method: 'POST',
        data: parcel_id_list,
      }),
    }),
    searchParcelAttachedTo: builder.mutation<SearchParcelAttachResponse, SearchParcelAttachRequest>(
      {
        query: ({ clientId, attach_to, material_id, search_query, search_fields }) => {
          const searchFields = JSON.stringify(search_fields)
            .replace('true', 'True')
            .replace('false', 'False')
          return {
            url: `v2/parcel/clients/${clientId}/search/attachable_parcels/`,
            method: 'GET',
            params: {
              search_fields: searchFields,
              search_query,
              material_id,
              attach_to,
            },
          }
        },
      },
    ),
    addParcel: builder.mutation<MutationResponse, AddParcelRequest>({
      query: ({ client_id, ...data }) => ({
        url: `v2/parcel/clients/${client_id}/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    linkTaskToParcel: builder.mutation<MutationResponse, LinkTaskRequest>({
      query: ({ client_id, parcel_id, ...data }) => ({
        url: `clients/${client_id}/parcel/${parcel_id}/link_task/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels', 'Parcel'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    unlinkTaskFromParcel: builder.mutation<
      MutationResponse,
      Pick<LinkTaskRequest, 'client_id' | 'parcel_id'>
    >({
      query: ({ client_id, parcel_id }) => ({
        url: `clients/${client_id}/parcel/${parcel_id}/link_task/`,
        method: 'POST',
        data: { task_id: null },
      }),
      invalidatesTags: ['Parcels', 'Parcel'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchParcel: builder.mutation<ParcelSearchResponse, SearchRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/parcel/search/`,
        method: 'POST',
        data,
      }),
    }),

    getParcelListByOrderIdExternal: builder.query<
      TableResponseV2<{}>,
      TableRequestV2External & { id_list: Array<number> }
    >({
      query: ({ client_external_id, ...data }) => ({
        url: `v2/external/${client_external_id}/parcel/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['WorkOrderListByOrderId'],
    }),
    changeParcelStatus: builder.mutation<MutationResponse, ChangeParcelStatusRequest>({
      query: ({ client_id, parcelId, ...data }) => ({
        url: `clients/${client_id}/parcel/${parcelId}/status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels', 'Parcel'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateParcelWeight: builder.mutation<MutationResponse, UpdateParcelWeightRequest>({
      query: ({ client_id, parcelId, data }) => ({
        url: `clients/${client_id}/parcel/${parcelId}/weight/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels', 'Parcel'],
    }),
    bulkImportParcels: builder.mutation<BulkImportResponse, BulkImportRequest>({
      query: ({ clientId, file }) => ({
        url: `v2/parcel/clients/${clientId}/bulk_upload/`,
        method: 'POST',
        data: file,
      }),
      onQueryStarted: getImportHandler,
    }),
  }),
})

export const {
  useGetParcelListQuery,
  useExportParcelListMutation,
  useGetParcelDetailsQuery,
  useSearchCarrierMutation,
  useEditCarrierMutation,
  useGetParcelHistoryQuery,
  useUpdateParcelMutation,
  useValidateUsabilityMutation,
  useDeleteParcelMutation,
  useGetParcelByParentIdQuery,
  useGetParcelListFromIDListQuery,
  useSearchParcelAttachedToMutation,
  useAddParcelMutation,
  useLinkTaskToParcelMutation,
  useSearchParcelMutation,
  useUnlinkTaskFromParcelMutation,
  useGetParcelListByOrderIdExternalQuery,
  useChangeParcelStatusMutation,
  useUpdateParcelWeightMutation,
  useBulkImportParcelsMutation,
} = parcelApi
