import React from 'react'
import { useRtl } from 'utils/useRtl'

export const WithRtl = <T extends object>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
): React.FC<React.PropsWithChildren<T>> => {
  const WithRtl: React.FC<React.PropsWithChildren<T>> = ({ ...props }) => {
    const isRtl = useRtl()
    return <Component {...props} isRtl={isRtl} />
  }

  return WithRtl
}
