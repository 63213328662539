import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import { ActionResponse } from 'services/types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { providesList } from 'services/util'

type SettingsResponse = Array<{
  client: number
  id: number
  limit: number
  mail: boolean
  min_limit: number
  notification: boolean
  option: number
  option_name: string
  type: number
  unit: string
}>

export const settingsApi = createApi({
  reducerPath: 'settingsApi',
  tagTypes: ['Settings'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getSettings: builder.query<SettingsResponse, number | undefined>({
      query: (clientId) => ({
        url: `clients/${clientId}/event_management/settings/`,
        method: 'GET',
      }),
      providesTags: (result) => providesList(result, 'Settings'),
    }),
    updateSettings: builder.mutation<
      ActionResponse,
      {
        clientId: number
        data: Array<{ id: number; mail: boolean; notification: boolean; limit: number }>
      }
    >({
      query({ clientId, data }) {
        return {
          url: `clients/${clientId}/event_management/settings/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Settings'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            //TODO: remove hardcoded translation
            toastContent: 'Success!',
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi
