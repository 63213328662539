import { Flex } from 'components'
import { TypographySkeleton } from 'components/Skeletons/TypographySkeleton'
import React from 'react'

export type DetailHeaderSkeletonProps = {}

export const DetailHeaderSkeleton: React.FC<DetailHeaderSkeletonProps> = () => {
  return (
    <Flex gap={0.5}>
      <TypographySkeleton fontSize={'text_xl'} words={2} />
    </Flex>
  )
}
