import React from 'react'
import { useMapEvents } from 'react-leaflet'

export const MapEvents: React.FC = () => {
  const map = useMapEvents({
    dragend: () => {
      map.invalidateSize()
    },
    zoomend: () => {
      map.invalidateSize()
    },
    resize: () => {
      map.invalidateSize()
    },
    autopanstart: () => {
      map.invalidateSize()
    },
    popupopen: () => {
      map.invalidateSize()
    },
    popupclose: () => {
      map.invalidateSize()
    },
    moveend: () => {
      map.invalidateSize()
    },
    zoomstart: () => {
      map.invalidateSize()
    },
  })

  return null
}
