import { Typography } from 'components'
import React, { useRef } from 'react'
import {
  SubItemStyled,
  SubItemsFlex,
  SubItemsLine,
  SubItemsWrapperStyled,
} from './SidebarMenu.style'
import { SubMenu } from 'services/slices/auth'
import { semanticSidebarColors } from '../Sidebar.style'
import { useSelector } from 'react-redux'
import { selectUi } from 'services/slices/ui'

export type SubItemProps = {
  item: SubMenu
  isPopover?: boolean
}

export const SubItem: React.FC<SubItemProps> = ({ item, isPopover }) => {
  const { currentPageId, sidebarTheme } = useSelector(selectUi)
  const isCurrentRouteSelected =
    currentPageId === item.id || currentPageId?.includes(item.id as string)
  return (
    <SubItemStyled to={item.link} sidebarTheme={isPopover ? 'light' : sidebarTheme}>
      <Typography
        fontSize="text_sm"
        fontWeight="regular"
        color={semanticSidebarColors.text[isPopover ? 'light' : sidebarTheme]}
        // TODO: implement
        // color={
        //   isCurrentRouteSelected
        //     ? 'green500'
        //     : semanticSidebarColors.text[isPopover ? 'light' : sidebarTheme]
        // }
      >
        {item.title}
      </Typography>
    </SubItemStyled>
  )
}

export const SubItemWrapper: React.FC<{
  items: SubMenu[]
  open: boolean
  isPopover?: boolean
}> = ({ items, open, isPopover }) => {
  const childrenRef = useRef<HTMLDivElement>(null)
  return (
    <SubItemsWrapperStyled
      style={{
        height: open ? childrenRef.current?.scrollHeight : '0px',
      }}
    >
      {!isPopover && <SubItemsLine />}
      <SubItemsFlex ref={childrenRef}>
        {items.map((item, index) => (
          <SubItem key={index} item={item} isPopover={isPopover} />
        ))}
      </SubItemsFlex>
    </SubItemsWrapperStyled>
  )
}
