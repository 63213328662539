import { useEffect, useState } from 'react'
import { Range } from 'components'
import { ColumnExtended } from '@evrekadev/evreka-ui-components'

type RangeColumnFilterProps = {
  column: ColumnExtended<any>
  filterValue: [string, string]
  onChange: (id: string, value: string | string[]) => void
  type: string
}

const STEP = 1
const MIN = 0
const MAX = 100

export function RangeColumnFilter({
  column: { id },
  onChange,
  filterValue,
}: RangeColumnFilterProps) {
  const [values, setValues] = useState(
    filterValue === undefined ? [0, 100] : Array.from(filterValue, Number),
  )

  const handleOnChange = (values: number[]) => {
    setValues(values)
    onChange && onChange(id, Array.from(values, String))
  }

  useEffect(() => {
    if (filterValue) {
      setValues(Array.from(filterValue, Number))
    } else {
      setValues([0, 100])
    }
  }, [filterValue])

  return <Range values={values} max={MAX} min={MIN} step={STEP} onChange={handleOnChange}></Range>
}
