import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  GeneralDynamicFieldsResponse,
  GeneralDynamicFieldsRequest,
  ImportantLocationsRequest,
  ImportantLocationsResponse,
  DefaultFiltersByModelRequest,
  DefaultFiltersByModelResponse,
  GenericSearchRequest,
  GenericSearchResponse,
  LastNavigationRecordsResponse,
} from './types'

export const opsGeneralApi = createApi({
  reducerPath: 'opsGeneralApi',
  tagTypes: [],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getDynamicFieldsByModelName: builder.query<
      GeneralDynamicFieldsResponse,
      GeneralDynamicFieldsRequest
    >({
      query: ({ clientId, modelName }) => ({
        url: `clients/${clientId}/ops_management/get_dynamic_fields_for_model/?model_name=${modelName}`,
        method: 'GET',
      }),
    }),
    genericSearchByParams: builder.mutation<GenericSearchResponse, GenericSearchRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/search/`,
        method: 'GET',
        params: {
          model_name: data.model_name,
          requested_values: JSON.stringify(data.requested_values),
          search_fields: JSON.stringify(data.search_fields),
          search_queries: JSON.stringify(data.search_queries),
          order_by: data.order_by,
          requested_labels: JSON.stringify(data.requested_labels),
          filter_expressions: JSON.stringify(data.filter_expressions || []),
          date_format: data.date_format || 'dd.MM.yyyy',
          datetime_format: data.datetime_format,
        },
      }),
    }),
    getImportantLocations: builder.query<ImportantLocationsResponse, ImportantLocationsRequest>({
      query: ({ clientId }) => ({
        url: `clients/${clientId}/ops_management/get_important_locations/`,
        method: 'GET',
      }),
    }),
    getImportantLocationsMarkers: builder.query<
      ImportantLocationsResponse,
      ImportantLocationsRequest
    >({
      query: ({ clientId }) => ({
        url: `clients/${clientId}/ops_management/get_important_locations/`,
        method: 'GET',
      }),
    }),
    getDefaultFiltersByModel: builder.query<
      DefaultFiltersByModelResponse,
      DefaultFiltersByModelRequest
    >({
      query: ({ clientId, modelId }) => ({
        url: `clients/${clientId}/ops_management/column_manager_default_filters/?model_id=${modelId}`,
        method: 'GET',
      }),
    }),
    getLastNavigationRecords: builder.query<
      LastNavigationRecordsResponse,
      { route_ids: Array<number>; client_id: number; date: string }
    >({
      query: ({ client_id, route_ids, date }) => ({
        url: `clients/${client_id}/ops_management/last_navigation_records/`,
        method: 'POST',
        data: {
          route_ids,
          date,
        },
      }),
    }),
  }),
})

export const {
  useGetDynamicFieldsByModelNameQuery,
  useGetImportantLocationsQuery,
  useLazyGetImportantLocationsMarkersQuery,
  useGetDefaultFiltersByModelQuery,
  useGenericSearchByParamsMutation,
  useGetLastNavigationRecordsQuery,
} = opsGeneralApi
