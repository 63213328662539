import { Dropdown } from 'components'
import { ColumnExtended, OptionType } from '@evrekadev/evreka-ui-components'

type MultipleDropdownColumnFilterProps = {
  column: ColumnExtended<any>
  filterValue: string | string[]
  onChange: (id: string, value: string | string[]) => void
  type: string
}

export function MultipleDropdownColumnFilter({
  column: { id, filterOptions },
  onChange,
  filterValue,
}: MultipleDropdownColumnFilterProps) {
  const dropdownValues: OptionType[] =
    filterOptions && filterValue
      ? filterOptions.filter((filterOption: OptionType) =>
          filterValue.includes(filterOption.value.toString()),
        )
      : []

  return (
    <Dropdown
      onChange={(e) => {
        onChange(
          id,
          (e as OptionType[]).map((v) => v.value.toString()),
        )
      }}
      // if portal is not used
      // on change doesn't fire
      // why this is needed?
      menuPortalTarget={document.body}
      isMulti
      options={filterOptions}
      value={dropdownValues}
      componentSize="small"
    />
  )
}
