import { NavigationCommandType } from 'containers/Command/types'

const moduleId = 'asset'

const defaultProps = {
  moduleId,
  submoduleId: 'assets',
  type: 'navigation',
} as const

export const assetRoutes: Array<NavigationCommandType> = [
  {
    ...defaultProps,
    commandId: 'list-page',
    to: '/assets',
    label: 'pages.asset_management.assets.title',
  },
  {
    ...defaultProps,
    commandId: 'add-page',
    to: '/assets/add_asset',
    label: 'pages.asset_management.assets.add_asset.title',
  },
  {
    ...defaultProps,
    commandId: 'settings-page',
    to: '/settings',
    label: 'pages.asset_management.settings.title',
  },
  {
    ...defaultProps,
    commandId: 'activities-page',
    to: '/activities',
    label: 'pages.asset_management.activities.title',
  },
]
