import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { getExportHandler } from 'utils'
import {
  BulkDeleteRequest,
  MutationResponse,
  ProcessDetailsRequest,
  ProcessDetailsResponse,
  ProcessInputParcelsResponse,
  ProcessListRequest,
  ProcessListResponse,
  ProcessOutputParcelsResponse,
  ProcessTableRequest,
  BulkMutationResponse,
  AddProcessRequest,
  EditProcessRequest,
  ProcessActionRequest,
  UpdateProcessStatusRequest,
  ParcelSearchResponse,
  SearchRequest,
  ProcessSearchResponse,
  ExportRequest,
  ExportResponse,
} from './types'

export const processApi = createApi({
  reducerPath: 'processApi',
  tagTypes: ['Processes', 'Process', 'InputParcels', 'OutputParcels', 'ProcessHistory'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/MRF/'}`,
  }),
  endpoints: (builder) => ({
    getProcessList: builder.query<ProcessListResponse, ProcessListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `v2/process/clients/${client_id}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Processes'],
    }),
    getProcessDetails: builder.query<ProcessDetailsResponse, ProcessDetailsRequest>({
      query: ({ client_id, processId }) => ({
        url: `v2/process/clients/${client_id}/?process_id=${processId}`,
        method: 'GET',
      }),
      providesTags: ['Process'],
    }),
    getProcessInputParcels: builder.query<ProcessInputParcelsResponse, ProcessTableRequest>({
      query: ({ client_id, processId, ...data }) => ({
        url: `clients/${client_id}/process/${processId}/list_input_parcels/`,
        method: 'POST',
        data,
      }),
      providesTags: ['InputParcels'],
    }),
    getProcessOutputParcels: builder.query<ProcessOutputParcelsResponse, ProcessTableRequest>({
      query: ({ client_id, processId, ...data }) => ({
        url: `clients/${client_id}/process/${processId}/list_output_parcels/`,
        method: 'POST',
        data,
      }),
      providesTags: ['OutputParcels'],
    }),
    getProcessHistory: builder.query<ProcessOutputParcelsResponse, ProcessTableRequest>({
      query: ({ client_id, processId, ...data }) => ({
        url: `clients/${client_id}/process/${processId}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ProcessHistory'],
    }),
    deleteProcess: builder.mutation<BulkMutationResponse, BulkDeleteRequest<number, string>>({
      query: ({ clientId, deleteIdArray: process_id_list }) => ({
        url: `clients/${clientId}/process/bulk_delete/`,
        method: 'DELETE',
        data: { process_id_list },
        errorOverride: true,
      }),
      invalidatesTags: ['Processes'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
          sendNotification({
            type: 'error',
            toastContent: (err as any).error.data.failure_list?.[0]?.description,
          })
        }
      },
    }),
    addProcess: builder.mutation<MutationResponse, AddProcessRequest>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/process/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Processes'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editProcess: builder.mutation<MutationResponse, EditProcessRequest>({
      query: ({ clientId, processId, body }) => ({
        url: `clients/${clientId}/process/${processId}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Processes', 'Process'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateProcessStatus: builder.mutation<MutationResponse, UpdateProcessStatusRequest>({
      query: ({ clientId, processId, ...data }) => ({
        url: `clients/${clientId}/process/${processId}/status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Processes', 'Process'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    processAction: builder.mutation<MutationResponse, ProcessActionRequest>({
      query: ({ clientId, processId, ...data }) => ({
        url: `clients/${clientId}/process/${processId}/action/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['InputParcels', 'OutputParcels', 'Process'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchProcess: builder.mutation<ProcessSearchResponse, SearchRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/process/search/`,
        method: 'POST',
        data,
      }),
    }),
    exportProcessList: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/export/process/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
  }),
})

export const {
  useGetProcessListQuery,
  useGetProcessDetailsQuery,
  useGetProcessInputParcelsQuery,
  useGetProcessOutputParcelsQuery,
  useGetProcessHistoryQuery,
  useDeleteProcessMutation,
  useAddProcessMutation,
  useEditProcessMutation,
  useUpdateProcessStatusMutation,
  useProcessActionMutation,
  useSearchProcessMutation,
  useExportProcessListMutation,
} = processApi
