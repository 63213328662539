import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { providesList } from 'services/util'

export type CategoriesResponse = Array<{
  id: string
  label: string
  totalEventsNumber: number
}>

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  tagTypes: ['Categories'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getCategories: builder.query<CategoriesResponse, number>({
      query: (clientId) => ({
        url: `clients/${clientId}/event_management/get_categories/`,
        method: 'GET',
      }),
      providesTags: (result) => providesList(result, 'Categories'),
    }),
  }),
})

export const { useGetCategoriesQuery } = categoryApi
