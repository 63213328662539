import { ComponentPropsWithoutRef } from 'react'
import { CircleProps, PolylineProps } from 'react-leaflet'
import {
  DivIcon,
  Icon as LIcon,
  LatLngExpression,
  LatLngTuple,
  LeafletEvent,
  Map as LeafletMap,
  LatLngBoundsExpression,
  LeafletEventHandlerFnMap,
} from 'leaflet'
import {
  ThemeColorKeys,
  ColumnExtended,
  FilterRequestList,
  UniconProps,
  HeaderButtonType,
  TableMapHeaderProps,
} from '@evrekadev/evreka-ui-components'
import { CustomMarkerProps } from './MapMarkers'
import { PopupProps } from './MapMarkers/MarkerPopup'
import { BoundFilter } from './utils'
import { PolygonItem } from './Polygons'
import { MapSettingsType } from './MapSettingsModal/types'
import { strEnum } from '@evrekadev/evreka-ui-components'
import { LegendProps } from './LegendComponent'

export const MAP_SHAPES = strEnum(['single', 'multiple', 'line', 'geofence', 'start_finish'])
export const MAP_SHAPES_V2 = strEnum(['SINGLE', 'MULTIPLE', 'LINE', 'GEOFENCE', 'START_FINISH'])

export type Coordinate = {
  latitude: number
  longitude: number
}

export type MapLocation =
  | {
      start_location: Coordinate
      finish_location: Coordinate
      type: typeof MAP_SHAPES.start_finish
    }
  | {
      locations: Coordinate[]
      location: Coordinate
      type: typeof MAP_SHAPES.geofence
    }
  | {
      coordinates: Coordinate[]
      type: typeof MAP_SHAPES.multiple
    }
  | {
      coordinates: Coordinate[]
      address: string
      type: typeof MAP_SHAPES.single
    }

export type MapLocationV2 = {
  start_location?: Coordinate
  finish_location?: Coordinate
  locations?: Coordinate[]
  location?: Coordinate
  coordinates: Coordinate[]
  address: string
  type: typeof MAP_SHAPES_V2
}

export type InfoAreaType = {
  isOpen: boolean
  type?: 'filter' | 'bulk'
}

export type ShapeWithoutColor<S> = Omit<S, 'color' | 'fillColor'> & {
  color?: ThemeColorKeys
  fillColor?: ThemeColorKeys
}

export type ShowBoundOrCenter = {
  center: L.LatLngTuple | L.LatLngLiteral | undefined
}

export type LegendItem = {
  icon?: ComponentPropsWithoutRef<'svg'>
  text: string
}

export type LegendType = {
  header?: string
  items?: LegendItem[]
  isLoading?: boolean
  isOpen?: boolean
}

export type MapContextType = {
  name?: string
  timezone?: string
  zoom?: number
  scrollWheelZoom?: boolean
  markers?: any[]
  onFitToMarkers?: VoidFunction
  popup?: PopupProps
  polylines?: ShapeWithoutColor<PolylineProps>[]
  circles?: ShapeWithoutColor<CircleProps>[]
  polygons?: PolygonItem[]
  legend?: Exclude<LegendProps, 'isRtl'>
  location?: LatLngExpression
  bounds?: LatLngBoundsExpression
  selectControlOnClick?: (e: LatLngTuple | undefined) => void
  icon?: LIcon | DivIcon | React.FC<React.PropsWithChildren<UniconProps>>
  isZoomControlVisible?: boolean
  isLayerControlVisible?: boolean
  onDrawStart?: (e?: LeafletEvent) => void
  onCreated?: (e?: LeafletEvent) => void
  onDeleted?: (e?: LeafletEvent) => void
  onEdited?: (e?: LeafletEvent) => void
  isRtl?: boolean
  renderMapSearch?: boolean
  defaultSearchLocation?: LatLngTuple
  getMapInstance?: (map: LeafletMap) => void
  onBoundChange?: (bounds: BoundFilter) => void
  onAddressChange?: (address?: string) => void
  extraMapHeaderButtons?: ExtraMapHeaderButtons
  mapCaptionButtonElement?: React.ReactNode
  mapCaptionButtons?: TableMapHeaderProps['tableCaptionButtons']
  toggle?: TableMapHeaderProps['toggle']
  refresh?: TableMapHeaderProps['refresh']
  noAddressCheck?: boolean

  /**
   * @deprecated
   */
  centerAutomatically?: boolean
  isClusteringEnabled?: boolean
  isFilter?: (
    setFilterVisibility: React.Dispatch<React.SetStateAction<boolean>>,
    setIconType: React.Dispatch<React.SetStateAction<boolean>>,
  ) => React.ReactNode
  isCustomFilter?: boolean
  // onSelectMarker?:()=>void
  // Map Header Props: if any of these (or MapControlProps) are given, the map will have a header -->
  heading?: string
  backToListView?: () => void
  customFilterArea?: React.ReactNode
  selectedMarkers?: CustomMarkerProps[]
  onMarkerSelect?: (marker: CustomMarkerProps) => void
  onFetch?: (filter: FilterRequestList) => void
  columns?: ColumnExtended<any>[]
  settings?: MapSettingsType
  onChangeSettings?: (settings: MapSettingsType) => void
  isEnabledBulkSelection?: boolean
  onToggleBulkSelection?: (value: boolean) => void
  onBulkSelection?: (selectedMarkers: CustomMarkerProps[]) => void
  // <-- Map Header Props

  dataTestId?: string
  events?: LeafletEventHandlerFnMap
  disableInternalParams?: boolean
  externalSearchProps?: {
    queryFn:
      | ((
          query: string,
        ) => Promise<
          Array<{ title: string; description: string; value: string | number }> | undefined
        >)
      | null
    header: string
    onSelectOption: (id: string | number) => void
    placeholder: string
  }
  noHeader?: boolean
  setAddress?: React.Dispatch<React.SetStateAction<string>>
  polygonCustomKey?: boolean
  onPolygonSelect?: (polygon: PolygonItem & { id: number }) => void
  polygonPopup?: PopupProps
  customFullScreen?: {
    onFullscreenChange: () => void
    isFullscreen: boolean
  }
} & Pick<
  TableMapHeaderProps,
  | 'bulkMode'
  | 'bulkActions'
  | 'onClearSelection'
  | 'onSelectAll'
  | 'selectedItemCount'
  | 'totalCount'
>

export type ExtraMapHeaderButtons = Array<HeaderButtonType & { placement?: 'right' | 'left' }>
