export enum LANGUAGES {
  ARABIC = 'ar',
  ENGLISH = 'en',
  FRENCH = 'fr',
  GERMAN = 'de',
  HEBREW = 'he',
  MALESIAN = 'ms',
  DUTCH = 'nl',
  NORWEGIAN = 'no',
  PERSIAN = 'pt',
  RUSSIAN = 'ru',
  SPANISH = 'es',
  TURKISH = 'tr',
  VIETNAMESE = 'vi',
  CHINESE = 'zh',
}

export enum FLAGS {
  ar = '🇦🇪',
  en = '🇬🇧',
  fr = '🇫🇷',
  de = '🇩🇪',
  he = '🇮🇱',
  ms = '🇲🇾',
  nl = '🇳🇱',
  no = '🇳🇴',
  pt = '🇵🇹',
  ru = '🇷🇺',
  es = '🇪🇸',
  tr = '🇹🇷',
  vi = '🇻🇳',
  zh = '🇨🇳',
}
