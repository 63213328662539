import { Grid, GridCell } from 'components'
import { InputSkeleton } from 'components/Skeletons'
import React from 'react'
type GridProps = {
  gap?: number
  columns?: string | number
  columnGap?: number
  rowGap?: number
  minRowHeight?: string
  rows?: string | number
  justifyContent?: 'center' | 'flex-start' | 'flex-end'
  alignContent?: 'center' | 'flex-start' | 'flex-end'
  style?: React.CSSProperties
  fullHeight?: boolean
  fullWidth?: boolean
  className?: string
}
type InputGridSkeletonProps = {
  gridProps?: GridProps
  count?: number
}

export const InputGridSkeleton: React.FC<React.PropsWithChildren<InputGridSkeletonProps>> = ({
  gridProps,
  count = 4,
}) => {
  const countArray = Array.from({ length: count })
  return (
    <Grid {...gridProps}>
      {countArray.map((_, i) => (
        <GridCell key={i}>
          <InputSkeleton />
        </GridCell>
      ))}
    </Grid>
  )
}
