import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'

export type InsightResponse = {
  tokenId: string
  accessToken: string
  tokenExpiry: string
  lastRefreshTime: string
  reportConfig: Array<{
    reportId: string
    reportName: string
    embedUrl: string
    datasetId: any
    bookmarksVisible: boolean
    filtersVisible: boolean
    pageNavVisible: boolean
    slicers: Array<EvrekaPowerBISlicer>
  }>
}

export type EvrekaPowerBISlicer = {
  type: 'date' | 'client' | 'top_one'
  column: string
  table: string
}

export type InsightRequest = {
  clientId?: number
  report_id?: number
  object_id?: string
}

export const insightApi = createApi({
  reducerPath: 'insightApi',
  tagTypes: ['Insights'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/v2/'}`,
  }),
  endpoints: (builder) => ({
    getInsight: builder.query<InsightResponse, InsightRequest>({
      query: ({ clientId, report_id, object_id }) => ({
        url: `clients/${clientId}/insight/${report_id}${
          object_id ? `?object_id=${object_id}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetInsightQuery } = insightApi
