import React, { useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { renderToStaticMarkup } from 'react-dom/server'
import { divIcon, PointTuple, DivIcon, Icon as LIcon } from 'leaflet'
import { MarkerProps, useMap } from 'react-leaflet'
import {
  UniconProps,
  BaseColorType,
  IconNames,
  ThemeColors,
  ThemeColorKeys,
} from '@evrekadev/evreka-ui-components'
import { Icon } from 'components'
import { getFeatureGroup } from '../BoundControl'
import Marker from './Marker'
import getThemeColorName from 'utils/getThemeColorName'
import { MapContext } from 'context'
import { MapContextType } from '../types'
import { OpsServicePointType } from 'services/OpsManagement/types'

type IconGeneratorParamsType = {
  color?: BaseColorType | null
  icon?: React.FC<React.PropsWithChildren<UniconProps>> | IconNames
  size?: number
}

type LegendMarkerProps = {
  color: string
}

export const getColor = (color: string | null) => {
  if (color == null) return
  if (color.startsWith('#')) {
    return ThemeColors[getThemeColorName(color)]
  }
  if (Object.keys(ThemeColors).find((key) => key === color)) {
    return ThemeColors[color as ThemeColorKeys]
  }
  return color
}

export const LegendMarker = styled.div<LegendMarkerProps>`
  width: 2em;
  height: 2em;
  background-color: ${({ color }) => getColor(color)};
  border-radius: 50%;
`

export const getColors = (color?: BaseColorType | null): ThemeColorKeys => {
  if (!color) return 'darkblue900'

  if (color === 'white' || color === 'black' || color === 'transparent') {
    return 'darkblue900'
  } else {
    return `${color}500`
  }
}

export const getDivIcon = ({
  icon,
  iconSize,
  iconAnchor,
  customClassName,
}: {
  iconSize: number[]
  iconAnchor: number[]
  icon: React.ReactElement
  customClassName?: string
}) => {
  return divIcon({
    className: `custom-icon ${customClassName ?? ''}`,
    iconAnchor: iconAnchor as PointTuple,
    iconSize: iconSize as PointTuple,
    html: renderToStaticMarkup(icon),
  })
}

const iconSize = [24, 36]
const iconAnchor = [12, 36]

export function iconGenerator({ color, icon, size }: IconGeneratorParamsType) {
  return divIcon({
    className: 'custom-icon',
    iconAnchor: iconAnchor as PointTuple,
    iconSize: iconSize as PointTuple,
    html: renderToStaticMarkup(<Icon icon={icon} size={size ?? 36} color={getColors(color)} />),
  })
}

export type CustomMarkerState = {
  current: string | number
  iconOptions: {
    [key: string | number]: DivIcon
  }
}

export type BaseMarkerProps = MarkerProps & {
  name?: string
  icon?: {
    idle: string | React.ReactNode | DivIcon
    hovered?: string | React.ReactNode | DivIcon
    selected?: string | React.ReactNode | DivIcon
    type?: OpsServicePointType
  }
  color?: BaseColorType | null
  state?: CustomMarkerState
  id: number | string
  tooltip?: {
    offset: PointTuple
    permanent?: boolean
  }
  isClusterable?: boolean
  anchorPoint?: PointTuple
  selectable?: boolean
  isDisabledPopup?: boolean
}

export type CustomMarkerProps = BaseMarkerProps & MarkerProps

export type MapMarkersProps = {
  markers?: CustomMarkerProps[]
  fitToMarkers?: boolean
}

export function isFunction(x: any): x is React.FC<React.PropsWithChildren<unknown>> {
  return typeof x === 'function'
}

export const MapMarkers: React.FC<React.PropsWithChildren<MapMarkersProps>> = ({
  markers,
  fitToMarkers,
}) => {
  const map = useMap()

  const { selectedMarkers, isEnabledBulkSelection } = useContext<MapContextType>(MapContext)

  useEffect(() => {
    if (fitToMarkers && markers?.length) {
      const featureGroup = getFeatureGroup(markers)
      map.fitBounds(featureGroup.getBounds().pad(0.1))
    }
  }, [map, markers, fitToMarkers])

  const mapMarkersContent = useMemo(() => {
    return (
      markers?.length &&
      markers.map((marker, idx) => {
        const isSelected = !!selectedMarkers?.find((selected) => selected.id === marker.id)

        if (isEnabledBulkSelection && !marker.selectable) {
          return null
        }

        return <Marker key={marker.id || idx} marker={marker} isSelected={isSelected} />
      })
    )
  }, [markers, isEnabledBulkSelection, selectedMarkers])

  return markers?.length ? <>{mapMarkersContent}</> : null
}
