import { color, shadow } from '@evrekadev/evreka-ui-components'
import { UilAngleLeft } from '@iconscout/react-unicons'
import { Icon } from 'components'
import React from 'react'
import styled from 'styled-components'
import { SIDEBAR_SIZES } from './Sidebar.style'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { selectUi, toggleSidebarCollapsed } from 'services/slices/ui'
import { SidebarBaseProps } from './Sidebar'

export const ToggleButton: React.FC = () => {
  const dispatch = useDispatch()
  const { sidebarCollapsed } = useSelector(selectUi)

  return (
    <ToggleStyled
      onClick={() => dispatch(toggleSidebarCollapsed())}
      sidebarCollapsed={sidebarCollapsed}
    >
      <ToggleIconStyled icon={UilAngleLeft} color="grey500" size={24} />
    </ToggleStyled>
  )
}

const ToggleIconStyled = styled(Icon)``

const ToggleStyled = styled.button<Pick<SidebarBaseProps, 'sidebarCollapsed'>>`
  ${shadow('xs')}
  position: fixed;
  z-index: 1010;
  left: calc(
    ${({ sidebarCollapsed }) =>
        sidebarCollapsed ? SIDEBAR_SIZES.collapsed : SIDEBAR_SIZES.expanded} - 12px
  );
  top: 20px;
  background-color: ${color('white')};
  border: 1px solid ${color('grey100')};
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  transition: left 0.3s ease-in-out;

  ${ToggleIconStyled} {
    ${({ sidebarCollapsed }) => sidebarCollapsed && `transform: rotate(180deg);`}
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    background-color: ${color('grey50')};
  }
`
