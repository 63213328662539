import { NavigationCommandType } from 'containers/Command/types'

const moduleId = 'ops'

enum OpsSubmodule {
  RoutesAndTasks = 'routes_and_tasks',
  Planning = 'planning',
  Cockpit = 'cockpit',
}

const defaultProps = {
  moduleId,
  type: 'navigation',
} as const

export const opsRoutes: Array<NavigationCommandType> = [
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.RoutesAndTasks,
    commandId: 'list-routes-page',
    to: '/routes_and_tasks/0',
    label: 'pages.ops_management.routes_and_tasks.routes.title',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.RoutesAndTasks,
    commandId: 'list-tasks-page',
    to: '/routes_and_tasks/1',
    label: 'pages.ops_management.routes_and_tasks.tasks.title',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.RoutesAndTasks,
    commandId: 'add-task-page',
    to: '/routes_and_tasks/add_adhoc_task',
    label: 'pages.ops_management.service_points.add_task.title',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.RoutesAndTasks,
    commandId: 'add-route-page',
    to: '/routes_and_tasks/add_ad_hoc_route',
    label: 'pages.ops_management.routes_and_tasks.add_ad_hoc_route.title',
  },

  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'add-service-point-page',
    to: '/planning/service_points/add',
    label: 'pages.ops_management.service_points.add.title',
  },

  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'add-task-order-page',
    to: '/planning/service_points/add_task_order/',
    label: 'pages.ops_management.service_points.add.task_order_title',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'add-static-tour-page',
    to: '/planning/static_tours/add',
    label: 'pages.ops_management.static_tours.add.title',
  },

  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'add-task-plan-page',
    to: '/planning/task_plans/add_task_order/',
    label: 'pages.ops_management.task_plans.add',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'add-route-plan-page',
    to: '/planning/route_plans/add',
    label: 'pages.ops_management.route_plans.add.title',
  },

  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'add-geomap-page',
    to: '/planning/geomaps/add',
    label: 'pages.ops_management.geomaps.upsert.title',
  },

  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Cockpit,
    commandId: 'map-page',
    to: '/cockpit',
    label: 'pages.ops_management.cockpit.title',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'list-route-plans-page',
    to: '/planning/route_plans',
    label: 'pages.ops_management.planning.route_plans',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'list-task-plans-page',
    to: '/planning/task_plans',
    label: 'pages.ops_management.planning.task_plans',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'list-service-points-page',
    to: '/planning/service_points',
    label: 'pages.ops_management.planning.service_points',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'list-static-tours-page',
    to: '/planning/static_tours',
    label: 'pages.ops_management.planning.static_tours',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'list-regions-page',
    to: '/planning/regions',
    label: 'pages.ops_management.planning.regions',
  },
  {
    ...defaultProps,
    submoduleId: OpsSubmodule.Planning,
    commandId: 'list-geomaps-page',
    to: '/planning/geomaps',
    label: 'pages.ops_management.planning.geomaps',
  },
]
