import React, { useRef, useState } from 'react'
import {
  MenuItemBadge,
  MenuItemChevron,
  MenuItemLabel,
  MenuItemStyled,
  ModuleIcon,
  PopoverSubItems,
} from './SidebarMenu.style'
import { Badge, Icon, Typography } from 'components'
import { UilAngleDown, UilChartLine, UilPresentationLine, UilStar } from '@iconscout/react-unicons'
import { Link } from 'react-router-dom'
import { BadgeProps, BaseColorType, IconNames } from '@evrekadev/evreka-ui-components'
import { SubItemWrapper } from './SubItem'
import { MenuItem as MenuItemType } from 'services/slices/auth'
import { SidebarColorTheme } from '../Sidebar'
import { semanticSidebarColors } from '../Sidebar.style'
import { useSelector } from 'react-redux'
import { selectUi } from 'services/slices/ui'
import { createPopper } from '@popperjs/core'
import { usePopper } from 'react-popper'

export type MenuItemProps = {
  icon: SidebarModules
  label: string
  links?: MenuItemType
  badge?: {
    label: string
    color: BadgeProps['color']
  }
  isSOS?: boolean
}

export type SidebarModules =
  | 'ops'
  | 'fleet'
  | 'asset'
  | 'engagement'
  | 'mrf'
  | 'user'
  | 'event'
  | 'report'
  | 'document'
  | 'favorites'
  | 'insights'

const getModuleIcon = (module: SidebarModules): IconNames => {
  switch (module) {
    case 'ops':
    default:
      return 'menu-operations'
    case 'fleet':
      return 'menu-fleet'
    case 'asset':
      return 'menu-asset'
    case 'engagement':
      return 'menu-engagement'
    case 'mrf':
      return 'menu-mrf'
    case 'user':
      return 'menu-users'
    case 'event':
      return 'menu-events'
    case 'report':
      return 'menu-reports'
    case 'document':
      return 'menu-document'
    case 'favorites':
      return UilStar
  }
}

export const MenuItem: React.FC<MenuItemProps> = ({ icon, label, links, badge, isSOS = false }) => {
  const { currentModuleId, sidebarCollapsed, sidebarTheme } = useSelector(selectUi)
  const [isOpen, setIsOpen] = useState(false)
  const [isShown, setIsShown] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    strategy: 'fixed',
    placement: 'right-start',
  })

  const submenus = links?.subMenus
  const isModuleSelected = currentModuleId === icon

  const props =
    links?.link != null ? ({ as: Link, to: links.link } as const) : { as: 'button' as const }

  return (
    <div onMouseOver={() => setIsShown(true)} onMouseOut={() => setIsShown(false)}>
      <MenuItemStyled
        sidebarCollapsed={sidebarCollapsed}
        onClick={() => setIsOpen((o) => !o)}
        sidebarTheme={sidebarTheme}
        ref={setReferenceElement}
        {...(props as any)}
      >
        <ModuleIcon
          isSOS={!!isSOS}
          sidebarTheme={sidebarTheme}
          icon={getModuleIcon(icon)}
          size={20}
          color={isModuleSelected ? 'green500' : semanticSidebarColors['text-muted'][sidebarTheme]}
        />
        <MenuItemLabel
          fontSize="text_sm"
          fontWeight="medium"
          noWrap
          sidebarCollapsed={sidebarCollapsed}
          color={semanticSidebarColors[isModuleSelected ? 'text-active' : 'text'][sidebarTheme]}
        >
          {label}
        </MenuItemLabel>

        {badge && (
          <MenuItemBadge sidebarCollapsed={sidebarCollapsed} isSOS={!!isSOS}>
            <Badge size="small" color={badge.color}>
              {badge.label}
            </Badge>
          </MenuItemBadge>
        )}
        {submenus && submenus.length > 0 && (
          <MenuItemChevron
            sidebarCollapsed={sidebarCollapsed}
            icon={UilAngleDown}
            size={20}
            color={semanticSidebarColors['text-muted'][sidebarTheme]}
          />
        )}
      </MenuItemStyled>
      {sidebarCollapsed && submenus && isShown && (
        <div
          ref={(ref) => setPopperElement(ref)}
          style={styles.popper}
          onMouseOver={() => setIsShown(true)}
          onMouseOut={() => setIsShown(false)}
          {...attributes.popper}
        >
          <PopoverSubItems>
            <SubItemWrapper items={submenus} open isPopover />
          </PopoverSubItems>
        </div>
      )}
      {!sidebarCollapsed && submenus && <SubItemWrapper items={submenus} open={isOpen} />}
    </div>
  )
}
