import React from 'react'
import { BreadCrumbType } from 'utils/useBreadcrumb'
import { ListHeader, ListHeaderProps } from 'containers/Header'
import { LayoutListChildWrapper, LayoutListWrapper } from '../Common.style'

type ListComponent = React.FC<React.PropsWithChildren<ListProps>>

type ListProps = {
  breadcrumbs?: BreadCrumbType[]
} & ListHeaderProps

export const List: ListComponent = ({
  children,
  breadcrumbs,
  onTabChange,
  tabs,
  selectedIndex,
  noTitle,
}) => {
  return (
    <LayoutListWrapper directionColumn="zero">
      <ListHeader
        tabs={tabs}
        onTabChange={onTabChange}
        breadcrumbs={breadcrumbs}
        selectedIndex={selectedIndex}
        noTitle={noTitle}
      />
      <LayoutListChildWrapper>{children}</LayoutListChildWrapper>
    </LayoutListWrapper>
  )
}
