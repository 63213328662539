import { UilQuestion } from '@iconscout/react-unicons'
import { Button, Message } from 'components'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { selectCurrentUser } from 'services/slices/auth'
import { useTranslation } from 'utils/useTranslation'

export const NotFound: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation('pages.common.fallback.not_found')
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const isCustomerPortalUser = user?.customer_portal_credentials != null
  return (
    <Message
      icon={{ icon: UilQuestion, color: 'darkblue600' }}
      heading={t('info_header_text')}
      subtitle={t('info_text')}
      primaryButton={
        <Button
          variant="text"
          onClick={() =>
            navigate(
              isCustomerPortalUser
                ? `/engagement/customer_portal/${user.customer_portal_credentials?.entity_id}`
                : '/',
            )
          }
        >
          {t('go_back')}
        </Button>
      }
      fullPage
    />
  )
}
