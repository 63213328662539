import { usersApi } from './users'

export const {
  useGetUsersQuery,
  useDeleteUserMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUserDetailsQuery,
  useGetUserPermissionsQuery,
  useChangePasswordMutation,
  useUpdateProfileMutation,
  useCheckUsernameMutation,
} = usersApi
