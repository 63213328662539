import { Icon, IconNames, ThemeColorKeys, ThemeColors } from '@evrekadev/evreka-ui-components'
import { CustomMarker } from './CustomMarker'
import { getDivIcon } from '../MapMarkers'
import { OpsServicePointType } from 'services/OpsManagement/types'

export const getCustomMapMarker = (
  color?: ThemeColors | string,
  isSelected?: boolean,
  opacity?: number,
  type?: OpsServicePointType,
  icon?: IconNames,
) => {
  const selectedSizes = {
    iconSize: [40, 40],
    iconAnchor: [20, 20],
  }

  return {
    iconSize: [22, 26],
    iconAnchor: [11, 13],
    ...(isSelected ? selectedSizes : {}),
    icon: icon ? (
      <Icon icon={icon} />
    ) : (
      <CustomMarker color={color} isSelected={isSelected} opacity={opacity} type={type} />
    ),
  }
}

export const getCustomMapMarkerIcon = (
  color?: ThemeColors | ThemeColorKeys,
  isSelected?: boolean,
  opacity?: number,
  type?: OpsServicePointType,
  icon?: IconNames,
) => {
  const customMarker = getCustomMapMarker(color, isSelected, opacity, type, icon)

  return getDivIcon(customMarker)
}
