import { NavigationCommandType } from 'containers/Command/types'

const moduleId = 'fleet'

enum FleetSubmodule {
  Vehicle = 'vehicle',
  Tracking = 'tracking',
  RFIDTags = 'rfid_tags',
}

const defaultProps = {
  moduleId,
  type: 'navigation',
} as const

export const fleetRoutes: Array<NavigationCommandType> = [
  {
    ...defaultProps,
    submoduleId: FleetSubmodule.Vehicle,
    commandId: 'list-page',
    to: '/vehicles',
    label: 'pages.fleet_management.vehicles.title',
  },
  {
    ...defaultProps,
    submoduleId: FleetSubmodule.Vehicle,
    commandId: 'add-page',
    to: '/vehicles/add_vehicle',
    label: 'pages.fleet_management.vehicle_upsert.add_title',
  },

  {
    ...defaultProps,
    submoduleId: FleetSubmodule.Tracking,
    commandId: 'list-page',
    to: '/tracking',
    label: 'pages.fleet_management.tracking.title',
  },
  {
    ...defaultProps,
    submoduleId: FleetSubmodule.Tracking,
    commandId: 'history-page',
    to: '/tracking/history',
    label: 'pages.fleet_management.tracking.track_history',
  },
  {
    ...defaultProps,
    submoduleId: FleetSubmodule.Vehicle,
    commandId: 'activities-page',
    to: '/activities',
    label: 'pages.fleet_management.activities.title',
  },
  {
    ...defaultProps,
    submoduleId: FleetSubmodule.RFIDTags,
    commandId: 'list-page',
    to: '/rfid_tags',
    label: 'pages.fleet_management.rfid_tags.title',
  },
]
