import styled from 'styled-components'

export type FleetVehicleIconProps = {
  isSelected?: boolean
  color?: any //TODO: fix me
  colorOpposite?: any //TODO: fix me
}

export const FleetVehicleIconWrapperStyled = styled.svg<FleetVehicleIconProps>`
  width: ${({ isSelected }) => (isSelected ? '56px' : '40px')};
  height: ${({ isSelected }) => (isSelected ? '56px' : '40px')};
  fill: none;
`

export const FleetVehicleIconBorderStyled = styled.circle<FleetVehicleIconProps>`
  fill: ${({ isSelected, colorOpposite }) => (isSelected ? colorOpposite : '#fff')};
  stroke: ${({ isSelected, color }) => (!isSelected ? color : '#fff')};
  stroke-width: 2;
`
