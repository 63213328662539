import { authApi } from './auth'

export const {
  useGetAuthQuery,
  useGetAuthWithUserQuery,
  useLogoutMutation,
  useLoginMutation,
  useForgotPasswordMutation,
  useLazyGetAuthQuery,
  useChangePasswordRecoveryMutation,
} = authApi

export { authApi } from './auth'
