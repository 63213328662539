import { createApi } from '@reduxjs/toolkit/query/react'
import {
  MutationResponse,
  ExportRequest,
  CustomFieldEngResponse,
  ContactDetailResponse,
  ContactUpsertRequestBody,
  ChangePasswordRequest,
  TableResponseV2WithTimezone,
} from 'services/Engagement/types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { sendNotification } from 'components'
import { getExportHandler, getImportHandler } from 'utils'
import i18n from 'utils/i18n'
import { TableRequestV2, TableResponse, TableResponseV2 } from 'services/types'

export const contactApi = createApi({
  reducerPath: 'contactApi',
  tagTypes: ['Contacts', 'ContactDetail', 'EntityContacts'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    getContactList: builder.query<
      TableResponseV2WithTimezone<{}>,
      TableRequestV2 & { entity_id?: string }
    >({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/contact/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Contacts'],
    }),
    importContacts: builder.mutation<MutationResponse, { clientId: string; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `engagement/clients/${clientId}/contact/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Contacts'],
      onQueryStarted: getImportHandler,
    }),
    addContact: builder.mutation<
      MutationResponse,
      {
        clientId: string | undefined
        body: FormData
      }
    >({
      query: ({ clientId, body }) => ({
        url: `engagement/clients/${clientId}/contact/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Contacts', 'EntityContacts'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editContact: builder.mutation<
      MutationResponse,
      {
        clientId: string | undefined
        body: FormData | ContactUpsertRequestBody
        contact_id: string
      }
    >({
      query: ({ clientId, contact_id, body }) => ({
        url: `engagement/clients/${clientId}/contact/${contact_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Contacts', 'ContactDetail', 'EntityContacts'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getContactDetail: builder.query<
      ContactDetailResponse,
      { client_id: string; contact_id: string }
    >({
      query: ({ client_id, contact_id }) => ({
        url: `engagement/clients/${client_id}/contact/?contact_id=${contact_id}`,
        method: 'GET',
      }),
      providesTags: ['ContactDetail'],
    }),
    deleteContact: builder.mutation<MutationResponse, { client_id: string; contact_id: string }>({
      query: ({ client_id, contact_id }) => ({
        url: `engagement/clients/${client_id}/contact/${contact_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contacts', 'EntityContacts'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    validateContactPhone: builder.mutation<
      { is_valid: boolean },
      { client_id: string; body: FormData }
    >({
      query: ({ client_id, body }) => ({
        url: `engagement/clients/${client_id}/contact/validate/phone/`,
        method: 'POST',
        data: body,
      }),
    }),
    validateContactEmail: builder.mutation<
      { is_valid: boolean },
      { client_id: string; body: FormData }
    >({
      query: ({ client_id, body }) => ({
        url: `engagement/clients/${client_id}/contact/validate/email/`,
        method: 'POST',
        data: body,
      }),
    }),
    getContactCustomFields: builder.query<CustomFieldEngResponse, { client_id: string }>({
      query: ({ client_id }) => ({
        url: `engagement/clients/${client_id}/contact_dynamic_fields/`,
        method: 'GET',
      }),
    }),
    changePassword: builder.mutation<
      MutationResponse & { is_invalid_current_password: boolean },
      ChangePasswordRequest
    >({
      query: ({ client_id, user_id, body }) => ({
        url: `engagement/clients/${client_id}/user/${user_id}/change_password/`,
        method: 'POST',
        data: body,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportContacts: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/contact/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
  }),
})

export const {
  useGetContactListQuery,
  useImportContactsMutation,
  useAddContactMutation,
  useEditContactMutation,
  useGetContactDetailQuery,
  useDeleteContactMutation,
  useValidateContactEmailMutation,
  useValidateContactPhoneMutation,
  useGetContactCustomFieldsQuery,
  useChangePasswordMutation,
  useExportContactsMutation,
} = contactApi
