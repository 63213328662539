export const checkImage = async (url: RequestInfo | URL): Promise<boolean> => {
  try {
    const res = await fetch(url)
    const buff = await res.blob()
    return buff.type.startsWith('image/')
  } catch (error) {
    console.error('Error checking image:', error)
    return false
  }
}
