import { useTranslation } from 'utils/useTranslation'
import { useSelector } from 'react-redux'
import { selectClientModules, selectMenu } from 'services/slices/auth'
import { MRFSubmodule, mrfRoutes } from './Commands/routes/mrfRoutes'
import { CommandType, NavigationCommandType } from './types'

export const useMrfNavigationCommands = () => {
  const { t } = useTranslation()
  const menu = useSelector(selectMenu)
  const availableModules = useSelector(selectClientModules)

  const hasMrf = availableModules.mrf

  if (!hasMrf) {
    return []
  }

  const mrfSidebar = menu?.find(({ id }) => id === 'mrf')
  const parcelLabel = mrfSidebar?.subMenus?.find(({ id }) => id === 'parcels')?.title
  const inboundLabel = mrfSidebar?.subMenus?.find(({ id }) => id === 'inbounds')?.title
  const allocationLabel = mrfSidebar?.subMenus?.find(({ id }) => id === 'allocations')?.title
  const processLabel = mrfSidebar?.subMenus?.find(({ id }) => id === 'processes')?.title
  const inventoryLabel = mrfSidebar?.subMenus?.find(({ id }) => id === 'inventory')?.title
  const materialsLabel = mrfSidebar?.subMenus?.find(({ id }) => id === 'materials')?.title
  const inboundsOutboundsLabel = mrfSidebar?.subMenus?.find(
    ({ id }) => id === 'inbounds_outbounds',
  )?.title

  const parsedMrfRoutes: Array<NavigationCommandType> = []

  for (const route of mrfRoutes) {
    const mergedCommandId = route.moduleId + '_' + route.submoduleId + '_' + route.commandId
    let translatedLabel = ''
    if (route.labelKey) {
      switch (route.submoduleId) {
        case MRFSubmodule.Parcels:
          translatedLabel = t(route.label, { id: parcelLabel })
          break
        case MRFSubmodule.Inbounds:
          translatedLabel = t(route.label, { id: inboundLabel })
          break
        case MRFSubmodule.Allocations:
          translatedLabel = t(route.label, { id: allocationLabel })
          break
        case MRFSubmodule.Processes:
          translatedLabel = t(route.label, { id: processLabel })
          break
        case MRFSubmodule.InboundsOutbounds:
          translatedLabel = inboundsOutboundsLabel || ''
          break
        default:
          break
      }
    } else {
      translatedLabel = t(route.label)
    }

    switch (route.submoduleId) {
      case MRFSubmodule.Parcels:
        if (parcelLabel) {
          parsedMrfRoutes.push({
            ...route,
            type: 'navigation',
            label: translatedLabel,
          })
        }
        break
      case MRFSubmodule.Inbounds:
        if (inboundLabel) {
          parsedMrfRoutes.push({
            ...route,
            label: translatedLabel,
          })
        }
        break
      case MRFSubmodule.Allocations:
        if (allocationLabel) {
          parsedMrfRoutes.push({
            ...route,
            label: translatedLabel,
          })
        }
        break
      case MRFSubmodule.Processes:
        if (processLabel) {
          parsedMrfRoutes.push({
            ...route,
            label: translatedLabel,
          })
        }
        break
      case MRFSubmodule.InboundsOutbounds:
        if (inboundsOutboundsLabel) {
          parsedMrfRoutes.push({
            ...route,
            label: translatedLabel,
          })
        }
        break
      case MRFSubmodule.Inventory:
        if (inventoryLabel) {
          parsedMrfRoutes.push({
            ...route,
            label: translatedLabel,
          })
        }
        break
      case MRFSubmodule.Materials:
        if (materialsLabel) {
          parsedMrfRoutes.push({
            ...route,
            label: translatedLabel,
          })
        }
        break
      default:
        break
    }
  }
  return parsedMrfRoutes
}
