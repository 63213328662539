import { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios, { AxiosRequestConfig } from 'axios'
import i18n from 'utils/i18n'
import { FingerprintModel } from './types'

axios.defaults.withCredentials = true
axios.defaults.timeout = 60 * 1000 // 60 seconds

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl?: string } = { baseUrl: '' },
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: Record<string, any>
      errorOverride?: boolean
      recieveFingerprint?: FingerprintModel
      sendFingerprint?: FingerprintModel
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, errorOverride, recieveFingerprint, sendFingerprint }) => {
    try {
      const shouldSendFingerprint = sendFingerprint && localStorage.getItem(sendFingerprint)

      const isFullUrl = url.startsWith('http://') || url.startsWith('https://')

      const axiosResponse = await axios({
        url: isFullUrl ? url : baseUrl + url,
        method,
        data,
        params,
        headers: {
          ...(shouldSendFingerprint
            ? { 'X-Data-Signature': localStorage.getItem(sendFingerprint) }
            : {}),
        },
      })

      // delete fingerprint after use
      if (shouldSendFingerprint) {
        localStorage.removeItem(sendFingerprint)
      }

      // if server sends fingerprint, save it to localStorage
      if (recieveFingerprint != null) {
        if (axiosResponse.headers.hasOwnProperty('x-data-signature')) {
          const fingerprint = axiosResponse.headers['x-data-signature']
          localStorage.setItem(recieveFingerprint, fingerprint)
        }
      }
      return axiosResponse
    } catch (axiosError) {
      const err = axiosError as any
      if (err.response) {
        if (err.response.data && err.response.data.detail) {
          return {
            error: {
              message: err.response.data.detail.message,
              data: err.response.data,
              errorOverride,
              code: err.response.status,
            },
          }
        } else if (err.response.data && err.response.data.message) {
          return {
            error: {
              message: err.response.data.message,
              data: err.response.data,
              errorOverride,
              code: err.response.status,
            },
          }
        } else if (err.response.status >= 500)
          return { error: { status: err.response.status, data: err } }
        else return { error: { message: i18n.t('response.message.unknown.error') } }
      } else if (err.code === 'ECONNABORTED') {
        if (err.message.startsWith('Request aborted ')) {
          return { data: undefined }
        } else {
          return { error: { message: i18n.t('response.message.network.error') } }
        }
      } else if (err.message) {
        const { message } = err
        return { error: { message } }
      } else return { error: { message: i18n.t('response.message.unknown.error') } }
    }
  }

export default axiosBaseQuery
