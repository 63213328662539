import React, { useEffect, useMemo, useState } from 'react'
import * as Sentry from '@sentry/react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Banner, MarketingBanner } from 'components'
import { MenuContainer } from 'containers/MenuContainer'
import { selectUi } from 'services/slices/ui'
import { Fallback } from 'pages/Fallback'
import { selectCurrentUser } from 'services/slices/auth'
import { Report } from 'containers/EventReports/Report'
import { ContainerStyled, ContentStyled, LayoutWrapperStyled } from './ProtectedLayout.style'
import { initPendo, initTrackingScripts } from '../utils/trackingScripts'
import { WebsocketNotification } from 'containers/WebsocketConsumer/WebsocketNotification'
import { BannerVariantEnum } from '@evrekadev/evreka-ui-components'
import { useAnnouncement } from 'utils/useAnnouncement'
import { ProfileEnvironment } from 'types/common'

/*! @noflip */
/* use @noflip tag with '!'
0- create a custom hook called useRTL() via listening const { i18n } = useTranslation() intance
1-add transformer for base components and detect isRTL change with useRTL() hook. (if fails switch webpack-rtl-plugin update useRTL with styleSheet.href.endsWith('.chunk.css'))
const isRTL = useRTL()

https://github.com/styled-components/stylis-plugin-rtl#readme
<StyleSheetManager key={isRTL ? 'rtl' : 'ltr'} stylisPlugins={isRTL ? [stylisRTLPlugin] : null}>
   ...
</StyleSheetManager>

2- wrap all base components with HOC
const withRTL = hookArgs => Comp => () => {
  const hookData = useRTL(hookArgs);
  return <Comp {...hookData} {...props}  />;
  // <Comp isRTl={true} ... />
}

3-set document.body.dir = i18n.dir() to flip layout
4-store language in redux store instead of rtl status.

*/

export const ProtectedLayout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const user = useSelector(selectCurrentUser)
  const [isReportModalOpen, setIsReportModalOpen] = useState(false)
  const [trackingScriptsLoaded, setTrackingScriptsLoaded] = useState(false)
  const [isPendoLoaded, setIsPendoLoaded] = useState(false)

  const navigate = useNavigate()
  const { currentClientId, pageTitle, settingsUrl, showLoadingBar, sidebarCollapsed } =
    useSelector(selectUi)
  const loc = useLocation()

  // TODO: this check should be updated and be found more elegant and rigid solution
  const profileEnvironment: ProfileEnvironment = useMemo(() => {
    if (loc.pathname.includes('customer_portal')) {
      return 'customer-portal'
    }
    if (loc.pathname.includes('user_management')) {
      return 'user-management'
    }
  }, [loc])

  const closeReportModal = () => {
    setIsReportModalOpen(false)
  }

  useEffect(() => {
    if (user?.account_type !== 'ADMIN' && !trackingScriptsLoaded) {
      initTrackingScripts()
      setTrackingScriptsLoaded(true)
    }
    if (user && user.account_type !== 'ADMIN' && !isPendoLoaded) {
      const foundClient = user.clients.find(
        ({ value }) => value.toString() === user.as_client_id.toString(),
      )
      initPendo(user.username, foundClient?.label)
      setIsPendoLoaded(true)
    }
  }, [user, trackingScriptsLoaded, isPendoLoaded])

  const {
    announcements: { marketingBanners, topBanners, onDismiss },
  } = useAnnouncement({
    clientId: currentClientId,
    userType: profileEnvironment !== 'customer-portal' ? 'dashboard' : 'customer_portal',
  })

  if (!user) {
    navigate('/login')
    return null
  }

  if (user.customer_portal_credentials != null && !loc.pathname.includes('customer_portal')) {
    navigate(`/engagement/customer_portal/${user.customer_portal_credentials.entity_id}`)
    return null
  }

  return (
    <>
      <MenuContainer />
      <ContainerStyled directionColumn="zero">
        {!topBanners.length
          ? null
          : topBanners.map((banner) => (
              <Banner
                key={banner.id}
                isShrunk={!sidebarCollapsed}
                text={banner.title}
                subText={banner.text}
                variant={BannerVariantEnum[banner.variant]}
                onDismiss={banner.is_dismissible ? onDismiss.bind(undefined, banner.id) : undefined}
              />
            ))}
        <LayoutWrapperStyled collapsed={sidebarCollapsed}>
          <ContentStyled>
            <Sentry.ErrorBoundary fallback={<Fallback />} showDialog={false}>
              <Outlet />
            </Sentry.ErrorBoundary>
          </ContentStyled>
        </LayoutWrapperStyled>
        {!marketingBanners.length
          ? null
          : marketingBanners.map((banner) => (
              <MarketingBanner
                key={banner.id}
                isShrunk={!sidebarCollapsed}
                text={banner.title}
                subText={banner.text}
                onDismiss={banner.is_dismissible ? onDismiss.bind(undefined, banner.id) : undefined}
              />
            ))}
      </ContainerStyled>
      <Report isOpen={isReportModalOpen} closeModal={closeReportModal} />

      {user && process.env.REACT_APP_WEBSOCKET_URL && (
        <WebsocketNotification
          url={
            process.env.REACT_APP_WEBSOCKET_URL +
            `:${process.env.REACT_APP_WEBSOCKET_PORT}/ws/${user.user_id}/notification/`
          }
        />
      )}
    </>
  )
}
