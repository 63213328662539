import { color, padding } from '@evrekadev/evreka-ui-components'
import { Grid, Flex } from 'components'
import styled from 'styled-components'
import frame from '../assets/frame.png'
import frame2 from '../assets/frame2.png'

export const LoginWrapper = styled(Grid)`
  height: 100vh;
`
export const LoginLeftSide = styled(Flex)`
  border-radius: 0 2rem 2rem 0;
  background-color: ${color('white')};
  z-index: 2;
  @media screen and (max-width: 640px) {
    width: 100vw;
    border-radius: 0;
  }
`

export const LoginRightSide = styled(Flex)`
  position: relative;
  background: linear-gradient(
    to bottom left,
    #f7e8bb,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0)
  );
  @media screen and (max-width: 640px) {
    display: none;
  }
`

export const StyledTopArea = styled(Flex)`
  ${padding([32])};
  position: relative;
`

export const ImageArea = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: url(${frame});
  width: 200px;
  height: 200px;
`

export const ImageArea2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-image: url(${frame2});
  width: 200px;
  height: 200px;
`

export const StyledIllustrationArea = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  left: -2rem;
  z-index: 1;
  height: 100%;
  width: calc(100% + 2rem);
  object-fit: cover;
`

export const StyledBannerArea = styled(Flex)`
  padding: 13rem 3rem 0 3rem;
  z-index: 2;
`

export const StyledTitleArea = styled(Flex)`
  flex-grow: 1;
  padding: 2.5rem 6.5rem;
`
