import React, { useMemo, useState } from 'react'
import { Tooltip, HyperLink, Typography, GridCell, Flex, Grid } from 'components'
import { useTranslation } from 'utils/useTranslation'
import { DATE_TIME_FORMAT, dateFormat } from '@evrekadev/evreka-ui-components'

const CHAR_LIMIT = 30
const CHAR_LIMIT_IF_LONG_TITLE = CHAR_LIMIT * 1.75

const truncateLongText = (text: string, limit: number) => {
  if (text.length > limit) {
    return text.substring(0, limit) + '...'
  }
  return text
}

type DetailItemObjectValueType = { label: string; value: string | null }

type DetailItemProps = {
  title?: string
  type?: string
  value?: string | JSX.Element | { label: string; value: string | number }
  to?: string | false
  noWrap?: boolean
  wordBreak?: 'initial' | 'break-all' | 'break-word'
  target?: string
  alignCenter?: boolean
  timezone?: string
  dataTestId?: string
}

const TooltipHyperLink: React.FC<{ isTitleAboveLimit: boolean; value: string; to: string }> = ({
  isTitleAboveLimit,
  to,
  value,
}) => {
  return (
    <Tooltip
      element={
        <span>
          <HyperLink
            value={truncateLongText(
              value,
              isTitleAboveLimit ? CHAR_LIMIT_IF_LONG_TITLE : CHAR_LIMIT,
            )}
            to={to}
            target="_blank"
            inline
          />
        </span>
      }
      content={
        <div style={{ maxWidth: 250 }}>
          <Typography color="white" wordBreak="break-all">
            {value}
          </Typography>
        </div>
      }
      placement={isTitleAboveLimit ? 'top-end' : 'top'}
    />
  )
}

export const TooltipText: React.FC<
  { isTitleAboveLimit: boolean; value: string } & Pick<DetailItemProps, 'noWrap' | 'wordBreak'>
> = ({ isTitleAboveLimit, value, noWrap, wordBreak }) => {
  const [copied, setCopied] = useState(false)
  const { t } = useTranslation()

  return (
    <Tooltip
      element={
        <Typography
          display="inline"
          noWrap={noWrap}
          wordBreak={wordBreak}
          onClick={() => {
            setCopied(true)
            navigator.clipboard.writeText(value)
          }}
          onMouseLeave={() => setCopied(false)}
          style={{ cursor: 'pointer' }}
        >
          {truncateLongText(
            value as string,
            isTitleAboveLimit ? CHAR_LIMIT_IF_LONG_TITLE : CHAR_LIMIT,
          )}
        </Typography>
      }
      content={
        <div style={{ maxWidth: 250 }}>
          <Typography color="white" wordBreak="break-word">
            {(copied && (
              <>
                {t('copied')}
                <br />
                {value}
              </>
            )) ||
              value}
          </Typography>
        </div>
      }
      placement={isTitleAboveLimit ? 'top-end' : 'top'}
    />
  )
}

const DetailItemValue: React.FC<
  {
    isLinkType: boolean
    isTitleAboveLimit: boolean
    isValueAboveLimit: boolean
    value: string
  } & Pick<DetailItemProps, 'noWrap' | 'wordBreak' | 'to'>
> = ({ isLinkType, isTitleAboveLimit, isValueAboveLimit, to, value, noWrap, wordBreak }) => {
  if (isLinkType && to) {
    if (isValueAboveLimit) {
      return <TooltipHyperLink isTitleAboveLimit={isTitleAboveLimit} to={to} value={value} />
    } else {
      return <HyperLink value={value} to={to} target="_blank" inline />
    }
  } else {
    if (isValueAboveLimit) {
      return (
        <TooltipText
          isTitleAboveLimit={isTitleAboveLimit}
          value={value}
          noWrap={noWrap}
          wordBreak={wordBreak}
        />
      )
    } else {
      return (
        <Typography
          display="inline"
          noWrap={noWrap}
          wordBreak={wordBreak}
          fontSize="text_sm"
          fontWeight="regular"
          color="grey600"
          style={{ margin: 0 }}
        >
          {value ?? '-'}
        </Typography>
      )
    }
  }
}

export const DetailItem: React.FC<React.PropsWithChildren<DetailItemProps>> = ({
  title,
  type,
  value: propValue,
  to: propTo,
  noWrap = false,
  wordBreak = 'initial',
  alignCenter,
  timezone,
  dataTestId,
}) => {
  let value = propValue
  let to = propTo

  const isTitleAboveLimit = useMemo(() => !!title?.length && title.length > CHAR_LIMIT, [title])
  const isValueAboveLimit = useMemo(
    () =>
      typeof value === 'string' &&
      !!value?.length &&
      value.length > (isTitleAboveLimit ? CHAR_LIMIT_IF_LONG_TITLE : CHAR_LIMIT),
    [value, isTitleAboveLimit],
  )

  const isValueObjectType = useMemo(() => typeof value === 'object', [value])

  const isValueJSXElement = useMemo(
    () =>
      value &&
      typeof value !== 'string' &&
      typeof value !== 'number' &&
      !('value' in value) &&
      'props' in value
        ? true
        : false,
    [value],
  )

  if (isValueObjectType && type === 'link') {
    const objectValue = value as any as DetailItemObjectValueType
    value = objectValue.label
    to = objectValue.value || ''
  }

  const isLinkType = useMemo(() => !!to || type === 'link', [to, type])

  if (typeof value === 'string' && type === 'datetime') {
    value = dateFormat(value, DATE_TIME_FORMAT, timezone)
  }

  if (isValueJSXElement) {
    return (
      <Grid columns={'2fr 3fr'} gap={0.5} data-testid={dataTestId}>
        <Flex middle="zero">
          <Typography
            display="inline"
            noWrap={noWrap}
            fontSize="text_sm"
            fontWeight="medium"
            color="grey900"
          >
            {title}
          </Typography>
        </Flex>
        {value as any}
      </Grid>
    )
  }

  return (
    <Grid columns={'2fr 3fr'} gap={0.5} data-testid={dataTestId}>
      <Typography
        display="inline"
        noWrap={noWrap}
        fontSize="text_sm"
        fontWeight="medium"
        color="grey900"
      >
        {title}
      </Typography>
      <DetailItemValue
        isLinkType={isLinkType}
        isTitleAboveLimit={isTitleAboveLimit}
        isValueAboveLimit={isValueAboveLimit}
        to={value != null && to}
        value={value as string}
        noWrap={noWrap}
        wordBreak={wordBreak}
      />
    </Grid>
  )
}
