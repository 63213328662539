import { NavigationCommandType } from 'containers/Command/types'

export const reportsRoutes: Array<NavigationCommandType> = [
  {
    moduleId: 'reports',
    submoduleId: 'reports',
    commandId: 'list-page',
    type: 'navigation',
    to: '/',
    label: 'pages.reports_management.title',
  },
]
