import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { SidebarBaseProps } from './Sidebar'

export const StyledLogo = styled(Link)`
  display: flex;
  gap: 6px;
  & > img {
    height: 40px;
  }
`
export const StyledLogoType = styled.img<Pick<SidebarBaseProps, 'sidebarCollapsed'>>`
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
`
