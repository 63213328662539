import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { GenericResponse, TableRequestV3, TableResponse } from 'services/types'
import { providesList, providesListWithKey } from 'services/util'
import {
  DevicesByIdRequest,
  DevicesByIdResponse,
  DevicesRequest,
  DevicesResponse,
  VehicleDeviceTypesRequest,
  VehicleDeviceTypesResponse,
  InfoRequest,
  InfoResponse,
  VehicleAddRequest,
  VehicleAddResponse,
  VehicleDeleteRequest,
  VehicleDeleteResponse,
  VehicleDetailRequest,
  VehicleDetailResponse,
  VehicleEditDetailRequest,
  VehicleEditDetailResponse,
  VehicleEditRequest,
  VehicleEditResponse,
  VehicleExportRequest,
  VehicleExportResponse,
  VehicleStatusRequest,
  VehicleStatusResponse,
  VehicleTypesRequest,
  VehicleTypesResponse,
  SnapshotByPlateRequest,
  SnapshotMapSettingsRequest,
  FleetResponseV2,
  FleetRequestV2,
  SnapshotByPlateResponse,
  DeviceLogsExportRequest,
  VehicleHistoriesResponse,
  VehicleHistoriesRequest,
  VehicleChangeStatusRequest,
  VehicleChangeStatusResponse,
  DeleteDeviceRequest,
  TrackHistoryRequest,
  TrackHistoryDetailRequest,
  TrackHistoryDetailResponse,
  VehicleResponse,
  VehicleRequest,
  ActivitiesRequest,
  ActivityTypesResponse,
  ActivityTypesRequest,
  ActivitiesExportRequest,
  ActivitiesExportResponse,
  RFIDTagsRequest,
  RFIDTagsResponse,
  TagsByVehicleRequest,
  TagsByVehicleResponse,
  DetachTagsRequest,
  VehicleActivitiesRequest,
  VehicleActivitiesResponse,
  ExportRFIDRequest,
  ExportRFIDResponse,
  BulkActionResponse,
  BulkDeleteRFIDRequest,
  AddRFIDTagRequest,
  AttachTagsRequest,
  SearchRFIDResponse,
  SearchRFIDRequest,
  BlackreasonsListResponse,
  VehicleListByListTypeResponse,
  VehicleListByListTypeRequest,
  AttachRFIDTagsResponse,
  AttachRFIDTagsRequest,
  DetachRFIDTagsRequest,
  ExportVehicleDevicesRequest,
  TrackHistoryResponse,
  TrackHistoryActivitiesResponse,
  TrackHistoryActivitiesRequest,
  TrackHistoryActivityDetailsRequest,
  TrackHistoryActivityDetailsResponse,
  TrackHistoryActivityTypesRequest,
  TrackHistoryActivityTypesResponse,
  FleetMapSettingsResponse,
  UpsertDeviceRequest,
  GetVehicleDeviceDetailsRequest,
  GetVehicleDeviceDetailsResponse,
} from './types'
import { getExportHandler, getImportHandler } from 'utils'
import { MutationResponse } from 'services/OpsManagement/types'
import { EventsCellType } from '@evrekadev/evreka-ui-components'

export const fleetApi = createApi({
  reducerPath: 'fleetApi',
  tagTypes: [
    'Fleets',
    'Vehicles',
    'DevicesById',
    'VehicleDetail',
    'FleetInfo',
    'VehicleWhitelist',
    'VehicleBlacklist',
    'RFIDTags',
    'VehicleListByListType',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/fleet/'}`,
  }),
  endpoints: (builder) => ({
    getFleetsV2: builder.query<FleetResponseV2, FleetRequestV2>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/snapshot/`,
        method: 'POST',
        data,
      }),
      providesTags: (result) => providesListWithKey(result, 'Fleets', 'record_id'),
    }),
    getSnapshotByPlate: builder.query<SnapshotByPlateResponse, SnapshotByPlateRequest>({
      query: ({ fleetClientId, plate, fields }) => ({
        url: `clients/${fleetClientId}/snapshot/v2/${plate}${
          fields ? `?fields=${fields.join(',')}` : ''
        }`,
        method: 'GET',
      }),
    }),
    getSnapshotMapSettings: builder.query<FleetMapSettingsResponse, SnapshotMapSettingsRequest>({
      query: ({ fleetClientId }) => ({
        url: `clients/${fleetClientId}/snapshot/v2/map_settings`,
        method: 'GET',
      }),
    }),
    getInfo: builder.query<InfoResponse, InfoRequest>({
      query: ({ clientId }) => ({
        url: `clients/${clientId}/info/`,
        method: 'GET',
      }),
      providesTags: ['FleetInfo'],
    }),
    getVehicleTypes: builder.query<VehicleTypesResponse, VehicleTypesRequest>({
      query: ({ fleetClientId }) => ({
        url: `clients/${fleetClientId}/vehicle_types/`,
        method: 'GET',
      }),
    }),
    getVehicleStatus: builder.query<VehicleStatusResponse, VehicleStatusRequest>({
      query: ({ fleetClientId, statusId, isEditMode }) => {
        let queryString = ''
        if (isEditMode) {
          queryString = '?all=1'
          if (statusId) {
            queryString = `?id=${statusId}&all=1`
          }
        } else if (!isEditMode && statusId) {
          queryString = `?id=${statusId}`
        }

        return {
          url: `clients/${fleetClientId}/vehicle_status/${queryString}`,
          method: 'GET',
        }
      },
    }),
    changeVehicleStatus: builder.mutation<VehicleChangeStatusResponse, VehicleChangeStatusRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/change_vehicle_status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { vehicle_id }) => [
        { type: 'Vehicles' },
        { type: 'VehicleDetail', id: vehicle_id },
      ],
    }),
    getVehicleList: builder.query<TableResponse<EventsCellType>, TableRequestV3>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/vehicles/`,
        method: 'POST',
        data,
      }),
      providesTags: (result) => providesList(result?.data, 'Vehicles'),
    }),
    getActivityList: builder.query<TableResponse<EventsCellType>, ActivitiesRequest>({
      query: ({ clientId, type, ...data }) => ({
        url: `clients/${clientId}/activities/?type=${type}`,
        method: 'POST',
        data,
      }),
    }),
    exportVehicleDevices: builder.mutation<MutationResponse, ExportVehicleDevicesRequest>({
      query({ clientId, vehicleId, ...data }) {
        return {
          url: `clients/${clientId}/vehicles/${vehicleId}/export_devices/`,
          method: 'POST',
          data,
        }
      },
      onQueryStarted: getExportHandler,
    }),
    attachRFIDTags: builder.mutation<AttachRFIDTagsResponse, AttachRFIDTagsRequest>({
      query: ({ fleetClientId, vehicles, tags }) => ({
        url: `clients/${fleetClientId}/vehicles/attach_rfid_tags/`,
        method: 'POST',
        data: {
          vehicles,
          tags,
        },
      }),
      invalidatesTags: ['RFIDTags', 'VehicleListByListType'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    detachRFIDTags: builder.mutation<AttachRFIDTagsResponse, DetachRFIDTagsRequest>({
      query: ({ fleetClientId, vehicles, tags }) => ({
        url: `clients/${fleetClientId}/vehicles/detach_rfid_tags/`,
        method: 'POST',
        data: {
          vehicles,
          tags,
        },
      }),
      invalidatesTags: ['RFIDTags', 'VehicleListByListType'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getActivityTypes: builder.query<ActivityTypesResponse, ActivityTypesRequest>({
      query: ({ fleetClientId }) => ({
        url: `clients/${fleetClientId}/activity_types/`,
        method: 'GET',
      }),
    }),
    getEditVehicleDetail: builder.query<VehicleEditDetailResponse, VehicleEditDetailRequest>({
      query: ({ fleetClientId, vehicleId }) => ({
        url: `clients/${fleetClientId}/edit_vehicle_detail/${vehicleId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { vehicleId }) => [{ type: 'Vehicles', id: vehicleId }],
    }),
    getContentFields: builder.query<any, any>({
      query: ({ fleetClientId }) => ({
        url: `clients/${fleetClientId}/get_vehicle_content_fields/`,
        method: 'GET',
      }),
    }),
    addVehicle: builder.mutation<VehicleAddResponse, VehicleAddRequest>({
      query({ fleetClientId, body }) {
        return {
          url: `clients/${fleetClientId}/create_vehicle/`,
          method: 'POST',
          data: body,
        }
      },
      invalidatesTags: ['Vehicles', 'FleetInfo'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editVehicle: builder.mutation<VehicleEditResponse, VehicleEditRequest>({
      query({ fleetClientId, body }) {
        return {
          url: `clients/${fleetClientId}/edit_vehicle/`,
          method: 'POST',
          data: body,
        }
      },
      invalidatesTags: ['Vehicles', 'VehicleDetail'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteVehicle: builder.mutation<VehicleDeleteResponse, VehicleDeleteRequest>({
      query: ({ fleetClientId, vehicleId }) => ({
        url: `clients/${fleetClientId}/delete_vehicle/${vehicleId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Vehicles', 'FleetInfo'],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportVehicleList: builder.mutation<VehicleExportResponse, VehicleExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/export_vehicles/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    exportDeviceLogs: builder.mutation<GenericResponse, DeviceLogsExportRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/export_device_logs/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    exportActivityList: builder.mutation<ActivitiesExportResponse, ActivitiesExportRequest>({
      query: ({ clientId, type, ...data }) => ({
        url: `clients/${clientId}/export_activities/?type=${type}`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    getVehicleDetail: builder.query<VehicleDetailResponse, VehicleDetailRequest>({
      query: ({ fleetClientId, vehicleId }) => ({
        url: `clients/${fleetClientId}/vehicle_detail/${vehicleId}`,
        method: 'GET',
      }),
      providesTags: (result, error, { vehicleId }) => [{ type: 'VehicleDetail', id: vehicleId }],
    }),
    getTrackHistoryActivities: builder.query<
      TrackHistoryActivitiesResponse,
      TrackHistoryActivitiesRequest
    >({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/activities/map`,
        method: 'POST',
        data,
      }),
    }),
    getTrackHistoryActivityDetails: builder.query<
      TrackHistoryActivityDetailsResponse,
      TrackHistoryActivityDetailsRequest
    >({
      query: ({ fleetClientId, activityId }) => ({
        url: `clients/${fleetClientId}/activities/map/${activityId}`,
        method: 'GET',
      }),
    }),
    getTrackHistoryActivityTypes: builder.query<
      TrackHistoryActivityTypesResponse,
      TrackHistoryActivityTypesRequest
    >({
      query: ({ fleetClientId }) => ({
        url: `clients/${fleetClientId}/activity_types/`,
        method: 'GET',
      }),
    }),
    getVehicleActivities: builder.query<VehicleActivitiesResponse, VehicleActivitiesRequest>({
      query: ({ fleetClientId, vehicleId, ...data }) => ({
        url: `clients/${fleetClientId}/vehicles/${vehicleId}/activities/`,
        method: 'POST',
        data,
      }),
    }),
    getDevices: builder.query<DevicesResponse, DevicesRequest>({
      query: ({ clientId, device_type }) => ({
        url: `clients/${clientId}/devices/?device_type=${device_type}`,
        method: 'GET',
      }),
    }),
    getDevicesById: builder.query<DevicesByIdResponse, DevicesByIdRequest>({
      query: ({ fleetClientId, vehicleId, ...data }) => ({
        url: `clients/${fleetClientId}/vehicles/${vehicleId}/devices/`,
        method: 'POST',
        data,
      }),
      providesTags: ['DevicesById'],
    }),
    getDeviceTypes: builder.query<VehicleDeviceTypesResponse, VehicleDeviceTypesRequest>({
      query: () => ({
        url: `device_types/`,
        method: 'GET',
      }),
    }),
    upsertDevice: builder.mutation<GenericResponse, UpsertDeviceRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/upsert_vehicle_device/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['DevicesById', 'Vehicles'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteDevice: builder.mutation<GenericResponse, DeleteDeviceRequest>({
      query: ({ fleetClientId, vehicleId, ...data }) => ({
        url: `clients/${fleetClientId}/vehicles/${vehicleId}/delete_device/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['DevicesById', 'Vehicles'],
    }),
    getVehicleDeviceDetails: builder.query<
      GetVehicleDeviceDetailsResponse,
      GetVehicleDeviceDetailsRequest
    >({
      query: ({ fleetClientId, vehicleId, deviceId }) => ({
        url: `clients/${fleetClientId}/vehicles/${vehicleId}/devices/${deviceId}/`,
        method: 'GET',
      }),
    }),
    getVehicleHistories: builder.query<VehicleHistoriesResponse, VehicleHistoriesRequest>({
      query: ({ fleetClientId, vehicle_id, ...data }) => ({
        url: `clients/${fleetClientId}/vehicles/${vehicle_id}/history/`,
        method: 'POST',
        data,
      }),
    }),
    getTrackingHistory: builder.query<TrackHistoryResponse, TrackHistoryRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/history/`,
        method: 'POST',
        data,
      }),
    }),
    getTrackingHistoryDetail: builder.query<TrackHistoryDetailResponse, TrackHistoryDetailRequest>({
      query: ({ fleetClientId, id }) => ({
        url: `clients/${fleetClientId}/history/${id}`,
        method: 'GET',
      }),
    }),
    getVehiclesBySearch: builder.mutation<VehicleResponse, VehicleRequest>({
      query: ({ clientId, vehicle }) => ({
        url: `clients/${clientId}/vehicle-search/?q=${vehicle}`,
        method: 'GET',
      }),
    }),
    getRFIDTags: builder.query<RFIDTagsResponse, RFIDTagsRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/rfid/tags/`,
        method: 'POST',
        data,
      }),
      providesTags: ['RFIDTags'],
    }),
    addRFIDTag: builder.mutation<MutationResponse, AddRFIDTagRequest>({
      query: ({ fleetClientId, body }) => ({
        url: `clients/${fleetClientId}/rfid/create/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['RFIDTags'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    attachTagsToVehicle: builder.mutation<GenericResponse, AttachTagsRequest>({
      query: ({ clientId, type, ...data }) => ({
        url: `clients/${clientId}/vehicles/attach_rfid_tags/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { type }) =>
        type === 1 ? ['VehicleWhitelist'] : ['VehicleBlacklist'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(
            sendNotification({
              type: 'success',
              toastContent: data.detail.message,
            }) as any,
          )
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchRFIDTag: builder.mutation<SearchRFIDResponse, SearchRFIDRequest>({
      query: ({ fleetClientId, query_param }) => ({
        url: `clients/${fleetClientId}/rfid/tags/search/?q=${query_param}`,
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getBlacklistReasons: builder.query<BlackreasonsListResponse, string>({
      query: () => ({
        url: `rfid/blacklist_reasons/`,
        method: 'GET',
      }),
    }),
    getVehicleListByListType: builder.query<
      VehicleListByListTypeResponse,
      VehicleListByListTypeRequest
    >({
      query: ({ fleetClientId, type, tags }) => ({
        url: `clients/${fleetClientId}/rfid/vehicles/`,
        method: 'POST',
        data: { type: type, tags: tags },
      }),
      providesTags: ['VehicleListByListType'],
    }),
    bulkDeleteRFID: builder.mutation<BulkActionResponse, BulkDeleteRFIDRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/rfid/tags/delete/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['RFIDTags'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),

    exportRFIDList: builder.mutation<ExportRFIDResponse, ExportRFIDRequest>({
      query: ({ fleetClientId, ...data }) => ({
        url: `clients/${fleetClientId}/rfid/export_tags/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    importRFIDList: builder.mutation<MutationResponse, { fleetClientId: string; body: FormData }>({
      query: ({ fleetClientId, body }) => ({
        url: `clients/${fleetClientId}/rfid/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['RFIDTags'],
      onQueryStarted: getImportHandler,
    }),
    getTagListByVehicle: builder.query<TagsByVehicleResponse, TagsByVehicleRequest>({
      query: ({ fleetClientId, vehicleId, type, ...data }) => ({
        url: `clients/${fleetClientId}/vehicles/${vehicleId}/rfid_tags/?type=${type}`,
        method: 'POST',
        data,
      }),
      providesTags: (result, error, { type }) =>
        type === 1 ? ['VehicleWhitelist'] : ['VehicleBlacklist'],
    }),
    detachTagsFromVehicle: builder.mutation<GenericResponse, DetachTagsRequest>({
      query: ({ clientId, type, ...data }) => ({
        url: `clients/${clientId}/vehicles/detach_rfid_tags/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { type }) =>
        type === 1 ? ['VehicleWhitelist'] : ['VehicleBlacklist'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})
