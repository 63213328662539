import React, { useState } from 'react'
import { Button, Flex, InputText } from 'components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useForgotPasswordMutation, useLoginMutation } from 'services/auth'
import LoginLayout from './LoginLayout'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'utils/useTranslation'
import email_sent from './assets/email_sent.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import { isCaptchaRequired } from './Login'
import i18n from 'utils/i18n'
type ForgotPasswordProps = {}

const schema = yup.object().shape({
  email: yup.string().email().required(),
})

type FormInputs = {
  email: string
}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const [reCAPTCHA, setReCAPTCHA] = useState<string>()
  const captchaRef = React.useRef<ReCAPTCHA>(null)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })
  const navigate = useNavigate()
  const { t } = useTranslation('pages.authentication')

  const [forgotPassword, { isSuccess }] = useForgotPasswordMutation()

  const onSubmit = (data: FormInputs) => {
    forgotPassword({ email: data.email, recaptchaToken: reCAPTCHA }).finally(() => {
      if (captchaRef.current) {
        captchaRef.current.reset()
      }
    })
  }

  return (
    <LoginLayout
      title={isSuccess ? t('email_sent') : t('forgot_password')}
      subtitle={isSuccess ? t('follow_the_instructions') : t('forgot_password_info')}
    >
      <Flex gap={2} directionColumn="zero">
        {isSuccess ? (
          <Flex center="zero">
            <img width={200} src={email_sent} />
          </Flex>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap={1} directionColumn="zero">
              <Controller
                render={({ field }) => (
                  <InputText {...field} label={t('email_address')} error={!!errors.email} />
                )}
                control={control}
                name="email"
              />
              {isCaptchaRequired && (
                <ReCAPTCHA
                  onChange={(e) => e && setReCAPTCHA(e)}
                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
                  lang={i18n.language}
                  ref={captchaRef}
                />
              )}

              <Button type="submit" variant="filled">
                {t('send_recovery')}
              </Button>
            </Flex>
          </form>
        )}

        <Button variant="text" onClick={() => navigate('/login')}>
          {t('back_to_login')}
        </Button>
      </Flex>
    </LoginLayout>
  )
}

export default ForgotPassword
