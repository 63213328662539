import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  AddInventoryRequest,
  ChangeAmountRequest,
  InventoryItemRequest,
  InventoryItemResponse,
  InventoryListRequest,
  InventoryListResponse,
  MutationResponse,
  DeleteInventoryItemRequest,
  TransactionListRequest,
  ExportResponse,
  ExportRequest,
  BulkMutationResponse,
  BulkDeleteRequest,
  ChangeCapacityRequest,
  ConvertInventoryRequest,
  TransactionChartResponse,
  TransactionChartRequest,
} from './types'
import { getExportHandler } from 'utils'

export const inventoryApi = createApi({
  reducerPath: 'inventoryApi',
  tagTypes: ['Inventory', 'InventoryDetails', 'Transactions', 'TransactionChart'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/MRF/'}`,
  }),
  endpoints: (builder) => ({
    getInventoryList: builder.query<InventoryListResponse, InventoryListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/inventoryitem/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Inventory'],
    }),
    getInventoryDetail: builder.query<InventoryItemResponse, InventoryItemRequest>({
      query: ({ clientId, inventoryItemId }) => ({
        url: `v2/inventory_item/clients/${clientId}/?inventory_item_id=${inventoryItemId}`,
        method: 'GET',
      }),
      providesTags: ['InventoryDetails'],
    }),
    addInventoryRecord: builder.mutation<MutationResponse, AddInventoryRequest>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/inventoryitem/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Inventory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    increaseAmount: builder.mutation<MutationResponse, ChangeAmountRequest>({
      query: ({ clientId, materialId, ...data }) => ({
        url: `clients/${clientId}/material/${materialId}/increase/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Inventory', 'InventoryDetails', 'Transactions', 'TransactionChart'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    decreaseAmount: builder.mutation<MutationResponse, ChangeAmountRequest>({
      query: ({ clientId, materialId, ...data }) => ({
        url: `clients/${clientId}/material/${materialId}/decrease/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Inventory', 'InventoryDetails', 'Transactions', 'TransactionChart'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    convertInventory: builder.mutation<MutationResponse, ConvertInventoryRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/inventoryitem/convert/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Inventory', 'InventoryDetails', 'Transactions', 'TransactionChart'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    changeCapacity: builder.mutation<MutationResponse, ChangeCapacityRequest>({
      query: ({ clientId, id, ...data }) => ({
        url: `clients/${clientId}/inventoryitem/${id}/capacity/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Inventory', 'InventoryDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteInventoryItem: builder.mutation<BulkMutationResponse, BulkDeleteRequest<number, string>>({
      query: ({ clientId, deleteIdArray: inventory_item_id_list }) => ({
        url: `clients/${clientId}/inventoryitem/bulk_delete/`,
        method: 'DELETE',
        data: { inventory_item_id_list },
        errorOverride: true,
      }),
      invalidatesTags: ['Inventory', 'InventoryDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
          sendNotification({
            type: 'error',
            toastContent: (err as any).error.data.failure_list?.[0]?.description,
          })
        }
      },
    }),
    exportInventoryList: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/inventoryitem/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    getTransactionList: builder.query<InventoryListResponse, TransactionListRequest>({
      query: ({ client_id, inventory_id, ...data }) => ({
        url: `clients/${client_id}/inventoryitem/${inventory_id}/transaction_list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Transactions'],
    }),
    getTransactionChart: builder.query<TransactionChartResponse, TransactionChartRequest>({
      query: ({ client_id, inventoryId, end_date, start_date }) => ({
        url: `clients/${client_id}/inventoryitem/${inventoryId}/inventory_chart/?start_date=${start_date}&end_date=${end_date}`,
        method: 'GET',
      }),
      providesTags: ['TransactionChart'],
    }),
  }),
})

export const {
  useGetInventoryListQuery,
  useAddInventoryRecordMutation,
  useGetInventoryDetailQuery,
  useDecreaseAmountMutation,
  useIncreaseAmountMutation,
  useDeleteInventoryItemMutation,
  useExportInventoryListMutation,
  useChangeCapacityMutation,
  useConvertInventoryMutation,
  useGetTransactionListQuery,
  useGetTransactionChartQuery,
} = inventoryApi
