import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import moment from 'moment'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  CreateAssetRequest,
  AssetDetailsRequest,
  AssetDevicesRequest,
  AssetDevicesResponse,
  AssetHistoriesRequest,
  DeviceAddDeleteRequest,
  MutationResponse,
  AssetsRequest,
  AssetsResponse,
  AssetHistoriesResponse,
  AssetDetailsResponse,
  GetAssetTypesResponse,
  GetAssetNameRequest,
  GetAssetTagRequest,
  GetAssetNameResponse,
  GetAssetTagResponse,
  EditAssetRequest,
  SearchDeviceRequest,
  SearchDeviceResponse,
  DeviceTypesResponse,
  DeviceTypesRequest,
  TempatureResponse,
  TempatureRequest,
  FullnessResponse,
  FullnessRequest,
  ViewSettingRequest,
  ViewSettingResponse,
  ViewSettingSaveRequest,
  ContentFieldsRequest,
  ImportAssetRequest,
  AssetChangeStatusRequest,
  WasteTypesResponse,
  AssetMapPopupDetailsRequest,
  AssetMapPopupDetailsResponse,
  AssetMapPointsResponse,
  AssetMapSettingsResponse,
  AssetMapSettingsRequest,
  GetInstallationTypesRequest,
  GetInstallationTypesResponse,
  GetAssetByIdRequest,
  GetAssetByIdResponse,
  AssetGetStatusTransitionsResponse,
  AssetGetStatusTransitionsRequest,
  AssetChangeStatusResponse,
  SearchAssetsWithActionResponse,
  SearchAssetsWithActionRequest,
  SearchRoutesByNameRequest,
  SearchRoutesByNameResponse,
  AssetsStatusAndOperationIdsRequest,
  AssetsStatusAndOperationIdsResponse,
  FullnessActivityType,
} from './types'
import { ContentFieldsResponse, MapRequest } from 'services/types'
import { providesListWithKey } from 'services/util'
import { ExportRequest } from 'services/OpsManagement/types'
import { getExportHandler, getImportHandler } from 'utils'
import { opsGeneralApi } from 'services/OpsManagement/opsGeneral'
import { servicePointApi } from 'services/OpsManagement/servicePoints'
import { ThemeColors } from '@evrekadev/evreka-ui-components'

export const assetApi = createApi({
  reducerPath: 'assetApi',
  tagTypes: [
    'Assets',
    'AssetDetail',
    'AssetTypes',
    'Devices',
    'DeviceTypes',
    'AssetSettings',
    'AssetMapPoints',
    'Activities',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    addAsset: builder.mutation<MutationResponse, CreateAssetRequest>({
      query({ clientId, ...data }) {
        return {
          url: `api/clients/${clientId}/asset_management/create_asset/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Assets', 'AssetMapPoints'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getAssetById: builder.query<GetAssetByIdResponse, GetAssetByIdRequest>({
      query: ({ clientId, assetId }) => ({
        url: `api/clients/${clientId}/asset_management/get_edit_asset_fields/?asset=${assetId}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { assetId }) => [{ type: 'AssetDetail', id: assetId }],
    }),
    editAsset: builder.mutation<MutationResponse, EditAssetRequest>({
      query({ clientId, ...data }) {
        return {
          url: `api/clients/${clientId}/asset_management/edit_asset/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Assets', 'AssetDetail', 'AssetMapPoints'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(servicePointApi.util.invalidateTags(['ServicePointDetail']))
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getStatusTransitionsQ: builder.query<
      AssetGetStatusTransitionsResponse,
      AssetGetStatusTransitionsRequest
    >({
      query: ({ clientId, id, source }) => ({
        url: `api/clients/${clientId}/asset_management/status_transitions/?id=${id}&source=${source}`,
        method: 'GET',
      }),
    }),
    getStatusTransitionsM: builder.mutation<
      AssetGetStatusTransitionsResponse,
      AssetGetStatusTransitionsRequest
    >({
      query: ({ clientId, id, source }) => ({
        url: `api/clients/${clientId}/asset_management/status_transitions/?id=${id}&source=${source}`,
        method: 'GET',
      }),
    }),
    getAssetsStatusAndOperationIds: builder.mutation<
      AssetsStatusAndOperationIdsResponse,
      AssetsStatusAndOperationIdsRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `asset/clients/${clientId}/get_asset_info/`,
        method: 'POST',
        data,
      }),
    }),
    changeAssetStatus: builder.mutation<AssetChangeStatusResponse, AssetChangeStatusRequest>({
      query: ({ clientId, doesUnmatchingOperationErrorExist, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/change_status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, __, { id }) => [{ type: 'Assets' }, { type: 'AssetDetail', id }],
      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          Object.keys(data.asset).length &&
            !data.error_list?.length &&
            !params.doesUnmatchingOperationErrorExist &&
            sendNotification({
              type: 'success',
              toastContent: data.detail.message,
            })

          dispatch(servicePointApi.util.invalidateTags(['Assets']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getAssetDetails: builder.query<AssetDetailsResponse, AssetDetailsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/asset_details/`,
        method: 'POST',
        data,
      }),
      providesTags: (_result, _error, { assetId }) => [{ type: 'AssetDetail', id: assetId }],
    }),
    getAssetHistories: builder.query<AssetHistoriesResponse, AssetHistoriesRequest>({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/asset_history/`,
        method: 'POST',
        data,
      }),
    }),
    searchAssetsWithAction: builder.query<
      SearchAssetsWithActionResponse,
      SearchAssetsWithActionRequest
    >({
      query: ({ clientId, ...data }) => {
        return {
          url: `api/clients/${clientId}/asset_management/search_asset_action/`,
          method: 'POST',
          data,
        }
      },
    }),
    getAssets: builder.query<AssetsResponse, AssetsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/list_asset/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Assets'],
    }),
    getAssetTypes: builder.query<GetAssetTypesResponse, number | undefined>({
      query: (clientId) => ({
        url: `api/clients/${clientId}/asset_management/get_asset_types/`,
        method: 'GET',
      }),
      providesTags: ['AssetTypes'],
    }),
    getInstallationTypes: builder.query<GetInstallationTypesResponse, GetInstallationTypesRequest>({
      query: ({ clientId, asset_type }) => ({
        url: `api/clients/${clientId}/asset_management/installation_types/?asset_type=${asset_type}`,
        method: 'GET',
      }),
    }),
    checkAssetName: builder.mutation<GetAssetNameResponse, GetAssetNameRequest>({
      query: ({ clientId, name }) => ({
        url: `api/clients/${clientId}/asset_management/check_asset_name/?name=${name}`,
        method: 'GET',
      }),
    }),
    checkAssetTag: builder.mutation<GetAssetTagResponse, GetAssetTagRequest>({
      query: ({ clientId, tag }) => ({
        url: `api/clients/${clientId}/asset_management/check_asset_tag/?tag=${tag}`,
        method: 'GET',
      }),
    }),
    getMapPoints: builder.query<AssetMapPointsResponse, MapRequest>({
      query: ({ client_id, ...data }) => ({
        url: `api/clients/${client_id}/asset_management/list_asset/`,
        method: 'POST',
        data: {
          ...data,
          sort: {},
        },
      }),
      providesTags: ['AssetMapPoints'],
    }),
    getAssetDevices: builder.query<AssetDevicesResponse, AssetDevicesRequest>({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/asset_devices/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Devices'],
    }),
    getDeviceTypes: builder.query<DeviceTypesResponse, DeviceTypesRequest>({
      query: ({ clientId }) => ({
        url: `api/clients/${clientId}/asset_management/get_device_types/`,
        method: 'GET',
      }),
      providesTags: ['DeviceTypes'],
    }),
    searchDevice: builder.mutation<SearchDeviceResponse, SearchDeviceRequest>({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/search_device/`,
        method: 'POST',
        data,
      }),
    }),
    addDeviceToAsset: builder.mutation<MutationResponse, DeviceAddDeleteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `api/clients/${clientId}/asset_management/add_device_to_asset/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Devices', 'Assets'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteDeviceFromAsset: builder.mutation<MutationResponse, DeviceAddDeleteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `api/clients/${clientId}/asset_management/remove_device_from_asset/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Devices', 'Assets'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getContentFields: builder.query<ContentFieldsResponse, ContentFieldsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/get_asset_content_fields/`,
        method: 'POST',
        data,
      }),
      providesTags: (result) => providesListWithKey(result, 'Assets', 'key'),
    }),
    getFullness: builder.query<
      Array<{
        name: string
        data: number[][]
        activities: Array<[number, FullnessActivityType]>
      }>,
      FullnessRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/get_fullness_records/`,
        method: 'POST',
        data,
        errorOverride: true,
      }),
      transformResponse(response: FullnessResponse) {
        return response.map((r) => ({
          name: r.name,
          data: r.data.map((d) => [
            +moment(d.fixed_time_tz, 'DD/MM/YYYY, HH:mm').valueOf(),
            Math.round(d.smoothed_fullness_rate),
          ]),
          activities: r.activities.map(({ fixed_time_tz, type }) => [
            +moment(fixed_time_tz, 'DD/MM/YYYY, HH:mm').valueOf(),
            type,
          ]),
        }))
      },
    }),
    getTempature: builder.query<
      Array<{
        name: string
        data: number[][]
      }>,
      TempatureRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `api/clients/${clientId}/asset_management/get_temperature_records/`,
        method: 'POST',
        data,
        errorOverride: true,
      }),
      transformResponse(response: TempatureResponse) {
        return response.map((r) => ({
          name: r.name,
          data: r.data.map((d) => [
            +moment(d.fixed_time_tz, 'DD/MM/YYYY, HH:mm').valueOf(),
            Math.round(d.temperature),
          ]),
          activities: r.activities.map(({ fixed_time_tz, type }) => [
            +moment(fixed_time_tz, 'DD/MM/YYYY, HH:mm').valueOf(),
            type,
          ]),
        }))
      },
    }),
    getViewSettings: builder.query<ViewSettingResponse, ViewSettingRequest>({
      query: ({ clientId }) => ({
        url: `api/clients/${clientId}/asset_management/get_view_settings/`,
        method: 'GET',
      }),
      providesTags: ['AssetSettings'],
    }),
    saveViewSettings: builder.mutation<MutationResponse, ViewSettingSaveRequest>({
      query({ clientId, ...data }) {
        return {
          url: `api/clients/${clientId}/asset_management/save_view_settings/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['AssetSettings'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportAssets: builder.mutation<MutationResponse, ExportRequest>({
      query({ client_id, ...data }) {
        return {
          url: `api/clients/${client_id}/export/assets/`,
          method: 'POST',
          data,
        }
      },
      onQueryStarted: getExportHandler,
    }),
    importAssets: builder.mutation<MutationResponse, ImportAssetRequest>({
      query({ clientId, body }) {
        return {
          url: `api/clients/${clientId}/import/assets/`,
          method: 'POST',
          data: body,
        }
      },
      onQueryStarted: getImportHandler,
    }),
    getWasteTypes: builder.query<WasteTypesResponse, number | undefined>({
      query(client_id) {
        return {
          url: `api/clients/${client_id}/asset_management/waste_types/`,
          method: 'GET',
        }
      },
    }),
    getAssetMapSettings: builder.query<AssetMapSettingsResponse, AssetMapSettingsRequest>({
      query: ({ clientId }) => ({
        url: `asset/clients/${clientId}/map/settings`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    getAssetMapPopupDetails: builder.query<
      AssetMapPopupDetailsResponse,
      AssetMapPopupDetailsRequest
    >({
      query: ({ clientId, assetId, fields }) => ({
        url: `asset/clients/${clientId}/map/detail/bin-${assetId}${
          fields ? `?fields=${fields.join(',')}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { assetId }) => [{ type: 'AssetDetail', id: assetId }],
    }),
    searchRoutesByName: builder.mutation<SearchRoutesByNameResponse, SearchRoutesByNameRequest>({
      queryFn: async (arg, api) => {
        try {
          const res = await api.dispatch(
            opsGeneralApi.endpoints.genericSearchByParams.initiate({
              clientId: arg.clientId,
              requested_values: ['route_id', 'route_name'],
              search_fields: [
                'route_name',
                'operation__id',
                'date',
                ...(arg.status?.length ? ['status'] : []),
              ],
              search_queries: [
                arg.search,
                arg.operationIds,
                arg.date,
                ...(arg.status?.length ? [arg.status] : []),
              ],
              filter_expressions: ['icontains', 'in', 'gte', 'in'],
              model_name: 'route',
              order_by: 'route_id',
              requested_labels: ['route_name'],
            }),
          )

          return res
        } catch (err) {}

        return { data: [] }
      },
    }),
    changeDeviceStatus: builder.mutation<
      MutationResponse,
      { clientId: number; deviceId: number; status: number }
    >({
      query({ clientId, deviceId, status }) {
        return {
          url: `asset/sensor/clients/${clientId}/update_sensor_state/`,
          method: 'PUT',
          data: {
            sensor: deviceId,
            sensor_state: status,
          },
        }
      },
      invalidatesTags: ['Devices'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const {
  useAddAssetMutation,
  useAddDeviceToAssetMutation,
  useDeleteDeviceFromAssetMutation,
  useEditAssetMutation,
  useGetAssetDetailsQuery,
  useGetAssetDevicesQuery,
  useGetAssetHistoriesQuery,
  useGetAssetsQuery,
  useSearchAssetsWithActionQuery,
  useCheckAssetNameMutation,
  useCheckAssetTagMutation,
  useGetAssetTypesQuery,
  useGetInstallationTypesQuery,
  useGetMapPointsQuery,
  useGetAssetByIdQuery,
  useGetContentFieldsQuery,
  useGetDeviceTypesQuery,
  useSearchDeviceMutation,
  useGetFullnessQuery,
  useGetTempatureQuery,
  useGetViewSettingsQuery,
  useSaveViewSettingsMutation,
  useExportAssetsMutation,
  useImportAssetsMutation,
  useChangeAssetStatusMutation,
  useGetStatusTransitionsQQuery,
  useGetStatusTransitionsMMutation,
  useGetAssetsStatusAndOperationIdsMutation,
  useGetWasteTypesQuery,
  useGetAssetMapSettingsQuery,
  useGetAssetMapPopupDetailsQuery,
  useSearchRoutesByNameMutation,
  useChangeDeviceStatusMutation,
} = assetApi
