import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { categoryApi, CategoriesResponse } from 'services/EventManagement/category'
import { SidebarColorTheme } from 'containers/Sidebar/Sidebar'

type UiState = {
  currentPageId?: string
  currentModuleId?: string
  pageTitle: string
  settingsUrl?: string
  isSOS: boolean
  notificationCount?: number
  currentClientId?: string
  sosNotificationCount?: number
  showLoadingBar: boolean
  detailSelectedTabs?: {
    [key in string]: {
      topSelectedTab: number
      bottomSelectedTab: number
    }
  }
  sidebarTheme: SidebarColorTheme
  sidebarCollapsed: boolean
  favorites: Array<string>
}

const currentClientIdValue = window.localStorage.getItem('current_client_id')
const currentClientId =
  currentClientIdValue && JSON.parse(currentClientIdValue)
    ? JSON.parse(currentClientIdValue)
    : undefined

const localTheme = window.localStorage.getItem('sidebarTheme')
const localCollapsed = window.localStorage.getItem('sidebarCollapsed')

const detailSelectedTabsValue = window.localStorage.getItem('detailSelectedTabs')
const detailSelectedTabsFromStorage: UiState['detailSelectedTabs'] = detailSelectedTabsValue
  ? JSON.parse(detailSelectedTabsValue)
  : undefined

const initialState: UiState = {
  settingsUrl: '',
  pageTitle: '',
  isSOS: false,
  currentClientId: currentClientId,
  showLoadingBar: false,
  detailSelectedTabs: detailSelectedTabsFromStorage,
  sidebarTheme: localTheme ? (localTheme as SidebarColorTheme) : 'light',
  sidebarCollapsed: localCollapsed
    ? (JSON.parse(localCollapsed) as 'true' | 'false') === 'true'
      ? true
      : false
    : false,
  favorites: [],
}

const getNotificationCount = (array: CategoriesResponse) => {
  return array.map((c) => c.totalEventsNumber).reduce((acc, currentValue) => acc + currentValue)
}

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    changeActiveSidebar(
      state,
      action: {
        payload: {
          subModuleId?: string
          moduleId: string
          pageId: string
        } | null
      },
    ) {
      const { moduleId, subModuleId } = action.payload || {}
      state.currentPageId = subModuleId ?? moduleId
      state.currentModuleId = moduleId
    },
    changePageTitle(state, action: { payload: string }) {
      state.pageTitle = action.payload
    },
    changeSettingsUrl(state, action: { payload: string }) {
      state.settingsUrl = action.payload
    },
    changeClientId(state, action: { payload: string }) {
      state.currentClientId = action.payload
      localStorage.setItem('current_client_id', JSON.stringify(action.payload))
      localStorage.removeItem('environment_engagement')
      localStorage.removeItem('environment_mrf')
    },
    changeTotalNotificationCount(state, action: { payload: CategoriesResponse }) {
      const categories = action.payload
      //id:6 stands for SOS
      const isSOS = categories.find((c) => c.id === '6')
      state.isSOS = isSOS?.totalEventsNumber !== 0 ? true : false
      const newNotificationCount = getNotificationCount(categories)
      // check if total notificationCount changed
      if (newNotificationCount !== state.notificationCount) {
        state.notificationCount = newNotificationCount
        // check if the SOS category exists and sosNotificationCount is changed
        if (isSOS && state.sosNotificationCount !== isSOS.totalEventsNumber) {
          state.sosNotificationCount = isSOS.totalEventsNumber
        }
      }
    },
    setLoading(state, action: { payload: boolean }) {
      state.showLoadingBar = action.payload
    },
    setSelectedTabs: (
      state,
      action: {
        payload: { model: string; tabs: { topSelectedTab: number; bottomSelectedTab: number } }
      },
    ) => {
      const { model, tabs } = action.payload
      if (!state.detailSelectedTabs) {
        state.detailSelectedTabs = {}
      }
      state.detailSelectedTabs[model] = tabs
      localStorage.setItem('detailSelectedTabs', JSON.stringify(state.detailSelectedTabs))
    },
    addToFavorites: (state, action: { payload: string }) => {
      const alreadyFavorite = state.favorites.includes(action.payload)
      if (alreadyFavorite) {
        const copy = [...state.favorites]
        const deleted = copy.filter((f) => f !== action.payload)
        state.favorites = deleted
      } else {
        state.favorites = [...state.favorites, action.payload]
      }
    },
    changeSidebarTheme: (state, action: { payload: SidebarColorTheme }) => {
      state.sidebarTheme = action.payload
      localStorage.setItem('sidebarTheme', action.payload)
    },
    toggleSidebarCollapsed: (state, action: { payload: boolean | undefined }) => {
      localStorage.setItem(
        'sidebarCollapsed',
        JSON.stringify(JSON.stringify(!state.sidebarCollapsed)),
      )
      state.sidebarCollapsed = action.payload ?? !state.sidebarCollapsed
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(categoryApi.endpoints.getCategories.matchFulfilled, (state, { payload }) => {
      const action = {
        payload: payload,
      }
      ui.caseReducers.changeTotalNotificationCount(state, action)
    })
  },
})

export const {
  changePageTitle,
  changeSettingsUrl,
  changeActiveSidebar,
  changeClientId,
  changeTotalNotificationCount,
  setLoading,
  setSelectedTabs,
  addToFavorites,
  changeSidebarTheme,
  toggleSidebarCollapsed,
} = ui.actions

const uiState = (state: RootState) => state
export const selectUi = createSelector(uiState, ({ ui }) => ui)

export default ui.reducer
