import { fleetApi } from './fleet'
import {
  FleetFilter,
  FleetFilterV2,
  FleetResponse,
  FleetResponseV2,
  TrackHistoryResponse,
  CarStatus,
  CarIcons,
} from './types'

export { fleetApi, CarStatus, CarIcons }
export const {
  useAddVehicleMutation,
  useDeleteVehicleMutation,
  useEditVehicleMutation,
  useExportVehicleListMutation,
  useExportDeviceLogsMutation,
  useExportVehicleDevicesMutation,
  useGetInfoQuery,
  useGetEditVehicleDetailQuery,
  useGetContentFieldsQuery,
  useGetVehicleListQuery,
  useGetVehicleTypesQuery,
  useGetVehicleStatusQuery,
  useGetVehicleDetailQuery,
  useGetDeviceTypesQuery,
  useGetDevicesQuery,
  useGetDevicesByIdQuery,
  useUpsertDeviceMutation,
  useGetVehicleDeviceDetailsQuery,
  useDeleteDeviceMutation,
  useGetFleetsV2Query,
  useGetSnapshotByPlateQuery,
  useGetSnapshotMapSettingsQuery,
  useGetVehicleHistoriesQuery,
  useChangeVehicleStatusMutation,
  useGetTrackingHistoryQuery,
  useLazyGetTrackingHistoryQuery,
  useGetTrackingHistoryDetailQuery,
  useGetVehiclesBySearchMutation,
  useGetActivityListQuery,
  useGetActivityTypesQuery,
  useExportActivityListMutation,
  useGetRFIDTagsQuery,
  useGetTagListByVehicleQuery,
  useDetachTagsFromVehicleMutation,
  useGetTrackHistoryActivitiesQuery,
  useGetTrackHistoryActivityDetailsQuery,
  useGetTrackHistoryActivityTypesQuery,
  useGetVehicleActivitiesQuery,
  useExportRFIDListMutation,
  useImportRFIDListMutation,
  useBulkDeleteRFIDMutation,
  useAddRFIDTagMutation,
  useSearchRFIDTagMutation,
  useGetBlacklistReasonsQuery,
  useGetVehicleListByListTypeQuery,
  useAttachRFIDTagsMutation,
  useDetachRFIDTagsMutation,
  useAttachTagsToVehicleMutation,
} = fleetApi

export type { FleetFilter, FleetFilterV2, FleetResponse, FleetResponseV2, TrackHistoryResponse }
