import React from 'react'
import { StyledLogo, StyledLogoType } from './Logo.style'
import logoTypeDark from '../../assets/logo_dark.png'
import logoTypeLight from '../../assets/logo_light.png'
import { useSelector } from 'react-redux'
import { selectUi } from 'services/slices/ui'

export type LogoProps = {
  to?: string
  src: string
}

export const Logo: React.FC<React.PropsWithChildren<LogoProps>> = ({ src, to = '/' }) => {
  const { sidebarCollapsed, sidebarTheme } = useSelector(selectUi)
  return (
    <StyledLogo to={to}>
      <img src={src} alt="logo" />
      <StyledLogoType
        src={sidebarTheme === 'dark' ? logoTypeDark : logoTypeLight}
        sidebarCollapsed={sidebarCollapsed}
      />
    </StyledLogo>
  )
}
