import {
  ControlledMenu,
  Divider,
  GetActionMenuProps,
  color,
  getActionMenu,
  useMenuState,
} from '@evrekadev/evreka-ui-components'
import {
  UilAngleDown,
  UilCopyAlt,
  UilEditAlt,
  UilFileAlt,
  UilFileGraph,
  UilFilePlus,
  UilFilePlusAlt,
  UilHistory,
  UilLinkAlt,
  UilQrcodeScan,
  UilStar,
  UilTrashAlt,
} from '@iconscout/react-unicons'
import { Button, Flex } from 'components'
import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { addToFavorites, selectUi } from 'services/slices/ui'
import styled from 'styled-components'
import { DetailHeaderProps } from './Header'
import { useTranslation } from 'utils/useTranslation'

export type DetailActionAreaProps = Omit<
  DetailHeaderProps,
  'objectName' | 'breadcrumbs' | 'headerExtra'
>

export const DetailActionArea: React.FC<DetailActionAreaProps> = ({
  action,
  onDelete,
  onDuplicate,
  onEdit,
  onHistory,
  onInsights,
  onPrintQR,
  note,
}) => {
  const { t } = useTranslation()
  const buttonAnchor = useRef<HTMLButtonElement>(null)
  const [{ state }, open] = useMenuState()

  if (
    !action &&
    !onDelete &&
    !onDuplicate &&
    !onEdit &&
    !onHistory &&
    !onInsights &&
    !onPrintQR &&
    !note
  ) {
    return null
  }

  const hasLeftSide = !!note || !!onDuplicate || !!onHistory || !!onInsights || !!onPrintQR
  const hasRightSide = !!onDelete || !!onEdit || !!action?.props.children

  return (
    <Flex gap={0.5} data-testid="detail-actions">
      {/* <Button
        onClick={() => window.navigator.clipboard.writeText(window.location.href)}
        variant="text"
        color="secondary"
        size="medium"
        iconLeft={UilLinkAlt}
        onlyIcon
        data-testid="copy-to-clipboard"
      /> */}

      {/* {currentPageId && (
        <Button
          onClick={() => dispatch(addToFavorites(currentPageId))}
          variant="text"
          color={isFavorite ? 'primary' : 'secondary'}
          size="medium"
          iconLeft={UilStar}
          onlyIcon
        />
      )} */}

      {note && (
        <Button
          onClick={note.onNote}
          variant="text"
          color={note.isAdded ? 'primary' : 'secondary'}
          size="medium"
          iconLeft={note.isAdded ? UilFileAlt : UilFilePlusAlt}
          onlyIcon
          data-testid="note"
        />
      )}
      {onDuplicate && (
        <Button
          onClick={onDuplicate}
          variant="text"
          color="secondary"
          size="medium"
          iconLeft={UilCopyAlt}
          onlyIcon
          data-testid="duplicate"
        />
      )}
      {onHistory && (
        <Button
          onClick={onHistory}
          variant="text"
          color="secondary"
          size="medium"
          iconLeft={UilHistory}
          onlyIcon
          data-testid="history"
        />
      )}
      {onInsights && (
        <Button
          onClick={onInsights}
          variant="text"
          color="secondary"
          size="medium"
          iconLeft={UilFileGraph}
          onlyIcon
          data-testid="insights"
        />
      )}
      {onPrintQR && (
        <Button
          onClick={onPrintQR}
          variant="text"
          color="secondary"
          size="medium"
          iconLeft={UilQrcodeScan}
          onlyIcon
          data-testid="print-qr"
        />
      )}
      {hasRightSide && (
        <Flex gap={0.75}>
          {hasLeftSide && hasRightSide && <VerticalDivider />}
          {onDelete && (
            <Button
              onClick={onDelete}
              variant="outline"
              color="secondary"
              size="medium"
              onlyIcon
              iconLeft={UilTrashAlt}
              data-testid="delete"
            />
          )}
          {onEdit && (
            <Button
              onClick={onEdit}
              variant="outline"
              color="secondary"
              size="medium"
              onlyIcon
              iconLeft={UilEditAlt}
              data-testid="edit"
            />
          )}
          {action?.props.children && (
            <>
              <Button
                ref={buttonAnchor}
                onClick={() => open(true)}
                size="medium"
                iconRight={UilAngleDown}
                data-testid="actions"
              >
                {t('actions')}
              </Button>
              <ControlledMenu
                menuStyle={{
                  minWidth: buttonAnchor.current?.offsetWidth,
                }}
                gap={4}
                anchorRef={buttonAnchor}
                onClose={() => open(false)}
                state={state}
                portal
              >
                {action?.props.children}
              </ControlledMenu>
            </>
          )}
        </Flex>
      )}
    </Flex>
  )
}

const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${color('grey200')};
`
