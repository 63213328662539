import React, { PropsWithChildren, useState } from 'react'
import { Flex, FlexCell, Grid, Typography } from 'components'
import { get } from 'lodash-es'
import { DividerStyled } from '../Common.style'
import { DetailHeader, DetailHeaderProps } from 'containers/Header'
import { TypographySkeleton } from 'components/Skeletons/TypographySkeleton'
import { SquareSkeleton } from 'components/Skeletons/SquareSkeleton'
import { TableSkeleton } from 'components/Skeletons'
import { Helmet } from 'react-helmet'
import { InnerContainer, PropertiesHeader, StyledBox } from './Detail.styled'
import { useTranslation } from 'utils/useTranslation'

export type DetailProps = {
  children?: JSX.Element[]
  detailBoxHeight?: number | string
  columns?: string
  helmet?: string
} & Omit<DetailHeaderProps, 'action' | 'headerExtra'>

type DetailComponent = React.FC<React.PropsWithChildren<DetailProps>> & {
  Header: React.FC<React.PropsWithChildren>
  Action: React.FC<React.PropsWithChildren>
  Details: React.FC<React.PropsWithChildren>
  TabArea: React.FC<React.PropsWithChildren>
  Right: React.FC<React.PropsWithChildren<{ title: string; right?: React.ReactNode }>>
  Extra: React.FC<React.PropsWithChildren>
}

const dividerStyles: React.CSSProperties = {
  marginLeft: '1.5rem',
  marginRight: '1.5rem',
  height: '100%',
}
export const Divider: React.FC = () => {
  return (
    <div style={dividerStyles}>
      <DividerStyled />
    </div>
  )
}

export const Detail: DetailComponent = ({
  children,
  breadcrumbs,
  detailBoxHeight,
  objectName,
  helmet,
  ...rest
}) => {
  const { t } = useTranslation()
  const header = children?.find((child) => get(child, 'type.displayName', '') === 'Header')
  const action = children?.find((child) => get(child, 'type.displayName', '') === 'Action')
  const details = children?.find((child) => get(child, 'type.displayName', '') === 'Details')
  const tabArea = children?.find((child) => get(child, 'type.displayName', '') === 'TabArea')
  const right = children?.find((child) => get(child, 'type.displayName', '') === 'Right')
  const extra = children?.find((child) => get(child, 'type.displayName', '') === 'Extra')

  return (
    <Flex directionColumn="zero" style={{ height: '100vh' }} data-testid="detail-layout">
      {helmet && (
        <Helmet>
          <title>{helmet}</title>
        </Helmet>
      )}
      <DetailHeader
        breadcrumbs={breadcrumbs}
        objectName={objectName}
        headerExtra={header?.props.children}
        action={action}
        {...rest}
      />
      <Flex
        directionColumn="zero"
        gap={1.5}
        style={{ padding: '1rem', flexGrow: 1, overflow: 'auto' }}
      >
        <Grid columns={'1fr 2fr'} gap={1}>
          <StyledBox style={{ height: detailBoxHeight }} data-testid="details">
            <PropertiesHeader>
              <Typography fontSize="text_md" fontWeight="semibold" color="grey900">
                {t('details')}
              </Typography>
            </PropertiesHeader>
            <InnerContainer>
              <DetailSection label={'Properties'}>{details?.props.children}</DetailSection>
            </InnerContainer>
          </StyledBox>
          <StyledBox
            hasPadding={!right}
            style={{ height: detailBoxHeight }}
            data-testid="details-right"
          >
            {right && (
              <PropertiesHeader between="zero">
                <Typography fontSize="text_md" fontWeight="semibold" color="grey900">
                  {right.props.title}
                </Typography>
                {right.props.right}
              </PropertiesHeader>
            )}
            {right ? (
              <InnerContainer data-testid="detail-inner-container">
                {right.props.children}
              </InnerContainer>
            ) : (
              tabArea?.props.children
            )}
          </StyledBox>
        </Grid>
        <FlexCell>{extra?.props.children}</FlexCell>
      </Flex>
      {children}
    </Flex>
  )
}

const Header: React.FC<React.PropsWithChildren<{}>> = () => <></>
const Action: React.FC<React.PropsWithChildren<{}>> = () => <></>
const Details: React.FC<React.PropsWithChildren<{}>> = () => <></>
const TabArea: React.FC<React.PropsWithChildren<{}>> = () => <></>
const Right: React.FC<React.PropsWithChildren<{ title: string; right?: React.ReactNode }>> = () => (
  <></>
)
const Extra: React.FC<React.PropsWithChildren<{}>> = () => <></>

Header.displayName = 'Header'
Action.displayName = 'Action'
Details.displayName = 'Details'
TabArea.displayName = 'TabArea'
Right.displayName = 'Right'
Extra.displayName = 'Extra'

Detail.Details = Details
Detail.Header = Header
Detail.Action = Action
Detail.TabArea = TabArea
Detail.Right = Right
Detail.Extra = Extra

const DetailSection: React.FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(true)
  const toggleOpen = () => setIsOpen((o) => !o)
  return (
    <Flex directionColumn="zero" gap={0.75}>
      {/* <Collapse onClick={toggleOpen}>
        <Typography fontSize="text_md" fontWeight="semibold" color="grey700">
          {label}
        </Typography>
        <Icon icon={isOpen ? UilAngleUp : UilAngleDown} color="grey600" size={24} />
      </Collapse> */}
      {isOpen && children}
    </Flex>
  )
}

export const DetailSkeletonNew: React.FC<{ customHeight?: string | number }> = ({
  customHeight,
}) => (
  <Flex directionColumn="zero" data-testid="detail-skeleton">
    <DetailHeader loading />
    <Flex directionColumn="zero" gap={1.5} style={{ padding: '1rem' }}>
      <Grid columns={'1fr 2fr'} gap={1}>
        <StyledBox style={{ height: customHeight }}>
          <PropertiesHeader>
            <TypographySkeleton height={'text_md'} words={3} />
          </PropertiesHeader>
          <InnerContainer>
            <TypographySkeleton words={5} />
            <TypographySkeleton words={3} />
            <TypographySkeleton words={4} />
            <TypographySkeleton words={2} />
            <TypographySkeleton words={5} />
            <TypographySkeleton words={3} />
            <TypographySkeleton words={4} />
            <TypographySkeleton words={2} />
            <TypographySkeleton words={5} />
            <TypographySkeleton words={3} />
          </InnerContainer>
        </StyledBox>
        <StyledBox hasPadding style={{ height: customHeight }}>
          <Flex gap={0.5} fullHeight directionColumn="zero">
            <SquareSkeleton height={34} width={200} />
            <SquareSkeleton height={340} width={'100%'} />
          </Flex>
        </StyledBox>
      </Grid>
      <StyledBox>
        <TableSkeleton />
      </StyledBox>
    </Flex>
  </Flex>
)
