import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { mrfEnvironmentApi } from 'services/environment'
import { getExportHandler, getImportHandler } from 'utils'
import {
  MutationResponse,
  MaterialListResponse,
  MaterialListRequest,
  AddMaterialRequest,
  EditMaterialRequest,
  GetMaterialResponse,
  GetMaterialRequest,
  SearchMaterialRequest,
  SearchMaterialResponse,
  BulkDeleteRequest,
  ExportResponse,
  ExportRequest,
  BulkImportResponse,
  BulkImportRequest,
} from './types'
import { inventoryApi } from './inventory'

export const materialApi = createApi({
  reducerPath: 'materialApi',
  tagTypes: ['Materials', 'MaterialDetails'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/MRF/'}`,
  }),
  endpoints: (builder) => ({
    getMaterialList: builder.query<MaterialListResponse, MaterialListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/material/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Materials'],
    }),
    getMaterial: builder.query<GetMaterialResponse, GetMaterialRequest>({
      query: ({ clientId, materialId }) => ({
        url: `clients/${clientId}/material/?material_id=${materialId}`,
        method: 'GET',
      }),
      providesTags: ['MaterialDetails'],
    }),
    addMaterial: builder.mutation<MutationResponse, AddMaterialRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/material/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Materials'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(mrfEnvironmentApi.util.invalidateTags(['MrfEnvironment']))
          dispatch(inventoryApi.util.invalidateTags(['Inventory', 'InventoryDetails']))
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editMaterial: builder.mutation<MutationResponse, EditMaterialRequest>({
      query: ({ clientId, materialId, ...data }) => ({
        url: `clients/${clientId}/material/${materialId}/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Materials', 'MaterialDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(mrfEnvironmentApi.util.invalidateTags(['MrfEnvironment']))
          dispatch(inventoryApi.util.invalidateTags(['Inventory', 'InventoryDetails']))

          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchMaterial: builder.mutation<SearchMaterialResponse, SearchMaterialRequest>({
      query: ({ clientId, search }) => ({
        url: `clients/${clientId}/material/search/?query_param=${search}`,
        method: 'GET',
      }),
    }),
    deleteMaterial: builder.mutation<MutationResponse, BulkDeleteRequest<number, string>>({
      query: ({ clientId, deleteIdArray }) => ({
        url: `clients/${clientId}/material/bulk_delete/`,
        method: 'DELETE',
        data: { material_id_list: deleteIdArray },
        errorOverride: true,
      }),
      invalidatesTags: ['Materials'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(mrfEnvironmentApi.util.invalidateTags(['MrfEnvironment']))
          dispatch(inventoryApi.util.invalidateTags(['Inventory', 'InventoryDetails']))

          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
          sendNotification({
            type: 'error',
            toastContent: (err as any).error.data.failure_list?.[0]?.description,
          })
        }
      },
    }),
    exportMaterials: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/material/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    bulkImportMaterials: builder.mutation<BulkImportResponse, BulkImportRequest>({
      query: ({ clientId, file }) => ({
        url: `clients/${clientId}/material/bulk_upload/`,
        method: 'POST',
        data: file,
      }),
      onQueryStarted: getImportHandler,
    }),
  }),
})
