import qs from 'qs'
import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  RoutesResponse,
  RoutesRequest,
  QhseResponsesRequest,
  QhseResponsesResponse,
  RouteDetailRequest,
  RouteDetailResponse,
  RouteHistoryRequest,
  RouteHistoryResponse,
  RouteAddRequest,
  GenericSuccessResponse,
  GenericExportListRequest,
  RoutesByOperationResponse,
  RoutesByOperationRequest,
  RoutePlansResponse,
  RoutePlansRequest,
  SearchRoutePlansResponse,
  SearchRoutePlansRequest,
  SearchStaticToursResponse,
  SearchStaticToursRequest,
  RoutePlanAddRequest,
  RoutePlanEditRequest,
  RoutePlanEditValuesResponse,
  RoutePlanEditValuesRequest,
  TaskItineraryListRequest,
  TaskItineraryListResponse,
  MutationResponse,
  TaskItineraryItemEditRequest,
  KMLImportResponse,
  KMLImportRequest,
  ImportSpItineraryListResponse,
  ImportSpItineraryListRequest,
  DrawPathResponse,
  DrawPathRequest,
  CalculateEtaResponse,
  CalculateEtaRequest,
  OptimizeServiceResponse,
  OptimizeServiceRequest,
  MonthlyRoutesResponse,
  MonthlyRoutesRequest,
  OperationRoutesResponse,
  OperationRoutesRequest,
  RouteBulkEditDuplicateRequest,
  RouteBulkCancelRequest,
  DeleteRouteRequest,
  DeleteRouteResponse,
  AssigneesByRoutesResponse,
  AssigneesByRoutesRequest,
  ChangeRoutesAssigneesRequest,
  ChangeRoutesAssigneesResponse,
  RoutesForTasksRequest,
  RoutesForTasksResponse,
  DeactivateRoutePlanRequest,
  AssetAndItemsRequest,
  AssetAndItemsResponse,
  SearchRoutesByParamsRequest,
  SearchRoutesByParamsResponse,
  RoutesByNameRequest,
  RoutesByNameResponse,
  EditDisposalAmountRequest,
  AddDisposalAmountRequest,
  CockpitRouteDetailResponse,
  GetNavigationRecordByRouteResponse,
  GetNavigationRecordByRouteRequest,
  CockpitRouteListResponse,
  PlayerActivityRecord,
} from './types'
import { sendNotification } from 'components'
import { opsGeneralApi } from './opsGeneral'
import { getExportHandler } from 'utils'
import { TableRequestV2, TableResponseV2 } from 'services/types'
import { DATE_TIME_FORMAT, ThemeColorType, dateFormat } from '@evrekadev/evreka-ui-components'
import { getRouteStatus } from 'utils/cockpitUtils'
import { KML_POLYLINE_COLORS } from 'components/Map/layers/KmlLayer'
import { HistoryRequest, HistoryResponse } from 'containers/HistoryV2/types'
import { RouteCardItem } from './types'

export const routeApi = createApi({
  reducerPath: 'routeApi',
  tagTypes: [
    'Routes',
    'RoutePlans',
    'RoutePlanDetail',
    'RouteDetail',
    'TaskItineraryList',
    'RouteCalendarMonth',
    'RouteCalendarDay',
    'RouteHistory',

    'Tasks',
    'TaskDetail',
    'EditTasksFields',
    'TaskTransitionDetails',
    'TaskTransitionDetailPage',
    'TaskHistory',

    'Disposals',

    'CockpitRouteList',
    'CockpitRouteDetail',

    'TasksInMaps',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getRouteDetails: builder.query<RouteDetailResponse, RouteDetailRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_route_by_id/`,
        method: 'POST',
        data,
      }),
      providesTags: ['RouteDetail'],
    }),
    getQhseResponses: builder.query<QhseResponsesResponse, QhseResponsesRequest>({
      query: ({ clientId, route_id, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_qhse_responses/?route_id=${route_id}`,
        method: 'GET',
        data,
      }),
    }),
    getAssetAndItems: builder.query<AssetAndItemsResponse, AssetAndItemsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route/get_route_assets_items/`,
        method: 'POST',
        data,
      }),
    }),
    getRoutes: builder.query<RoutesResponse, RoutesRequest>({
      query: ({ clientId, static_tour_id, ...data }) => ({
        url: `clients/${clientId}/ops_management/list_route/${
          static_tour_id ? '?static_tour_id=' + static_tour_id : ''
        }`,
        method: 'POST',
        data,
      }),
      providesTags: ['Routes'],
    }),
    searchRoutesByName: builder.mutation<RoutesByNameResponse, RoutesByNameRequest>({
      query: ({ clientId, search }) => ({
        url: `clients/${clientId}/ops_management/route/search/?query_param=${search}`,
        method: 'GET',
      }),
    }),
    getRoutesByOperation: builder.query<RoutesByOperationResponse, RoutesByOperationRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_routes_by_operation/?${qs.stringify(data)}`,
        method: 'GET',
      }),
      providesTags: ['Routes'],
    }),
    getRoutesForTasks: builder.query<RoutesForTasksResponse, RoutesForTasksRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_routes_for_tasks/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Routes'],
    }),
    addAdHocRoute: builder.mutation<GenericSuccessResponse, RouteAddRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/create_adhoc_route/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Routes'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportRoutes: builder.mutation<GenericSuccessResponse, GenericExportListRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/export/route/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    getRouteHistory: builder.query<RouteHistoryResponse, RouteHistoryRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_history/`,
        method: 'POST',
        data,
      }),
    }),
    getRoutePlans: builder.query<RoutePlansResponse, RoutePlansRequest>({
      query: ({ clientId, staticTourId, ...data }) => ({
        url: staticTourId
          ? `clients/${clientId}/ops_management/route_plan/list/?static_tour_id=${staticTourId}`
          : `clients/${clientId}/ops_management/route_plan/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['RoutePlans'],
    }),
    searchRoutePlans: builder.mutation<SearchRoutePlansResponse, SearchRoutePlansRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/search/`,
        method: 'POST',
        data,
      }),
    }),
    getStaticTourOptions: builder.query<SearchStaticToursResponse, SearchStaticToursRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/static_tour/search/`,
        method: 'POST',
        data: {
          ...data,
          query_param: '',
        },
      }),
    }),
    addRoutePlan: builder.mutation<GenericSuccessResponse, RoutePlanAddRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/route_plan/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['RoutePlans'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editRoutePlan: builder.mutation<GenericSuccessResponse, RoutePlanEditRequest>({
      query({ clientId, routePlanId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/route_plan/${routePlanId}/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['RoutePlans', 'RoutePlanDetail'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getRoutePlanEditValues: builder.query<RoutePlanEditValuesResponse, RoutePlanEditValuesRequest>({
      query({ clientId, routePlanId, duplicate_date, duplicate_id }) {
        return {
          url: `clients/${clientId}/ops_management/edit_route_plan_detail/${routePlanId}/${
            duplicate_id ? `?duplicate_id=${duplicate_id}` : ''
          }${duplicate_date ? `&duplicate_date=${duplicate_date}` : ''}`,
          method: 'GET',
        }
      },
      providesTags: ['RoutePlanDetail'],
    }),
    importKml: builder.mutation<KMLImportResponse, KMLImportRequest>({
      query: ({ clientId, id, body }) => ({
        url: `clients/${clientId}/ops_management/route/${id}/upload_kml/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['RouteDetail'],
    }),
    importTaskItineraryList: builder.mutation<
      ImportSpItineraryListResponse,
      ImportSpItineraryListRequest
    >({
      query: ({ clientId, id, body }) => ({
        url: `clients/${clientId}/import/route_itinerary_list/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['TaskItineraryList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getItineraryList: builder.query<TaskItineraryListResponse, TaskItineraryListRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route/itinerary_list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['TaskItineraryList'],
    }),
    editTaskItineraryItem: builder.mutation<MutationResponse, TaskItineraryItemEditRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route/edit_route_itinerary/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['TaskItineraryList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    drawPathService: builder.mutation<DrawPathResponse, DrawPathRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/route/${id}/draw_path/`,
        method: 'GET',
      }),
      invalidatesTags: ['RouteDetail'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    calculateEta: builder.mutation<CalculateEtaResponse, CalculateEtaRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/route/${id}/calculate_eta/`,
        method: 'GET',
      }),
      invalidatesTags: ['TaskItineraryList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    optimizeRouteService: builder.mutation<OptimizeServiceResponse, OptimizeServiceRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/route/${id}/optimize_route/`,
        method: 'GET',
      }),
      invalidatesTags: ['TaskItineraryList', 'RouteDetail'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getMonthlyRoutes: builder.query<MonthlyRoutesResponse, MonthlyRoutesRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/monthly_route_list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['RouteCalendarMonth'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getDailyRoutes: builder.query<OperationRoutesResponse, OperationRoutesRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/daily_route_list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['RouteCalendarDay'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    routeBulkDuplicate: builder.mutation<MutationResponse, RouteBulkEditDuplicateRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/duplicate_date/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['RouteCalendarMonth', 'RouteCalendarDay'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    routeBulkEdit: builder.mutation<MutationResponse, RouteBulkEditDuplicateRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/edit_date/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['RouteCalendarMonth', 'RouteCalendarDay'],

      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    routeBulkCancel: builder.mutation<MutationResponse, RouteBulkCancelRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/cancel_date/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['RouteCalendarMonth', 'RouteCalendarDay'],

      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteRoute: builder.mutation<DeleteRouteResponse, DeleteRouteRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route/delete_route/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['Routes', 'CockpitRouteList'],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getAssigneesByRoutes: builder.query<AssigneesByRoutesResponse, AssigneesByRoutesRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_assignees_by_routes/`,
        method: 'POST',
        data,
        errorOverride: true,
      }),
    }),
    changeRoutesAssignees: builder.mutation<
      ChangeRoutesAssigneesResponse,
      ChangeRoutesAssigneesRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/change_route_assignee/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'Routes',
        'RouteDetail',
        'CockpitRouteList',
        'CockpitRouteDetail',
        'RouteHistory',
      ],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deactivateRoutePlans: builder.mutation<MutationResponse, DeactivateRoutePlanRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route_plan/deactivate/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['RoutePlans', 'RoutePlanDetail'],

      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchRoutesByParams: builder.mutation<
      SearchRoutesByParamsResponse,
      SearchRoutesByParamsRequest
    >({
      queryFn: async (arg, api) => {
        try {
          const res = await api.dispatch(
            opsGeneralApi.endpoints.genericSearchByParams.initiate({
              clientId: arg.clientId,
              model_name: 'route',
              requested_values: [
                'status',
                'route_id',
                'route_name',
                'create_datetime',
                'last_status_change',
                'start_time',
                'finish_time',
                'route_order__id',
                'route_order__name',
                'route_assignee__vehicle__plate',
                'route_assignee__employee__driver_name',
                'processed_kml_file',
                'important_locations_v2__id',
              ],
              search_fields: ['operation__id', 'date', ...(arg.status?.length ? ['status'] : [])],
              search_queries: [
                arg.operation,
                arg.date,
                ...(arg.status?.length ? [arg.status] : []),
              ],
              filter_expressions: ['exact', 'exact', ...(arg.status?.length ? ['in'] : [])],

              order_by: 'route_id',
              requested_labels: ['route_id'],
            }),
          )

          return res
        } catch (err) {}

        return { data: [] }
      },
    }),
    getNavigationRecordByRoute: builder.query<
      GetNavigationRecordByRouteResponse,
      GetNavigationRecordByRouteRequest
    >({
      query: ({ clientId, route_id, noDateFormat }) => ({
        url: `clients/${clientId}/ops_management/search/`,
        method: 'GET',
        params: {
          model_name: 'navigationrecord',
          requested_values: JSON.stringify(['latitude', 'longitude', 'create_time', 'speed']),
          search_fields: JSON.stringify(['route__route_id']),
          search_queries: JSON.stringify([route_id]),
          order_by: 'record_id',
          requested_labels: JSON.stringify(['latitude']),
          filter_expressions: JSON.stringify(['exact']),
          date_format: noDateFormat ? undefined : 'dd.MM.yyyy',
          datetime_format: noDateFormat ? undefined : 'dd.MM.yyyy HH:mm:ss',
        },
      }),
    }),
    getDisposalList: builder.query<
      TableResponseV2<{}>,
      TableRequestV2<number> & { route_id: number }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/dump_notification/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Disposals'],
    }),
    addDisposalAmount: builder.mutation<MutationResponse, AddDisposalAmountRequest>({
      query({ data }) {
        return {
          url: `navigation/auth/report_dumping_weight/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Disposals'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editDisposalAmount: builder.mutation<MutationResponse, EditDisposalAmountRequest>({
      query({ client_id, disposal_id, ...data }) {
        return {
          url: `clients/${client_id}/ops_management/dump_notification/edit/${disposal_id}/`,
          method: 'PUT',
          data,
        }
      },
      invalidatesTags: ['Disposals'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getRouteActivities: builder.query<
      { data: Array<PlayerActivityRecord> },
      { clientId: number; routeId: number }
    >({
      query({ clientId, routeId }) {
        return {
          url: `clients/${clientId}/ops_management/route/${routeId}/activities/`,
          method: 'GET',
        }
      },
    }),
    getCockpitRouteDetails: builder.query<
      CockpitRouteDetailResponse,
      { client_id: number; route_id: number }
    >({
      query({ client_id, route_id }) {
        return {
          url: `clients/${client_id}/ops_management/cockpit/route_detail/`,
          method: 'POST',
          data: {
            route_id,
          },
        }
      },
      providesTags: ['CockpitRouteDetail'],
    }),
    getCockpitRouteList: builder.query<
      Array<RouteCardItem>,
      {
        client_id: number
        operation_id: number
        date: string
        status?: Array<number>
        timezone?: string
      }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/cockpit/routes/`,
        method: 'POST',
        data,
      }),
      providesTags: ['CockpitRouteList'],
      transformResponse(res: CockpitRouteListResponse, undefined, { timezone }) {
        let count = 0

        const mappedData = res.map((data) => {
          if (count > 6) {
            count = 0
          }

          const item: RouteCardItem = {
            id: data.route_id,
            name: data.route_name,
            create_time: dateFormat(data.create_datetime, DATE_TIME_FORMAT, timezone),
            last_status_change: dateFormat(data.last_status_change, DATE_TIME_FORMAT, timezone),
            kml: {
              url: data.kml ?? null,
              color: KML_POLYLINE_COLORS[count] as keyof ThemeColorType,
            },
            time:
              data.start_time.substring(0, data.start_time.length - 3) +
              '-' +
              data.finish_time.substring(0, data.finish_time.length - 3),
            status: {
              id: data.status,
              name: getRouteStatus(data.status),
            },
            assignee: {
              vehicle: data.assignee_vehicle_plate,
              employee: data.assignee_employee_driver_name,
            },
            routePlan:
              data.route_plan_id && data.route_plan_name
                ? { id: data.route_plan_id, name: data.route_plan_name }
                : null,
            start_time_raw: data.start_time,
            end_time_raw: data.finish_time,
            important_locations: data.important_locations,
          }

          count++

          return item
        })

        return mappedData
      },
    }),
    getRouteHistoryV2: builder.query<
      HistoryResponse & MutationResponse,
      HistoryRequest & { clientId: number; routeId: number }
    >({
      query({ clientId, routeId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/route/${routeId}/history/`,
          method: 'POST',
          data,
        }
      },
      providesTags: ['RouteHistory'],
    }),
  }),
})

export const {
  useGetRoutesQuery,
  useAddAdHocRouteMutation,
  useGetRouteDetailsQuery,
  useGetQhseResponsesQuery,
  useExportRoutesMutation,
  useGetRouteHistoryQuery,
  useSearchRoutesByNameMutation,
  useGetRoutesByOperationQuery,
  useGetRoutePlansQuery,
  useSearchRoutePlansMutation,
  useGetStaticTourOptionsQuery,
  useAddRoutePlanMutation,
  useEditRoutePlanMutation,
  useGetRoutePlanEditValuesQuery,
  useGetItineraryListQuery,
  useEditTaskItineraryItemMutation,
  useImportKmlMutation,
  useImportTaskItineraryListMutation,
  useDrawPathServiceMutation,
  useCalculateEtaMutation,
  useOptimizeRouteServiceMutation,
  useGetMonthlyRoutesQuery,
  useGetDailyRoutesQuery,
  useRouteBulkCancelMutation,
  useRouteBulkDuplicateMutation,
  useRouteBulkEditMutation,
  useDeleteRouteMutation,
  useGetAssigneesByRoutesQuery,
  useChangeRoutesAssigneesMutation,
  useGetRoutesForTasksQuery,
  useDeactivateRoutePlansMutation,
  useGetAssetAndItemsQuery,
  useSearchRoutesByParamsMutation,
  useGetDisposalListQuery,
  useAddDisposalAmountMutation,
  useEditDisposalAmountMutation,
  useGetNavigationRecordByRouteQuery,
  useGetRouteActivitiesQuery,
  useGetCockpitRouteDetailsQuery,
  useGetCockpitRouteListQuery,
  useLazyGetCockpitRouteListQuery,
  useGetRouteHistoryV2Query,
} = routeApi
