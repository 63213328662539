import React from 'react'
import { useTranslation } from './useTranslation'

export enum ComponentVariant {
  DROPDOWN,
  SEARCH,
}

export const WithTranslation = <T extends object>(
  Component: React.ComponentType<React.PropsWithChildren<T>>,
  variant: ComponentVariant,
): React.ForwardRefExoticComponent<
  React.PropsWithoutRef<React.PropsWithChildren<T>> & React.RefAttributes<any>
> => {
  const WithTranslation = React.forwardRef<any, React.PropsWithChildren<T>>(({ ...props }, ref) => {
    const { t: globalT } = useTranslation()

    if (variant === ComponentVariant.DROPDOWN) {
      return <Component {...props} placeholder={globalT('placeholder.default.select')} />
    }

    if (variant === ComponentVariant.SEARCH) {
      return (
        <Component
          {...props}
          placeholder={globalT('placeholder.default.search')}
          noOptionsPlaceholder={globalT('placeholder.default.no_options')}
          loadingMessagePlaceholder={globalT('placeholder.default.loading')}
        />
      )
    }

    return <Component {...props} />
  })

  return WithTranslation
}
