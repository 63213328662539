import { NavigationCommandType } from 'containers/Command/types'

export const usersRoutes: Array<NavigationCommandType> = [
  {
    moduleId: 'users',
    submoduleId: 'users',
    commandId: 'list-page',
    type: 'navigation',
    to: '/',
    label: 'pages.user_management.title',
  },
]
