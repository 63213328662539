import styled from 'styled-components'
import { Search } from 'components'
import { color, padding, borderRadius } from '@evrekadev/evreka-ui-components'

export const LegendStyled = styled.div<{ isRtl?: boolean }>`
  ${padding([8])}
  ${borderRadius(12)}
  background: ${color('white')};
  border: 1px solid ${color('grey200')};

  cursor: default;
  left: ${(props) => (props.isRtl ? '10px' : 'unset')};
  right: ${(props) => (props.isRtl ? 'unset' : '10px')};
  top: 10px;
  position: absolute;
  z-index: 501;
  height: calc(100% - 2rem);
  width: 30%;
  overflow: auto;
`

export const SearchStyled = styled((props) => <Search {...props} />)`
  position: absolute;
  z-index: 1000;
`
