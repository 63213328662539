import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { ExternalSearchReq, ExternalSearchRes } from './types'

export const externalApi = createApi({
  reducerPath: 'externalApi',
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  }),
  endpoints: (builder) => ({
    searchMRFExternal: builder.mutation<ExternalSearchRes, ExternalSearchReq>({
      query: ({ model_name, external_client_id, search_query }) => ({
        url: `/MRF/v1/external/${external_client_id}/search/?model_name=${model_name}&search_fields=[{"field_name": "name", "exact_match": False}]&search_query=${search_query}`,
        method: 'GET',
      }),
    }),
  }),
})

export const { useSearchMRFExternalMutation } = externalApi
