import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { GenericResponse, TableRequestV2, TableResponseV2 } from 'services/types'
import {
  AddShiftRequest,
  DeleteShiftRequest,
  EditShiftRequest,
  GetShiftDetailsRequest,
  GetShiftDetailsResponse,
  DeleteIncidentCategoryRequest,
  SearchSPByParamsRequest,
  SearchSPByParamsResponse,
  UpsertIncidentCategoryRequest,
  Question,
  Form,
  FormUpsertRequestBody,
  Answer,
  AnswerGroup,
  ImportantLocationsMapListResponse,
  ImportantLocationsEnvironmentResponse,
  ImportantLocationDetailResponse,
  PreferencesResponse,
  PreferenceType,
  ListIncidentCategoriesResponse,
  MutationResponse,
  ImportantLocationAddRequest,
  ImportantLocationEditRequest,
  EmployeeDetailsResponse,
  AddEmployeeRequest,
  AddVehicleRequest,
} from './types'
import { sendNotification } from 'components'
import { ActionResponse } from 'services/types'
import { opsGeneralApi } from './opsGeneral'
import { DropdownOptionTemplate } from 'types/common'
import { servicePointApi } from './servicePoints'

export const opsSettingsApi = createApi({
  reducerPath: 'opsSettingsApi',
  tagTypes: [
    'IncidentCategories',
    'IncidentTypes',
    'Shifts',
    'ShiftDetails',
    'QHSE_forms',
    'QHSE_form',
    'Questions',
    'Question',
    'Form_Operations',
    'ImportantLocations',
    'ImportantLocationDetail',
    'Preferences',
    'Employees',
    'EmployeeDetails',
    'Vehicles',
    'VehicleDetails',
    'ImportantLocationsEnvironment',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    listShifts: builder.query<TableResponseV2<{}>, TableRequestV2>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/shifts/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Shifts'],
    }),
    getShiftDetails: builder.query<GetShiftDetailsResponse, GetShiftDetailsRequest>({
      query: ({ clientId, shiftId }) => ({
        url: `clients/${clientId}/ops_management/shifts/${shiftId}/`,
        method: 'GET',
      }),
      providesTags: ['ShiftDetails'],
    }),
    addShift: builder.mutation<GenericResponse, AddShiftRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/shifts/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Shifts'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(servicePointApi.util.invalidateTags(['Shifts']))
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editShift: builder.mutation<GenericResponse, EditShiftRequest>({
      query: ({ clientId, shiftId, ...data }) => ({
        url: `clients/${clientId}/ops_management/shifts/${shiftId}/`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Shifts', 'ShiftDetails'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(servicePointApi.util.invalidateTags(['Shifts']))
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteShift: builder.mutation<GenericResponse, DeleteShiftRequest>({
      query: ({ clientId, shiftId }) => ({
        url: `clients/${clientId}/ops_management/shifts/${shiftId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Shifts'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(servicePointApi.util.invalidateTags(['Shifts']))
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getIncidentCategories: builder.query<ListIncidentCategoriesResponse, { clientId: number }>({
      providesTags: ['IncidentCategories'],
      queryFn: async (arg, api) => {
        try {
          const res = await api.dispatch(
            opsGeneralApi.endpoints.genericSearchByParams.initiate({
              clientId: arg.clientId,
              model_name: 'incidentcategory',
              requested_values: ['name', 'image'],
              search_fields: [],
              search_queries: [],
              filter_expressions: [],

              order_by: 'id',
              requested_labels: ['name'],
            }),
          )

          return res
        } catch (err) {}

        return { data: [] }
      },
    }),
    addIncidentCategory: builder.mutation<ActionResponse, UpsertIncidentCategoryRequest>({
      query({ clientId, body }) {
        return {
          url: `clients/${clientId}/ops_management/incident_categories/`,
          method: 'POST',
          data: body,
        }
      },
      invalidatesTags: ['IncidentCategories'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editIncidentCategory: builder.mutation<ActionResponse, UpsertIncidentCategoryRequest>({
      query({ clientId, id, body }) {
        return {
          url: `clients/${clientId}/ops_management/incident_categories/${id}/`,
          method: 'PUT',
          data: body,
        }
      },
      invalidatesTags: ['IncidentCategories'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteIncidentCategory: builder.mutation<ActionResponse, DeleteIncidentCategoryRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/incident_categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IncidentCategories'],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getIncidentTypes: builder.query<
      TableResponseV2<{}>,
      { incidentCategory: number } & TableRequestV2<number>
    >({
      query: ({ client_id, incidentCategory, ...data }) => ({
        url: `clients/${client_id}/ops_management/incident_types/list/?incident_category_id=${incidentCategory}`,
        method: 'POST',
        data,
      }),
      providesTags: ['IncidentTypes'],
    }),
    getIncidentType: builder.query<
      {
        data: {
          id: number
          incident_category_id: number
          name: string
        }
      },
      { clientId: number; incidentType: number }
    >({
      query: ({ clientId, incidentType }) => ({
        url: `clients/${clientId}/ops_management/incident_types/${incidentType}`,
        method: 'GET',
      }),
    }),
    addIncidentType: builder.mutation<
      { success: boolean; detail: { message: string }; form_id: number },
      { client_id: number; name: string; incident_category_id: number }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/incident_types/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['IncidentTypes'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editIncidentType: builder.mutation<
      { success: boolean; detail: { message: string }; form_id: number },
      { client_id: number; name: string; incident_category_id: number; incident_type_id: number }
    >({
      query: ({ client_id, incident_type_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/incident_types/${incident_type_id}/`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['IncidentTypes'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteIncidentType: builder.mutation<
      MutationResponse,
      { client_id: number; incidentTypeId: number }
    >({
      query: ({ client_id, incidentTypeId }) => ({
        url: `clients/${client_id}/ops_management/incident_types/${incidentTypeId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['IncidentTypes'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getQHSEForms: builder.query<{ form_list: Form[] }, { client_id: number }>({
      query: ({ client_id }) => ({
        url: `clients/${client_id}/ops_management/qshe_forms/`,
        method: 'GET',
      }),
      providesTags: ['QHSE_forms'],
    }),
    getQHSEForm: builder.query<Form, { client_id: number; form_id: number }>({
      query: ({ client_id, form_id }) => ({
        url: `clients/${client_id}/ops_management/qshe_forms/${form_id}/`,
        method: 'GET',
      }),
      providesTags: ['QHSE_form'],
    }),
    addQHSEForm: builder.mutation<
      { success: boolean; detail: { message: string }; form_id: number },
      { client_id: number; body: FormUpsertRequestBody }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/ops_management/qshe_forms/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['QHSE_forms', 'Form_Operations', 'Preferences'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editQHSEForm: builder.mutation<
      { success: boolean; detail: { message: string } },
      { client_id: number; form_id: number; body: FormUpsertRequestBody }
    >({
      query: ({ client_id, form_id, body }) => ({
        url: `clients/${client_id}/ops_management/qshe_forms/${form_id}/`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['QHSE_forms', 'QHSE_form', 'Form_Operations'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteQHSEForm: builder.mutation<
      { success: boolean; detail: { message: string } },
      { client_id: number; form_id: string }
    >({
      query: ({ client_id, form_id }) => ({
        url: `clients/${client_id}/ops_management/qshe_forms/${form_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['QHSE_forms', 'Form_Operations', 'Preferences'],
    }),
    getQuestions: builder.query<
      Question<number, AnswerGroup>[],
      TableRequestV2 & { form_id: number }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/questions/list/`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: TableResponseV2<{}>) => {
        return (response.data as Question<number, AnswerGroup>[])
          .map((question) => ({
            id: Number(question?.id),
            placement: question?.placement,
            text: question?.text,
            answers: question?.answers,
            is_critical: question?.is_critical,
            image: question?.image,
            order: question?.order,
          }))
          .sort((a, b) => a?.order - b?.order)
      },
      providesTags: ['Questions'],
    }),
    deleteQuestion: builder.mutation<
      { success: boolean; detail: { message: string } },
      { client_id: number; question_id: string }
    >({
      query: ({ client_id, question_id }) => ({
        url: `clients/${client_id}/ops_management/questions/${question_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Questions'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getQuestion: builder.query<
      { data: Question<DropdownOptionTemplate<number>[0], Answer[]> },
      { client_id: number; question_id: string }
    >({
      query: ({ client_id, question_id }) => ({
        url: `clients/${client_id}/ops_management/questions/${question_id}/`,
        method: 'GET',
      }),
      providesTags: ['Question'],
    }),
    addQuestion: builder.mutation<
      { success: boolean; form_id: number; detail: { message: string } },
      { client_id: number; body: FormData }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/ops_management/questions/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Questions'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editQuestion: builder.mutation<
      { success: boolean; form_id: number; detail: { message: string } },
      { client_id: number; body: FormData; question_id: number }
    >({
      query: ({ client_id, body, question_id }) => ({
        url: `clients/${client_id}/ops_management/questions/${question_id}/`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Questions', 'Question'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getFormOperations: builder.query<
      { value: number; label: string; disable: boolean }[],
      { client_id: number }
    >({
      query: ({ client_id }) => ({
        url: `clients/${client_id}/ops_management/get_form_operations/`,
        method: 'GET',
      }),
      providesTags: ['Form_Operations'],
    }),
    setQuestionOrders: builder.mutation<
      { value: number; label: string; disable: boolean }[],
      {
        client_id: number
        body: { question_order: { question_id: number; order: number }[] }
      }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/ops_management/set_question_orders/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Questions'],
    }),
    getImportantLocationMapList: builder.query<
      ImportantLocationsMapListResponse,
      { client_id: number }
    >({
      query: ({ client_id }) => ({
        url: `clients/${client_id}/ops_management/important_location/map_list/`,
        method: 'GET',
      }),
      providesTags: ['ImportantLocations'],
    }),
    getImportantLocationEnvironment: builder.query<
      ImportantLocationsEnvironmentResponse,
      { client_id: number }
    >({
      query: ({ client_id }) => ({
        url: `clients/${client_id}/ops_management/important_location/environment/`,
        method: 'GET',
      }),
      providesTags: ['ImportantLocationsEnvironment'],
    }),
    addImportantLocation: builder.mutation<GenericResponse, ImportantLocationAddRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/important_location/add/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['ImportantLocations', 'Preferences'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editImportantLocation: builder.mutation<GenericResponse, ImportantLocationEditRequest>({
      query: ({ client_id, id, ...data }) => ({
        url: `clients/${client_id}/ops_management/important_location/edit/${id}`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['ImportantLocations', 'ImportantLocationDetail'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getImportantLocationDetail: builder.query<
      ImportantLocationDetailResponse,
      { client_id: number; id: number }
    >({
      query: ({ client_id, id }) => ({
        url: `clients/${client_id}/ops_management/important_location/detail/${id}`,
        method: 'GET',
      }),
      providesTags: ['ImportantLocationDetail'],
    }),
    deleteImportantLocation: builder.mutation<GenericResponse, { client_id: number; id: number }>({
      query: ({ client_id, id }) => ({
        url: `clients/${client_id}/ops_management/important_location/delete/${id}`,
        method: 'GET',
      }),
      invalidatesTags: ['ImportantLocations', 'Preferences'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getPreferences: builder.query<PreferencesResponse, { client_id: number; operation_id: number }>(
      {
        query: ({ client_id, operation_id }) => ({
          url: `clients/${client_id}/ops_management/operations/${operation_id}/operation_preferences/`,
          method: 'GET',
        }),
        providesTags: ['Preferences'],
      },
    ),
    updatePreferences: builder.mutation<
      GenericResponse,
      {
        client_id: number
        operation_id: number
        data: {
          key: PreferenceType
          enabled: boolean
        }
      }
    >({
      query: ({ client_id, operation_id, data }) => ({
        url: `clients/${client_id}/ops_management/operations/${operation_id}/operation_preferences/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Preferences'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    employeeList: builder.query<
      TableResponseV2<{}> & { employee_limit: number },
      TableRequestV2<number>
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/employees/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Employees'],
    }),
    searchCustomerDriverId: builder.mutation<
      { customer_driver_list: DropdownOptionTemplate<number> },
      { client_id: number; query_param: string }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/employees/customer_driver_id/search/`,
        method: 'POST',
        data,
      }),
    }),
    searchEmployeeCode: builder.mutation<
      { employee_code_list: DropdownOptionTemplate<number> },
      { client_id: number; query_param: string }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/employees/employee_code/search/`,
        method: 'POST',
        data,
      }),
    }),
    employeeDetail: builder.query<
      EmployeeDetailsResponse,
      { client_id: number; employee_id: number }
    >({
      query: ({ client_id, employee_id }) => ({
        url: `clients/${client_id}/ops_management/employees/${employee_id}/`,
        method: 'GET',
      }),
      providesTags: ['EmployeeDetails'],
    }),
    addEmployee: builder.mutation<GenericResponse, AddEmployeeRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/employees/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Employees'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editEmployee: builder.mutation<GenericResponse, AddEmployeeRequest & { id: number }>({
      query: ({ client_id, id, ...data }) => ({
        url: `clients/${client_id}/ops_management/employees/${id}/`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['Employees', 'EmployeeDetails'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteEmployee: builder.mutation<GenericResponse, { client_id: number; id: number }>({
      query: ({ client_id, id }) => ({
        url: `clients/${client_id}/ops_management/employees/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Employees'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    vehicleList: builder.query<
      TableResponseV2<{}> & { vehicle_limit: number },
      TableRequestV2<number>
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/vehicle/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Vehicles'],
    }),
    vehicleDetail: builder.query<
      { external_id: string; operations: number[]; plate: string },
      { client_id: number; vehicle_id: number }
    >({
      query: ({ client_id, vehicle_id }) => ({
        url: `clients/${client_id}/ops_management/vehicle/${vehicle_id}/`,
        method: 'GET',
      }),
      providesTags: ['VehicleDetails'],
    }),
    addVehicle: builder.mutation<GenericResponse, AddVehicleRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/vehicle/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Vehicles'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editVehicle: builder.mutation<GenericResponse, AddVehicleRequest & { id: number }>({
      query: ({ client_id, id, ...data }) => ({
        url: `clients/${client_id}/ops_management/vehicle/${id}/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Vehicles', 'VehicleDetails'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteVehicle: builder.mutation<GenericResponse, { client_id: number; id: number }>({
      query: ({ client_id, id }) => ({
        url: `clients/${client_id}/ops_management/vehicle/${id}/delete/`,
        method: 'GET',
      }),
      invalidatesTags: ['Vehicles'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: data.success ? 'success' : 'error',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const {
  useGetIncidentCategoriesQuery,
  useAddIncidentCategoryMutation,
  useEditIncidentCategoryMutation,
  useDeleteIncidentCategoryMutation,
  useGetQHSEFormsQuery,
  useGetQHSEFormQuery,
  useAddQHSEFormMutation,
  useEditQHSEFormMutation,
  useDeleteQHSEFormMutation,
  useGetQuestionsQuery,
  useDeleteQuestionMutation,
  useGetQuestionQuery,
  useAddQuestionMutation,
  useGetFormOperationsQuery,
  useEditQuestionMutation,
  useSetQuestionOrdersMutation,
  useGetImportantLocationMapListQuery,
  useGetImportantLocationEnvironmentQuery,
  useAddImportantLocationMutation,
  useEditImportantLocationMutation,
  useGetImportantLocationDetailQuery,
  useDeleteImportantLocationMutation,
  useGetPreferencesQuery,
  useUpdatePreferencesMutation,
  useGetIncidentTypesQuery,
  useEditIncidentTypeMutation,
  useAddIncidentTypeMutation,
  useDeleteIncidentTypeMutation,
  useGetIncidentTypeQuery,
  useEmployeeListQuery,
  useSearchCustomerDriverIdMutation,
  useSearchEmployeeCodeMutation,
  useEmployeeDetailQuery,
  useAddEmployeeMutation,
  useEditEmployeeMutation,
  useDeleteEmployeeMutation,
  useVehicleListQuery,
  useDeleteVehicleMutation,
  useVehicleDetailQuery,
  useAddVehicleMutation,
  useEditVehicleMutation,
} = opsSettingsApi
