import React, { useEffect, useRef } from 'react'
import { RouteProps, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { selectAuthRoutes, selectClientId, selectCurrentUser } from 'services/slices/auth'
import { NotAuthenticated } from 'pages/NotAuthenticated'
import { usePrevious } from 'utils/usePrevious'
import { selectUi } from 'services/slices/ui'
import { changeTotalNotificationCount } from 'services/slices/ui'
import { Heading, sendNotificationModal } from 'components'
import { useGetCategoriesQuery } from 'services/EventManagement'
import { useTranslation } from 'utils/useTranslation'
import { LayoutList } from 'containers/Layout'
import { skipToken } from '@reduxjs/toolkit/dist/query'

export const SocketContext = React.createContext<any>({})

export const useWebsocket = () => React.useContext(SocketContext)

type ProtectedRouteProps = {
  pageId?: string
  moduleId: string
  subModuleId?: string
} & RouteProps

export const ProtectedRoute: React.FC<React.PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  pageId,
  moduleId,
  subModuleId,
  element,
  ...rest
}) => {
  const navigate = useNavigate()
  const user = useSelector(selectCurrentUser)
  const authRoutes = useSelector(selectAuthRoutes)
  const selectedClientId = useSelector(selectClientId)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sosNotificationCount } = useSelector(selectUi)
  const socket = useRef<WebSocket | null>(null)
  const prevNotificationCount = usePrevious(sosNotificationCount)

  const { data, refetch } = useGetCategoriesQuery(selectedClientId ?? skipToken)

  const userHasRequiredRole =
    authRoutes &&
    ((pageId && authRoutes.includes(pageId)) ||
      (subModuleId && authRoutes.includes(subModuleId)) ||
      //mrf inbounds_outbounds related fix
      (subModuleId && subModuleId === 'mrf_inbounds') ||
      (subModuleId === 'mrf_allocations' && authRoutes.includes('mrf_inbounds_outbounds')) ||
      (moduleId && authRoutes.includes(moduleId)))

  useEffect(() => {
    if (selectedClientId && selectedClientId !== -1 && process.env.REACT_APP_WEBSOCKET_URL) {
      if (socket.current !== null) {
        socket.current.close()
      }
      socket.current = new WebSocket(
        `${process.env.REACT_APP_WEBSOCKET_URL}:6789/alert/${selectedClientId}`,
      )

      socket.current.onopen = () => {}

      socket.current.onmessage = (event: any) => {
        dispatch(changeTotalNotificationCount(JSON.parse(event.data)))
      }
    }
    return () => {
      socket?.current?.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientId])

  useEffect(() => {
    if (selectedClientId && selectedClientId !== -1) {
      data && refetch()
    }
  }, [selectedClientId])

  useEffect(() => {
    // check if there is a prevNotificationCount set and prevNotificationCount < sosNotificationCount
    // to render notification modal
    if (
      prevNotificationCount &&
      sosNotificationCount &&
      prevNotificationCount < sosNotificationCount
    ) {
      sendNotificationModal({
        variant: 'error',
        children: (
          <Heading>
            {t('pages.event_management.events.emergency_text', {
              id: selectedClientId,
            })}
          </Heading>
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sosNotificationCount])

  if (!user) {
    // && !(process.env.NODE_ENV === 'development')
    navigate(`/login`)
    return null
  } else if (selectedClientId === undefined || selectedClientId === -1) {
    return (
      <Heading style={{ position: 'absolute', margin: 'auto' }}>
        {t('placeholder.default.select_user')}
      </Heading>
    )
  } else if (
    (rest.path === '/' || rest.path === '/invalid_client') &&
    process.env.REACT_APP_WEBSOCKET_URL
  ) {
    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  } else if (rest.path === '/' || rest.path === '/invalid_client') {
    return <>{children}</>
  } else if (userHasRequiredRole && process.env.REACT_APP_WEBSOCKET_URL) {
    return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  } else if (userHasRequiredRole) {
    return <>{children}</>
  } else {
    return (
      <LayoutList>
        <NotAuthenticated />
      </LayoutList>
    )
  }
}
