import { BaseColorType, ThemeColors } from '@evrekadev/evreka-ui-components'
import i18n from 'utils/i18n'

export const getColorByHexCode = (hex: string) => {
  const colors = [
    ['green', '#3BA935'],
    ['grey', ThemeColors.darkblue400],
    ['red', ThemeColors.red500],
    ['green', ThemeColors.green500],
    ['yellow', ThemeColors.yellow500],
    ['blue', ThemeColors.blue500],
    ['orange', ThemeColors.orange500],
  ]

  const found = colors.find((color) => color.includes(hex) && color[0])

  return found ? (found[0] as Exclude<BaseColorType, 'black' | 'white'>) : null
}

const TRANSLATION_PREFIX = 'pages.ops_management.cockpit.routes.statuses'
export const getRouteStatus = (id: number) => {
  const statuses = [
    [i18n.t(`${TRANSLATION_PREFIX}.upcoming`), 0],
    [i18n.t(`${TRANSLATION_PREFIX}.undispatched`), 1],
    [i18n.t(`${TRANSLATION_PREFIX}.checkin`), 2],
    [i18n.t(`${TRANSLATION_PREFIX}.dispatched`), 3],
    [i18n.t(`${TRANSLATION_PREFIX}.vcrFail`), 4],
    [i18n.t(`${TRANSLATION_PREFIX}.cancelled`), 5],
    [i18n.t(`${TRANSLATION_PREFIX}.offline`), 6],
    [i18n.t(`${TRANSLATION_PREFIX}.expired`), 7],
    [i18n.t(`${TRANSLATION_PREFIX}.finished`), 8],
  ]

  const found = statuses.find((status) => status.includes(id) && status[0])

  return found ? (found[0] as string) : null
}
