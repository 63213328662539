import styled from 'styled-components'
import {
  DefaultTheme,
  ThemeColorKeys,
  color,
  mediaQuery,
  padding,
} from '@evrekadev/evreka-ui-components'
import { SidebarBaseProps, SidebarColorTheme, SidebarContextProps } from './Sidebar'
import { Flex } from 'components'

export const SIDEBAR_SIZES = {
  collapsed: '65px',
  expanded: '241px',
}

export const themedColor =
  (
    $color: SidebarSemanticKeys,
  ): ((
    props: {
      theme: DefaultTheme
    } & Pick<SidebarBaseProps, 'sidebarTheme'>,
  ) => ReturnType<typeof color>) =>
  (props) =>
    color(semanticSidebarColors[$color][props.sidebarTheme])

export const SidebarContentStyled = styled(Flex)`
  flex-grow: 1;
`

export const SidebarLayoutStyled = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
`

export const SidebarInnerStyled = styled.div<SidebarBaseProps>`
  background-color: ${themedColor('background')};
  height: 100%;
  position: relative;
  border-right: 1px solid ${themedColor('border')};

  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  scrollbar-gutter: stable;

  ${padding([16, 12])}

  padding-right: calc(12px - 5px); //for scrollbar-gutter
`
export const SidebarStyled = styled.div<SidebarBaseProps>`
  left: 0;
  width: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? SIDEBAR_SIZES.collapsed : SIDEBAR_SIZES.expanded};
  min-width: ${({ sidebarCollapsed }) =>
    sidebarCollapsed ? SIDEBAR_SIZES.collapsed : SIDEBAR_SIZES.expanded};
  text-align: left;
  transition:
    width,
    left,
    right,
    0.3s ease-in-out;
  z-index: 1009;
  bottom: 0;
  float: none;
  height: 100vh;
  position: fixed;
  top: 0;
  min-height: 100%;
  overflow-y: hidden;
  ${mediaQuery('md', 'max')} {
    transform: ${({ sidebarCollapsed }) =>
      sidebarCollapsed ? `translate(-${SIDEBAR_SIZES.expanded}px, 0)` : 'translate(0px, 0)'};
  }

  // color transition for theme change
  * {
    transition:
      color,
      border-color,
      opacity,
      background-color 0.2s ease-in-out;
  }
`
type SidebarSemanticKeys =
  | 'background'
  | 'background-muted'
  | 'border'
  | 'text'
  | 'text-muted'
  | 'text-active'

export const semanticSidebarColors: Record<
  SidebarSemanticKeys,
  { light: ThemeColorKeys; dark: ThemeColorKeys }
> = {
  background: {
    light: 'white',
    dark: 'darkblue900',
  },
  'background-muted': {
    light: 'grey50',
    dark: 'darkblue800',
  },
  border: {
    light: 'grey200',
    dark: 'transparent',
  },
  text: {
    light: 'grey700',
    dark: 'grey25',
  },
  'text-muted': {
    light: 'grey500',
    dark: 'grey300',
  },
  'text-active': {
    light: 'grey900',
    dark: 'grey100',
  },
}
