// TODO move this file into OpsManagement folder
import { Coordinate, MAP_SHAPES } from 'components/Map/types'
import {
  ColumnExtended,
  FilterRequestList,
  SortRequest,
  BaseColorType,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  ColumnType,
} from '@evrekadev/evreka-ui-components'
import { strEnum } from '@evrekadev/evreka-ui-components'
import { AssetSettingsLegendType } from 'services/AssetManagement/types'
import { OpsServicePointType } from 'services/OpsManagement/types'
import { DropdownOptionTemplate } from 'types/common'

export type ActionResponse = { success: boolean; id: number; detail: { message: string } }

export type MapPointsRequest = {
  clientId: number | undefined | string
  coloring_type?: string
  _southWest: { lat: number; lng: number }
  _northEast: { lat: number; lng: number }
  filters: FilterRequestList
  sort: SortRequest
}

export type MapPointItem = {
  id: number
  latitude: number
  longitude: number
  name?: string
  color: string
  icon: string
}

export type MapPointsResponse = Array<MapPointItem>

export type TableResponseV2<CType> = {
  columns: Array<ColumnType>
  data: Array<{ [key: string]: CType | string | DropdownOptionTemplate[0] }>
  pagination: {
    page_size: number
    page_index: number
    total_count: number
  }
}

export type TableResponse<CType> = {
  columns: Array<ColumnType>
  data: Array<{ [key: string]: CType | string | number[] } & { id: string | number }>
  pagination: {
    pageSize: number
    pageIndex: number
    totalCount: number
  }
}

export type TableRequest = {
  clientId: number | undefined
  pageSize: number
  pageIndex: number
  filters: FilterRequestList
  sort: SortRequest
}

export type TableRequestV2<T = string> = {
  client_id: T
  page_size: number
  page_index: number
  filters: FilterRequestList
  sort: SortRequest
}

//TODO: use TableRequestV3 with skipToken
export type TableRequestV3 = {
  clientId: string | number
  pageSize: number
  pageIndex: number
  filters: FilterRequestList
  sort: SortRequest
}

export type MapResponse = {
  columns: Array<ColumnType>
  data: {
    id: number
    name: string | null
    location: {
      latitude: number
      longitude: number
    }
    color: BaseColorType | null
  }[]
}

export type MapTransformedResponse = {
  columns: Array<ColumnType>
  data: {
    id: number
    name: string | null
    position: {
      lat: number
      lng: number
    }
    has_sensor?: boolean
    color: BaseColorType | null
  }[]
}

export type MapRequest<T = number> = {
  client_id: T
  location: {
    _southWest: { lat: number; lng: number }
    _northEast: { lat: number; lng: number }
  }
  coloring_type?: AssetSettingsLegendType // TODO: if this endpoint will be used in different module in the future, parameter type may be replaced with "string" instead of being string literal
  filters: FilterRequestList
}

export type DetailFieldType<T> = {
  key: string
  type: string
  value: T
  label: string
}

export const FIELD_TYPE = strEnum([
  'text',
  'number',
  'e-mail',
  'checkbox',
  'radio',
  'dropdown',
  'multiple-dropdown',
  'date',
])

export type ContentFieldsRequest = {
  clientId: number | undefined
  service_point: number | null
}

export type ContentFieldsResponse = Array<{
  default_value: any
  label: string
  key: string
  type: keyof typeof FIELD_TYPE
  required?: boolean
  editable?: boolean
}>

export type LocationType =
  | {
      start_location: Coordinate
      finish_location: Coordinate
      type: typeof MAP_SHAPES.start_finish
      address: string
      coordinates: Coordinate[]
    }
  | {
      locations: Coordinate[]
      location: Coordinate
      type: typeof MAP_SHAPES.geofence
      coordinates: Coordinate[]
      address: string
    }
  | {
      coordinates: Coordinate[]
      type: typeof MAP_SHAPES.multiple
      address: string
    }
  | {
      coordinates: Coordinate[]
      address: string
      type: typeof MAP_SHAPES.single
    }

export type DetailType = {
  value: string
  label: string
  type: string
  is_linked: boolean
  id?: number
}

export type LocationTypeV2 = {
  start_location: {}
  finish_location: {}
  locations: []
  location: {}
  type: 'SINGLE' | 'MULTIPLE' | 'GEOFENCE' | 'START_FINISH'
  coordinates: [
    {
      latitude: number
      longitude: number
    },
  ]
  address: string
}

export type GenericResponse = {
  detail: { message: string }
  success: boolean
}

export type ServicePointMapPopupDetailResponse = Array<
  MapPointItem & {
    is_active: boolean
    status?: {
      color: BaseColorType
      id: string
      name: string
    }
    type: OpsServicePointType
  }
>

export type TableRequestV2External<T = number> = {
  client_external_id: T
  page_size: number
  page_index: number
  filters: FilterRequestList
  sort: SortRequest
}

type AutoNamingConfigType = { prefix: string; delimiter: string }

export enum AutoNamingObjects {
  Order = 'Order',
  OrderPlan = 'OrderPlan',
  Case = 'Case',
  Inbound = 'Inbound',
  Parcel = 'Parcel',
  Consignment = 'Consignment',
  Process = 'Process',
  Bin = 'Bin',
}

export type AutoNamingConfigResponse = {
  auto_naming_configuration: {
    asset?: {}
    fleet?: {}
    engagement?: {
      [AutoNamingObjects.Order]?: AutoNamingConfigType
      [AutoNamingObjects.OrderPlan]?: AutoNamingConfigType
      [AutoNamingObjects.Case]?: AutoNamingConfigType
    }
    MRF?: {
      [AutoNamingObjects.Inbound]?: AutoNamingConfigType
      [AutoNamingObjects.Parcel]?: AutoNamingConfigType
      [AutoNamingObjects.Consignment]?: AutoNamingConfigType
      [AutoNamingObjects.Process]?: AutoNamingConfigType
    }
    evrekaTest?: {
      [AutoNamingObjects.Bin]?: AutoNamingConfigType
    }
  }
}

export enum FingerprintModel {
  Allocation = 'Allocation',
}
