import { createApi } from '@reduxjs/toolkit/query/react'
import { LatLngBoundsExpression, LatLngLiteral, LatLngTuple } from 'leaflet'
import { WorkOrderContentType, WorkOrderTypeList } from 'services/Engagement/types'
import { DropdownOptionTemplate } from 'types/common'
import axiosBaseQuery from './axiosBaseQuery'
import { AutoNamingConfigResponse, DetailFieldType } from './types'

type GetLocationRequest = {
  clientId: number | undefined
}

type GetLocationResponse = {
  latitude: number
  longitude: number
}

type GetModuleLocationRequest = {
  clientId: number | undefined
  module: 'asset' | 'ops' | 'fleet' | 'engagement'
}

type GetModuleLocationResponse = LatLngBoundsExpression

type DeviceTypesResponse = DropdownOptionTemplate

type GetLanguagesResponse = {
  languages: Array<[string, string]>
}

type GetUserOperationsRequest = {
  clientId?: number
}
type GetUserOperationsResponse = Array<
  Record<string, null | number | boolean | string | Array<number> | Array<string>> & {
    id: number
    name: string
  }
>

export type ClientLocationResponse = {
  latitude: number
  longitude: number
}

export type SearchServicePointResponse = DropdownOptionTemplate
export type SearchServicePointRequest = {
  clientId: number | undefined
  search: string
  operations?: number[]
}

type QRConfigRequest = { clientId: number } & (
  | { appLabel: 'MRF'; model: 'inbound' | 'parcel' }
  | { appLabel: 'evrekaTest'; model: 'bin' }
)

type QRFieldsRequest = QRConfigRequest & { id_list: Array<string | number> }

type QRConfigResponse = {
  enabled: boolean
  dynamic_fields: Array<string>
  static_fields: Array<string>
}

export type QRFieldType = DetailFieldType<string | number | null> & {
  qr_field: boolean
  id?: number | string
  options?: Array<{ value: number | string; label: string }>
}

export type QRFieldsResponse = Array<
  Record<string, QRFieldType> & {
    dynamic: Record<string, QRFieldType>
  }
>

export const commonApi = createApi({
  reducerPath: 'commonApi',
  tagTypes: [],
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
  }),
  endpoints: (builder) => ({
    getLocation: builder.query<GetLocationResponse, GetLocationRequest>({
      query: ({ clientId }) => ({ url: `/api/v2/clients/${clientId}/get_location`, method: 'GET' }),
    }),
    getModuleLocation: builder.query<GetModuleLocationResponse, GetModuleLocationRequest>({
      query: ({ clientId, module }) => ({
        url: `/api/v2/clients/${clientId}/get_module_location/?module=${module}`,
        method: 'GET',
      }),
      transformResponse: ({ _northEast, _southWest }: any) => {
        return [
          [_northEast.lat, _northEast.lng],
          [_southWest.lat, _southWest.lng],
        ]
      },
    }),
    getDeviceTypes: builder.query<DeviceTypesResponse, void>({
      query: () => ({ url: `/api/v2/device_types`, method: 'GET' }),
    }),
    //TODO: speak with be to move api from /ops to common
    getClientLocation: builder.query<ClientLocationResponse, number | undefined>({
      query: (clientId) => ({
        url: `/api/clients/${clientId}/ops_management/get_client_location/`,
        method: 'GET',
      }),
    }),
    getClientDetails: builder.query<LatLngLiteral, string>({
      query: (clientId) => ({
        url: `/engagement/clients/${clientId}/detail/`,
        method: 'GET',
      }),
      transformResponse: ({ latitude, longitude }: ClientLocationResponse) => {
        return {
          lat: latitude,
          lng: longitude,
        }
      },
    }),
    getLanguages: builder.query<GetLanguagesResponse, void>({
      query: () => ({
        url: '/api/languages/',
        method: 'GET',
      }),
    }),
    getUserOperations: builder.query<GetUserOperationsResponse, GetUserOperationsRequest>({
      query: ({ clientId }) => ({
        url: `/api/clients/${clientId}/operations/`,
        method: 'GET',
      }),
    }),
    //TODO: speak with be to move api from /ops to common ? or entity
    searchServicePoint: builder.mutation<SearchServicePointResponse, SearchServicePointRequest>({
      query: ({ clientId, ...data }) => ({
        url: `/api/clients/${clientId}/ops_management/search_service_point/`,
        method: 'POST',
        data,
      }),
    }),
    setLanguage: builder.mutation<{ language: string }, string>({
      query: (language) => ({
        url: `/set_language_react/`,
        method: 'POST',
        data: {
          language: language === 'zh' ? 'zh-cn' : language,
        },
      }),
    }),
    getMrfLabels: builder.query<WorkOrderTypeList, number>({
      query: (external_client_id) => ({
        url: `/MRF/clients/external/${external_client_id}/label/list/`,
        method: 'GET',
      }),
      transformResponse: (
        response: { value: WorkOrderContentType & 'process'; label: string }[],
      ) => {
        return response.filter((item) => item.value !== 'process')
      },
    }),
    getClientModules: builder.query<{ modules: Array<string> }, number>({
      query: (client_id) => ({
        url: `/int-api/clients/${client_id}/modules/`,
        method: 'GET',
      }),
    }),
    getAutoNamingConfig: builder.query<
      AutoNamingConfigResponse,
      { clientId: number; module_client_id?: number }
    >({
      query: ({ clientId, module_client_id }) => ({
        url: `/int-api/auto_name_configuration/clients/${clientId}/auto_name_configuration/`,
        method: 'GET',
        params: {
          module_client_id,
        },
      }),
    }),
    getQRConfig: builder.query<QRConfigResponse, QRConfigRequest>({
      query: ({ clientId, appLabel, model }) => ({
        url: `/int-api/qr_fields_configuration/clients/${clientId}/qr_field_configuration/${appLabel}/${model}/`,
        method: 'GET',
      }),
    }),
    getQRFields: builder.query<QRFieldsResponse, QRFieldsRequest>({
      query: ({ clientId, appLabel, model, ...data }) => ({
        url: `/int-api/qr_fields_configuration/clients/${clientId}/qr_fields/${appLabel}/${model}/`,
        method: 'POST',
        data,
        errorOverride: true,
      }),
    }),
    getKML: builder.query<LatLngTuple[], string>({
      query: (url) => ({
        url,
        method: 'GET',
      }),
      transformResponse: (kml: string): LatLngTuple[] => {
        const parser = new DOMParser()
        const xmlDoc = parser.parseFromString(kml, 'application/xml')
        const coordElements = xmlDoc.getElementsByTagName('coordinates')

        const coordsArray: L.LatLngTuple[] = []
        Array.from(coordElements).forEach((element) => {
          const coords = element?.textContent
            ?.trim()
            .split(/\s+/)
            .map((coord) => {
              const parts = coord.split(',')
              if (parts.length >= 2) {
                return [parseFloat(parts[1]), parseFloat(parts[0])] as L.LatLngTuple
              }
              return undefined
            })
            .filter((coord): coord is L.LatLngTuple => coord !== undefined)

          if (coords) {
            coordsArray.push(...coords)
          }
        })

        return coordsArray
      },
    }),
  }),
})

export const {
  useGetLocationQuery,
  useGetModuleLocationQuery,
  useGetDeviceTypesQuery,
  useGetClientLocationQuery,
  useSearchServicePointMutation,
  useSetLanguageMutation,
  useGetClientDetailsQuery,
  useGetLanguagesQuery,
  useGetUserOperationsQuery,
  useGetMrfLabelsQuery,
  useGetClientModulesQuery,
  useGetAutoNamingConfigQuery,
  useGetQRConfigQuery,
  useGetQRFieldsQuery,
  useGetKMLQuery,
} = commonApi
