import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentLanguage } from 'services/slices/auth'
import i18n from './i18n'
import { LANGUAGES } from './languages'

const isRtl = (language: string) => {
  return language === LANGUAGES.ARABIC || language === LANGUAGES.HEBREW
}

export const useRtl = () => {
  const currentLanguage = useSelector(selectCurrentLanguage)
  const [rtl, setRtl] = useState(isRtl(currentLanguage))

  useEffect(() => {
    if (isRtl(currentLanguage)) {
      setRtl(true)
      document.body.dir = 'rtl'
    } else {
      setRtl(false)
      document.body.dir = 'ltr'
    }
    //TODO move this logic to state ?

    i18n.changeLanguage(currentLanguage)
    moment.locale(currentLanguage)
  }, [currentLanguage])

  return rtl
}
