import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useMap } from 'react-leaflet'
import * as L from 'leaflet'
import 'leaflet-kml'
import { ThemeColors, ThemeColorType } from '@evrekadev/evreka-ui-components'

export type KmlLayerItem = {
  name?: string
  url: string
  color?: keyof ThemeColorType
  opacity?: number
}

type KmlLayerProps = {
  kmls: Array<KmlLayerItem>
  fitBounds?: boolean
  noTooltip?: boolean
}

export const KML_POLYLINE_COLORS = [
  'orange500',
  'green500',
  'blue500',
  'pink500',
  'yellow500',
  'purple500',
  'red500',
]

export const KmlLayer: React.FC<React.PropsWithChildren<KmlLayerProps>> = ({
  kmls,
  fitBounds = true,
  noTooltip = false,
}) => {
  const map = useMap()
  const [isMounted, setIsMounted] = useState(false)
  const layers = useRef<L.Polyline[]>([])

  const clearLayers = useCallback(() => {
    if (layers.current.length) {
      layers.current.forEach((layer) => map.removeLayer(layer))
    }
  }, [])

  useEffect(() => {
    clearLayers()

    if (kmls.length) {
      const promises = kmls.map((kml) => fetch(kml.url))

      Promise.all(promises)
        .then((responses) => responses.map((res) => res.text()))
        .then((kmltexts) => {
          if (!isMounted) {
            return []
          }

          const kmlLayers: any[] = []

          kmltexts.forEach(async (kmltext, i) => {
            const parser = new DOMParser()
            const kml = parser.parseFromString(await kmltext, 'text/xml')

            const kmlLayer: L.Polyline = new (L as any).KML(kml)

            const selectedKml = kmls[i]

            if (selectedKml.name) {
              kmlLayer.bindTooltip(selectedKml.name as string).addTo(map)
            }

            if (noTooltip) {
              kmlLayer.bindPopup(selectedKml.name as string).addTo(map)
            }

            if (selectedKml.color) {
              kmlLayer.setStyle({
                color: ThemeColors[selectedKml.color],
                opacity: selectedKml.opacity,
              })
            }

            layers.current.push(kmlLayer)

            map.addLayer(kmlLayer)

            kmlLayers.push(kmlLayer)
          })

          return kmlLayers
        })
        .then((layers) => {
          if (fitBounds) {
            const bounds = layers[0] && layers[0].getBounds()

            try {
              bounds && map.fitBounds(bounds)
            } catch (err) {}
          }
        })
    }

    setIsMounted(true)
  }, [map, kmls, fitBounds, isMounted])

  useEffect(() => {
    return () => {
      setIsMounted(false)
      clearLayers()
    }
  }, [])

  return null
}
