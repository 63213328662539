import React from 'react'
import { Tooltip, TooltipProps } from 'react-leaflet'

const MarkerTooltip: React.FC<React.PropsWithChildren & TooltipProps> = ({
  children,
  ...props
}) => {
  return (
    <Tooltip
      permanent={props.permanent}
      {...props}
      direction={props.direction || 'top'}
      offset={props.offset}
    >
      {children}
    </Tooltip>
  )
}

export default MarkerTooltip
