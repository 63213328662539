import { createSelector, createSlice, isRejectedWithValue } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { engagementEnvironmentApi, mrfEnvironmentApi } from 'services/environment'
import {
  EngagementEnvironmentResponse,
  MrfEnvironmentTransformedResponse as MrfEnvironmentResponse,
} from 'services/environment/types'

export type EnvironmentState = {
  environment_engagement: {
    isLoading: boolean
    isError: boolean
    data: EngagementEnvironmentResponse | undefined
  }
  environment_mrf: {
    isLoading: boolean
    isError: boolean
    data: MrfEnvironmentResponse | undefined
  }
}

const engEnvValue = window.localStorage.getItem('environment_engagement')
const environmentEngagement =
  engEnvValue && engEnvValue !== 'undefined'
    ? (JSON.parse(engEnvValue) as EngagementEnvironmentResponse)
    : undefined
const engMrfValue = window.localStorage.getItem('environment_mrf')
const environmentMrf =
  engMrfValue && engMrfValue !== 'undefined'
    ? (JSON.parse(engMrfValue) as MrfEnvironmentResponse)
    : undefined
const initialState: EnvironmentState = {
  environment_engagement: {
    isLoading: environmentEngagement ? false : true,
    data: environmentEngagement,
    isError: false,
  },
  environment_mrf: {
    isLoading: environmentMrf ? false : true,
    data: environmentMrf,
    isError: false,
  },
}

const environmentSlice = createSlice({
  name: 'environment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      engagementEnvironmentApi.endpoints.getEnvironment.matchFulfilled,
      (state, { payload }) => {
        const data = payload
        state.environment_engagement.isLoading = false
        state.environment_engagement.isError = false
        state.environment_engagement.data = data
        localStorage.setItem('environment_engagement', JSON.stringify(data))
      },
    )
    builder.addMatcher(
      engagementEnvironmentApi.endpoints.getEnvironment.matchRejected,
      (state, action) => {
        if (isRejectedWithValue(action)) {
          state.environment_engagement.isLoading = false
          state.environment_engagement.isError = true
          state.environment_engagement.data = undefined
          localStorage.removeItem('environment_engagement')
          window.location.assign(`/not_authenticated`)
        }
      },
    )
    builder.addMatcher(
      engagementEnvironmentApi.endpoints.getEnvironment.matchPending,
      (state, action) => {
        state.environment_engagement.isLoading = true
        state.environment_engagement.isError = false
        state.environment_engagement.data = undefined
      },
    )
    builder.addMatcher(
      mrfEnvironmentApi.endpoints.getEnvironment.matchFulfilled,
      (state, { payload }) => {
        const data = payload
        state.environment_mrf.isLoading = false
        state.environment_mrf.isError = false
        state.environment_mrf.data = data
        localStorage.setItem('environment_mrf', JSON.stringify(data))
      },
    )
    builder.addMatcher(
      mrfEnvironmentApi.endpoints.getEnvironment.matchRejected,
      (state, action) => {
        if (isRejectedWithValue(action)) {
          state.environment_mrf.isLoading = false
          state.environment_mrf.isError = true
          state.environment_mrf.data = undefined
          localStorage.removeItem('environment_mrf')
          window.location.assign(`/not_authenticated`)
        }
      },
    )
    builder.addMatcher(mrfEnvironmentApi.endpoints.getEnvironment.matchPending, (state, action) => {
      state.environment_mrf.isLoading = true
      state.environment_mrf.isError = false
      state.environment_mrf.data = undefined
    })
  },
})

export default environmentSlice.reducer

const environmentState = (state: RootState) => state.environment
export const selectEnvironmentEngagement = createSelector(
  environmentState,
  ({ environment_engagement }) => environment_engagement,
)
export const selectEnvironmentMrf = createSelector(
  environmentState,
  ({ environment_mrf }) => environment_mrf,
)
