import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Polygon, PolygonProps, Popup, Tooltip } from 'react-leaflet'
import { Typography } from 'components'
import { MapContextType } from './types'
import { MapContext } from 'context'
import { LeafletEventHandlerFnMap } from 'leaflet'
import MarkerPopup from './MapMarkers/MarkerPopup'

export type PolygonItem = PolygonProps & { tooltipText?: string }

export type PolygonsProps = {
  polygons?: PolygonItem[]
  pmIgnore?: boolean
  customKey?: boolean
}

export const Polygons: React.FC<React.PropsWithChildren<PolygonsProps>> = ({
  polygons,
  pmIgnore = true,
  customKey = false,
}) => {
  return (
    <>
      {polygons &&
        polygons.length > 0 &&
        polygons.map((polygon, i) => (
          <CustomPolygon
            polygon={polygon}
            key={
              customKey
                ? `${polygon.color} ${polygon.positions.join('i')}`
                : polygon.positions.join('i')
            }
            pmIgnore={pmIgnore}
          />
        ))}
    </>
  )
}

type CustomPolygonProps = {
  polygon: PolygonItem
  pmIgnore?: boolean
}

export const CustomPolygon: React.FC<React.PropsWithChildren<CustomPolygonProps>> = (props) => {
  const { polygon, pmIgnore } = props
  const { onPolygonSelect: onSelect, polygonPopup } = useContext<MapContextType>(MapContext)

  const handleOnSelect = useCallback(
    (polygon: PolygonItem & { id: number }) => {
      onSelect?.(polygon)
    },
    [onSelect],
  )

  const eventHandlers = useMemo(
    () =>
      ({
        click: () => handleOnSelect?.(polygon as any),
      }) as LeafletEventHandlerFnMap,
    [polygon, handleOnSelect],
  )

  return (
    <Polygon {...polygon} pathOptions={{ pmIgnore }} eventHandlers={eventHandlers}>
      {pmIgnore && polygon.tooltipText && (
        <Tooltip sticky>
          <div style={{ width: 150 }}>
            <Typography align="center">{polygon.tooltipText}</Typography>
          </div>
        </Tooltip>
      )}
      {polygonPopup && pmIgnore && <MarkerPopup {...polygonPopup} />}
      {pmIgnore && polygon.children && <>{polygon.children}</>}
    </Polygon>
  )
}
