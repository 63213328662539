import React from 'react'
import styled from 'styled-components'
import { Flex, FlexCell, Switch, Typography } from 'components'
import { MapSettingsOption } from '../types'
import { padding, color } from '@evrekadev/evreka-ui-components'

export const StyledSettingFlex = styled(Flex)`
  ${padding([10])}
  width: 100%;
  border-bottom: 1px solid ${color('grey100')};
  background-color: ${color('white')};
`

type ToggleItemProps = {
  item: MapSettingsOption
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const ToggleItem: React.FC<React.PropsWithChildren<ToggleItemProps>> = ({
  item,
  onChange,
}) => (
  <StyledSettingFlex between="sm">
    <FlexCell>
      <Typography fontSize="text_sm"> {item.label}</Typography>
    </FlexCell>
    <FlexCell>
      <Switch
        data-id={item.value}
        disabled={!item.hidable}
        checked={item.checked}
        onChange={onChange}
        componentSize="small"
      />
    </FlexCell>
  </StyledSettingFlex>
)
