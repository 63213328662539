/*@akursat endpoint should return "id" field as unique a string or number.
 * eg: list_users_table providesTags: ({data}) => providesList(data, 'User'),
 * eg: list_users_table providesTags: ({data}) => providesList(data, 'User'),
 **/
export function providesList<R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
) {
  return resultsWithIds
    ? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
    : [{ type: tagType, id: 'LIST' }]
}

export function providesListWithKey<R extends { [key: string]: any }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T,
  key: string,
) {
  return resultsWithIds
    ? [
        { type: tagType, id: 'LIST' },
        ...resultsWithIds.map((property) => ({ type: tagType, id: property[key] })),
      ]
    : [{ type: tagType, id: 'LIST' }]
}

// providesTags: (_result, _error, { assetId }) => [
//   { type: 'Assets', id: assetId },
// ],
// use for single item
//providesTags:(_result, _error, {servicePointId}) => [{ type: 'ServicePoints', id:servicePointId }],
export function providesItem<R extends { id: string | number }, T extends string>(
  resultsWithId: R,
  tagType: T,
) {
  return [{ type: tagType, id: resultsWithId }]
}

// In this case, `getEvent` will be re-run. `getEvents` *might*  rerun, if this id was under it's results.
// invalidatesTags: (_result, _error, args) => [
//   ...args.eventId.map((id) => ({ type: 'Events', id } as const)),
// ],

//https://gist.github.com/Shrugsy/6b6af02aef1f783df9d636526c1e05fa

/**
 * An individual cache item
 */
export type CacheItem<T, ID> = { type: T; id: ID }

/**
 * HOF to create an entity cache to invalidate a LIST.
 *
 * Invalidates regardless of result.
 *
 * @example
 * ```ts
 * invalidatesList('Todo')()
 * // [{ type: 'Todo', id: 'List' }]
 * ```
 */
export const invalidatesList =
  <T extends string>(type: T) =>
  (): readonly [CacheItem<T, 'LIST'>] =>
    [{ type, id: 'LIST' }] as const
