import React, { useCallback, useState } from 'react'
import { Flex, Infobox, Modal, Typography, sendNotification } from 'components'
import {
  WebsocketConsumer,
  WebsocketMessage,
  WebsocketResultModalMessage,
} from './WebsocketConsumer'
import { useDispatch } from 'react-redux'
import { reportsApi } from 'services/ReportManagement/reports'
import { useTranslation } from 'utils/useTranslation'

type WebsocketNotificationProps = {
  url: string
}

export const WebsocketNotification: React.FC<
  React.PropsWithChildren<WebsocketNotificationProps>
> = ({ url }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [resultModal, setResultModalState] = useState<
    WebsocketResultModalMessage['data'] & { isOpen: boolean }
  >()

  const closeResultModal = () => setResultModalState(undefined)

  const onMessage = useCallback((message: WebsocketMessage) => {
    const { type, data } = message

    if (type === 'toast') {
      const { description, link, title, type: notificationType } = data
      sendNotification({
        type: notificationType,
        header: title,
        toastContent: description,
        toastOptions: { toastId: Math.random().toString() },
        hyperLink: link
          ? {
              type: link.target === '_blank' ? 'newTab' : 'currentTab',
              label: link.label,
              to: link.value,
              isExternalLink: false,
            }
          : undefined,
      })

      dispatch(reportsApi.util.invalidateTags(['ExportReports', 'ImportDetail', 'ImportReports']))
    } else if (type === 'result-modal') {
      setResultModalState({
        ...data,
        isOpen: true,
      })
    }
  }, [])

  return (
    <WebsocketConsumer url={url} onMessage={onMessage}>
      {resultModal && (
        <Modal
          data-testid="result-modal"
          heading={resultModal?.title}
          isOpen={resultModal?.isOpen}
          noCancel
          onSubmit={closeResultModal}
          customSubmitText={t('okay')}
          onRequestClose={closeResultModal}
        >
          <Flex directionColumn="zero" gap={0.5}>
            {resultModal?.success_list?.length > 0 && (
              <Infobox variant="success" text={resultModal?.success_message} hideClose width="100%">
                {resultModal?.success_list.map(({ message, value }, i) => (
                  <Typography
                    variant="span"
                    fontSize="text_sm"
                    fontWeight="regular"
                    color="green600"
                    key={i}
                  >
                    <b style={{ fontWeight: '600' }}>{value}</b> {message}
                  </Typography>
                ))}
              </Infobox>
            )}
            {resultModal?.failure_list?.length > 0 && (
              <Infobox variant="error" text={resultModal?.failure_message} hideClose width="100%">
                {resultModal?.failure_list?.map(({ reason, value }, i) => (
                  <Typography
                    variant="span"
                    fontSize="text_sm"
                    fontWeight="regular"
                    color="red600"
                    key={i}
                  >
                    <b style={{ fontWeight: '600' }}>{value}</b> {reason}
                  </Typography>
                ))}
              </Infobox>
            )}
          </Flex>
        </Modal>
      )}
    </WebsocketConsumer>
  )
}
