import { DropdownOptionTemplate } from 'types/common'
import {
  BaseColorEnum,
  BaseColorType,
  IconNames,
  strEnum,
  ThemeColorKeys,
  ThemeColorType,
} from '@evrekadev/evreka-ui-components'
import {
  StatusType,
  ColumnExtended,
  FilterRequestList,
  SortRequest,
  TableSettingsRequest,
} from '@evrekadev/evreka-ui-components'
import { DetailFieldType, DetailType, GenericResponse, LocationType } from 'services/types'
import { Icon, LatLngTuple } from 'leaflet'
import { MapSettingsOption } from 'components/Map/MapSettingsModal/types'
import { DynamicFieldArrayType, DynamicFieldDetailArrayType } from 'containers/DynamicFormV2/types'
import { RequestSourceType } from 'services/AssetManagement/types'
import { FormItemType, TransitionStepType } from './taskTranstionTypes'
import { PolygonProps } from 'react-leaflet'

export const FIELD_TYPE = strEnum([
  'text',
  'number',
  'e-mail',
  'checkbox',
  'radio',
  'dropdown',
  'multiple-dropdown',
  'date',
])

//Service Points

export type ServicePointUpsertRequest = {
  clientId: number
  service_point?: number
  name: string
  address: string
  latitude: number
  longitude: number
  note?: string
  operations?: number[]
  regions?: number[]
  dynamic: {
    [key in string]: any
  }
  type?: string
  default_items?: number[]
}

export type ServicePointEditResponse = {
  success: boolean
  detail: {
    message: string
  }
  service_point: number
}

export type TaskOrderDeleteRequest = {
  clientId: number
  ids: number[]
}

export type TaskOrderDeleteResponse = {
  success: boolean
  detail: {
    message: string
  }
}

export type ServicePointViewResponse = {
  name: string
  latitude: number
  longitude: number
  note?: string
  operations: DropdownOptionTemplate
  regions: DropdownOptionTemplate
  dynamic: DynamicFieldDetailArrayType
  address: string
  type: OpsServicePointType
  default_items?: number[]
}

export type OperationRegionRequest = {
  operations: Array<number>
  clientId: number | undefined
}

export type OperationsRegionResponse = DropdownOptionTemplate

export type ServicePointDetailResponse = {
  location: LocationType & { service_point_type: OpsServicePointType }
  details: Array<{
    label: string
    value: string | number
    type: string
  }>
  default_items?: Array<{
    value: number
    label: string
    operation_id: number
  }>
  statusType: StatusType
}

export type ServicePointDetailRequest = {
  servicePointId: number
  clientId: number | undefined
}

export type ServicePointLocationRequest = {
  service_points: Array<number>
  clientId: number | undefined
}

export type ServicePointLocationResponse = Array<{
  id: number
  name: string
  latitude: number
  longitude: number
}>

type TableResponse<CType> = {
  columns: Array<ColumnExtended<any> & { header: string; Cell: Function }>
  data: Array<{ [key: string]: CType | string }>
  pagination: {
    pageSize: number
    pageIndex: number
    totalCount: number
  }
}

type TableRequest = {
  clientId: number | undefined
  pageSize: number
  pageIndex: number
  filters?: FilterRequestList
  sort?: SortRequest
}

export type TaskOrdersResponse = TableResponse<{}>
export type TaskOrdersRequest = TableRequest & { service_point?: string }

export type AssetsResponse = TableResponse<{}>
export type AssetsRequest = TableRequest & { service_point?: number; route_id?: number }

export type DetachAssetFromSPRequest = {
  clientId: number
  source: RequestSourceType
  action: 'detach_service_point' | 'detach_route'
  asset_list: number[]
}

export type OperationResponse = Array<{ value: number; label: string; assignee_type: number }>

export type OperationRequest = {
  clientId: number
  service_points?: number | number[]
  filter?: string
}

export type ShiftsResponse = DropdownOptionTemplate

export type TaskTemplatesResponse = DropdownOptionTemplate
export type TaskTemplatesRequest = {
  clientId: number | undefined
  operationId: number | null
  isAdHoc?: boolean
}

export type RegionsResponse = DropdownOptionTemplate
export type RegionsRequest = {
  clientId: number | undefined
  query_param?: string
}

export type GeomapsResponse = {
  geomap_list: DropdownOptionTemplate
}

export type GeomapsRequest = {
  clientId: number | undefined
  query_param?: string
}

export enum DumpLocationType {
  DisposalLocation,
  StartEndLocation,
  FuelStation = 3,
}

export type DumpLocationsResponse = {
  dump_location_list: Array<{
    dump_location_type: number
    label: string
    value: number
    key?: string
    payload: {
      uom: DisposalUOM | null
      decimal_rate: number | null
      distance_limit: number | null
      max_value: number | null
      min_value: number | null
    } | null
  }>
}

export type DumpLocationsRequest = {
  clientId: number | undefined
  static_tour?: number
}

export type AssigneesResponse = Array<{ label: string; value: number; assignee_type: number }>
export type AssigneesRequest = {
  clientId: number | undefined
  operation: number | null
  route_plan?: number
}

export type RoutesByOperationResponse = DropdownOptionTemplate
export type RoutesByOperationRequest = {
  clientId: number | undefined
  operation_id: number
  operation_date?: string
  shift_id?: number
}

export type RoutesForTasksResponse = DropdownOptionTemplate
export type RoutesForTasksRequest = {
  clientId: number | undefined
  operation_date?: string
  due_date?: string
  shift_id?: number
  task_ids: number[]
}

export type ServiceTypes = 'asset' | 'item' | 'other'

export type RoutesByNameResponse = Array<{
  value: number
  label: string
}>
export type RoutesByNameRequest = {
  clientId?: number
  search: string
}

export type TaskStep = {
  id: number
  name: string
  service_type: ServiceTypes
  search_type: string | undefined | null
  default_asset_type: Array<number>
  default_uom: string | null
  step_service_type: 'collect_asset' | 'pickup_asset' | 'collect_item'
}

export type OperationItemType = DropdownOptionTemplate[0]

export type TaskTemplateStepsResponse = Array<TaskStep>
export type TaskTemplateStepsRequest = {
  clientId: number
  task_template_id: number
}

export type OperationItemTypeRequest = {
  clientId: number | undefined
  operation_id?: number | null
  operation_id_list?: Array<number>
}
export type OperationItemTypeResponse = Array<{
  value: number
  label: string
  uom: string
  operation_id: number
  is_quantity_invisible: boolean
}>

export type TaskDetailResponse = {
  location: LocationType
  media: {
    images: Array<string>
  }
  details: Array<
    Omit<DetailType, 'value'> & {
      value: string | number | { label: string; value: string | number }
    }
  >
  statusType: StatusType & { category: string }
}
export type TaskDetailRequest = {
  clientId: number | undefined
  task_id: number
}

export type TaskDetailForParcelResponse = {
  task_detail: Array<{
    label: string
    value: string
    type: string
  }>
}
export type TaskDetailForParcelRequest = {
  clientId: number | undefined
  task_id: number
}

export type TaskEditDetailFieldsResponse = {
  operation: number
  route: number
  shift: number
  due_date: string
  operation_date: string
}

export type TaskEditDetailFieldsRequest = {
  clientId: number | undefined
  task: number
}

export type FormType = {
  label: string
  value: string | Array<string>
  type: string
  is_visible_fe?: boolean
  json_value?: Array<string> | string | null
  options?: Array<{ label: string; value: string | number }> | null
}

export type AssetTypeResponse = DropdownOptionTemplate
export type AssetTypeRequest = {
  clientId: number | undefined
}

export type GetServicePointOperationsRequest = {
  clientId?: number
  service_points: number[]
}

export type GetServicePointOperationsResponse = DropdownOptionTemplate

// FIXME: the following 2 types should be removed, when related request will be deleted from respective api file
export type SearchAssetResponse = Array<DropdownOptionTemplate[0] & { items?: Array<FormItemType> }>
export type SearchAssetRequest = {
  clientId: number | undefined
  search: string
  asset_type?: number
  asset_type_list?: number[]
  search_type?: string | null | undefined
  service_point?: number
  engagement_service_point?: string
  operation?: number
  route?: number
  items?: boolean
}

export type SearchTaskResponse = {
  tasks: Array<{
    operation_date: string
    service_point: string
    task_id: number
  }>
}

export type SearchTaskRequest = {
  clientId: number | undefined
  task_id: number
}

export type TransitionAssetItemType = {
  item_name: string
  item_type: number
  actual_quantity?: number | null
  actual_amount?: number | null
  quantity?: number | null
  amount?: number | null
  uom?: string | null
  item_id?: number | null
  is_quantity_invisible?: boolean
}

export type TransitionType = {
  id: number
  name: string
  start_time: string | null
  end_time: string | null
  user: string | null
  done: boolean
  steps: Array<{
    id: number
    name: string
    form: Array<FormType>
    description: string
    order: number
    assets: Array<{
      items: Array<TransitionAssetItemType>
      name: string
    }>
  }>
  state: 'to_do' | 'in_progress' | 'done'
  next_transitions: Array<number>
  task_status_category_id: number
}

export type TaskTransitionDetailPageResponse = {
  transitions: Array<TransitionType>
  permission: boolean
}

export type TaskTransitionDetailPageRequest = {
  clientId: number | undefined
  task_id: number
}

type Item = {
  id: number | string
  name: string
  module: string
  message: string
}

export type DeleteServicePointResponse = {
  success: boolean
  detail: {
    message: string
  }
  successful: Array<Item>
  error: Array<Item>
}
export type DeleteServicePointRequest = {
  clientId: number | undefined
  service_point_id_list: Array<number>
  select_all?: boolean
  filters?: FilterRequestList
}

export type DeleteRouteResponse = DeleteServicePointResponse

export type DeleteRouteRequest = {
  clientId: number | undefined
  route_ids: Array<number>
}

export type MutationResponse = {
  success: boolean
  detail: { message: string }
}

export type ExportRequest = {
  client_id: string
  filters: FilterRequestList
  sort: SortRequest
  columns?: TableSettingsRequest
}

export type RouteDetailResponse = {
  location: LocationType
  details: Array<DetailType & { key: string }>
  statusType: StatusType & { category: string }
  route: {
    kml: string | null
  }
}

export type RouteDetailRequest = {
  clientId: number | undefined
  routeId: number
}

export type QhseResponsesResponse = {
  answers: Array<{
    value: string
    label: string
    type: 'pre' | 'post'
    is_critical: boolean
  }>
  pre_approver: string | null
  post_approver: string | null
}

export type QhseResponsesRequest = {
  clientId: number | undefined
  route_id: number
}

export type AssetAndItemsResponse = {
  items: Array<{ label: string; value: string }>
  assets: Array<{ label: string; value: string }>
}

export type AssetAndItemsRequest = {
  clientId: number | undefined
  route_id: number
}

export type TaskItem = {
  id: number
  status: StatusType
  operation: string
  task_template: string
  operation_date: string
  due_date: string
  shift: string
  assignee: string
  create_time: string
  location: LocationType
  service_point_type?: OpsServicePointType
}

export type TaskMapPointsRequest = {
  clientId: number | undefined
  route_id?: string
}

export type TaskMapPointsResponse = {
  tasks: TaskItem[]
}

export type RoutesResponse = TableResponse<{}>
export type RoutesRequest = TableRequest & { static_tour_id?: number }

export type RouteHistoryResponse = TableResponse<{}>
export type RouteHistoryRequest = TableRequest & { routeId: number }

export type RouteAddRequest = {
  clientId: number
  vehicle?: number
  employee?: number
  operation: number
  name?: string
  regions?: Array<number>
  geomaps?: Array<number>
  planned_date?: string
  start_time?: string
  end_time?: string
  start_location?: number
  end_location?: number
  fuel_station?: number
  disposal_location?: number
  shift?: number
  static_tour_id?: number
  crews?: Array<number>
  dynamic: {
    [key in string]: any
  }
}

export type TasksResponse = TableResponse<{}>
export type TasksRequest = TableRequest & {
  route_id?: number
  service_point_id?: string
  order_id?: string
  inbound_id?: number
  task_plan_id?: number
  consignment_id?: number
}

export type TasksByFilterResponse = Array<{ task_id: number }>
export type TasksByFilterRequest = {
  clientId?: number
  operation_date: string
  operation_id: number
  shift_id: number
}

export type GenericSuccessResponse = {
  detail: { message: string }
  success: boolean
}

export type GenericExportListRequest = {
  clientId: number
  filters: FilterRequestList
  sort: SortRequest
  columns?: TableSettingsRequest
}

export type TaskAddRequest = {
  clientId: number
  operation: number
  task_template: number
  due_date: string
  note?: string
  operation_date: string
  shift: number
  route: number | null
  latitude: number | null
  longitude: number | null
  service_points: Array<number>
  steps: any
}

export type TaskAddResponse = {
  success: boolean
  task: any
  task_id: number
  detail: {
    message: string
  }
  successful: Array<{
    id: number
    name: string
    module: string
    message: string
  }>
  error: Array<{
    id: number
    name: string
    module: string
    message: string
  }>
}

export type RoutePlansResponse = TableResponse<{}> & { route_plan_limit: number }
export type RoutePlansRequest = TableRequest & { staticTourId?: number }

export type SearchRoutePlansResponse = { route_plan_list: DropdownOptionTemplate }
export type SearchRoutePlansRequest = {
  clientId: number
  operation: number
  query_param: string
}

export type StaticToursResponse = TableResponse<{}>
export type StaticToursRequest = TableRequest

export type SpItineraryListResponse = TableResponse<{}>
export type SpItineraryListRequest = TableRequest & { staticTourId?: number }

export type StaticTourAllKmlListResponse = {
  static_tour_kmls: Array<{
    name: string
    kml_url: string
  }>
}
export type StaticTourAllKmlListRequest = {
  clientId?: number
}

export type TaskItineraryListResponse = TableResponse<{}>
export type TaskItineraryListRequest = TableRequest & { routeId?: number }

export type StaticTourEditDetailResponse = {
  name: string
  operation: number
  task_template: number
  regions: number[]
  start_location?: number
  end_location?: number
  disposal_location?: number
  fuel_station?: number
  steps: Array<{
    step_id: number
    service_type: ServiceTypes
    items?: Array<{
      amount?: number
      item_type?: number
      quantity?: number
      uom?: string
    }>
    assets?: {
      amount?: string
      asset_ids?: Array<number>
      asset_type?: number[]
      quantity?: string
      uom?: string
    }
  }>
}

export type StaticTourEditDetailRequest = {
  clientId?: number
  id?: number
}

export type StaticTourUpsertRequest = {
  clientId: number
  id?: number
  name: string
  operation: number
  regions: number[]
  task_template: number
  steps: any
  start_location?: number
  end_location?: number
  disposal_location?: number
  fuel_station?: number
}

export type StaticTourUpsertResponse = GenericSuccessResponse

export type StaticTourDeleteResponse = GenericSuccessResponse

export type StaticTourDeleteRequest = {
  clientId?: number
  static_tour_ids: number[]
}

export type RegionsServicePointResponse = {
  service_point_list: Array<{
    id: number
    name: string
    latitude: number
    longitude: number
    type: OpsServicePointType
  }>
}

export type RegionsServicePointRequest = {
  clientId: number
  region_id: number
}

export type RegionListResponse = TableResponse<{}>
export type RegionListRequest = TableRequest

export type RegionEditDetailResponse = {
  name: string
  service_points: number[]
}

export type RegionEditDetailRequest = {
  clientId?: number
  id?: number
}

export type RegionsServicePointsResponse = {
  region_name: string
  all_service_points: DropdownOptionTemplate
  selected_service_points: DropdownOptionTemplate
}

export type RegionsServicePointsRequest = {
  clientId?: number
  region_id?: number
}

export type RegionUpsertRequest = {
  clientId: number
  id?: number
  region_id?: number
  region_name: string
  service_points: DropdownOptionTemplate | number[] | null
  select_all?: boolean
  filters?: FilterRequestList
  positions?: PolygonProps['positions']
}

export type RegionUpsertResponse = GenericSuccessResponse

export type RegionDeleteRequest = {
  clientId: number
  region_id: number
}

export type RegionDeleteResponse = GenericSuccessResponse

export type SearchStaticToursResponse = {
  static_tour_list: DropdownOptionTemplate
}
export type SearchStaticToursRequest = {
  clientId: number
  query_param?: string
  operation: number
}

export type SearchRegionsResponse = {
  dump_location_list: DropdownOptionTemplate
}
export type SearchRegionsRequest = {
  clientId: number
  query_param: string
}

export type RoutePlanAddRequest = {
  clientId: number
  name: string
  operation: number
  tour_type: number
  static_tour?: number
  regions?: Array<number>
  geomaps?: Array<number>
  item_capacity?: number
  start_date: string
  end_date: string
  schedule_type: number
  schedule_value: number | number[]
  shift: number
  start_time?: string
  end_time?: string
  vehicle: number
  employee: number
  crews?: Array<number>
  start_location?: number
  end_location?: number
  fuel_station?: number
  disposal_location?: number
  order_time: string
  finish_time: string
  dynamic: {
    [key in string]: any
  }
}

export type RoutePlanEditRequest = RoutePlanAddRequest & { routePlanId: number }

export type RoutePlanEditValuesResponse = Omit<
  RoutePlanAddRequest,
  'clientId' | 'static_tour' | 'regions' | 'geomaps'
> & {
  static_tour: DropdownOptionTemplate[number]
  crews: DropdownOptionTemplate
  regions: DropdownOptionTemplate
  geomaps: DropdownOptionTemplate
  dynamic?: DynamicFieldDetailArrayType
}

export type RoutePlanEditValuesRequest = {
  clientId: number
  routePlanId: number
  duplicate_id?: number
  duplicate_date?: string
}

export type StaticTourLocationItem = {
  latitude: number
  longitude: number
  type: string
  id?: number
  status?: { color: string }
  name?: string
  sequence?: number
  service_point_type: OpsServicePointType
}

export type StaticTourDetailResponse = {
  location: {
    type: string
    coordinates: Array<StaticTourLocationItem>
  }
  route: { kml: string | null }
  details: Array<{
    label: string
    value: string | number
    type: string
  }>
}
export type StaticTourDetailRequest = {
  clientId: number
  static_tour_id: number
}

export type GeomapItem = {
  id?: number
  name: string
  positions: Array<LatLngTuple>
}

export type GeomapListResponse = TableResponse<GeomapItem>
export type GeomapListRequest = TableRequest

export type GeomapAllListResponse = Array<GeomapItem>

export type GeomapAllListRequest = {
  clientId?: number
}

export type GeomapUpsertRequest = {
  clientId: number
} & GeomapItem

export type GeomapUpsertResponse = GenericSuccessResponse

export type GeomapDeleteResponse = GenericSuccessResponse
export type GeomapDeleteRequest = {
  clientId: number
  geomap_id: number
}

export type CalendarResponse = {
  data: Array<{ operation_type: Array<number>; operation_date: string }>
  legend: Array<{ label: string; colorId: number; type: number }>
}

export type CalendarDetailResponse = {
  data: Array<{ operation_type: number; operation_name: string }>
}

export type CalendarDetailTransformedResponse = {
  data: Array<{ type: string; name: string }>
}

export type CalendarListRequest<T> = {
  client_id: number
  start_date: string
  end_date: string
} & T

export type CalendarDetailRequest<T> = {
  client_id: number
  date: string
} & T

type TaskPlanType = {
  taskPlanId: number
}

type ServicePointType = {
  servicePointId: string
}

export type TaskPlanCalendarDetailRequest = CalendarDetailRequest<TaskPlanType>
export type ServicePointCalendarDetailRequest = CalendarDetailRequest<ServicePointType>

export type TaskPlanCalendarListRequest = CalendarListRequest<TaskPlanType>
export type ServicepointCalendarListRequest = CalendarListRequest<ServicePointType>

export type KMLImportResponse = MutationResponse

export type KMLImportRequest = {
  clientId?: number
  body: FormData
  id?: string
}

export type CalculateEtaResponse = MutationResponse

export type CalculateEtaRequest = {
  clientId?: number
  id?: string
}

export type OptimizeServiceResponse = MutationResponse

export type OptimizeServiceRequest = {
  clientId?: number
  id?: string
}

export type DrawPathResponse = MutationResponse

export type DrawPathRequest = {
  clientId?: number
  id?: string
}

export type ImportSpItineraryListResponse = MutationResponse

export type ImportSpItineraryListRequest = {
  clientId?: number
  body: FormData
  id?: string
}

export type BaseItineraryItemEditRequest = {
  id: number
  clientId: number
  sequence: number
  eta?: number
  task_duration?: number
}

export type SpItineraryItemEditRequest = BaseItineraryItemEditRequest & {
  static_tour_id: number
}

export type TaskItineraryItemEditRequest = BaseItineraryItemEditRequest & {
  route_id: number
  task_id: number
}

export type TaskChangeStatusRequest = {
  client_id: number
  task_id: number
  status_id: number
}

export type TaskOptimizeFilterRequest = {
  clientId?: number
  operation_date: string
  operation_id: number
  shift_id: number
  routes?: Array<number>
  id?: number
}

export type TaskOptimizeServiceResponse = MutationResponse & {
  data: {
    items: Array<{
      name: string
      count: number
      link: string
    }>
    totalUnassignedTaskCount: number
  }
}

export type TasksCountByFilterResponse = MutationResponse & {
  task_count: number
}

export type RoutesByFilterResponse = MutationResponse & {
  data: DropdownOptionTemplate
}

export type MonthlyRoutesRequest = {
  clientId: number
  start_date: string
  end_date: string
  route_plan_name?: string
  geomap_id?: number
  vehicle_id?: number
  region_id?: number
  operation_id?: number
  employee_id?: number
  tour_type_id?: number
}

export type MonthlyRoutesResponse = Array<{
  id: number
  operation_name: string
  date: string
  route_count: number
}>

export type OperationRoutesRequest = {
  clientId: number
  operation_id: number
  date: string
}
export type OperationRoutesResponse = Array<{
  id: number
  name: string
  assignee: string
  shift: string
  cancelled: boolean
  duplicate_id: number | null
}>

export type RouteBulkEditDuplicateRequest = RouteBulkCancelRequest & {
  shift_id?: number
  vehicle_id?: number
  employee_id?: number
  start_time?: string
  finish_time?: string
}

export type RouteBulkCancelRequest = {
  clientId: number
  route_plan_id: Array<{
    id: number
    duplicate_id: number | null
  }>
  date: string
  from_date: string
}

export type ValidateTaskUsabilityResponse = {
  message: string
  success: boolean
}

export type ValidateTaskUsabilityRequest = {
  object_id: number
  external_client_id: number
}

export type ActionNoteRequest = {
  clientId?: number
  id?: number
  note: string
}

export type GeneralDynamicFieldsRequest = {
  clientId?: number
  modelName: string
}

export type GeneralDynamicFieldsResponse = {
  success: boolean
  dynamic_fields: DynamicFieldArrayType
}

export type DefaultFiltersByModelRequest = {
  clientId?: number
  modelId: number
}

export type DefaultFiltersByModelResponse = FilterRequestList

export type ImportantLocationsResponse = Array<{
  location_id: number
  type_id: number
  type: string
  name: string
  latitude: number
  longitude: number
}>

export type ImportantLocationsRequest = {
  clientId?: number
}

export type ServicePointMapPopupDetailsRequest = {
  clientId: number | string | undefined
  servicePointId: number
  fields?: string[]
}

export type ServicePointMapPopupDetailsResponse = {
  details: Array<{
    value: string | { value: string }
    label: string
    type: string

    cellType?: string
  }>
  last_update: {
    label: string
    value: string
  }
  name: {
    label: string
    value: string
  }
  is_active: {
    label: string
    value: boolean
  }
  status?: {
    value: StatusType & {
      id: number | string
    }
  }
  updated_at?: {
    label: string
    value: string
  }
}

export type DeleteTaskResponse = DeleteServicePointResponse

export type DeleteTaskRequest = {
  clientId: number | undefined
  task_ids: Array<number>
}

export type TaskMapPopupDetailsRequest = {
  clientId: number | undefined
  taskId: number
}

export type TaskMapPopupDetailsResponse = {
  details: Array<{
    value: string | { value: string }
    label: string
    type: string
  }>
  id: {
    label: string
    value: string
  }
  status: {
    label: string
    value: {
      color: string
      category: string
      value: string
    }
  }
}

export type AssigneesByRoutesResponse = {
  operation: { value: number; label: string; assignee_type: number }
  assignees: Array<{
    label: string
    value: number
    assignee_type: number
  }>
}

export type AssigneesByRoutesRequest = {
  clientId: number | undefined
  route: number[]
}

export type ChangeRoutesAssigneesResponse = MutationResponse & {
  success_ids: number[]
  fail_ids: number[]
}

export type ChangeRoutesAssigneesRequest = {
  clientId: number | undefined
  route: number[]
  vehicle_id?: number
  employee_id?: number
}

export type DeactivateRoutePlanRequest = {
  clientId?: number
  route_plan_ids: Array<number>
}

export type SPMapSettingsResponse = {
  popup: {
    fields: MapSettingsOption[]
  }
}

export type GenericSearchResponse = Array<{
  value: number
  label: string
  data: any
}>

export type GenericSearchRequest = {
  clientId?: number
  model_name: 'servicepoint' | 'task' | 'route' | 'incidentcategory' | 'navigationrecord'
  requested_values: Array<string>
  search_fields: Array<string>
  search_queries: Array<any>
  delimiter?: string
  order_by: string
  date_format?: string
  datetime_format?: string
  requested_labels: Array<string>
  filter_expressions?: Array<string>
}

export type SearchSPByParamsResponse =
  | GenericSearchResponse
  | Array<
      GenericSearchResponse[0] & {
        data: {
          id: number
          name: string
          latitude: number
          longitude: number
          operations__id: Array<number>
          type: OpsServicePointType
        }
      }
    >

export type SearchSPByParamsRequest = {
  clientId?: number
  name?: string
  operations?: Array<number>
  exact?: boolean
}

export type SearchTasksByParamsResponse =
  | GenericSearchResponse
  | Array<
      GenericSearchResponse[0] & {
        data: {
          id: number
          status_category__id: number
          status_category__name: number
          status_category__state: string
          due_date: string
          create_time: string
          last_updated: string
          service_point__id: string | null
          service_point__name: string | null
          service_point__type: OpsServicePointType
          address: string
          info: string | null
          latitude: number | null
          longitude: number | null
          task_template__name: string
          operation_date: string
          shift__start_time: string
          shift__end_time: string
          client__client_tz: string
          route_plan__name: string | null
          route_plan__id: string | null
          route__route_id: number
          route__route_name: string
          task_order_tasks__id: Array<number | null>
          task_order_tasks__name: Array<string | null>
        }
      }
    >

export type SearchTasksByParamsRequest = {
  clientId?: number
  date: string
  operation: string | number
  status?: number[]
}

export type SearchRoutesByParamsResponse =
  | GenericSearchResponse
  | Array<
      GenericSearchResponse[0] & {
        data: {
          status: number
          route_id: number
          route_name: string
          create_datetime: string
          last_status_change: string
          start_time: string
          finish_time: string
          route_order__id: number | null
          route_order__name: string | null
          route_assignee__vehicle__plate: string | null
          route_assignee__employee__driver_name: string | null
          processed_kml_file: string | null
          important_locations_v2__id: [null] | Array<number>
        }
      }
    >

export type SearchRoutesByParamsRequest = {
  clientId?: number
  date: string
  operation: string | number
  status?: number[]
}

export type MandatoryAssetSummaryRequest = {
  clientId?: number
  route_id: number
}

export type MandatoryAssetSummaryResponse = {
  total_assets: number
  mandatory_asset_ids: Array<number>
  attached_mandatory: number
  total_mandatory: number
}

export type AddShiftRequest = {
  clientId: number
  name: string
  earliest_start_time: string // FIXME: make this "earliest_start_time"
  latest_start_time: string // FIXME: make this "latest_start_time"
}

export type EditShiftRequest = AddShiftRequest & {
  shiftId: number
}

export type DeleteShiftRequest = {
  clientId: number
  shiftId: number
}

export type GetShiftDetailsRequest = DeleteShiftRequest
export type GetShiftDetailsResponse = {
  data: {
    id: number
    name: string
    earliest_start_time: string
    latest_start_time: string
  }
}

export type UpsertIncidentCategoryRequest = {
  clientId?: number
  id?: number
  body: FormData
}

export type DeleteIncidentCategoryRequest = {
  clientId?: number
  id: number
}

export type TaskPlanDetailsRequest = {
  clientId: number
  taskPlanId: number
}
export type TaskPlanDetailsResponse = {
  id: DetailFieldType<number>
  name: DetailFieldType<string>
  operation: DetailFieldType<string> & { id: number }
  service_point: DetailFieldType<{ value: string; label: string }> & {
    id: number
    location: { latitude: number; longitude: number }
    service_point_type: OpsServicePointType
  }
  task_template: DetailFieldType<string> & { id: number }
  start_date: DetailFieldType<string>
  end_date: DetailFieldType<string>
  schedule: DetailFieldType<string>
  schedule_value: DetailFieldType<number | Array<number>>
  schedule_type: DetailFieldType<number>
  shift: DetailFieldType<string> & { id: number }
  note: DetailFieldType<string>
  route_plan: DetailFieldType<string> & { id: number }
  status: DetailFieldType<string> & { is_indirect: boolean; color: BaseColorType }
  steps: Array<{
    step_id: number
    service_type: ServiceTypes
    items?: Array<{
      amount?: number
      item_type?: number
      quantity?: number
      uom?: string
    }>
    assets?: {
      amount?: string
      asset_ids?: Array<number>
      asset_types?: number[]
      quantity?: string
      uom?: string
    }
  }>
}

export type TaskTransitionDetailsResponse = {
  has_inbound: boolean
  has_order: boolean
  service_point_id: number
  route_id: number | null
  operation_id: number
  transitions: Array<{
    transition_id: number
    steps: Array<TransitionStepType>
  }>
}

export type TaskTransitionDetailsRequest = {
  clientId: number | undefined
  task_id: number
  taskStatus: number
}

type TaskTransitionItemType = {
  item_id: number
  item_type: number
  quantity: number
  amount?: number
}

export type TaskTransitionRequest = {
  route_id: number
  task_id: number
  transition_id: number
  steps: Array<{
    step_id: number
    assets: Array<{
      asset_id: number
      items: Array<TaskTransitionItemType>
    }>
    items: Array<TaskTransitionItemType>
    form: Array<{
      id: number
      value: any // serialized array
    }>
  }>
}

export type OpsServicePointType = 'vip' | 'special_needs' | 'default'

export type Answer = {
  text?: string
  value: string
  notify: boolean
  id?: number
}

export type AnswerGroup = {
  label: string
  items: Answer[]
}

export type Question<
  PlacementType = number | string | DropdownOptionTemplate<number>,
  AnswersType = AnswerGroup | Answer[],
> = {
  id: number
  placement: PlacementType
  text: string
  answers: AnswersType
  is_critical: boolean
  image: string | null
  order: number
}

export type Form = { id: number; name: string; operations: DropdownOptionTemplate<number> }

export type FormUpsertRequestBody = { name: string; operations?: string[] }

export type ImportantLocationArea = {
  id: number
  point_type: 'area'
  color: 'primary' | 'grey' | 'white' | 'green' | 'yellow' | 'blue' | 'orange'
  location: Array<LatLngTuple>
  name: string
}

export type ImportantLocationPoint = {
  id: number
  point_type: 'point'
  icon: IconNames
  location: LatLngTuple
  name: string
}

export type ImportantLocationsMapListResponse = {
  items: Array<ImportantLocationArea | ImportantLocationPoint>
  detail: { message: string }
}

export type ImportantLocationsEnvironmentResponse = {
  types: Array<{
    label: string
    value: string
    fields: {
      uom?: DropdownOptionTemplate<string>
      distance_control: boolean
      min_max_control: boolean
      decimal_rate: boolean
    }
    point_type: 'area' | 'point'
    color?: ImportantLocationArea['color']
    icon?: IconNames
  }>
}

export type ImportantLocationAddRequest = {
  client_id: number
  name: string
  type: string
  payload?: {
    uom: string
    decimal_rate?: number
    distance_limit?: number
    min_value?: number
    max_value?: number
  }
  location: LatLngTuple | Array<LatLngTuple>
}

export type ImportantLocationEditRequest = ImportantLocationAddRequest & {
  id: number
}

export type ImportantLocationDetailResponse = {
  name: string
  details: Array<{
    color: null
    form_key: string
    form_value: string | number
    icon: IconNames | null
    label: string
    value: string
  }>
}

export type PreferenceType =
  | 'start_end_odometer'
  | 'start_end_hourmeter'
  | 'fuel_info'
  | 'disposal_info'
  | 'incident_info'
  | 'qhse_pre_route'
  | 'qhse_post_route'
  | 'route_assignment_configurability'
  | 'crew_member'
  | 'break'
  | 'new_task'
  | 'manage_asset'
  | 'fieldwork_maps_enabled'

export type PreferencesResponse = Array<{
  availability: boolean
  enabled: boolean
  key: PreferenceType
}>

export type EditDisposalAmountRequest = {
  client_id: number
  disposal_id: number
  dump_weight: number
}
export type AddDisposalAmountRequest = {
  // route_id: number
  // dump_location: number
  // dump_weight: number
  // create_time: number
  data: FormData
}

export type ListIncidentCategoriesResponse = Array<{
  data: { name: string; image: string }
  label: string
  value: number
}>

export type EmployeeDetailsResponse = {
  data: {
    customer_driver_id: string
    employee_code: number
    id: number
    name: string
    operations: Array<number>
    phone_number: string
    type: number
    username: string
  }
}

export type AddEmployeeRequest = {
  client_id: number
  name: string
  phone_number?: string
  operations: Array<number>
  type: number
  password?: string
  confirm_password?: string
  customer_driver_id: string
  employee_code?: number
  username?: string
}

export type AddVehicleRequest = {
  external_id?: string
  operations?: Array<number>
  plate: string
  client_id: number
}

export type LastNavigationRecordsResponse = Array<{
  latitude: number
  longitude: number
  creation_time: string
  speed: number
  route_id: number
}>

export type ImportantLocationActivity = {
  uom: string
  value: number
  location_id: number
}

export type TaskCountByStatus = Array<
  Omit<StatusType, 'label'> & { count: number; value: string; category: string }
>

export type CockpitRouteDetailResponse = {
  route_name: DropdownOptionTemplate[0]
  status: StatusType
  date: DropdownOptionTemplate[0]
  route_start_time: DropdownOptionTemplate[0]
  route_finish_time: DropdownOptionTemplate[0]
  total_duration: DropdownOptionTemplate[0]
  total_distance: DropdownOptionTemplate[0]
  shift_name: DropdownOptionTemplate[0]
  route_assignee: DropdownOptionTemplate[0]
  crew_names: DropdownOptionTemplate[0]
  total_fuel: Array<ImportantLocationActivity>
  total_dump: Array<ImportantLocationActivity>
  average_speed: DropdownOptionTemplate[0]
  total_break: DropdownOptionTemplate[0]
} & GenericResponse

export type GetNavigationRecordByRouteRequest = {
  clientId: number
  route_id: number
  noDateFormat?: boolean
}

export type GetNavigationRecordByRouteResponse = Array<{
  value: number
  data: {
    latitude: number
    longitude: number
    create_time: string
    speed: number
  }
}>

export type CockpitRouteListItem = {
  route_id: number
  status: number
  route_name: string
  create_datetime: string
  last_status_change: any
  start_time: string
  finish_time: string
  route_plan_id: number | null
  route_plan_name: string | null
  assignee_employee_driver_name: string | null
  assignee_vehicle_plate: string | null
  kml: string | null
  important_locations: Array<number>
  task_count_by_status: TaskCountByStatus
}
export type CockpitRouteListResponse = Array<CockpitRouteListItem>

export type CockpitTaskListResponse = Array<{
  task_id: number
  status: StatusType & {
    id: number
    name: string
    value: string //status category
  }
  latitude: number
  longitude: number
  route: {
    id: number
    name: string
  } | null
  sequence: number | null
  eta: number | null
  task_duration: number | null
  due_date: string
  operation: {
    operation_id: number
    operation_date: string
  }
  creation_time: string
  last_update_time: string
  service_point: {
    id: number
    name: string
    type: OpsServicePointType
  } | null
  route_plan: { id: number; name: string } | null
  task_template_name: string
  task_shift: {
    start_time: string
    end_time: string
  } | null
  address: string | null
  note: string | null
  task_plan: { id: number; name: string } | null
}>

export type DisposalUOM = 'liter' | 'gallon' | 'kg' | 'tone' | 'cubic_meter'

export type TaskCardItem = {
  id: number
  status: {
    id: number
    name: string
    state: string
    color: BaseColorType
    hexColor: string
    count?: number
  }
  due_date: string
  create_time: string
  last_updated: string
  servicePoint: { id: number; name: string; type: OpsServicePointType } | null
  note: string | null
  address: string | null
  latitude: number
  longitude: number
  task_template: string
  operation_date: string
  shift: string
  routePlan: { id: number; name: string } | null
  route: { id: number; name: string } | null
  taskPlan: { id: number; name: string } | null
  sequence: number | null
  eta?: number
}

export type RouteCardItem = {
  id: number
  status: {
    id: RouteStatus
    name: string | null
    count?: number
  }
  name: string
  create_time: string
  last_status_change: string
  kml: { url: string | null; color: keyof ThemeColorType }
  time: string
  routePlan: { id: number; name: string } | null
  assignee: { vehicle: string | null; employee: string | null }
  start_time_raw: string
  end_time_raw: string
  important_locations: Array<number>
}

export enum RouteStatus {
  UPCOMING,
  UNDISPATCHED,
  CHECKIN,
  DISPATCHED,
  VCR_FAIL,
  CANCELLED,
  OFFLINE,
  EXPIRED,
  FINISHED,
}

export const RouteStatusColor: Record<RouteStatus, Exclude<BaseColorType, 'white' | 'black'>> = {
  [RouteStatus.UPCOMING]: BaseColorEnum.blue,
  [RouteStatus.DISPATCHED]: BaseColorEnum.green,
  [RouteStatus.FINISHED]: BaseColorEnum.green,
  [RouteStatus.CHECKIN]: BaseColorEnum.yellow,
  [RouteStatus.VCR_FAIL]: BaseColorEnum.grey,
  [RouteStatus.OFFLINE]: BaseColorEnum.grey,
  [RouteStatus.UNDISPATCHED]: BaseColorEnum.red,
  [RouteStatus.EXPIRED]: BaseColorEnum.red,
  [RouteStatus.CANCELLED]: BaseColorEnum.red,
}

export const routeStatusOrder: RouteStatus[] = [
  RouteStatus.UPCOMING,
  RouteStatus.DISPATCHED,
  RouteStatus.FINISHED,
  RouteStatus.CHECKIN,
  RouteStatus.VCR_FAIL,
  RouteStatus.OFFLINE,
  RouteStatus.UNDISPATCHED,
  RouteStatus.EXPIRED,
  RouteStatus.CANCELLED,
]

export enum RouteActivity {
  start_point = 'start-point',
  end_point = 'end-point',
  fuel = 'fuel',
  truck_disposal = 'truck-disposal',
  break = 'break',
  speed_limit = 'speed-limit',
}

export type PlayerActivityRecord = {
  id: number
  type: 'TASK' | 'DISPOSAL' | 'FUEL' | 'SPEED_LIMIT' | 'START_LOCATION' | 'END_LOCATION' | 'BREAK'
  start_time: string
  end_time: string | null
  sequence?: number
  location: {
    latitude: number | null
    longitude: number | null
  }
  info?: string
}

export type RegionItem = {
  id: number
  name: string
  positions: Array<[number, number]>
}
