import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const CageIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <path
        d="M11.7441 25.8219C11.7441 26.1707 11.9259 26.4944 12.2238 26.6759L13.4225 27.4062C13.5904 27.5085 13.7848 27.5591 13.9813 27.5515L26.6761 27.0621C27.213 27.0414 27.6375 26.6002 27.6375 26.0629V15.002C27.6375 14.4633 27.2108 14.0214 26.6724 14.0026L13.9453 13.5582C13.7714 13.5522 13.599 13.5915 13.445 13.6725L12.2788 14.2857C11.95 14.4585 11.7441 14.7994 11.7441 15.1708V25.8219ZM25.8452 25.8953C26.2472 25.8789 26.5648 25.5482 26.5647 25.1458L26.5642 15.9072C26.5642 15.5031 26.2441 15.1717 25.8404 15.1577L15.5825 14.8003C15.3792 14.7932 15.1817 14.869 15.0354 15.0103C14.889 15.1516 14.8064 15.3464 14.8064 15.5498V20.4313C14.8032 20.4594 14.8015 20.488 14.8015 20.517C14.8015 20.5461 14.8032 20.5747 14.8064 20.6028L14.8064 25.5622C14.8064 25.7664 14.8896 25.9617 15.0369 26.1031C15.1842 26.2446 15.3828 26.3198 15.5868 26.3115L25.8452 25.8953ZM16.3064 24.7811V21.267H18.3064V24.6999L16.3064 24.7811ZM16.3064 19.767H18.3064V16.3961L16.3064 16.3264V19.767ZM19.8064 24.6391V21.267H21.8064V24.5579L19.8064 24.6391ZM19.8064 19.767H21.8064V16.518L19.8064 16.4483V19.767ZM23.3064 24.497V21.267H25.0645L25.0647 24.4257L23.3064 24.497ZM23.3064 19.767H25.0645L25.0643 16.6315L23.3064 16.5703V19.767Z"
        fill={isSelected ? '#fff' : color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </AssetIconWrapperStyled>
  )
}

export default CageIcon
