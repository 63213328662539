import { orderApi } from 'services/Engagement/order'
import { servicePointApi } from 'services/Engagement/servicePoint'
import { entityApi } from './entity'
import { financialDetailApi } from './financialDetail'
import { contactApi } from './contact'
import { caseApi } from './case'

export const {
  useGetCustomFieldsQuery,
  useAddEntityMutation,
  useEditEntityMutation,
  useGetEntityListQuery,
  useDeleteEntityMutation,
  useGetEntityDetailQuery,
  useGetEntitySummaryQuery,
  useGetServiceDayListQuery,
  useGetServiceDayDetailQuery,
  useBulkAddServicePointMutation,
  useBulkDeleteEntityMutation,
  useSearchEntityMutation,
  useExportEntityMutation,
  useListAllServicePointsOfEntityQuery,
  useUnlinkServicePointMutation,
  useGetEntityOrdersExternalQuery,
  useGetEntityContactsQuery,
  useImportEntitiesMutation,
  useEntityHistoryQuery,
  useUpdateEntityStatusMutation,
  useLazyGetEntityDetailQuery,
} = entityApi

export const {
  useGetServicePointListQuery,
  useGetServicePointDetailsQuery,
  useGetServicePointsCustomFieldsQuery,
  useAddServicePointMutation,
  useEditServicePointMutation,
  useGetServicePointLocationsQuery,
  useLazyGetServicePointLocationsQuery,
  useSearchServicePointMutation,
  useExportServicePointMutation,
  useLinkAssetToServicePointMutation,
  useGetServicePointMappingMutation,
  useImportServicePointsMutation,
  useUnlinkAssetToServicePointMutation,
  useServicePointHistoryQuery,
  useUpdateSpStatusMutation,
  useDeleteServicePointMutation,
  useGetMapPointsQuery,
  useGetMapSettingsQuery,
  useGetServicePointMapPopupDetailsQuery,
  useLazyGetServicePointDetailsQuery,
} = servicePointApi

export const {
  useGetOrdersQuery,
  useGetOrderPlansQuery,
  useDeleteOrderMutation,
  useDeleteOrderPlanMutation,
  useAddOrderMutation,
  useEditOrderMutation,
  useAddOrderPlanMutation,
  useGetOrderDetailsQuery,
  useGetOrderItemsQuery,
  useBulkDeleteOrderMutation,
  useBulkDeleteOrderPlanMutation,
  useGetOrderPlanDetailsQuery,
  useSearchOrderMutation,
  useEditOrderPlanMutation,
  useSearchOrderPlanMutation,
  useExportOrderMutation,
  useExportOrderPlanMutation,
  useGetOrderDynamicFieldsQuery,
  useGetWorkOrdersQuery,
  useLinkWorkOrderMutation,
  useGetOrderItemListQuery,
  useGetWorkOrderForDisputeQuery,
  useAddObjectionMutation,
  useGetObjectionListQuery,
  useUnlinkWorkOrderMutation,
  useImportOrdersMutation,
  useImportOrderPlansMutation,
  useOrderHistoryQuery,
  useUpdateOrderStatusMutation,
  useAddAdjustmentOrderItemMutation,
  useEditAdjustmentOrderItemMutation,
  useDeleteAdjustmentOrderItemMutation,
  useGetAdjustmentOrderItemDetailQuery,
  useGetOrderItemMappingQuery,
  useGetMismatchedOrderPlansQuery,
  useUpdateOrderNoteMutation,
  useAddBulkOrderMutation,
  useAddExclusionMutation,
  useServiceExclusionsQuery,
  useOrderCalendarListQuery,
  useDeleteExclusionMutation,
  useEditExclusionMutation,
  useGetOrderSettingsQuery,
  useLazyGetMismatchedOrderPlansQuery,
  useAvailableDatesQuery,
} = orderApi

export const {
  useGetFinancialDetailListQuery,
  useAddFinancialDetailMutation,
  useDeleteFinancialDetailMutation,
  useGetFinancialDetailQuery,
  useEditFinancialDetailMutation,
  useExportFinancialDetailListMutation,
  useImportFinancialDetailListMutation,
  useFinancialDetailHistoryQuery,
} = financialDetailApi

export const {
  useGetContactListQuery,
  useImportContactsMutation,
  useAddContactMutation,
  useEditContactMutation,
  useGetContactDetailQuery,
  useDeleteContactMutation,
  useValidateContactEmailMutation,
  useValidateContactPhoneMutation,
  useGetContactCustomFieldsQuery,
  useChangePasswordMutation,
  useExportContactsMutation,
} = contactApi

export const {
  useGetCasesQuery,
  useGetCommentsQuery,
  useGetCaseDetailQuery,
  useGetCaseDynamicFieldsQuery,
  useAddCaseMutation,
  useEditCaseMutation,
  useAddCommentMutation,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useImportCasesMutation,
  useExportCasesMutation,
  useCaseHistoryQuery,
  useUpdateCaseStatusMutation,
  useDeleteCaseMutation,
} = caseApi

export { servicePointApi, orderApi, entityApi, financialDetailApi, contactApi, caseApi }
