import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Checkbox, Dropdown, Flex, Typography } from 'components'
import {
  ImageArea,
  ImageArea2,
  LoginLeftSide,
  LoginRightSide,
  LoginWrapper,
  StyledBannerArea,
  StyledIllustrationArea,
  StyledTitleArea,
  StyledTopArea,
} from './styles'
import logo_svg from './assets/360.svg'
import evreka from './assets/evreka.svg'
import illustration_area from './assets/illustration_area.svg'
import { FLAGS, LANGUAGES } from 'utils/languages'
import { UilEnvelope } from '@iconscout/react-unicons'
import i18n from 'utils/i18n'
import { useTranslation } from 'utils/useTranslation'
import { OptionType, Spinner } from '@evrekadev/evreka-ui-components'
import { checkImage } from './utils'

type LoginLayoutProps = {
  title: string
  subtitle?: string
}

const LoginLayout: React.FC<React.PropsWithChildren<LoginLayoutProps>> = ({
  title,
  subtitle,
  children,
}) => {
  const { t } = useTranslation('pages.authentication')
  const [isWhiteLabel, setIsWhiteLabel] = useState<boolean | null>(null)

  const getDefaultLanguage = () => {
    const browserLanguage = navigator.language.split('-')[0]
    const languageKeys = Object.values(LANGUAGES)

    return languageKeys.includes(browserLanguage as LANGUAGES)
      ? (browserLanguage as LANGUAGES)
      : LANGUAGES.ENGLISH
  }

  const [loginLanguage, setLoginLanguage] = useState(getDefaultLanguage())
  const languageOptions = Object.entries(LANGUAGES).map(([key, value]) => ({
    value: value,
    label: `${FLAGS[value]} ${value.toUpperCase()}`,
  }))

  useEffect(() => {
    i18n.changeLanguage(loginLanguage)
  }, [loginLanguage])

  const hostname = window.location.hostname.split('.')[0]

  const whitelabelLogoUrl = `https://s3.eu-west-1.amazonaws.com/media.evreka.co/white_label_images/${hostname}_logo.jpg`
  const whiteLabelbgUrl = `https://s3.eu-west-1.amazonaws.com/media.evreka.co/white_label_images/${hostname}_background.jpg`
  const isWhiteLabelAsync = useCallback(async () => {
    try {
      const isImageBg = await checkImage(whiteLabelbgUrl)
      const isImageLogo = await checkImage(whitelabelLogoUrl)
      setIsWhiteLabel(isImageBg && isImageLogo)
    } catch {
      setIsWhiteLabel(false)
    }
  }, [whitelabelLogoUrl, whiteLabelbgUrl])

  useEffect(() => {
    isWhiteLabelAsync()
  }, [isWhiteLabelAsync])

  if (isWhiteLabel === null) {
    return (
      <Flex middle="zero" center="zero" fullWidth style={{ height: '100vh' }}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <div dir={loginLanguage === 'he' || loginLanguage === 'ar' ? 'rtl' : 'ltr'}>
      <LoginWrapper columns={'2fr 3fr'}>
        <LoginLeftSide directionColumn="zero" between="zero">
          <StyledTopArea middle="zero" between="zero">
            <img src={isWhiteLabel ? whitelabelLogoUrl : logo_svg} alt="evreka_360_logo" />
            <Flex>
              <Dropdown
                isSearchable={false}
                options={languageOptions}
                defaultValue={languageOptions.find(
                  (option) => option.value === getDefaultLanguage(),
                )}
                onChange={(e) => setLoginLanguage((e as OptionType)?.value as LANGUAGES)}
              ></Dropdown>
            </Flex>
          </StyledTopArea>
          <StyledTitleArea gap={2} directionColumn="zero">
            <Flex directionColumn="zero">
              <Typography fontSize="header_sm" color="darkblue900" fontWeight="semibold">
                {title}
              </Typography>
              <Typography
                variant="span"
                fontSize="text_md"
                color="darkblue700"
                fontWeight="regular"
              >
                {subtitle}
              </Typography>
            </Flex>
            {children}
          </StyledTitleArea>

          <StyledTopArea between="zero" middle="zero">
            <Flex directionColumn="zero" center="zero">
              <Typography fontSize="text_sm" color="grey500" fontWeight="medium">
                {t('developed_by')}
              </Typography>
              <img src={evreka} alt="evreka_logo" />
            </Flex>
            <Button
              iconLeft={UilEnvelope}
              variant="link"
              color="secondary"
              size="small"
              href="mailto:info@evreka.co"
            >
              info@evreka.co
            </Button>
            <ImageArea />
          </StyledTopArea>
        </LoginLeftSide>

        <LoginRightSide>
          {!isWhiteLabel && (
            <StyledBannerArea directionColumn="zero">
              <Typography fontSize="header_xs" fontWeight="regular" color="darkblue900">
                {t('banner_title')}
              </Typography>
              <Typography
                variant="span"
                fontSize="header_sm"
                fontWeight="semibold"
                color="darkblue900"
              >
                {t('banner_subtitle')}
              </Typography>
            </StyledBannerArea>
          )}

          <StyledIllustrationArea
            src={isWhiteLabel ? whiteLabelbgUrl : illustration_area}
            alt="illustration_area"
          />

          <ImageArea2 />
        </LoginRightSide>
      </LoginWrapper>
    </div>
  )
}

export default LoginLayout
