import { ModuleSliderArrowProps } from 'containers/ModuleSlider/ModuleSliderArrow'
import styled, { css } from 'styled-components'
import { color, padding, borderRadius, margin } from '@evrekadev/evreka-ui-components'

export const ModuleIconWrapper = styled.div`
  ${margin({ t: 4 })} // to align with padded info icon
  height: 56px;
`
export const ModuleInfoIconWrapper = styled.div`
  ${padding([4])}
  ${borderRadius(4)}
  &:hover {
    cursor: pointer;
    background-color: ${color('grey100')};
  }
`
export const ModuleArrowGradientWrapper = styled.div<ModuleSliderArrowProps>`
  ${borderRadius(4)}
  position: absolute;
  z-index: 2;
  margin-top: 1px;
  height: 343px;
  background: ${(props) =>
    props.direction === 'left'
      ? 'linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 60.42%)'
      : 'linear-gradient(270deg, #FFFFFF 42.19%, rgba(255, 255, 255, 0) 100%)'};
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    !props.visible &&
    css`
      display: none;
    `}

  &:hover {
    cursor: pointer;
  }
`

export const RtlWrapper = styled.div<{ isRtl: boolean }>`
  .left {
    ${(props) =>
      props.isRtl
        ? css`
            right: 0;
          `
        : css`
            left: 0;
          `}
  }
  .right {
    ${(props) =>
      props.isRtl
        ? css`
            left: 0;
          `
        : css`
            right: 0;
          `}
  }
`

export const ModuleCardStyled = styled.div`
  ${borderRadius(12)}
  ${padding([24])}
  background: ${color('white')};
  border: 1px solid ${color('grey300')};
  box-sizing: border-box;
  // slider stuff
  scroll-snap-align: start;
  flex-shrink: 0;
  width: 363px;
  height: 345px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
`
export const ModuleSliderStyled = styled.div`
  width: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
`

export const ModuleSlideContainerStyled = styled.div`
  display: flex;

  overflow-x: auto;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  & > ${ModuleCardStyled} {
    ${margin({ r: 8 })}
  }

  & :last-child {
    margin-right: 0px !important;
  }

  /*
  scroll-snap-points-x: repeat(300px);
  scroll-snap-type: mandatory;
  */
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`
export const ModuleDescriptionWrapperStyled = styled.div`
  ${padding({ r: 16 })}
  height: 114px;
`
