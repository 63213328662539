import React, { useCallback, useEffect } from 'react'
import { useLeafletContext } from '@react-leaflet/core'
import { PolygonItem, Polygons } from '../Polygons'
import * as L from 'leaflet'
import { Polygon } from 'leaflet'
import * as turf from '@turf/turf'

type Props = {
  items: PolygonItem[]
  onChange?: (items: PolygonItem[]) => void
  pathOptions?: L.PathOptions
}

export const getCircleDrawing = (item: any) => {
  const { lat, lng } = item.getLatLng()
  const center = [lng, lat]
  const radius = item.getRadius()

  const options = { steps: 64, units: 'meters' } as any
  const circle = turf.circle(center, radius, options)

  return {
    positions: circle.geometry.coordinates[0].map(([lon, lat]) => [lat, lon]),
  }
}

export const ControlledDrawings: React.FC<React.PropsWithChildren<Props>> = ({
  items,
  pathOptions,
  onChange,
}) => {
  const { map } = useLeafletContext()

  const getDrawing = (item: Polygon) => {
    const geojson = item.toGeoJSON()

    return {
      positions: geojson.geometry.coordinates[0].map(([lng, lat]) => [lat, lng]) as any,
    }
  }

  const onUpdate = useCallback(
    (e?: any) => {
      if (onChange) {
        const items = map.pm.getGeomanLayers() as Polygon[]

        onChange(
          items.map((item) =>
            (item as any).pm._shape === 'Circle' ? getCircleDrawing(item) : getDrawing(item),
          ),
        )
      }
    },
    [onChange],
  )

  const onCreate = useCallback(
    (e: any) => {
      if (onChange) {
        onUpdate(e)
        e.layer.remove()
      }
    },
    [onUpdate],
  )

  useEffect(() => {
    if (items.length > 0) {
      const geomanLayers = map.pm.getGeomanLayers() as Polygon[]

      geomanLayers.forEach((layer) => {
        layer.on('pm:edit', onUpdate)
        layer.on('pm:remove', onUpdate)
      })

      return () => {
        geomanLayers.forEach((layer) => {
          layer.off('pm:edit', onUpdate)
          layer.off('pm:remove', onUpdate)
        })
      }
    }
  }, [items, onUpdate])

  useEffect(() => {
    map.on('pm:create', onCreate)

    return () => {
      map.off('pm:create', onCreate)
    }
  }, [map, onCreate])

  const manipulatedItems = pathOptions
    ? items.map((item) => ({
        ...pathOptions,
        ...item,
      }))
    : items

  return <Polygons polygons={manipulatedItems} pmIgnore={false} />
}
