/**
 * Check if the field exists in formData object, if so edit field else add field
 * @param formData FormData object
 * @param field Field name to be checked
 * @param value Value of the field
 */
export const formDataFieldHandler = (
  formData: FormData | null | undefined,
  field: string,
  value: string | Blob,
) => {
  return formData?.has(field) ? formData?.set(field, value) : formData?.append(field, value)
}

//TODO:(stringfy object[] internally)
function buildFormData(formData: any, data: any, parentKey?: any) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      if (data[key] || data[key] === 0) {
        formData.append(key, data[key])
      }
    })
  } else if (data) {
    formData.append(parentKey, data)
  }
}

export function jsonToFormData(data: any) {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}
