import { InputText, Typography, Flex, FlexCell, Icon, Spacing } from 'components'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { selectCurrentLanguage } from 'services/slices/auth'
import { mirrorFetch } from '../utils'
import { useSelector } from 'react-redux'
import { AUTOCOMPLETE_API, FINDPLACEFROMTEXT_API } from '../contants'
import { useMap, useMapEvents } from 'react-leaflet'
import { LatLngTuple } from 'leaflet'
import L from 'leaflet'
import { ControlledMenu, useMenuState, MenuHeader } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import { debounce } from 'lodash-es'
import {
  IconWrapper,
  MenuItemStyled,
  MapSearchContainerStyled,
  SeparatorStyled,
} from './MapSearch.style'
import { UilMapPin, UilMapMarker } from '@iconscout/react-unicons'
import { divideMainTextToMatchedSubstrings, divideTextBySearchVal } from './util'
import { LocationOptionType, AutoCompleteResponse } from './types'
import { useTranslation } from 'utils/useTranslation'
import { MapContext } from 'context'
import { MapContextType } from '../types'

type MapSearchProps = {
  currentAddress?: string
}
export const MapSearch: React.FC<MapSearchProps> = ({ currentAddress }) => {
  const currentLanguage = useSelector(selectCurrentLanguage)
  const { t } = useTranslation('components.map.search')
  const currentMap = useMap()
  const [menuProps, toggleMenu] = useMenuState({ initialOpen: false })

  const {
    selectControlOnClick: onClick,
    externalSearchProps,
    setAddress,
  } = useContext<MapContextType>(MapContext)

  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)
  const [externalLoading, setExternalLoading] = useState(false)

  const [options, setOptions] = useState<Array<LocationOptionType>>([])
  const [externalOptions, setExternalOptions] = useState<Array<LocationOptionType>>([])

  const [foundLatLng, setFoundLatLng] = useState<LatLngTuple>()
  const [inputVal, setInputVal] = useState('')

  const onMenuItemClick = (address: string | number) => {
    if (typeof address === 'number') {
      return
    }
    mirrorFetch(
      `${FINDPLACEFROMTEXT_API}&input=${address}&language=${currentLanguage || 'en'}`,
    ).then((res) => {
      setInputVal('')
      toggleMenu(false)
      if (res.candidates?.[0]?.formatted_address) {
        setAddress && setAddress(res.candidates?.[0].formatted_address)
      }
      if (res.candidates?.[0]?.geometry) {
        setFoundLatLng([
          res.candidates[0].geometry.location.lat,
          res.candidates[0].geometry.location.lng,
        ] as LatLngTuple)
        currentMap.setZoomAround(
          [
            res.candidates[0].geometry.location.lat,
            res.candidates[0].geometry.location.lng,
          ] as LatLngTuple,
          17,
        )
      }
    })
  }
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputVal(e.target.value)
  }
  const handleSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        if (search === '') {
          toggleMenu(false)
          setOptions([])
          setFoundLatLng(undefined)
          setExternalOptions([])
          return
        }
        setLoading(true)
        setExternalLoading(true)
        toggleMenu(true)

        const addressResponse: AutoCompleteResponse = await mirrorFetch(
          `${AUTOCOMPLETE_API}&input=${search}&language=${currentLanguage || 'en'}`,
        )

        if (externalSearchProps) {
          const { queryFn } = externalSearchProps
          const externalResponse = await queryFn?.(search)
          const parsedExternalOptions: Array<LocationOptionType> | undefined = externalResponse
            ? externalResponse.map(({ title, ...rest }) => {
                const parsedTitle = divideTextBySearchVal(title, search)
                return { ...rest, fullAddress: title, title: parsedTitle }
              })
            : undefined
          parsedExternalOptions && setExternalOptions(parsedExternalOptions)
        }
        if (addressResponse.success && addressResponse.predictions) {
          setOptions(
            addressResponse.predictions
              .slice(0, externalSearchProps ? 3 : addressResponse.predictions.length)
              .map(
                ({
                  description,
                  structured_formatting: {
                    main_text,
                    main_text_matched_substrings,
                    secondary_text,
                  },
                  place_id,
                }) => ({
                  title: divideMainTextToMatchedSubstrings(
                    main_text,
                    main_text_matched_substrings?.[0],
                  ),
                  description: secondary_text,
                  value: place_id,
                  fullAddress: description,
                }),
              ),
          )
        }
        setLoading(false)
        setExternalLoading(false)
        inputRef.current?.focus()
      }, 800),
    [externalSearchProps],
  )

  //set the map view and marker if an address is clicked
  useEffect(() => {
    if (foundLatLng) {
      currentMap.setView(foundLatLng)
      onClick && onClick(foundLatLng)
    }
  }, [foundLatLng])
  // run search if inout is changed
  useEffect(() => {
    inputVal && handleSearch(inputVal)
  }, [inputVal])
  //put marker if clicked on map
  useMapEvents({
    click(e) {
      onClick && onClick([e.latlng.lat, e.latlng.lng] as LatLngTuple)
    },
  })
  //check for outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        toggleMenu(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  //disable mouse events inside the search and menu
  useEffect(() => {
    if (containerRef.current) {
      L.DomEvent.disableScrollPropagation(containerRef.current)
      L.DomEvent.disableClickPropagation(containerRef.current)
      if (containerRef.current) {
        if (containerRef.current) {
          containerRef.current.addEventListener('mouseenter', () => {
            currentMap.dragging.disable()
            currentMap.doubleClickZoom.disable()
            currentMap.scrollWheelZoom.disable()
          })
          containerRef.current.addEventListener('mouseleave', () => {
            currentMap.dragging.enable()
            currentMap.doubleClickZoom.enable()
            currentMap.scrollWheelZoom.enable()
          })
        }
      }
    }
  }, [containerRef])
  return (
    <MapSearchContainerStyled ref={containerRef} id="map-search-container">
      <InputText
        ref={inputRef}
        value={inputVal}
        style={{ borderRadius: 8 }}
        onChange={onChange}
        placeholder={externalSearchProps?.placeholder || t('placeholder')}
      />
      <Spacing mb={4} />
      <ControlledMenu
        {...menuProps}
        anchorRef={containerRef}
        menuStyle={{
          width: containerRef.current?.offsetWidth,
          maxHeight: 380,
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: 8,
        }}
      >
        {loading || externalLoading ? (
          <MenuHeader>
            <Typography>{t('loading')}</Typography>
          </MenuHeader>
        ) : options.length === 0 && (externalSearchProps ? externalOptions.length === 0 : true) ? (
          <MenuHeader>
            <Typography>{t('no_options')}</Typography>
          </MenuHeader>
        ) : (
          <>
            <MenuHeader>
              <Typography fontSize="text_xs" color="darkblue500">
                {t('locations')}
              </Typography>
            </MenuHeader>
            {options.length === 0 ? (
              <MenuHeader>
                <Typography>{t('no_options')}</Typography>
              </MenuHeader>
            ) : (
              <div data-testid="locations">
                {options.map((props) => (
                  <ResponseItem
                    onItemClick={onMenuItemClick}
                    type="location"
                    key={props.value}
                    {...props}
                  />
                ))}
              </div>
            )}
            <div data-testid={externalSearchProps && externalSearchProps.header}>
              {externalSearchProps && externalSearchProps.queryFn && (
                <>
                  <SeparatorStyled />
                  <MenuHeader>
                    <Typography fontSize="text_xs" color="darkblue500">
                      {externalSearchProps.header}
                    </Typography>
                  </MenuHeader>
                </>
              )}
              {externalSearchProps &&
                externalSearchProps.queryFn &&
                (externalOptions.length === 0 ? (
                  <MenuHeader>
                    <Typography>{t('no_options')}</Typography>
                  </MenuHeader>
                ) : (
                  externalOptions.map((props) => {
                    return (
                      <ResponseItem
                        toggleMenu={toggleMenu}
                        onItemClick={externalSearchProps.onSelectOption}
                        type="external"
                        key={props.value}
                        {...props}
                      />
                    )
                  })
                ))}
            </div>
          </>
        )}
      </ControlledMenu>
    </MapSearchContainerStyled>
  )
}
type ResponseItemProps = LocationOptionType & {
  onItemClick: (value: LocationOptionType['value']) => void
  toggleMenu?: (param: boolean) => void
  type: 'location' | 'external'
}
const ResponseItem: React.FC<ResponseItemProps> = ({
  title,
  value,
  fullAddress,
  description,
  onItemClick,
  type,
  toggleMenu,
}) => {
  return (
    <MenuItemStyled
      value={fullAddress}
      onClick={(e) => {
        // e.value is fullAddress, value is id
        onItemClick(type === 'location' ? e.value : value)
        toggleMenu && toggleMenu(false)
      }}
    >
      <Flex gap={0.8} middle="zero" fullWidth>
        <FlexCell>
          <IconWrapper color={type === 'location' ? 'grey100' : 'green50'}>
            <Icon
              icon={type === 'location' ? UilMapPin : UilMapMarker}
              color={type === 'location' ? 'darkblue600' : 'green500'}
            />
          </IconWrapper>
        </FlexCell>
        <Flex
          directionColumn="zero"
          style={{
            flexGrow: 1,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography variant="span" display="inline" fontWeight="bold" noWrap>
            {title.before}
            <Typography variant="span" display="inline" color="green500" fontWeight="bold">
              {title.matched}
            </Typography>
            {title.after}
          </Typography>
          <FlexCell>
            <Typography fontSize="text_xs" color="darkblue600">
              {description}
            </Typography>
          </FlexCell>
        </Flex>
      </Flex>
    </MenuItemStyled>
  )
}
