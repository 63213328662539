import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  MutationResponse,
  AddAllocationRequest,
  AllocationDetailRequest,
  AllocationDetailResponse,
  ConsignmentListRequest,
  ConsignmentListResponse,
  ParcelsRequest,
  ParcelsResponse,
  EditAllocationRequest,
  LinkParcelToAllocationRequest,
  ParcelMutationResponse,
  ConsignmentStatusMutationRequest,
  ConsignmentStatusMutationResponse,
  ExportRequest,
  ExportResponse,
  RequestDeliveryNoteResponse,
  RequestDeliveryNoteRequest,
  DeliveryNoteResponse,
  DeliveryNoteRequest,
  AddInventoryItemRequest,
  BulkDeleteRequest,
  BulkMutationResponse,
  GetTransferClientsRequest,
  GetTransferClientsResponse,
  SearchRequest,
  ConsignmentSearchResponse,
  AllocationHistoryResponse,
  AllocationHistoryRequest,
} from './types'
import { getExportHandler } from 'utils'
import { FingerprintModel, TableRequestV2External, TableResponse } from 'services/types'
import { inventoryApi } from './inventory'

export const consignmentApi = createApi({
  reducerPath: 'consignmentApi',
  tagTypes: [
    'Consignments',
    'Parcels',
    'InventoryItems',
    'ConsignmentDetails',
    'TransferClients',
    'History',
    'WorkOrderListByOrderId',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    addConsignment: builder.mutation<MutationResponse, AddAllocationRequest>({
      query: ({ clientId, ...data }) => ({
        url: `MRF/v2/consignment/clients/${clientId}/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Consignments'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editConsignment: builder.mutation<MutationResponse, EditAllocationRequest>({
      query: ({ clientId, consignmentId, ...data }) => ({
        url: `MRF/v2/consignment/clients/${clientId}/update/${consignmentId}/`,
        method: 'POST',
        data,
        sendFingerprint: FingerprintModel.Allocation,
      }),
      invalidatesTags: ['Consignments', 'ConsignmentDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(
            inventoryApi.util.invalidateTags([
              'Inventory',
              'InventoryDetails',
              'Transactions',
              'TransactionChart',
            ]),
          )
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getConsignmentList: builder.query<ConsignmentListResponse, ConsignmentListRequest>({
      query: ({ client_id, task_id, ...data }) => ({
        url: `MRF/v3/consignment/clients/${client_id}/list/`,
        method: 'POST',
        data,
        params: { task_id },
      }),
      providesTags: ['Consignments'],
    }),
    getConsignmentDetails: builder.query<AllocationDetailResponse, AllocationDetailRequest>({
      query: ({ clientId, consignment_id }) => ({
        url: `MRF/v3/consignment/clients/${clientId}/?consignment_id=${consignment_id}`,
        method: 'GET',
        recieveFingerprint: FingerprintModel.Allocation,
      }),
      providesTags: ['ConsignmentDetails'],
    }),
    updateConsignmentStatus: builder.mutation<
      ConsignmentStatusMutationResponse,
      ConsignmentStatusMutationRequest
    >({
      query: ({ client_id, consignment_id, ...data }) => ({
        url: `MRF/clients/${client_id}/consignment/${consignment_id}/status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Consignments', 'ConsignmentDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(
            inventoryApi.util.invalidateTags([
              'Inventory',
              'InventoryDetails',
              'Transactions',
              'TransactionChart',
            ]),
          )
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getConsignmentParcels: builder.query<ParcelsResponse, ParcelsRequest>({
      query: ({ clientId, consignment_id, ...data }) => ({
        url: `MRF/clients/${clientId}/consignment/${consignment_id}/parcel_list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Parcels'],
    }),
    linkParcelToConsignment: builder.mutation<MutationResponse, LinkParcelToAllocationRequest>({
      query: ({ client_id, consignment_id, ...data }) => ({
        url: `MRF/clients/${client_id}/consignment/${consignment_id}/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Parcels', 'Consignments', 'ConsignmentDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportConsignmentList: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `MRF/clients/${clientId}/export/consignment/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    requestDeliveryNote: builder.mutation<RequestDeliveryNoteResponse, RequestDeliveryNoteRequest>({
      query: ({ client_id, consignment_id }) => ({
        url: `MRF/clients/${client_id}/consignment/${consignment_id}/request_delivery_note/`,
        method: 'GET',
      }),
    }),
    getDeliveryNote: builder.query<DeliveryNoteResponse, DeliveryNoteRequest>({
      query: ({ client_id, consignment_id }) => ({
        url: `MRF/clients/${client_id}/consignment/${consignment_id}/get_delivery_note/`,
        method: 'GET',
      }),
    }),
    addInventoryItem: builder.mutation<ParcelMutationResponse, AddInventoryItemRequest>({
      query: ({ client_id, allocation_id, ...data }) => ({
        url: `MRF/clients/${client_id}/bulk_management/consignment/${allocation_id}/add/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['InventoryItems'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteConsignment: builder.mutation<BulkMutationResponse, BulkDeleteRequest<number, string>>({
      query: ({ clientId, deleteIdArray: consignment_id_list }) => ({
        url: `MRF/clients/${clientId}/consignment/bulk_delete`,
        method: 'DELETE',
        data: { consignment_id_list },
        errorOverride: true,
      }),
      invalidatesTags: ['Consignments'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
          sendNotification({
            type: 'error',
            toastContent: (err as any).error.data.failure_list?.[0]?.description,
          })
        }
      },
    }),
    getTransferClients: builder.query<GetTransferClientsResponse, GetTransferClientsRequest>({
      query: ({ client_id }) => ({
        url: `MRF/clients/${client_id}/transfer_client/list/`,
        method: 'GET',
      }),
      providesTags: ['TransferClients'],
    }),
    searchConsignment: builder.mutation<ConsignmentSearchResponse, SearchRequest>({
      query: ({ client_id, ...data }) => ({
        url: `MRF/clients/${client_id}/consignment/search/`,
        method: 'POST',
        data,
      }),
    }),
    getConsignmentHistory: builder.query<AllocationHistoryResponse, AllocationHistoryRequest>({
      query: ({ client_id, allocationId, ...data }) => ({
        url: `MRF/clients/${client_id}/consignment/${allocationId}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['History'],
    }),
    getConsignmentListByOrderIdExternal: builder.query<
      TableResponse<{}>,
      TableRequestV2External & { order_id: string }
    >({
      query: ({ client_external_id, order_id, ...data }) => ({
        url: `MRF/v2/external/${client_external_id}/consignment/list/?order_id=${order_id}`,
        method: 'POST',
        data,
      }),
      providesTags: ['WorkOrderListByOrderId'],
    }),
  }),
})
//TODO:change link path and type when the reports page is done

export const {
  useGetConsignmentListQuery,
  useGetConsignmentDetailsQuery,
  useGetConsignmentParcelsQuery,
  useUpdateConsignmentStatusMutation,
  useAddConsignmentMutation,
  useEditConsignmentMutation,
  useExportConsignmentListMutation,
  useGetDeliveryNoteQuery,
  useRequestDeliveryNoteMutation,
  useAddInventoryItemMutation,
  useDeleteConsignmentMutation,
  useGetTransferClientsQuery,
  useSearchConsignmentMutation,
  useGetConsignmentHistoryQuery,
  useLinkParcelToConsignmentMutation,
  useGetConsignmentListByOrderIdExternalQuery,
} = consignmentApi
