import { createApi } from '@reduxjs/toolkit/query/react'
import {
  AddOrderRequest,
  DeleteOrderPlanRequest,
  DeleteOrderPlanResponse,
  DeleteOrderRequest,
  DeleteOrderResponse,
  MutationResponse,
  OrderDetailRequest,
  OrderDetailResponse,
  OrderItemsRequest,
  OrderItemsResponse,
  OrderListRequest,
  OrderListResponse,
  OrderPlanDetailRequest,
  OrderPlanDetailResponse,
  OrderPlanListRequest,
  OrderPlanListResponse,
  BulkActionResponse,
  BulkDeleteOrderRequest,
  BulkDeleteOrderPlanRequest,
  SearchOrderResponse,
  SearchOrderPlanResponse,
  SearchOrderRequest,
  OrderDetailTransformedResponse,
  ExportRequest,
  OrderPlanDetailTransformedResponse,
  DynamicFieldsResponse,
  DynamicFieldsTransformedResponse,
  WorkOrdersResponse,
  WorkOrdersRequest,
  LinkWorkOrderRequest,
  CustomFieldEngResponse,
  CustomFieldsRequest,
  OrderItemListResponse,
  OrderItemListRequest,
  WorkOrdersRequestForDispute,
  WorkOrdersResponseForDispute,
  AddObjectionResponse,
  ObjectionListResponse,
  UnlinkWorkOrderRequest,
  UpdateStatusRequest,
  AddAdjustmentOrderItemRequest,
  AdjustmentOrderItemDetailsResponse,
  OrderItemMappingResponse,
  MismatchedOrderPlansType,
  OrderItem,
  ServiceExclusionsRequest,
  AvailableDatesRequest,
  AvailableDatesResponse,
  ServiceExclusionsResponse,
} from 'services/Engagement/types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { sendNotification } from 'components'
import { Coordinate, MAP_SHAPES } from 'components/Map/types'
import { getExportHandler, getImportHandler } from 'utils'
import i18n from 'utils/i18n'
import { TableRequestV2, TableResponse } from 'services/types'
import { entityApi } from './entity'
import { parcelApi } from 'services/MrfManagement/parcel'
import { taskApi } from 'services/OpsManagement'
import { consignmentApi } from 'services/MrfManagement/consignment'
import { inboundApi } from 'services/MrfManagement/inbound'
import { DropdownOptionTemplate } from 'types/common'
import { EngagementEnvironmentResponse } from 'services/environment'

export const orderApi = createApi({
  reducerPath: 'engagementOrderApi',
  tagTypes: [
    'Orders',
    'OrderPlans',
    'OrderDetails',
    'OrderPlanDetails',
    'OrderSearch',
    'WorkOrders',
    'ObjectionList',
    'OrderItems',
    'OrderHistory',
    'AdjustmentOrderItemDetail',
    'ServiceAvailability',
    'OrderCalendar',
    'OrderSettings',
    'AvailableDates',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/engagement/'}`,
  }),
  endpoints: (builder) => ({
    addOrder: builder.mutation<
      MutationResponse & { order_id: string },
      { client_id: string; body: FormData }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/order/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Orders', 'OrderHistory', 'OrderCalendar'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/orders/detail/${data.order_id}`,
            },
          })
          dispatch(entityApi.util.invalidateTags(['ServiceDays', 'ServiceDayDetails']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editOrder: builder.mutation<
      MutationResponse,
      { client_id: string; order_id: string; body: FormData }
    >({
      query: ({ client_id, order_id, body }) => ({
        url: `clients/${client_id}/order/${order_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Orders', 'OrderDetails', 'OrderHistory', 'OrderCalendar'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editOrderPlan: builder.mutation<
      MutationResponse & { order_plan_id: string },
      { client_id: string; order_plan_id: string; body: FormData }
    >({
      query: ({ client_id, order_plan_id, body }) => ({
        url: `clients/${client_id}/order_plan/${order_plan_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['OrderPlans', 'OrderPlanDetails', 'Orders', 'OrderItems', 'OrderCalendar'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/order_plans/detail/${data.order_plan_id}`,
            },
          })
          dispatch(entityApi.util.invalidateTags(['ServiceDays', 'ServiceDayDetails']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    addOrderPlan: builder.mutation<
      MutationResponse & { order_plan_id: string },
      { client_id: string; body: FormData }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/order_plan/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['OrderPlans', 'OrderCalendar'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/order_plans/detail/${data.order_plan_id}`,
            },
          })
          dispatch(entityApi.util.invalidateTags(['ServiceDays', 'ServiceDayDetails']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getOrders: builder.query<OrderListResponse, OrderListRequest>({
      query: ({ client_id, task_id, ...data }) => ({
        url: `clients/${client_id}/order/list/${task_id ? `?task_id=${task_id}` : ''}`,
        method: 'POST',
        data,
      }),
      providesTags: ['Orders'],
    }),
    getOrderPlans: builder.query<OrderPlanListResponse, OrderPlanListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_plan/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['OrderPlans'],
    }),
    getOrderItems: builder.query<OrderItemsResponse, OrderItemsRequest>({
      query: ({ client_id, list_order_items_type, list_order_items, ...data }) => ({
        url: `clients/${client_id}/${list_order_items_type}/${list_order_items}/`,
        method: 'POST',
        data,
      }),
      providesTags: ['OrderItems'],
    }),
    getOrderItemList: builder.query<OrderItemListResponse, OrderItemListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_item/list/`,
        method: 'POST',
        data,
      }),
    }),
    getOrderDetails: builder.query<OrderDetailTransformedResponse, OrderDetailRequest>({
      query: ({ clientId, orderId }) => ({
        url: `clients/${clientId}/order/?order_id=${orderId}`,
        method: 'GET',
      }),
      providesTags: ['OrderDetails'],
      transformResponse: ({
        location: { address, coordinates, type },
        ...rest
      }: OrderDetailResponse) => ({
        location: {
          coordinates: coordinates as Coordinate[],
          address: address as string,
          type: type.toLowerCase() as (typeof MAP_SHAPES)['single'],
        },
        ...rest,
      }),
    }),
    getOrderPlanDetails: builder.query<OrderPlanDetailTransformedResponse, OrderPlanDetailRequest>({
      query: ({ client_id, orderPlanId }) => ({
        url: `clients/${client_id}/order_plan/?order_plan_id=${orderPlanId}`,
        method: 'GET',
      }),
      providesTags: ['OrderPlanDetails'],
      transformResponse: ({
        location: { address, coordinates, type },
        ...rest
      }: OrderPlanDetailResponse) => ({
        location: {
          coordinates: coordinates as Coordinate[],
          address: address as string,
          type: type.toLowerCase() as (typeof MAP_SHAPES)['single'],
        },
        ...rest,
      }),
    }),
    deleteOrder: builder.mutation<DeleteOrderResponse, DeleteOrderRequest>({
      query: ({ client_id, order_id }) => ({
        url: `clients/${client_id}/order/${order_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Orders', 'OrderPlanDetails', 'OrderCalendar'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(entityApi.util.invalidateTags(['ServiceDays', 'ServiceDayDetails']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteOrderPlan: builder.mutation<DeleteOrderPlanResponse, DeleteOrderPlanRequest>({
      query: ({ client_id, order_plan_id, ...data }) => ({
        url: `clients/${client_id}/order_plan/${order_plan_id}/`,
        data: data,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrderPlans', 'OrderCalendar'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(entityApi.util.invalidateTags(['ServiceDays', 'ServiceDayDetails']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchOrder: builder.mutation<SearchOrderResponse, SearchOrderRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order/search/`,
        method: 'POST',
        data,
      }),
    }),
    searchOrderPlan: builder.mutation<SearchOrderPlanResponse, SearchOrderRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_plan/search/`,
        method: 'POST',
        data,
      }),
    }),
    bulkDeleteOrder: builder.mutation<BulkActionResponse, BulkDeleteOrderRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order/bulk_delete/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['Orders', 'OrderDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          if (data.failure_list.length === 0) {
            sendNotification({
              type: 'success',
              toastContent: data.detail.message,
            })
          } else if (data.successful_list.length === 0) {
            sendNotification({
              type: 'error',
              toastContent: data.detail.message,
            })
          } else {
            let failure_list_items = data.detail.message
            data.failure_list.forEach((item) => (failure_list_items += item.description))
            sendNotification({
              type: 'warning',
              toastContent: failure_list_items,
            })
          }

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    bulkDeleteOrderPlan: builder.mutation<BulkActionResponse, BulkDeleteOrderPlanRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_plan/bulk_delete/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['OrderPlans', 'OrderPlanDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          if (data.failure_list.length === 0) {
            sendNotification({
              type: 'success',
              toastContent: data.detail.message,
            })
          } else if (data.successful_list.length === 0) {
            sendNotification({
              type: 'error',
              toastContent: data.detail.message,
            })
          } else {
            let failure_list_items = data.detail.message
            data.failure_list.forEach((item) => (failure_list_items += item.description))
            sendNotification({
              type: 'warning',
              toastContent: failure_list_items,
            })
          }

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportOrder: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    exportOrderPlan: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_plan/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    getOrderDynamicFields: builder.query<DynamicFieldsTransformedResponse, CustomFieldsRequest>({
      query: ({ clientId, type_id }) => ({
        url: `clients/${clientId}/order_template_dynamic_fields_by_order_type/?order_type=${type_id}`,
        method: 'GET',
      }),
      transformResponse: ({ order_template_content_fields: data }: DynamicFieldsResponse) => {
        return data.map(({ field_type, ...rest }, index) => ({
          type: field_type,
          ...rest,
        }))
      },
    }),
    getWorkOrders: builder.query<WorkOrdersResponse, WorkOrdersRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/work_order/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['WorkOrders'],
    }),
    linkWorkOrder: builder.mutation<MutationResponse, LinkWorkOrderRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/work_order/create/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['WorkOrders'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          setTimeout(() => {
            dispatch(parcelApi.util.invalidateTags(['WorkOrderListByOrderId']))
            dispatch(taskApi.util.invalidateTags(['Tasks']))
            dispatch(consignmentApi.util.invalidateTags(['WorkOrderListByOrderId']))
            dispatch(inboundApi.util.invalidateTags(['WorkOrderListByOrderId']))
          }, 1000)
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    unlinkWorkOrder: builder.mutation<MutationResponse, UnlinkWorkOrderRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/work_order/unlink/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['OrderItems'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getWorkOrderForDispute: builder.query<
      WorkOrdersResponseForDispute,
      WorkOrdersRequestForDispute
    >({
      query: ({ client_id, order_id }) => ({
        url: `clients/${client_id}/order/${order_id}/list/work_orders/`,
        method: 'POST',
      }),
    }),
    addObjection: builder.mutation<
      AddObjectionResponse,
      {
        client_id: string
        body: FormData
      }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/objection/create/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['OrderDetails', 'ObjectionList', 'Orders'],
    }),
    getObjectionList: builder.query<ObjectionListResponse, { client_id: string; order_id: string }>(
      {
        query: ({ client_id, order_id }) => ({
          url: `clients/${client_id}/objection/list/?order_id=${order_id}`,
          method: 'GET',
        }),
        providesTags: ['ObjectionList'],
      },
    ),
    importOrders: builder.mutation<MutationResponse, { clientId: string; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/order/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Orders'],
      onQueryStarted: getImportHandler,
    }),
    importOrderPlans: builder.mutation<MutationResponse, { clientId: string; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/order_plan/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['OrderPlans'],
      onQueryStarted: getImportHandler,
    }),
    orderHistory: builder.query<
      TableResponse<{}>,
      TableRequestV2 & {
        order_id: string
      }
    >({
      query: ({ client_id, order_id, ...data }) => ({
        url: `clients/${client_id}/order/${order_id}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['OrderHistory'],
    }),
    updateOrderStatus: builder.mutation<MutationResponse, UpdateStatusRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/status/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Orders', 'OrderDetails', 'OrderPlans', 'OrderPlanDetails', 'OrderHistory'],
    }),
    addAdjustmentOrderItem: builder.mutation<MutationResponse, AddAdjustmentOrderItemRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_item_record/`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['OrderItems', 'OrderDetails'],
    }),
    editAdjustmentOrderItem: builder.mutation<MutationResponse, AddAdjustmentOrderItemRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_item_record/${data.order_item_id}/`,
        method: 'POST',
        data: data,
      }),
      invalidatesTags: ['OrderItems', 'AdjustmentOrderItemDetail', 'OrderDetails'],
    }),
    deleteAdjustmentOrderItem: builder.mutation<
      MutationResponse,
      { order_item_id: string; client_id: string }
    >({
      query: ({ client_id, order_item_id }) => ({
        url: `clients/${client_id}/order_item_record/${order_item_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrderItems', 'OrderDetails'],
    }),
    getAdjustmentOrderItemDetail: builder.query<
      AdjustmentOrderItemDetailsResponse,
      { client_id: string; order_item_id: string }
    >({
      query: ({ client_id, order_item_id }) => ({
        url: `clients/${client_id}/order_item_record/?order_item_record_id=${order_item_id}`,
        method: 'GET',
      }),
      providesTags: ['AdjustmentOrderItemDetail'],
    }),
    getOrderItemMapping: builder.query<
      OrderItemMappingResponse,
      { client_id: string; order_type_id: string }
    >({
      query: ({ client_id, order_type_id }) => ({
        url: `clients/${client_id}/order_item/list_external_items/`,
        method: 'POST',
        data: {
          order_type_id,
        },
      }),
      providesTags: ['AdjustmentOrderItemDetail'],
    }),
    getMismatchedOrderPlans: builder.query<
      { detail: { message: string }; order_plans: MismatchedOrderPlansType },
      {
        client_id: string
        service_point_id?: string
        entity_id?: string
        order_settings: Array<{ order_type: string; order_items: Array<string> }>
      }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/order_plan/check_mismatched_order_plans/`,
        method: 'POST',
        data,
      }),
    }),
    updateOrderNote: builder.mutation<
      MutationResponse,
      { clientId: string; orderId: string; note?: string }
    >({
      query: ({ clientId, orderId, ...data }) => ({
        url: `clients/${clientId}/order/${orderId}/note/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
      invalidatesTags: ['Orders'],
    }),
    addExclusion: builder.mutation<
      MutationResponse,
      {
        client_id: string
        body: {
          name: string
          entity_id?: string
          order_type_id?: string
          service_point_id?: string
          date_ranges?: Array<{ start: string; end: string }>
          dates?: Array<string>
        }
      }
    >({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/service_exclusion/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['ServiceAvailability'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteExclusion: builder.mutation<
      MutationResponse,
      {
        client_id: string
        exclusion_id: string
      }
    >({
      query: ({ client_id, exclusion_id }) => ({
        url: `clients/${client_id}/service_exclusion/${exclusion_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['ServiceAvailability'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editExclusion: builder.mutation<
      MutationResponse,
      {
        client_id: string
        exclusion_id: string
        body: {
          name: string
          entity_id?: string
          order_type_id?: string
          service_point_id?: string
          date_ranges?: Array<{ start: string; end: string }>
          dates?: Array<string>
          deleted_date_ids?: Array<string>
        }
      }
    >({
      query: ({ client_id, exclusion_id, body }) => ({
        url: `clients/${client_id}/service_exclusion/${exclusion_id}/`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['ServiceAvailability'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    serviceExclusions: builder.query<ServiceExclusionsResponse, ServiceExclusionsRequest>({
      query: ({ client_id, ...rest }) => ({
        url: `clients/${client_id}/service_exclusion/exclusions/`,
        method: 'POST',
        data: rest,
      }),
      providesTags: ['ServiceAvailability'],
    }),
    availableDates: builder.query<AvailableDatesResponse, AvailableDatesRequest>({
      query: ({ client_id, ...rest }) => ({
        url: `clients/${client_id}/service_exclusion/available_dates/`,
        method: 'POST',
        data: rest,
      }),
      providesTags: ['AvailableDates'],
    }),
    orderCalendarList: builder.query<
      {
        orders: Array<{
          date: string
          orders: Array<DropdownOptionTemplate<string>[0] & { is_planned: boolean }>
        }>
      },
      ServiceExclusionsRequest
    >({
      query: ({ client_id, ...rest }) => ({
        url: `clients/${client_id}/order/monthly_order_list/`,
        method: 'POST',
        data: rest,
      }),
      providesTags: ['OrderCalendar'],
    }),
    addBulkOrder: builder.mutation<MutationResponse, { client_id: string; body: FormData }>({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/order/bulk_create/`,
        method: 'POST',
        data: body,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
      invalidatesTags: ['OrderCalendar', 'Orders'],
    }),
    getOrderSettings: builder.query<
      {
        order_types: EngagementEnvironmentResponse['order_types']

        order_items: Array<OrderItem & { order_type_id: string }>
      },
      {
        client_id: string
        service_point_id?: string
        entity_id?: string
      }
    >({
      query: ({ client_id, entity_id, service_point_id }) => ({
        url: `clients/${client_id}/order_settings/`,
        method: 'GET',
        params: {
          entity_id,
          service_point_id,
        },
      }),
      providesTags: ['OrderSettings'],
    }),
  }),
})

export const {
  useGetOrdersQuery,
  useGetOrderPlansQuery,
  useDeleteOrderMutation,
  useDeleteOrderPlanMutation,
  useAddOrderMutation,
  useEditOrderMutation,
  useAddOrderPlanMutation,
  useGetOrderDetailsQuery,
  useGetOrderItemsQuery,
  useBulkDeleteOrderMutation,
  useBulkDeleteOrderPlanMutation,
  useGetOrderPlanDetailsQuery,
  useSearchOrderMutation,
  useEditOrderPlanMutation,
  useSearchOrderPlanMutation,
  useExportOrderMutation,
  useExportOrderPlanMutation,
  useGetOrderDynamicFieldsQuery,
  useGetWorkOrdersQuery,
  useLinkWorkOrderMutation,
  useGetOrderItemListQuery,
  useGetWorkOrderForDisputeQuery,
  useAddObjectionMutation,
  useGetObjectionListQuery,
  useUnlinkWorkOrderMutation,
  useImportOrdersMutation,
  useImportOrderPlansMutation,
  useOrderHistoryQuery,
  useUpdateOrderStatusMutation,
  useAddAdjustmentOrderItemMutation,
  useEditAdjustmentOrderItemMutation,
  useDeleteAdjustmentOrderItemMutation,
  useGetAdjustmentOrderItemDetailQuery,
  useGetOrderItemMappingQuery,
  useGetMismatchedOrderPlansQuery,
  useUpdateOrderNoteMutation,
  useAddBulkOrderMutation,
  useAddExclusionMutation,
  useServiceExclusionsQuery,
  useOrderCalendarListQuery,
  useDeleteExclusionMutation,
  useEditExclusionMutation,
  useGetOrderSettingsQuery,
  useLazyGetMismatchedOrderPlansQuery,
  useAvailableDatesQuery,
} = orderApi
