import { NavigationCommandType } from 'containers/Command/types'

export const eventRoutes: Array<NavigationCommandType> = [
  {
    moduleId: 'event',
    submoduleId: 'event',
    commandId: 'list-page',
    to: '/events',
    type: 'navigation',
    label: 'pages.event_management.title',
  },
]
