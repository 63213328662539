import React from 'react'
import { Button, Flex, Icon, InputPassword, Typography } from 'components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'utils/useTranslation'
import LoginLayout from './LoginLayout'
import { useChangePasswordRecoveryMutation } from 'services/auth'
import { useCheckPasswordValidityAndMatch } from 'utils/useCheckPasswordValidityAndMatch'
import { UilCheckCircle } from '@iconscout/react-unicons'

type ChangePasswordRecoveryProps = {}

//TODO: make validation passowrd
const schema = yup.object().shape({
  new_password: yup.string().required(),
  confirm_new_password: yup.string().required(),
})

type FormInputs = {
  new_password: string
  confirm_new_password: string
}

const ChangePasswordRecovery: React.FC<
  React.PropsWithChildren<ChangePasswordRecoveryProps>
> = () => {
  const navigate = useNavigate()

  const params = useParams()
  const token = params.token!
  const { t } = useTranslation('pages.authentication')
  const { t: tHelperText } = useTranslation(
    'pages.common.profile.change_password_modal.helper_text',
  )
  const [changePassword] = useChangePasswordRecoveryMutation()

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })

  const watchPasswordValue = watch('new_password')
  const watchConfirmPasswordValue = watch('confirm_new_password')

  const { doPasswordsMatch, passwordHelperText } = useCheckPasswordValidityAndMatch({
    compare: {
      base: watchPasswordValue,
      confirm: watchConfirmPasswordValue,
    },
    helperText: {
      at_least_one_symbol: tHelperText('at_least_one_symbol'),
      at_least_one_number: tHelperText('at_least_one_number'),
      at_least_one_uppercase: tHelperText('at_least_one_uppercase'),
      min_length: tHelperText('min_length'),
    },
  })

  const onSubmit = async (data: FormInputs) => {
    if (!token || !doPasswordsMatch) {
      return
    }
    const { new_password } = data
    changePassword({
      new_password,
      token,
    })
      .unwrap()
      .then(() => navigate('/login'))
  }

  return (
    <LoginLayout title={t('new_password')} subtitle={t('create_new_password')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={1} directionColumn="zero">
          <Controller
            render={({ field }) => (
              <InputPassword {...field} label={t('new_password')} error={!!errors.new_password} />
            )}
            control={control}
            name="new_password"
          />
          <Controller
            render={({ field }) => (
              <InputPassword
                {...field}
                label={t('confirm_new_password')}
                error={!!errors.confirm_new_password}
              />
            )}
            control={control}
            name="confirm_new_password"
          />
          {passwordHelperText.map((helperText, idx) => (
            <Flex key={helperText.id || idx} middle="sm" gap={1}>
              <Icon
                color={helperText.rule ? 'green500' : 'darkblue900'}
                icon={UilCheckCircle}
                size={20}
              />
              <Typography color={helperText.rule ? 'green500' : 'darkblue900'} variant="span">
                {helperText.text}
              </Typography>
            </Flex>
          ))}
          <Button disabled={!doPasswordsMatch} type="submit" variant="filled" color="primary">
            {t('change_my_password')}
          </Button>
        </Flex>
      </form>
    </LoginLayout>
  )
}

export default ChangePasswordRecovery
