import { borderRadius, color, padding, shadow } from '@evrekadev/evreka-ui-components'
import { Flex } from 'components'
import { Popup } from 'react-leaflet'
import styled from 'styled-components'
import { PopupProps } from './MarkerPopup'

export const MarkerPopupStyled = styled(Popup)<Pick<PopupProps, 'size'>>`
  p {
    margin: 0;
  }

  & > .leaflet-popup-content-wrapper {
    overflow: hidden;
    ${borderRadius(12)}
    ${shadow('xl')}
    padding: 0;
    background-color: ${color('white')};
    display: flex;
    flex-direction: column;
    max-height: ${({ size }) => (size === 'small' ? '16rem' : '20rem')};

    & > .leaflet-popup-content {
      display: flex;
      flex-direction: column;
    }
  }

  #action-menu-button {
    & > div {
      height: unset;
    }

    & > div > button {
      ${padding([2])}
    }
  }
`
export const MarkerPopupHeader = styled(Flex)`
  ${padding({
    t: 12,
    b: 12,
    l: 12,
    r: 8,
  })}
`
export const MarkerPopupContent = styled.div`
  ${padding([12])}
  overflow: auto;
`
