import { Typography, borderRadius, color, padding, shadow } from '@evrekadev/evreka-ui-components'
import { UilSearch } from '@iconscout/react-unicons'
import { Icon } from 'components'
import styled from 'styled-components'
import { SidebarBaseProps, SidebarColorTheme } from './Sidebar'
import { semanticSidebarColors } from './Sidebar.style'
import { useSelector } from 'react-redux'
import { selectUi } from 'services/slices/ui'
import { useEffect, useState } from 'react'
import { Command } from 'containers/Command/Command'

export const CommandTriggerStyled = styled.div<Pick<SidebarBaseProps, 'sidebarCollapsed'>>`
  ${padding([6, 8])}
  ${borderRadius(8)}
  ${({ sidebarCollapsed }) => !sidebarCollapsed && shadow('xs')}
  border: 1px solid ${({ sidebarCollapsed }) =>
    color(sidebarCollapsed ? 'transparent' : 'grey300')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
  background-color: ${({ sidebarCollapsed }) => color(sidebarCollapsed ? 'transparent' : 'white')};

  &:hover {
    border-color: ${({ sidebarCollapsed }) => color(sidebarCollapsed ? 'transparent' : 'grey400')};
  }
`

export const KeyboardShortcutStyled = styled.div<Pick<SidebarBaseProps, 'sidebarCollapsed'>>`
  ${padding([2, 4])}
  ${borderRadius(4)}
  background-color:${color('white')};
  border: 1px solid ${color('grey100')};
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};
  transition: opacity 0.2s ease-in-out;
`

export const CommandTrigger: React.FC = () => {
  const { sidebarCollapsed, sidebarTheme } = useSelector(selectUi)
  const [open, setOpen] = useState(false)

  const toggleOpen = () => {
    setOpen((open) => !open)
  }

  // Toggle the menu when ⌘K is pressed
  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'b' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault()
        toggleOpen()
      }
    }

    document.addEventListener('keydown', down)
    return () => document.removeEventListener('keydown', down)
  }, [])

  return (
    <>
      <CommandTriggerStyled sidebarCollapsed={sidebarCollapsed} onClick={toggleOpen}>
        <Icon
          icon={UilSearch}
          size={20}
          color={semanticSidebarColors['text-muted'][sidebarTheme]}
          style={{
            flexShrink: 0,
          }}
        />
        <KeyboardShortcutStyled sidebarCollapsed={sidebarCollapsed}>
          <Typography fontSize="text_xs" fontWeight="medium" color="grey500">
            ⌘B
          </Typography>
        </KeyboardShortcutStyled>
      </CommandTriggerStyled>
      <Command open={open} setOpen={setOpen} />
    </>
  )
}
