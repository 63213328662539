import React, { useContext, useMemo, useState } from 'react'
import { UilFilter, UilListUl, UilSetting } from '@iconscout/react-unicons'
import { ExtraMapHeaderButtons, InfoAreaType, MapContextType } from 'components/Map/types'
import { HeaderButtonsType, TableMapHeader as MapHeader } from '@evrekadev/evreka-ui-components'
import { useTranslation } from 'utils/useTranslation'
import { MapSettingsModal } from './MapSettingsModal'
import { MapHeaderStyled } from './Map.style'
import { MapContext } from 'context'

export type MapControlProps = {
  onInfoAreaChange?: (changeType: InfoAreaType['type']) => void
}

export const MapControl: React.FC<React.PropsWithChildren<MapControlProps>> = ({
  onInfoAreaChange,
}) => {
  const { t } = useTranslation('components.map')

  const {
    heading,
    mapCaptionButtonElement,
    backToListView,
    columns,
    isCustomFilter,
    extraMapHeaderButtons,
    settings,
    onChangeSettings,
    customFilterArea,
    isEnabledBulkSelection,
    bulkActions,
    onClearSelection,
    mapCaptionButtons,
    onSelectAll,
    selectedMarkers,
    markers,
    toggle,
    refresh,
  } = useContext<MapContextType>(MapContext)

  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(
    settings?.isModalOpen || false,
  )

  const handleFilterToggle = () => {
    onInfoAreaChange && onInfoAreaChange('filter')
  }

  const handleSettingsToggle = () => {
    setIsSettingsVisible(!isSettingsVisible)

    if (settings) {
      onChangeSettings?.({
        ...settings,
        isModalOpen: !isSettingsVisible,
      })
    }
  }

  const extraMapButtons: HeaderButtonsType = useMemo(() => {
    let mapButtons: HeaderButtonsType = []

    backToListView &&
      mapButtons.push({
        label: t('list_view'),
        icon: UilListUl,
        onClick: backToListView,
      })

    if (columns || (customFilterArea && !isCustomFilter)) {
      mapButtons.unshift({
        label: t('filters'),
        icon: UilFilter,
        onClick: handleFilterToggle,
      })
    }

    // settings &&
    //   mapButtons.unshift({
    //     label: t('settings.title'),
    //     icon: UilSetting,
    //     onClick: handleSettingsToggle,
    //   })

    if (extraMapHeaderButtons) {
      let leftExtraButtons: ExtraMapHeaderButtons = []
      let rightExtraButtons: ExtraMapHeaderButtons = []
      extraMapHeaderButtons.forEach((button) =>
        button.placement === 'left'
          ? leftExtraButtons.push(button)
          : rightExtraButtons.push(button),
      )

      leftExtraButtons && mapButtons.unshift(...leftExtraButtons)
      rightExtraButtons && mapButtons.push(...rightExtraButtons)
    }

    return mapButtons
  }, [
    extraMapHeaderButtons,
    onInfoAreaChange,
    backToListView,
    columns,
    customFilterArea,
    isCustomFilter,
  ])

  return (
    <MapHeaderStyled>
      <MapHeader
        caption={heading}
        tableCaptionButtonElement={mapCaptionButtonElement}
        extraButtons={extraMapButtons}
        bulkMode={isEnabledBulkSelection}
        bulkActions={bulkActions}
        onClearSelection={onClearSelection}
        onSelectAll={onSelectAll}
        tableCaptionButtons={mapCaptionButtons}
        selectedItemCount={selectedMarkers?.length}
        totalCount={markers?.filter((m) => m.selectable)?.length}
        toggle={toggle}
        settings={settings && handleSettingsToggle}
        refresh={refresh}
      />
      {settings && <MapSettingsModal isOpen={isSettingsVisible} onClose={handleSettingsToggle} />}
    </MapHeaderStyled>
  )
}
