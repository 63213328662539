import { sendNotification } from '../components'

export const getExportHandler = async (_id: any, { queryFulfilled }: any) => {
  try {
    const { data } = await queryFulfilled

    sendNotification({
      type: 'success',
      toastContent: data.detail.message,
      toastOptions: { autoClose: false },
      hyperLink: {
        type: 'newTab',
        label: 'reports',
        to: '/reports/',
        isExternalLink: false,
      },
    })
  } catch (err) {}
}

export const getImportHandler = async (_id: any, { queryFulfilled }: any) => {
  try {
    const { data } = await queryFulfilled

    sendNotification({
      type: 'success',
      toastContent: data.detail.message,
      toastOptions: { autoClose: false },
      hyperLink: {
        type: 'newTab',
        label: 'imports',
        to: '/reports/1',
        isExternalLink: false,
      },
    })
  } catch (err) {}
}
