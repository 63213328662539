import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {
  FilterObjectType,
  generateUrlFilterParams,
  parseFilterParams,
} from '@evrekadev/evreka-ui-components'

export type URLMapParams = {
  filterBy?: string
}

export function useInternalMapParams(
  name: string | null,
  filterBy: string,
  readOnly?: boolean,
): {
  filterByValues: FilterObjectType
  setFilterBy: Dispatch<SetStateAction<FilterObjectType>>
  searchParams: URLSearchParams
  setSearchParams: ({ filterBy }: URLMapParams) => void
} {
  const filterByName = `${name}_filterBy`

  const [searchParams, setSearchParamsLocal] = useSearchParams(
    readOnly
      ? {}
      : {
          [filterByName]: filterBy,
        },
  )

  const [filterByValues, setFilterBy] = useState<FilterObjectType>(() => {
    const gFilter = searchParams.get(filterByName) ?? ''
    return parseFilterParams(gFilter)
  })

  useEffect(() => {
    name &&
      setSearchParams({
        filterBy: generateUrlFilterParams(filterByValues),
      })
  }, [])

  const setSearchParams = ({ filterBy }: URLMapParams) => {
    if (readOnly) {
      return
    }
    let filterByV: string
    if (filterBy === undefined) {
      filterByV = searchParams.get(filterByName) ?? ''
    } else if (filterBy === '') {
      filterByV = ''
    } else {
      filterByV = filterBy
    }

    setSearchParamsLocal({
      [filterByName]: filterByV,
    })
  }

  return {
    filterByValues,
    searchParams,
    setFilterBy,
    setSearchParams,
  }
}
