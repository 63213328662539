import { NavigationCommandType } from 'containers/Command/types'

export const documentsRoutes: Array<NavigationCommandType> = [
  {
    moduleId: 'documents',
    commandId: 'list-page',
    submoduleId: 'documents',
    type: 'navigation',
    to: '/document_list',
    label: 'pages.document_management.title',
  },
]
