import { createSelector, createSlice } from '@reduxjs/toolkit'
import { IconNames } from '@evrekadev/evreka-ui-components'
import { authApi } from 'services/auth'
import { commonApi } from 'services/common'
import { RootState } from '../store'

export type UserType = {
  email: string
  username: string
  account_type: 'ADMIN' | 'USER_ADMIN' | 'USER'
  clients: { label: string; value: number; timezone: string }[]
  default_language: string
  account_name: string
  as_client_id: number
  currentUserName?: string
  user_id: number
  partner_logo: string | null
  permissions: {
    edit_dump_notif_perm: boolean
  }
  customer_portal_credentials?: {
    client_id?: string
    entity_id?: string
  }
  language_culture?: string
}

export type SubMenu = {
  title: string
  link: string
  id: string | number
  redirect_react: boolean
}

export interface MenuItem {
  title: string
  link?: string
  subMenus?: SubMenu[]
  icon: IconNames
  id?: string
  redirect_react: boolean
}

export type MenuItems = MenuItem[]

export type AnnouncementType = 'customer_portal' | 'dashboard'
export type BannerType = {
  id: string
  is_dismissible?: boolean
  title: string
  text?: string
  type: 'product_update' | 'release_info' | 'payment'
  variant: 'INFO' | 'WARNING' | 'ERROR'
}
export type AuthState = {
  auth_routes: string[]
  user: UserType | null
  sidebar: MenuItems | null
  currentLanguage: string
  announcements: Record<AnnouncementType, Array<BannerType>>
  available_modules: {
    asset: boolean
    engagement: boolean
    fleet: boolean
    mrf: boolean
    ops: boolean
  }
  timezone: string
  //token: null | string toggle this line on client side auth.
  //TODO remve data key
}
//TODO persist store via persister
const userValue = window.localStorage.getItem('user')
const user = userValue && userValue !== 'undefined' ? (JSON.parse(userValue) as UserType) : null
const auth_routesValue = window.localStorage.getItem('auth_routes')
const auth_routes =
  auth_routesValue && auth_routesValue !== 'undefined'
    ? (JSON.parse(auth_routesValue) as string[])
    : null
const currentLanguageValue = window.localStorage.getItem('current_language')
const currentLanguage =
  currentLanguageValue && JSON.parse(currentLanguageValue) ? JSON.parse(currentLanguageValue) : 'en'

const currentTimezoneValue = window.localStorage.getItem('timezone')
const timezone =
  currentTimezoneValue && JSON.parse(currentTimezoneValue) ? JSON.parse(currentTimezoneValue) : ''

const initialState: AuthState = {
  user: user ? user : null,
  sidebar: [],
  auth_routes: auth_routes ? auth_routes : [],
  currentLanguage,
  announcements: { customer_portal: [], dashboard: [] },
  available_modules: {
    asset: false,
    engagement: false,
    fleet: false,
    mrf: false,
    ops: false,
  },
  timezone: timezone,
}

const removeDialect = (language?: string) => {
  if (!language || !language.includes('-')) {
    return null
  }

  return language.split('-')[0]
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.getAuth.matchFulfilled, (state, { payload }) => {
      const data = payload

      const language = removeDialect(data.user?.default_language) ?? state.currentLanguage
      //state.token = data.token; toggle this line on client side auth.
      state.user = data.user
      state.sidebar = data.sidebar
      state.auth_routes = data.auth_routes
      state.currentLanguage = language
      state.available_modules = data.available_modules
      state.timezone = data.timezone
      if (state.user) {
        state.user.currentUserName = ''
      }

      localStorage.setItem('user', JSON.stringify(data.user))
      localStorage.setItem('auth_routes', JSON.stringify(data.auth_routes))
      localStorage.setItem('current_language', JSON.stringify(language))
      localStorage.setItem('timezone', JSON.stringify(data.timezone))
      //window.location.reload()
    })
    builder.addMatcher(authApi.endpoints.getAuthWithUser.matchFulfilled, (state, { payload }) => {
      //state.token = payload.result.token; toggle this line on client side auth.
      const data = payload
      const language = removeDialect(data.user?.default_language) || state.currentLanguage
      state.user = data.user
      state.sidebar = data.sidebar
      state.auth_routes = data.auth_routes
      state.currentLanguage = language
      state.available_modules = data.available_modules
      state.timezone = data.timezone
      if (state.user && data.user) {
        const currentUserNameIndex = data.user?.clients.findIndex(
          (client) => Number(client.value) === Number(data.user?.as_client_id),
        )
        state.user.currentUserName =
          currentUserNameIndex > -1
            ? data.user.clients[currentUserNameIndex]['label'].toString()
            : ''
      }
      localStorage.setItem('user', JSON.stringify(data.user))
      localStorage.setItem('auth_routes', JSON.stringify(data.auth_routes))
      localStorage.setItem('current_language', JSON.stringify(language))
      localStorage.setItem('timezone', JSON.stringify(data.timezone))

      //window.location.reload()
    })
    builder.addMatcher(commonApi.endpoints.setLanguage.matchFulfilled, (state, { payload }) => {
      const language = payload.language || state.currentLanguage
      if (state.user) {
        state.user.default_language = language
      }
      state.currentLanguage = language
      localStorage.setItem('current_language', JSON.stringify(language))

      // window.location.reload()
    })
    //TODO logout it is not restful,get help from be - change to matchFulfilled
    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state, { payload }) => {
      state = initialState
      window.localStorage.removeItem('user')
      window.localStorage.removeItem('auth_routes')
      window.localStorage.removeItem('current_language')
      window.localStorage.removeItem('timezone')
    })
  },
})

export default authSlice.reducer

const authState = (state: RootState) => state.auth
export const selectCurrentUser = createSelector(authState, ({ user }) => user)
export const selectAuthRoutes = createSelector(authState, ({ auth_routes }) => auth_routes)
export const selectMenu = createSelector(authState, ({ sidebar }) => sidebar)
export const selectCurrentLanguage = createSelector(
  authState,
  ({ currentLanguage }) => currentLanguage,
)
export const selectCurrentTimezone = createSelector(authState, ({ timezone }) => timezone)
export const selectClientId = createSelector(authState, ({ user }) => user?.as_client_id)
export const selectClientModules = createSelector(
  authState,
  ({ available_modules }) => available_modules,
)
export const selectPermissions = createSelector(authState, ({ user }) => user?.permissions)
