import React, { useEffect } from 'react'
import useWebSocket from 'react-use-websocket'
import { TypeOptions } from 'react-toastify'

export type WebsocketToastMessage = {
  type: 'toast'
  data: {
    type: TypeOptions
    title: string
    description: string
    link: {
      label: string
      value: string
      target: '_blank'
    }
  }
}

export type WebsocketResultModalMessage = {
  type: 'result-modal'
  data: {
    title: string
    success_message?: string
    failure_message?: string
    success_list: Array<{ value: string; message: string }>
    failure_list: Array<{ value: string; reason: string }>
  }
}

export type WebsocketMessage = WebsocketToastMessage | WebsocketResultModalMessage

type WebsocketConsumerProps = {
  url: string
  onMessage: (message: WebsocketMessage) => void
}

export const WebsocketConsumer: React.FC<React.PropsWithChildren<WebsocketConsumerProps>> = ({
  url,
  onMessage,
  children,
}) => {
  const { lastJsonMessage } = useWebSocket(url)

  useEffect(() => {
    if (lastJsonMessage) {
      onMessage(lastJsonMessage as any)
    }
  }, [lastJsonMessage, onMessage])

  return <>{children}</>
}
