import React, { useEffect, useState } from 'react'
import { InputText } from 'components'
import { ColumnExtended } from '@evrekadev/evreka-ui-components'
import { useTranslation } from 'utils/useTranslation'

type DefaultColumnFilterProps = {
  column: ColumnExtended<any>
  filterValue: string
  onChange: (id: string, value: string | string[]) => void
  type: string
}

export function DefaultColumnFilter({
  column: { id },
  onChange,
  filterValue,
}: DefaultColumnFilterProps) {
  const { t } = useTranslation()
  const [value, setValue] = useState(filterValue || '')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setValue('')
    } else {
      setValue(event.target.value)
    }
    onChange && onChange(id, event.target.value)
  }

  // ensure that reset loads the new value
  useEffect(() => {
    setValue(filterValue || '')
  }, [filterValue])

  return (
    <InputText
      placeholder={t('placeholder.default.search')}
      onChange={handleChange}
      componentSize="sm"
      value={value}
      clearButton
      data-testid={'filter-field-' + id}
    />
  )
}
