import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { mrfEnvironmentApi, engagementEnvironmentApi } from 'services/environment'
import { selectClientId, selectClientModules } from 'services/slices/auth'

const { useGetEnvironmentQuery: useGetEnvironmentEngagementQuery } = engagementEnvironmentApi
const { useGetEnvironmentQuery: useGetEnvironmentMrfQuery } = mrfEnvironmentApi

//paths that need env, checks with startsWith, so get the path from the beginning
const mrfEnvPaths = ['/mrf_management', '/ops_management/tasks/']
const engEnvPaths = ['/engagement', '/ops_management/tasks/']

const shouldEnvLoad = (
  module: 'eng' | 'mrf',
  pathName: string,
  isModuleUse: boolean,
  clientId?: number,
) => {
  let result = false
  const currentPathArr = module === 'mrf' ? mrfEnvPaths : engEnvPaths

  currentPathArr.forEach((path) => {
    if (pathName.startsWith(path) && isModuleUse && Number(clientId) !== 0) {
      result = true
    }
  })
  return result
}

export const EnvironmentProvider: React.FC<{}> = () => {
  const { pathname } = useLocation()
  const clientId = useSelector(selectClientId)
  const { mrf, engagement } = useSelector(selectClientModules)
  const { data: envEng } = useGetEnvironmentEngagementQuery(
    shouldEnvLoad('eng', pathname, engagement, clientId) ? clientId : skipToken,
  )
  const { data: envMrf } = useGetEnvironmentMrfQuery(
    shouldEnvLoad('mrf', pathname, mrf, clientId) ? clientId : skipToken,
  )

  return null
}
