import { margin, borderRadius, color, padding } from '@evrekadev/evreka-ui-components'
import { Flex } from 'components'
import styled from 'styled-components'

export const WrapperFlex = styled(Flex)`
  ${margin([12, 0])};
  display: flex;
  border: 1px solid ${color('grey300')};
  ${borderRadius(8)}
  & :not(:last-child) {
    border-right: 1px solid ${color('grey300')};
  }
  & div:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  & div:first-child {
    border-top-left-radius: ${(props) => props.theme.borderRadiuses[8]};
    border-bottom-left-radius: ${(props) => props.theme.borderRadiuses[8]};
  }
`

export const StyledDiv = styled.div<{ selected?: boolean }>`
  ${padding([6, 12])};
  cursor: pointer;
  &:hover {
    background-color: ${color('grey50')};
  }
  background-color: ${(props) => (props.selected ? color('grey50') : color('white'))};
`
