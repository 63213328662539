import { createApi } from '@reduxjs/toolkit/query/react'
import { ExportRequest, MutationResponse } from 'services/OpsManagement/types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { TableResponse, TableRequest } from 'services/types'
import { getExportHandler } from 'utils'

export const activityApi = createApi({
  reducerPath: 'activityApi',
  tagTypes: ['Activities'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/asset/'}`,
  }),
  endpoints: (builder) => ({
    getActivitiesList: builder.query<TableResponse<any>, TableRequest & { asset_id?: number }>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/activities/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Activities'],
    }),
    exportActivities: builder.mutation<MutationResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/export_activities/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
  }),
})

export const { useGetActivitiesListQuery, useExportActivitiesMutation } = activityApi
