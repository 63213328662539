import styled from 'styled-components'
import { color, padding } from '@evrekadev/evreka-ui-components'
import { Flex } from 'components'

export const DividerStyled = styled.div`
  height: 100%;
  border-right: 0.5px solid ${color('grey300')};
`
export const LayoutListWrapper = styled(Flex)`
  height: 100vh;
`
export const LayoutListChildWrapper = styled.div`
  ${padding([16])}
  flex-grow: 1;
  overflow: auto;
`
