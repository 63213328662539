import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const UndergroundContainerIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <path
        d="M25.5368 15.2498H23.1639V14.4589C23.1639 13.8295 22.9139 13.226 22.4689 12.7809C22.0239 12.3359 21.4203 12.0859 20.791 12.0859H19.209C18.5797 12.0859 17.9761 12.3359 17.5311 12.7809C17.0861 13.226 16.8361 13.8295 16.8361 14.4589V15.2498H14.4632C14.2534 15.2498 14.0522 15.3332 13.9039 15.4815C13.7556 15.6298 13.6722 15.831 13.6722 16.0408C13.6722 16.2506 13.7556 16.4518 13.9039 16.6001C14.0522 16.7484 14.2534 16.8318 14.4632 16.8318L14.4632 20.0859H25.5368V16.8318C25.7466 16.8318 25.9478 16.7484 26.0961 16.6001C26.2444 16.4518 26.3278 16.2506 26.3278 16.0408C26.3278 15.831 26.2444 15.6298 26.0961 15.4815C25.9478 15.3332 25.7466 15.2498 25.5368 15.2498ZM18.6497 13.8996C18.5014 14.0479 18.418 14.2491 18.418 14.4589V15.2498H21.5819V14.4589C21.5819 14.2491 21.4986 14.0479 21.3503 13.8996C21.2019 13.7512 21.0007 13.6679 20.791 13.6679H19.209C18.9992 13.6679 18.798 13.7512 18.6497 13.8996Z"
        fill={isSelected ? '#fff' : color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path d="M12 20.4844H28" stroke={isSelected ? '#fff' : color} strokeLinecap="round" />
      <mask
        id="mask0_5563_108189"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="14"
        y="17"
        width="12"
        height="11"
      >
        <rect x="14.4648" y="17.1719" width="11.0664" height="10.3838" rx="1" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_5563_108189)">
        <path
          d="M8.40826 27.5557L15.4793 20.4846"
          stroke={isSelected ? '#fff' : color}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M12.4793 27.5557L19.5504 20.4846"
          stroke={isSelected ? '#fff' : color}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M16.5504 27.5557L23.6214 20.4846"
          stroke={isSelected ? '#fff' : color}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M20.6214 27.5557L27.6925 20.4846"
          stroke={isSelected ? '#fff' : color}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
        <path
          d="M24.6924 27.5557L31.7635 20.4846"
          stroke={isSelected ? '#fff' : color}
          strokeWidth="1.5"
          strokeLinecap="square"
        />
      </g>
    </AssetIconWrapperStyled>
  )
}

export default UndergroundContainerIcon
