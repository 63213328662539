import { sendNotification } from 'components'
import { createApi } from '@reduxjs/toolkit/dist/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { TableRequestV3, TableResponse } from 'services/types'
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  CheckUsernameRequest,
  CheckUsernameResponse,
  CreateUserRequest,
  CreateUserResponse,
  DeleteUserRequest,
  DeleteUserResponse,
  GetUserPermissionsRequest,
  GetUserPermissionsResponse,
  UpdateProfileRequest,
  UpdateProfileResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  UserDetailsRequest,
  UserDetailsResponse,
} from './types'

export const usersApi = createApi({
  reducerPath: 'usersApi',
  tagTypes: ['Users', 'User Detail'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/user`,
  }),
  endpoints: (builder) => ({
    getUsers: builder.query<TableResponse<{}>, TableRequestV3>({
      query: ({ clientId, ...data }) => ({
        url: `/clients/${clientId}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Users', 'User Detail'],
    }),
    deleteUser: builder.mutation<DeleteUserResponse, DeleteUserRequest>({
      query: ({ clientId, ...data }) => ({
        url: `/clients/${clientId}/delete/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query: ({ clientId, ...data }) => ({
        url: `/clients/${clientId}/create/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateUser: builder.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: ({ clientId, ...data }) => ({
        url: `/clients/${clientId}/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users', 'User Detail'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getUserDetails: builder.query<UserDetailsResponse, UserDetailsRequest>({
      query: ({ clientId, userId }) => ({
        url: `/clients/${clientId}/detail/${userId}/`,
        method: 'GET',
      }),
    }),
    getUserPermissions: builder.query<GetUserPermissionsResponse, GetUserPermissionsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `/permissions/`,
        method: 'GET',
        data,
      }),
    }),
    changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
      query: ({ ...data }) => ({
        url: `/change_password/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users', 'User Detail'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    updateProfile: builder.mutation<UpdateProfileResponse, UpdateProfileRequest>({
      query: ({ ...data }) => ({
        url: `/update_profile/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Users', 'User Detail'],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    checkUsername: builder.mutation<CheckUsernameResponse, CheckUsernameRequest>({
      query: ({ ...data }) => ({
        url: '/check_username/',
        method: 'POST',
        data,
      }),
    }),
  }),
})
