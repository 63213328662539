import { FleetVehicleIconWrapperStyled, FleetVehicleIconBorderStyled } from './FleetIcon.style'

const CompactorIcon = ({ color: propColor, isSelected }: any) => {
  const color = isSelected ? '#fff' : propColor
  const colorOpposite = isSelected ? propColor : '#fff'
  return (
    <FleetVehicleIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <FleetVehicleIconBorderStyled
        cx="24"
        cy="24"
        r="23"
        color={color}
        colorOpposite={colorOpposite}
        isSelected={isSelected}
      />
      <path
        d="M35.4539 31.3586H13.0586V27.7281H38.4717V28.3453C38.4693 29.1445 38.1504 29.9103 37.5848 30.475C37.0193 31.0397 36.2531 31.3574 35.4539 31.3586Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4707 22.7046V27.7236H31.3051V16.8369H34.872C35.1556 16.8342 35.4341 16.9121 35.6751 17.0616C35.9161 17.211 36.1097 17.4258 36.2334 17.6809L36.7462 18.7201L38.271 21.8378C38.4022 22.1079 38.4705 22.4043 38.4707 22.7046Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.624 34C20.0047 34 21.124 32.8807 21.124 31.5C21.124 30.1193 20.0047 29 18.624 29C17.2433 29 16.124 30.1193 16.124 31.5C16.124 32.8807 17.2433 34 18.624 34Z"
        fill={colorOpposite}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 34C33.8807 34 35 32.8807 35 31.5C35 30.1193 33.8807 29 32.5 29C31.1193 29 30 30.1193 30 31.5C30 32.8807 31.1193 34 32.5 34Z"
        fill={colorOpposite}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.5 23H32.9996V18H35.4771" fill={colorOpposite} />
      <path
        d="M37.5 23H32.9996V18H35.4771C35.4771 18 36.6281 19.6232 37.138 20.8141C37.2512 21.0783 37.5 21.7126 37.5 22C37.5 22.5 37.5 23 37.5 23Z"
        stroke={colorOpposite}
        strokeLinejoin="round"
      />
      <path
        d="M26.5 25.93H13L17 20H28.5V23.965L26.5 25.93Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 34C8 33.4477 8.44772 33 9 33H11C11.5523 33 12 33.4477 12 34V35H8V34Z"
        fill={color}
      />
      <path
        d="M10 33.5V24L14.9658 16.6471L19.1285 14L22 17"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2441 20.9231V23.9231"
        stroke={colorOpposite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2441 20.9231V23.9231"
        stroke={colorOpposite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.2441 20.9231V23.9231"
        stroke={colorOpposite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </FleetVehicleIconWrapperStyled>
  )
}

export default CompactorIcon
