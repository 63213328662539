import styled from 'styled-components'
import { color } from '@evrekadev/evreka-ui-components'

export const WelcomeBannerStyled = styled.div`
  width: 100%;
  height: fit-content;
  background: ${color('white')};
  border: 1px solid ${color('grey300')};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.borderRadiuses[12]};
  overflow: hidden;
  position: relative;
`
