import React from 'react'
import { HeaderWrapperStyled, ProgressBarStyled } from './Header.style'
import { Button, Flex, Tab, TabList, Tabs, Typography } from 'components'
import { selectUi } from 'services/slices/ui'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientId, selectCurrentTimezone, selectCurrentUser } from 'services/slices/auth'
import { TabPropsV2 } from '@evrekadev/evreka-ui-components'
import { useTranslation } from 'utils/useTranslation'
import { BreadCrumbType, useBreadCrumb } from 'utils/useBreadcrumb'
import { DetailActionArea } from './DetailActionArea'
import { ClientSelection } from './ClientSelection'
import { BreadcrumbsSkeleton } from 'containers/Layout/Skeletons/BreadcrumbsSkeleton'
import { DetailHeaderSkeleton } from 'containers/Layout/Skeletons/DetailHeaderSkeleton'
import { UilSetting, UilStar } from '@iconscout/react-unicons'

export type DetailHeaderProps = {
  objectName?: string
  breadcrumbs?: Array<BreadCrumbType>
  headerExtra?: React.ReactNode
  action?: JSX.Element
  onHistory?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onInsights?: () => void
  onDuplicate?: () => void
  note?: {
    onNote: () => void
    isAdded: boolean
  }
  onPrintQR?: () => void
  loading?: boolean
}

export const DetailHeader: React.FC<DetailHeaderProps> = ({
  breadcrumbs,
  headerExtra,
  objectName,
  loading,
  ...rest
}) => {
  const breadcrumbsElement = useBreadCrumb(breadcrumbs ?? [])
  const navigate = useNavigate()
  const { settingsUrl, showLoadingBar } = useSelector(selectUi)

  return (
    <HeaderWrapperStyled data-testid="detail-header">
      {showLoadingBar && <ProgressBarStyled />}
      <Flex between="zero" middle="zero">
        {loading ? (
          <BreadcrumbsSkeleton />
        ) : breadcrumbs ? (
          breadcrumbsElement
        ) : (
          <Flex middle="zero" gap={0.5} data-testid="detail-header-info">
            <Typography
              fontSize="text_xl"
              fontWeight="semibold"
              color="grey900"
              data-testid="detail-name"
            >
              {objectName}
            </Typography>
            {headerExtra}
          </Flex>
        )}
        <Flex gap={0.75} middle="zero">
          {settingsUrl && (
            <Flex gap={0.25} middle="zero">
              <Button
                variant="text"
                color="secondary"
                onlyIcon
                iconLeft={UilSetting}
                onClick={() => navigate(settingsUrl)}
              />
            </Flex>
          )}
          <ClientSelection />
        </Flex>
      </Flex>
      {!loading && breadcrumbs && (
        <Flex between="zero" middle="zero">
          {breadcrumbs &&
            (loading ? (
              <DetailHeaderSkeleton />
            ) : (
              <Flex middle="zero" gap={0.5} data-testid="detail-header-info">
                <Typography
                  fontSize="text_xl"
                  fontWeight="semibold"
                  color="grey900"
                  data-testid="detail-name"
                >
                  {objectName}
                </Typography>
                {headerExtra}
              </Flex>
            ))}
          <DetailActionArea {...rest} />
        </Flex>
      )}
    </HeaderWrapperStyled>
  )
}

export type ListHeaderProps = {
  tabs?: Array<string>
  onTabChange?: TabPropsV2['onSelect']
  selectedIndex?: number
  breadcrumbs?: Array<BreadCrumbType>
  noTitle?: boolean
}

export const ListHeader: React.FC<ListHeaderProps> = ({
  tabs,
  onTabChange,
  selectedIndex,
  breadcrumbs,
  noTitle,
}) => {
  const { pageTitle, settingsUrl, showLoadingBar } = useSelector(selectUi)
  const breadcrumbsElement = useBreadCrumb(breadcrumbs ?? [])
  const navigate = useNavigate()
  return (
    <HeaderWrapperStyled hasTabs={!!tabs} data-testid="detail-header">
      {showLoadingBar && <ProgressBarStyled />}
      <Flex between="zero" middle="zero">
        {breadcrumbs && breadcrumbsElement}
        {!breadcrumbs && !noTitle && (
          <Typography fontSize="header_xs" fontWeight="semibold" color="grey900">
            {pageTitle}
          </Typography>
        )}
        <Flex gap={0.75} middle="zero">
          {settingsUrl && (
            <Flex gap={0.25} middle="zero">
              <Button
                variant="text"
                color="secondary"
                onlyIcon
                iconLeft={UilSetting}
                onClick={() => navigate(settingsUrl)}
              />
            </Flex>
          )}
          <ClientSelection />
        </Flex>
      </Flex>
      {breadcrumbs && !noTitle && (
        <Typography fontSize="header_xs" fontWeight="semibold" color="grey900">
          {pageTitle}
        </Typography>
      )}
      {tabs && (
        <Tabs onSelect={onTabChange} selectedIndex={selectedIndex}>
          <TabList>
            {tabs.map((tab, i) => (
              <Tab key={i}>{tab}</Tab>
            ))}
          </TabList>
        </Tabs>
      )}
    </HeaderWrapperStyled>
  )
}

export const FormHeader: React.FC<{ breadcrumbs?: BreadCrumbType[]; loading?: boolean }> = ({
  breadcrumbs,
  loading,
}) => {
  const breadcrumbsElement = useBreadCrumb(breadcrumbs ?? [])
  const navigate = useNavigate()
  const { settingsUrl, showLoadingBar } = useSelector(selectUi)
  return (
    <HeaderWrapperStyled data-testid="detail-header">
      {showLoadingBar && <ProgressBarStyled />}
      <Flex between="zero" middle="zero">
        {loading ? <BreadcrumbsSkeleton /> : breadcrumbsElement}
        <Flex gap={0.75} middle="zero">
          {settingsUrl && (
            <Flex gap={0.25} middle="zero">
              <Button
                variant="text"
                color="secondary"
                onlyIcon
                iconLeft={UilSetting}
                onClick={() => navigate(settingsUrl)}
              />
            </Flex>
          )}
          <ClientSelection />
        </Flex>
      </Flex>
    </HeaderWrapperStyled>
  )
}
