import { useCallback, useEffect } from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, FieldError, FormProvider, useForm } from 'react-hook-form'
import { Grid, GridCell, InputText, Modal } from 'components'
import { useUpdateProfileMutation } from 'services/UserManagement'
import { useTranslation } from 'utils/useTranslation'
import { PhoneInput } from 'containers/PhoneInputContainer'

import { selectCurrentUser } from 'services/slices/auth'
import { useSelector } from 'react-redux'
import { PhoneInputValue } from 'containers/PhoneInputContainer/PhoneInput'

type UpdateProfileModalProps = {
  closeModal: () => void
  isOpen: boolean
}

type FormInputs = {
  email: string
  phone?: PhoneInputValue
}

const schema = yup.object().shape({
  email: yup.string().email().required('required.email'),
  phone: yup.object().test('phone', 'invalid.phone', (value) => {
    if (!value?.value || value.country_format_len === 0) return true
    if ((value.value as string)?.length !== value.country_format_len) {
      return false
    }

    return true
  }),
})

const TRANSLATION_KEY = 'pages.common.profile'

export const UpdateProfileModal: React.FC<React.PropsWithChildren<UpdateProfileModalProps>> = ({
  closeModal,
  isOpen,
}) => {
  const { t: tGlobal } = useTranslation()
  const { t: tProfile } = useTranslation(TRANSLATION_KEY)
  const { t: tUpdateProfileModal } = useTranslation(`${TRANSLATION_KEY}.update_profile_modal`)

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = methods
  const user = useSelector(selectCurrentUser)

  const [updateProfile, { isLoading: isUpdateProfileLoading }] = useUpdateProfileMutation()

  useEffect(() => {
    if (user?.email) {
      reset({
        email: user.email,
      })
    }
  }, [user])

  const onSubmit = useCallback((data: FormInputs) => {
    updateProfile({
      email: data.email,
      phone: data.phone?.value,
    })
      .unwrap()
      .then(closeModal)
  }, [])

  useEffect(() => {
    !isOpen && reset()
  }, [isOpen])
  return (
    <FormProvider {...methods}>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        heading={tProfile('update')}
        formProps={{ onSubmit: handleSubmit(onSubmit) }}
        loading={isUpdateProfileLoading}
        componentSize="small"
      >
        <Grid rowGap={1.6} columns={1}>
          <GridCell width={1}>
            <Controller
              render={({ field }) => (
                <InputText
                  {...field}
                  label={tUpdateProfileModal('email')}
                  error={!!errors.email}
                  errorText={
                    errors.email && tUpdateProfileModal((errors.email as FieldError).message ?? '')
                  }
                  labelRequired
                  disabled
                />
              )}
              control={control}
              name="email"
            />
          </GridCell>
          <GridCell width={1}>
            <Controller
              render={({ field }) => (
                <PhoneInput
                  label={tUpdateProfileModal('phone')}
                  componentSize="medium"
                  name={field.name}
                  error={!!errors.phone}
                />
              )}
              control={control}
              name="phone"
            />
          </GridCell>
        </Grid>
      </Modal>
    </FormProvider>
  )
}
