import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Marker as ReactMarker } from 'react-leaflet'
import { CustomMarkerProps, iconGenerator, isFunction } from './'
import MarkerTooltip from './MarkerTooltip'
import MarkerPopup from './MarkerPopup'
import { LeafletEventHandlerFnMap, PointTuple } from 'leaflet'
import { BaseColorType, ThemeColors } from '@evrekadev/evreka-ui-components'
import { getCustomMapMarkerIcon } from '../icons/utils'
import { MapContext } from 'context'
import { MapContextType } from '../types'

export type MarkerProps = {
  marker: CustomMarkerProps
  isSelected: boolean
  customOnSelect?: boolean
}

const Marker: React.FC<MarkerProps> = ({ marker, isSelected, customOnSelect }) => {
  const {
    icon,
    onMarkerSelect: onSelect,
    popup,
    isEnabledBulkSelection,
  } = useContext<MapContextType>(MapContext)

  const [isHovered, setIsHovered] = useState(false)
  const [popupIsOpen, setPopupIsOpen] = useState(false)
  const defaultTooltipOffset = useMemo<PointTuple>(
    () => (isSelected ? [6, -36] : [0, -16]),
    [isSelected],
  )

  const handleOnSelect = useCallback(
    (marker: CustomMarkerProps) => {
      onSelect?.(marker)
    },
    [onSelect],
  )

  const handleMouseOver = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleMouseOut = useCallback(() => {
    setIsHovered(false)
  }, [])

  const handlePopupOpen = useCallback(() => {
    setPopupIsOpen(true)
  }, [])

  const handlePopupClose = useCallback(() => {
    setPopupIsOpen(false)
    setIsHovered(false)
  }, [])

  let markerIcon = marker.icon?.idle || (icon as any)

  if (isFunction(icon)) {
    markerIcon = iconGenerator({ color: marker.color as BaseColorType, icon })
  } else if ((popupIsOpen || isSelected) && marker.icon?.selected) {
    markerIcon = marker.icon?.selected
  } else if (isHovered && marker.icon?.hovered) {
    markerIcon = marker.icon?.hovered
  } else if (isSelected) {
    markerIcon = getCustomMapMarkerIcon(ThemeColors.green600, true)
  }

  const eventHandlers = useMemo(
    () =>
      ({
        click: (e) =>
          customOnSelect ? marker.eventHandlers?.click?.(e) : handleOnSelect?.(marker),
        popupopen: handlePopupOpen,
        popupclose: handlePopupClose,
        mouseover: handleMouseOver,
        mouseout: handleMouseOut,
      }) as LeafletEventHandlerFnMap,
    [
      marker,
      handleOnSelect,
      handlePopupOpen,
      handlePopupClose,
      handleMouseOver,
      handleMouseOut,
      customOnSelect,
    ],
  )

  return (
    <ReactMarker
      icon={markerIcon}
      position={marker.position}
      title=""
      pmIgnore
      eventHandlers={eventHandlers}
    >
      {(typeof marker.tooltip?.permanent === 'undefined' || marker.tooltip?.permanent) &&
        (marker.title || marker.name ? (
          <MarkerTooltip
            offset={marker.tooltip?.offset || defaultTooltipOffset}
            permanent={marker.tooltip?.permanent}
          >
            {marker.title || marker.name}
          </MarkerTooltip>
        ) : undefined)}
      {!isEnabledBulkSelection && popup && !marker.isDisabledPopup && <MarkerPopup {...popup} />}
    </ReactMarker>
  )
}

export default Marker
