import { useCallback, useMemo } from 'react'
import { DateRangeSelector } from 'components'
import {
  ColumnExtended,
  dateFormat,
  DATE_FORMAT,
  stringToTimezoneISO,
} from '@evrekadev/evreka-ui-components'

type DateRangeSelectorFilterProps = {
  column: ColumnExtended<any>
  filterValue: [string, string]
  onChange: (id: string, value: string | string[]) => void
  type: string
  timezone?: string
}

export function DateRangeSelectorColumnFilter({
  column: { id },
  onChange,
  filterValue,
  timezone,
}: DateRangeSelectorFilterProps) {
  const startDate = filterValue && filterValue[0]
  const endDate = filterValue && filterValue[1]

  const timezoneStart = useMemo(
    () => (timezone ? (startDate ? dateFormat(startDate, DATE_FORMAT, timezone) : '') : startDate),
    [startDate],
  )
  const timezoneEnd = useMemo(
    () => (timezone ? (endDate ? dateFormat(endDate, DATE_FORMAT, timezone) : '') : endDate),
    [endDate],
  )

  const onDateChange = useCallback(
    (date?: Array<string> | null) => {
      if (timezone) {
        if (!date) {
          onChange(id, '')
          return
        }
        const timezoneDate = date.map((d) => stringToTimezoneISO(d, DATE_FORMAT, timezone))
        timezoneDate && onChange(id, timezoneDate)
      } else {
        !date ? onChange(id, '') : onChange(id, date)
      }
    },
    [onChange],
  )

  return (
    <div>
      <DateRangeSelector
        onChange={onDateChange}
        showClearDate
        start={timezone ? timezoneStart : startDate}
        end={timezone ? timezoneEnd : endDate}
        componentSize="small"
      />
    </div>
  )
}
