import { Flex, FlexCell, Icon, Spacing, Typography } from 'components'
import React from 'react'
import engagement from 'assets/icons/module_engagement.svg'
import fleet from 'assets/icons/module_fleet_management.svg'
import asset from 'assets/icons/module_asset_management.svg'
import ops from 'assets/icons/module_ops_management.svg'
import mrf from 'assets/icons/module_mrf_management.svg'
import workforce from 'assets/icons/module_workforce_management.svg'
import route from 'assets/icons/module_route_optimization.svg'
import {
  ModuleIconWrapper,
  ModuleCardStyled,
  ModuleInfoIconWrapper,
  ModuleDescriptionWrapperStyled,
} from 'containers/ModuleSlider/ModuleSlider.styled'
import { UilInfoCircle } from '@iconscout/react-unicons'
import { useTranslation } from 'utils/useTranslation'

type ModuleCardProps = { module: 'eng' | 'mrf' | 'ops' | 'asset' | 'fleet' | 'workforce' | 'route' }

const getModuleData = (module: ModuleCardProps['module']) => {
  switch (module) {
    case 'eng':
      return {
        icon: engagement,
        title: 'engagement.title',
        description: 'engagement.description',
        link: 'https://evreka.co/solutions/engagement/',
      }

    case 'mrf':
      return {
        icon: mrf,
        title: 'mrf.title',
        description: 'mrf.description',
        link: 'https://evreka.co/solutions/materials-recovery-facility-management/',
      }

    case 'ops':
      return {
        icon: ops,
        title: 'ops.title',
        description: 'ops.description',
        link: 'https://evreka.co/solutions/operations-management/',
      }

    case 'asset':
      return {
        icon: asset,
        title: 'asset.title',
        description: 'asset.description',
        link: 'https://evreka.co/solutions/asset-management/',
      }

    case 'fleet':
      return {
        icon: fleet,
        title: 'fleet.title',
        description: 'fleet.description',
        link: 'https://evreka.co/solutions/fleet-management/',
      }

    case 'workforce':
      return {
        icon: workforce,
        title: 'workforce.title',
        description: 'workforce.description',
        link: 'https://evreka.co/solutions/workforce-management/',
      }

    case 'route':
      return {
        icon: route,
        title: 'route.title',
        description: 'route.description',
        link: 'https://evreka.co/route-optimization-for-waste-management/',
      }
  }
}

export const ModuleCard: React.FC<React.PropsWithChildren<ModuleCardProps>> = ({ module }) => {
  const { t } = useTranslation('pages.home')
  return (
    <ModuleCardStyled>
      <Flex directionColumn="zero" fullHeight fullWidth>
        <Flex fullWidth between="zero">
          <FlexCell>
            <ModuleIconWrapper>
              <img src={getModuleData(module).icon} />
            </ModuleIconWrapper>
          </FlexCell>
          <FlexCell>
            <ModuleInfoIconWrapper>
              <a href={getModuleData(module).link} target="_blank" rel="noreferrer">
                <Icon size={28} icon={UilInfoCircle} color={'darkblue500'} />
              </a>
            </ModuleInfoIconWrapper>
          </FlexCell>
        </Flex>
        <Flex fullWidth fullHeight directionColumn="zero" around="zero">
          <FlexCell>
            <Typography fontSize="text_md" fontWeight="semibold">
              {t(getModuleData(module).title)}
            </Typography>
            <Spacing mt={4} />
            <ModuleDescriptionWrapperStyled>
              <Typography align="justify">{t(getModuleData(module).description)}</Typography>
            </ModuleDescriptionWrapperStyled>
          </FlexCell>
          <FlexCell>
            <Typography></Typography>
          </FlexCell>
        </Flex>
      </Flex>
    </ModuleCardStyled>
  )
}
