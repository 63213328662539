import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import { MutationResponse, TableResponseV2WithTimezone } from 'services/Engagement/types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  CreateDocumentRequest,
  DocumentListRequest,
  DocumentListRequestByObjectId,
  DocumentListResponse,
  DocumentListGenericRequest,
} from './types'
import i18n from 'utils/i18n'
import { TableResponseV2 } from 'services/types'

export const documentApi = createApi({
  reducerPath: 'documentApi',
  tagTypes: ['Documents', 'GenericDocuments'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    getGenericDocumentList: builder.query<
      TableResponseV2WithTimezone<{}>,
      DocumentListGenericRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `int-api/document/${clientId}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['GenericDocuments'],
    }),
    getDocumentList: builder.query<TableResponseV2WithTimezone<{}>, DocumentListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/document/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Documents'],
    }),
    getDocumentListByObjectId: builder.query<DocumentListResponse, DocumentListRequestByObjectId>({
      query: ({ client_id, object_id, object_type, app_label, ...data }) => ({
        url: `${app_label}/clients/${client_id}/document/list/${object_id}/${object_type}/`,
        method: 'POST',
        data,
      }),
    }),
    createDocument: builder.mutation<MutationResponse, CreateDocumentRequest>({
      query: ({ externalClientId, body }) => ({
        url: `engagement/clients/${externalClientId}/document/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Documents', 'GenericDocuments'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.show_documents'),
              to: `/documents/document_list`,
            },
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    createDeliveryNote: builder.mutation<
      MutationResponse,
      { externalClientId: number; allocationId: number }
    >({
      query: ({ externalClientId, allocationId }) => ({
        url: `MRF/v1/document/clients/${externalClientId}/document/`,
        method: 'POST',
        data: {
          environment: process.env.REACT_APP_BASE_URL,
          app_label: 'MRF',
          model_name: 'consignment',
          document_type_id: '627fd25c-9f9e-4aa8-a735-f36a04e9fbf5',
          object_id: allocationId,
        },
      }),
      invalidatesTags: ['Documents', 'GenericDocuments'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.show_documents'),
              to: `/documents/document_list`,
            },
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const {
  useGetDocumentListQuery,
  useGetGenericDocumentListQuery,
  useGetDocumentListByObjectIdQuery,
  useCreateDocumentMutation,
  useCreateDeliveryNoteMutation,
} = documentApi
