import React from 'react'
import { InputV2, InputPropsV2 } from '@evrekadev/evreka-ui-components'

const InputNumber = React.forwardRef<HTMLInputElement, InputPropsV2>((props, ref) => {
  return (
    <InputV2
      type="number"
      step="any"
      ref={ref}
      onWheel={(e) => e.currentTarget.blur()}
      {...props}
    />
  )
})

export default InputNumber
