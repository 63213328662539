import { useCallback, useEffect, useMemo } from 'react'
import { useGetAuthWithUserQuery } from 'services/auth'
import { useLocalStorage } from '@evrekadev/evreka-ui-components'

type Evreka360UserType = 'customer_portal' | 'dashboard' // TODO: maybe "waste_dashboard" might be added in the future based on need
type AnnouncementsStorageType = Record<Evreka360UserType, Record<string, { isDismissed: boolean }>>

type UseAnnouncementParams = {
  clientId?: string
  userType?: Evreka360UserType
}

const announcementKeys = ['customer_portal', 'dashboard'] as const // TODO: maybe "waste_dashboard" might be added in the future based on need

// can't escape out of using type "any". if you find a solution,
// please apply it here! :pray:
const getInitialStorageState = () => {
  return announcementKeys.reduce((initState: any, key) => {
    initState[key] = {}
    return initState
  }, {})
}

export const useAnnouncement = ({ clientId, userType }: UseAnnouncementParams) => {
  const { data: authWithUserData, isLoading: isAuthWithUserDataLoading } = useGetAuthWithUserQuery(
    clientId,
    {
      skip: clientId ? false : true,
      refetchOnMountOrArgChange: true,
    },
  )

  const [announcementsStorage, setAnnouncementsStorage] = useLocalStorage<
    AnnouncementsStorageType | undefined
  >('announcements', undefined)

  const announcementBanners = useMemo(() => {
    if (!authWithUserData?.announcements || !userType) return []
    return authWithUserData.announcements[userType]
  }, [authWithUserData, announcementsStorage, userType])

  useEffect(() => {
    if (
      announcementsStorage &&
      Object.keys(announcementsStorage).length > 0 &&
      !isAuthWithUserDataLoading &&
      userType
    ) {
      const notDismissibleBannerIds = announcementBanners
        .filter((banner) => !banner.is_dismissible)
        .map((banner) => banner.id)

      const overriddenAnnouncementsStorageData = Object.keys(announcementsStorage[userType]).reduce(
        (data: AnnouncementsStorageType[typeof userType], key) => {
          if (notDismissibleBannerIds.includes(key)) {
            data[key] = {
              isDismissed: false,
            }
          }
          return data
        },
        {},
      )

      setAnnouncementsStorage((prevState) => {
        if (!prevState) {
          return getInitialStorageState()
        }
        return {
          ...prevState,
          [userType]: {
            ...prevState[userType],
            ...overriddenAnnouncementsStorageData,
          },
        }
      })
    }
  }, [isAuthWithUserDataLoading])

  const handleCheckIsBannerActive = useCallback(
    (id: string) => {
      if (!userType) return false
      const foundBanner = announcementBanners.find((banner) => banner.id === id)
      if (!foundBanner) return false // redundant check
      if (!foundBanner.is_dismissible) return true // banner must be visible to user regardless of its state in local storage, if it was saved before

      if (announcementsStorage && Object.keys(announcementsStorage).length) {
        const bannerInLocalStorage = announcementsStorage[userType][id]
        if (!bannerInLocalStorage) return true // banner should be visible, if no data is kept in local storage
        return !bannerInLocalStorage.isDismissed
      }
      return true
    },
    [announcementsStorage, announcementBanners, userType],
  )

  const activeTopBanners = useMemo(() => {
    if (!announcementBanners.length) return []
    return announcementBanners
      .filter((banner) => banner.type === 'payment' || banner.type === 'release_info')
      .filter((banner) => handleCheckIsBannerActive(banner.id))
  }, [announcementBanners, handleCheckIsBannerActive])

  const activeMarketingBanners = useMemo(() => {
    if (!announcementBanners.length) return []
    return announcementBanners
      .filter((banner) => banner.type === 'product_update')
      .filter((banner) => handleCheckIsBannerActive(banner.id))
  }, [announcementBanners, handleCheckIsBannerActive])

  const handleDismissBanner = useCallback(
    (id: string) => {
      const foundBanner = announcementBanners.find((banner) => banner.id === id)
      if (!foundBanner || !userType) return
      setAnnouncementsStorage((prevState) => {
        if (!prevState) {
          return {
            ...getInitialStorageState(),
            [userType]: {
              [foundBanner.id]: {
                isDismissed: true,
              },
            },
          }
        }

        return {
          ...prevState,
          [userType]: {
            ...prevState[userType],
            [foundBanner.id]: {
              isDismissed: true,
            },
          },
        }
      })
    },
    [announcementsStorage, announcementBanners, userType],
  )

  return {
    announcements: {
      topBanners: activeTopBanners,
      marketingBanners: activeMarketingBanners,
      onDismiss: handleDismissBanner,
    },
  }
}
