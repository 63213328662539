import { FleetVehicleIconWrapperStyled, FleetVehicleIconBorderStyled } from './FleetIcon.style'

const RearLoaderIcon = ({ color: propColor, isSelected }: any) => {
  const color = isSelected ? '#fff' : propColor
  const colorOpposite = isSelected ? propColor : '#fff'

  return (
    <FleetVehicleIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      isSelected={isSelected}
    >
      <FleetVehicleIconBorderStyled
        cx="24"
        cy="24"
        r="23"
        color={color}
        colorOpposite={colorOpposite}
        isSelected={isSelected}
      />
      <path
        d="M35.4538 31.3586H15.0002V27.7281L38.4716 27.7281V28.3453C38.4692 29.1445 38.1504 29.9103 37.5848 30.475C37.0193 31.0397 36.2531 31.3574 35.4538 31.3586Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.4707 22.7046V27.7236H31.3051V16.8369H34.872C35.1556 16.8342 35.4341 16.9121 35.6751 17.0616C35.9161 17.211 36.1097 17.4258 36.2334 17.6809L36.7462 18.7201L38.271 21.8378C38.4022 22.1079 38.4705 22.4043 38.4707 22.7046Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 34C20.8807 34 22 32.8807 22 31.5C22 30.1193 20.8807 29 19.5 29C18.1193 29 17 30.1193 17 31.5C17 32.8807 18.1193 34 19.5 34Z"
        fill={colorOpposite}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.5 34C33.8807 34 35 32.8807 35 31.5C35 30.1193 33.8807 29 32.5 29C31.1193 29 30 30.1193 30 31.5C30 32.8807 31.1193 34 32.5 34Z"
        fill={colorOpposite}
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M37.5 23H32.9996V18H35.4771" fill={colorOpposite} />
      <path
        d="M37.5 23H32.9996V18H35.4771C35.4771 18 36.6281 19.6232 37.138 20.8141C37.2512 21.0783 37.5 21.7126 37.5 22C37.5 22.5 37.5 23 37.5 23Z"
        stroke={colorOpposite}
        strokeLinejoin="round"
      />
      <path
        d="M28.5 14H20L17 25H28.5V14Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 14L14.1243 25H12V29.3492H9.5V24.807L13 14H17Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 16H15.5"
        stroke={colorOpposite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 19.6H14.5"
        stroke={colorOpposite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 23.2H13.5"
        stroke={colorOpposite}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </FleetVehicleIconWrapperStyled>
  )
}

export default RearLoaderIcon
