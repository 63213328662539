import { createContext } from 'react'

import { MapContextType } from 'components/Map/types'

type MapContextProviderProps = MapContextType

export const MapContext = createContext<MapContextProviderProps>({})

export const MapContextProvider: React.FC<React.PropsWithChildren<MapContextProviderProps>> = ({
  children,
  ...componentProps
}) => {
  return <MapContext.Provider value={componentProps}>{children}</MapContext.Provider>
}
