import { categoryApi } from './category'
import { eventApi } from './event'
import { operationApi } from './operation'
import { settingsApi } from './settings'

export const { useGetCategoriesQuery } = categoryApi
export const {
  useGetEventHistoriesQuery,
  useGetEventQuery,
  useGetEventsQuery,
  useUpdateEventAddNoteMutation,
  useUpdateEventCreateATaskMutation,
  useUpdateEventNoActionMutation,
  useUpdateEventResolvedMutation,
  useUpdateEventResolvingMutation,
  useUpdateEventsReportMutation,
  useUpdateMoveAssetMutation,
} = eventApi

export const {
  useGetOperationsQuery,
  useGetEmployeesQuery,
  useGetActiveShiftsQuery,
  useGetTasksQuery,
  useGetVehiclesQuery,
} = operationApi

export const { useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi

export { eventApi, categoryApi, operationApi, settingsApi }
