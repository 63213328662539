import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const DetailBoxSkeleton = (props: IContentLoaderProps) => (
  <ContentLoader
    width={'100%'}
    height={'100%'}
    viewBox="0 0 680 390"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="4" y="8" rx="3" ry="3" width="7" height="388" />
    <rect x="6" y="382" rx="6" ry="6" width="669" height="8" />
    <rect x="670" y="9" rx="3" ry="3" width="6" height="385" />
    <rect x="30" y="30" rx="14" ry="14" width="122" height="32" />
    <rect x="260" y="60" rx="16" ry="16" width="375" height="276" />
    <rect x="30" y="150" rx="3" ry="3" width="178" height="6" />
    <rect x="30" y="170" rx="3" ry="3" width="102" height="7" />
    <rect x="30" y="200" rx="3" ry="3" width="178" height="6" />
    <rect x="30" y="220" rx="3" ry="3" width="102" height="7" />
    <rect x="30" y="240" rx="3" ry="3" width="178" height="6" />
    <rect x="30" y="280" rx="3" ry="3" width="102" height="7" />
    <rect x="30" y="310" rx="3" ry="3" width="178" height="6" />
    <rect x="5" y="8" rx="3" ry="3" width="669" height="7" />
    <rect x="30" y="80" rx="3" ry="3" width="200" height="29" />
  </ContentLoader>
)

export const DetailSideColumnSkeleton = (props: IContentLoaderProps) => (
  <ContentLoader
    width={'100%'}
    height={'100%'}
    viewBox="0 0 680 390"
    backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb"
    preserveAspectRatio="none"
    {...props}
  >
    <rect x="22" y="30" rx="0" ry="0" width="0" height="1" />
    <rect x="8" y="40" rx="8" ry="8" width="400" height="36" />
    <rect x="8" y="8" rx="4" ry="4" width="200" height="16" />
    <rect x="8" y="96" rx="8" ry="8" width="400" height="350" />
  </ContentLoader>
)
