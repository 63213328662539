import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { TableRequestV3, TableResponse } from 'services/types'
import { sendNotification } from 'components'

type GetImportDetailsRequest = {
  importId: string
}

// TODO: refer to this thread for exact type: https://evrekagroup.slack.com/archives/C0210A23VSS/p1669102195732689
type ImporDetailData = {
  id: string
  module: string
  page: string
  create_date: string
  file: {
    name: string
    url: string
  } | null
  status: {
    id: number
    label: string
    description: string | null
    color: string
  }
  result?: {
    success?: Record<string, Record<string, string>>
    error_rows?: Record<string, Record<string, string>>
  }
}

type GetImportDetailsResponse = {
  success: boolean
  detail: {
    data: ImporDetailData
  }
}

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  tagTypes: ['Reports', 'ExportReports', 'ImportReports', 'ImportDetail'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/int-api'}`,
  }),
  endpoints: (builder) => ({
    getExportedReports: builder.query<TableResponse<{}>, TableRequestV3>({
      query: ({ clientId, ...data }) => ({
        url: `/export/${clientId}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ExportReports'],
    }),
    getImportRequests: builder.query<TableResponse<{}>, TableRequestV3>({
      query: ({ clientId, ...data }) => ({
        url: `/import/${clientId}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['ImportReports'],
    }),
    getImportDetails: builder.query<GetImportDetailsResponse, GetImportDetailsRequest>({
      query: ({ importId }) => ({
        url: `/import/${importId}/detail/`,
        method: 'GET',
      }),
      providesTags: ['ImportDetail'],
      async onQueryStarted() {
        try {
          // `onSuccess` side-effect
        } catch (err) {
          sendNotification({
            type: 'error',
            toastContent:
              'An error occurred while trying to fetch import details. Please try again later.',
          })
          // `onError` side-effect
        }
      },
    }),
  }),
})
