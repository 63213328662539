export const divideMainTextToMatchedSubstrings = (
  mainText: string,
  matchedSubstrings?: { length: number; offset: number },
) => {
  if (!matchedSubstrings) {
    return { before: '', matched: mainText, after: '' }
  }
  // regex: typography cuts of spaces if they are at the end or the beginning, this fixes it
  const before = mainText.substring(0, matchedSubstrings.offset).replace(/ /g, '\u00a0')
  const matchedText = mainText
    .substring(matchedSubstrings.offset, matchedSubstrings.offset + matchedSubstrings.length)
    .replace(/ /g, '\u00a0')
  const after = mainText
    .substring(matchedSubstrings.offset + matchedSubstrings.length)
    .replace(/ /g, '\u00a0')
  return { before, matched: matchedText, after }
}

export const divideTextBySearchVal = (
  text: string,
  search: string,
): { before: string; matched: string; after: string } => {
  const index = text.toLowerCase().indexOf(search.toLowerCase())
  if (index === -1) {
    return {
      before: text,
      matched: '',
      after: '',
    }
  }

  return {
    before: text.slice(0, index),
    matched: text.slice(index, index + search.length),
    after: text.slice(index + search.length),
  }
}
