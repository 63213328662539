import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

export const InputSkeleton = (props: IContentLoaderProps) => (
  <ContentLoader
    height={42}
    width="100%"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect width="100%" height="42" rx={8} ry={8} />
  </ContentLoader>
)
