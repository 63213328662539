import React, { lazy, Suspense as ReactSuspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ScrollToTop } from '../utils/scrollToTop'
import { NotFound } from 'pages/NotFound'
import { ProtectedRoute } from 'routes/ProtectedRoute'
import { PublicLayout } from './PublicLayout'
import Home from 'pages/Home'
import { ProtectedRouteType, BaseRouteType } from './types'
import { ToastContainer } from 'components'
import { EnvironmentProvider } from 'utils/EnvironmentProvider'
import { NotAuthenticated } from 'pages/NotAuthenticated'
import { InvalidClient } from 'pages/InvalidClient'
import { ProtectedLayout } from './ProtectedLayout'
import { selectCurrentLanguage, selectCurrentUser } from 'services/slices/auth'
import { useSelector } from 'react-redux'
import { OverlayLoader } from 'containers/Overlay'
// import eventRoutes from 'pages/EventManagement'
// import opsRoutes from 'pages/OpsManagement'
// import engagementRoutes from 'pages/Engagement'
// import assetRoutes from 'pages/AssetManagement'
// import mrfRoutes from 'pages/MrfManagement'
// import fleetRoutes from 'pages/FleetManagement'
// import insightRoutes from 'pages/InsightManagement'
// import documentRoutes from 'pages/DocumentManagement'
// import reportsRoutes from 'pages/ReportsManagement'
// import userRoutes from 'pages/UserManagement'
import Login from 'pages/Authentication/Login'
import ForgotPassword from 'pages/Authentication/ForgotPassword'
import ChangePasswordRecovery from 'pages/Authentication/ChangePasswordRecovery'

const EventRoutes = lazy(() => import(/* webpackChunkName: "event-mng" */ 'pages/EventManagement'))
const OpsRoutes = lazy(() => import(/* webpackChunkName: "ops-mng" */ 'pages/OpsManagement'))
const EngagementRoutes = lazy(() => import(/* webpackChunkName: "engagement" */ 'pages/Engagement'))
const AssetRoutes = lazy(() => import(/* webpackChunkName: "asset-mng" */ 'pages/AssetManagement'))
const MrfRoutes = lazy(() => import(/* webpackChunkName: "mrf-mng" */ 'pages/MrfManagement'))
const FleetRoutes = lazy(() => import(/* webpackChunkName: "fleet-mng" */ 'pages/FleetManagement'))
const InsightsRoutes = lazy(
  () => import(/* webpackChunkName: "insights" */ 'pages/InsightManagement'),
)
const DocumentsRoutes = lazy(
  () => import(/* webpackChunkName: "documents" */ 'pages/DocumentManagement'),
)
const ReportsRoutes = lazy(
  () => import(/* webpackChunkName: "reports" */ 'pages/ReportsManagement'),
)
const UsersRoutes = lazy(() => import(/* webpackChunkName: "users" */ 'pages/UserManagement'))

export const protectedRoutes: Array<ProtectedRouteType> = [
  // eventRoutes,
  // opsRoutes,
  // engagementRoutes,
  // assetRoutes,
  // mrfRoutes,
  // fleetRoutes,
  // insightRoutes,
  // documentRoutes,
  // reportsRoutes,
  // userRoutes,
  { path: '/', Component: <Home />, pageId: 'home' },
  { path: '/invalid_client', Component: <InvalidClient />, pageId: 'invalid_client' },
]
export const publicRoutes: Array<BaseRouteType> = [
  { path: '/login', Component: <Login />, pageId: 'login' },
  { path: '/', Component: <Login />, pageId: 'login' },
  { path: '/forgot_password', Component: <ForgotPassword />, pageId: 'forgot_password' },
  {
    path: '/change_password_recovery/:token',
    Component: <ChangePasswordRecovery />,
    pageId: 'change_password_recovery',
  },
  { path: '/not_authenticated', Component: <NotAuthenticated />, pageId: 'not_authenticated' },
]

const Suspense: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <ReactSuspense fallback={<OverlayLoader isOpen />}>{children}</ReactSuspense>
)

function Routing() {
  const currentLanguage = useSelector(selectCurrentLanguage)
  const user = useSelector(selectCurrentUser)

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer currentLanguage={currentLanguage} containerId="toast" />
      <ToastContainer currentLanguage={currentLanguage} containerId="toast-modal" isModal />
      <EnvironmentProvider />

      <Routes>
        {user &&
          protectedRoutes.map((route, i) => (
            <Route path={route.path} element={<ProtectedLayout />} key={i}>
              <Route
                path={route.path}
                element={
                  <ProtectedRoute
                    pageId={route.pageId}
                    subModuleId={route.subModuleId}
                    moduleId={route.pageId}
                    path={route.path}
                  >
                    {route.Component}
                  </ProtectedRoute>
                }
              />
              {/* {route.innerRoutes &&
                route.innerRoutes.map((innerRoute, i) => (
                  <Route
                    key={i}
                    path={innerRoute.path}
                    element={
                      <ProtectedRoute
                        pageId={innerRoute.pageId}
                        moduleId={innerRoute.moduleId}
                        subModuleId={innerRoute.subModuleId}
                        path={innerRoute.path}
                      >
                        {innerRoute.Component}
                      </ProtectedRoute>
                    }
                  />
                ))} */}
            </Route>
          ))}

        {publicRoutes.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={<PublicLayout>{route.Component}</PublicLayout>}
          />
        ))}
        {/* LAZY LOADED PROTECTED MODULES */}
        <Route path="/" element={<ProtectedLayout />}>
          <Route
            path={'/event_management/*'}
            element={
              <Suspense>
                <EventRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/ops_management/*'}
            element={
              <Suspense>
                <OpsRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/engagement/*'}
            element={
              <Suspense>
                <EngagementRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/asset_management/*'}
            element={
              <Suspense>
                <AssetRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/mrf_management/*'}
            element={
              <Suspense>
                <MrfRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/fleet_management/*'}
            element={
              <Suspense>
                <FleetRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/documents/*'}
            element={
              <Suspense>
                <DocumentsRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/insights/:workspaceId/:powerbiReportId/:reportId'}
            element={
              <Suspense>
                <InsightsRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/reports/*'}
            element={
              <Suspense>
                <ReportsRoutes />
              </Suspense>
            }
          />
          <Route
            path={'/user_management/*'}
            element={
              <Suspense>
                <UsersRoutes />
              </Suspense>
            }
          />
        </Route>
        <Route path={'*'} element={<NotFound />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
