import React, { useEffect, useState } from 'react'
import { GridCell } from 'components'
import { MapSettingsOptions, MapSettingsOption } from 'components/Map/MapSettingsModal/types'
import { ToggleItem } from './ToggleItem'

export type MapSettingsModalProps = {
  options?: MapSettingsOptions
  value?: MapSettingsOptions | null
  onChange?: (value: MapSettingsOptions) => void
}

export const extractCheckedColumnsIds = (source: MapSettingsOptions | null) => {
  return source?.reduce((itemIds: string[], settingItem) => {
    if (settingItem.checked) itemIds.push(settingItem.value || (settingItem.id as string))
    return itemIds
  }, [])
}

export const ToggleGroup: React.FC<React.PropsWithChildren<MapSettingsModalProps>> = ({
  options = [],
  value,
  onChange,
}) => {
  const [columnSettings, setColumnSettings] = useState<MapSettingsOptions>([])

  useEffect(() => {
    if (!columnSettings.length) {
      const selectedColumnsIds = value ? extractCheckedColumnsIds(value) : []

      const parsedFields =
        options.map((option) => ({
          checked: !value
            ? typeof option.checked !== 'undefined'
              ? option.checked
              : true
            : selectedColumnsIds?.includes(option.value) || false,
          value: option.value,
          label: option.label,
          hidable: option.hidable,
          disabledOrdering: false,
        })) || []

      onChange?.(parsedFields)
      setColumnSettings(parsedFields)
    }
  }, [value])

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const switchId = event.currentTarget.getAttribute('data-id')

    if (switchId) {
      const index = columnSettings.findIndex((c) => c.value === switchId)
      const items = [...columnSettings]
      let item = { ...columnSettings[index] }
      item.checked = !item.checked
      items[index] = item

      setColumnSettings(items)
      onChange?.(items)
    }
  }

  return (
    <GridCell>
      {columnSettings.map((item: MapSettingsOption) => (
        <ToggleItem key={item.value} item={item} onChange={handleSwitch} />
      ))}
    </GridCell>
  )
}
