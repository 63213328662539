import { MenuItem, MenuItemProps } from '@szhsin/react-menu'
import styled from 'styled-components'
import { color, padding, ThemeColorKeys } from '@evrekadev/evreka-ui-components'

export const MenuItemStyled = styled((props: MenuItemProps) => <MenuItem {...props} />)`
  /* height: 44px; */
  ${padding([4, 16])}
  width: 100%;

  &:hover,
  &:focus {
    background-color: ${color('grey100')};
  }
`

export const IconWrapper = styled.div<{ color: ThemeColorKeys }>`
  min-height: 36px;
  min-width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => color(props.color)};
  border-radius: 50%;
`

export const MapSearchContainerStyled = styled.div`
  width: 60%;
  position: absolute;
  z-index: 401;
  left: 20%;
  top: 10px;

  .szh-menu-container {
    margin-top: 12px;
  }
`
export const SeparatorStyled = styled.div`
  border: 1px solid ${color('grey100')};
  margin: 8px 0;
`
