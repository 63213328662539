import { color, padding, shadow } from '@evrekadev/evreka-ui-components'
import { SIDEBAR_SIZES } from 'containers/Sidebar/Sidebar.style'
import styled, { keyframes } from 'styled-components'

export const HeaderWrapperStyled = styled.div<{ hasTabs?: boolean }>`
  ${shadow('xs')}
  ${padding([16])}
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.hasTabs ? '0.5rem' : '1rem')};
  ${(props) => props.hasTabs && `padding-bottom: 0;`}
  background-color: ${color('white')};
  min-height: 64px;
  width: 100%;
  border-bottom: 1px solid ${color('grey200')};
  flex-shrink: 0;
  position: relative;
`

export const progressAnimation = keyframes`
  0% {
      width: 10%;
      left: 0%;
    }
    50% {
      width: 40%;
      left: 30%;
    }
    100% {
      width: 10%;
      left: 90%;
    }
`

export const ProgressBarStyled = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #3ba935;
  width: 75%;
  transition: transform 0.2s linear;
  animation: ${progressAnimation} infinite alternate 0.6s linear;
`
