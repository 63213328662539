import { WithRtl } from 'utils/WithRtl'
import {
  ActionLinkButton,
  Avatar,
  AttachmentGallery,
  Banner,
  MarketingBanner,
  Box,
  BreadcrumbV2 as Breadcrumb,
  ButtonV2 as Button,
  StatusButtonV2 as StatusButton,
  Checkbox,
  LineChart,
  LinkCell,
  DonutChart,
  DropdownV2 as Dropdown,
  SearchV2 as Search,
  ErrorText,
  Header,
  HeaderButton,
  Heading,
  HelperText,
  Icon,
  TooltipV2 as Tooltip,
  InfoBar,
  InfoboxV2 as Infobox,
  FormInfo,
  Flex,
  FlexCell,
  Grid,
  GridCell,
  Loading,
  Modal,
  Message,
  Range,
  Spacing,
  Switch,
  TabV2 as Tab,
  TabListV2 as TabList,
  TabPanelV2 as TabPanel,
  TabsV2 as Tabs,
  TextareaV2 as Textarea,
  Typography,
  Accordion,
  FileUpload,
  DividerWithText,
  HyperLink,
  FlowChart,
  SheetCell,
  generateFlow,
  Menu,
  MenuItem,
  Daypicker as DatePicker,
  Rangepicker as DateRangeSelector,
  Radio,
  RadioGroup,
  Table,
  TableMapHeader,
  sendNotification,
  sendNotificationModal,
  BreadcrumbItem,
  Calendar,
  DrawerV2 as Drawer,
  TransferV2 as Transfer,
  ImageViewer,
  AudioPlayer,
  TimePickerV2 as TimePicker,
  ToastContainer,
  CalendarContainer,
  CurrencyText,
  VerticalTab,
  VerticalTabList,
  VerticalTabs,
  VerticalTabPanel,
  Timeline,
  BadgeV2 as Badge,
  HelpIcon,
  ProgressBar,
  CollapsibleCard,
  Tag,
  Toggle,
} from '@evrekadev/evreka-ui-components'
import { Map } from 'components/Map'
import { ButtonGroup } from 'components/ButtonGroup'
import { ComponentVariant, WithTranslation } from 'utils/WithTranslation'
import InputText from './InputText'
import InputNumber from './InputNumber'
import InputPassword from './InputPassword'

const WithRtlDropdown = WithRtl(Dropdown)
const WithTranslatedDropdown = WithTranslation(WithRtlDropdown, ComponentVariant.DROPDOWN)
const WithTranslatedSearch = WithTranslation(Search, ComponentVariant.SEARCH)
const WithRtlAvatar = WithRtl(Avatar)
const WithRtlCheckbox = WithRtl(Checkbox)
const WithRtlBreadcrumb = WithRtl(Breadcrumb)
const WithRtlRange = WithRtl(Range)
const WithRtlSwitch = WithRtl(Switch)
const WithRtlDateRangeSelector = WithRtl(DateRangeSelector)
const WithRtlDatepicker = WithRtl(DatePicker)
const WithRtlMap = WithRtl(Map)
const WithRtlTable = WithRtl(Table)
const WithRtlModal = WithRtl(Modal)

export {
  WithRtlMap as Map,
  WithTranslatedDropdown as Dropdown,
  WithRtlCheckbox as Checkbox,
  WithRtlBreadcrumb as Breadcrumb,
  WithRtlDateRangeSelector as DateRangeSelector,
  WithRtlDatepicker as DatePicker,
  WithTranslatedSearch as Search,
  WithRtlAvatar as Avatar,
  ActionLinkButton,
  AttachmentGallery,
  Banner,
  MarketingBanner,
  Box,
  Button,
  ButtonGroup,
  StatusButton,
  ErrorText,
  FlowChart,
  generateFlow,
  Header,
  HeaderButton,
  Heading,
  HelperText,
  Icon,
  Tooltip,
  InfoBar,
  Infobox,
  InputNumber,
  InputText,
  LinkCell,
  Flex,
  FlexCell,
  Grid,
  GridCell,
  Loading,
  Menu,
  MenuItem,
  WithRtlModal as Modal,
  Message,
  Radio,
  RadioGroup,
  Spacing,
  WithRtlSwitch as Switch,
  Tab,
  LineChart,
  DonutChart,
  WithRtlTable as Table,
  Textarea,
  Typography,
  sendNotification,
  sendNotificationModal,
  Accordion,
  FormInfo,
  SheetCell,
  WithRtlRange as Range,
  FileUpload,
  Calendar,
  Drawer,
  Transfer,
  TabList,
  TabPanel,
  Tabs,
  TableMapHeader,
  DividerWithText,
  HyperLink,
  BreadcrumbItem,
  ImageViewer,
  AudioPlayer,
  TimePicker,
  ToastContainer,
  CalendarContainer,
  CurrencyText,
  VerticalTab,
  VerticalTabList,
  VerticalTabs,
  VerticalTabPanel,
  Timeline,
  Badge,
  HelpIcon,
  ProgressBar,
  CollapsibleCard,
  Tag,
  Toggle,
  InputPassword,
}

export * from './CustomIcons'
