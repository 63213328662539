import { i18n, TOptions } from 'i18next'
import { useTranslation as translation, UseTranslationOptions } from 'react-i18next'

export type TranslationKey = string | TemplateStringsArray | (string | TemplateStringsArray)[]
export type TranslationOptions = string | TOptions<any> | undefined

export const useTranslation = (
  masterKey?: string,
  ns?: string | undefined,
  options?: UseTranslationOptions<string> | undefined,
): any => {
  const translationProps = translation(ns, options)
  const t = (key: TranslationKey, options?: TranslationOptions) => {
    const mKey = masterKey ? `${masterKey}.${key}` : `${key}`
    return translationProps.t(mKey, options)
  }
  return { ...translationProps, t: t }
}
