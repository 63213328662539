import React, { useCallback, useEffect, useState, useMemo, Fragment } from 'react'
import { MapContainer, ZoomControl } from 'react-leaflet'
import L, { LatLngTuple } from 'leaflet'
import { debounce, isArray } from 'lodash-es'
import {
  UilLocationArrow,
  UilFilter,
  UilExpandArrows,
  UilShrink,
  UilCompress,
  UilCompressArrows,
  UilFocus,
} from '@iconscout/react-unicons'
import { Button, Flex, FlexCell, Grid, GridCell, Loading, Typography, Spacing } from 'components'
import { MapInfoArea } from 'components/Map/MapInfoArea'
import { InfoAreaType, MapContextType } from 'components/Map/types'
import { ThemeColors } from '@evrekadev/evreka-ui-components'
import MarkerClusterControl from './MarkerClusterControl'
import { MapControl } from './MapControl'
import { LayerControl } from './LayerControl'
import { BoundControl } from './BoundControl'
import { FilterSection } from './FilterSection'
import MapEventsEmitter from './MapEventsEmitter'
import {
  MapStyled,
  FilterButtonStyled,
  FilterAreaStyled,
  FitToMarkersButtonStyled,
} from './Map.style'
import { mirrorFetch, parseFilters, setSerializableMapBounds } from './utils'
import { useTranslation } from 'utils/useTranslation'
import { useInternalMapParams } from 'utils/useInternalMapParams'
import { Polygons } from './Polygons'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet/dist/leaflet.css'
import { GEOCODE_API } from 'components/Map/contants'
import { getCustomMapMarkerIcon } from './icons/utils'
import { MarkerBulkSelection } from './MapMarkers/MarkerBulkSelection'
import SelectedMarkers from './MapMarkers/SelectedMarkers'
import { MapSearch as MapSearchNew } from 'components/Map/MapSearch/MapSearch'
import { MapContextProvider } from 'context'
import { CustomMarkerProps, MapMarkers } from './MapMarkers'
import { ShowBoundOrCenter } from './types'
import { renderCircles, renderPolyLines } from './utils'
import LegendComponent from './LegendComponent'
import { MapEvents } from './MapEvents'

export const iconMarkup = getCustomMapMarkerIcon(ThemeColors.darkblue900, false)

export const Map: React.FC<React.PropsWithChildren<MapContextType>> = ({ ...props }) => {
  const { t, i18n } = useTranslation('components.map')
  const { t: tGlobal } = useTranslation()

  const [isFilterVisible, setFilterVisibility] = useState(false)
  const [isFilterIconEmpty, setIconType] = useState(false)
  const [map, setMap] = useState<L.Map | null>(null)

  const [infoArea, setInfoArea] = useState<InfoAreaType>({ isOpen: false })
  const [infoAreaContent, setInfoAreaContent] = useState<React.ReactNode>(null)

  const [currentAddress, setCurrentAddress] = useState<string>()

  const {
    filterByValues: filters,
    setFilterBy: setFilters,
    searchParams,
    setSearchParams,
  } = useInternalMapParams(props.name || null, '', props.disableInternalParams)

  const onFilterChange = debounce(
    useCallback(
      (id: string, value: string | string[], type: string) => {
        setFilters((prev) => parseFilters(prev, i18n, id, value, type))
      },
      [setFilters, i18n],
    ),
    100,
  )

  const hasHeader =
    !props.noHeader &&
    (props.heading ||
      (props.mapCaptionButtons && props.mapCaptionButtons.length > 0) ||
      props.columns ||
      props.customFilterArea ||
      props.backToListView ||
      props.bulkActions ||
      (props.extraMapHeaderButtons && props.extraMapHeaderButtons.length > 0))

  const renderFilterButton = () => {
    return (
      <Grid columns={2} gap={1} justifyContent="center">
        <GridCell>
          <FilterButtonStyled isFilterVisible={isFilterVisible}>
            <Button
              iconLeft={UilFilter}
              color={isFilterIconEmpty ? 'secondary' : 'primary'}
              variant="text"
              onClick={() => {
                setFilterVisibility(!isFilterVisible)
              }}
              onlyIcon
            ></Button>
          </FilterButtonStyled>
        </GridCell>
        <FilterAreaStyled isFilterVisible={isFilterVisible}>
          {props.isFilter && props.isFilter(setFilterVisibility, setIconType)}
        </FilterAreaStyled>
      </Grid>
    )
  }

  const updateInfoAreaContent = useCallback(
    (changeType: InfoAreaType['type']) => {
      let newContent: React.ReactNode

      switch (changeType) {
        case 'filter':
          newContent = props.customFilterArea ? (
            props.customFilterArea
          ) : (
            <FilterSection
              filters={filters}
              onFetch={props.onFetch}
              onFilterChange={onFilterChange}
              setFilters={setFilters}
              setSearchParams={setSearchParams}
              columns={props.columns}
              timezone={props.timezone}
            />
          )
          break
        default:
          newContent = <p>Error</p>
          break
      }

      setInfoAreaContent(newContent)
    },
    [props.columns, filters],
  )

  const onInfoAreaChange = (changeType: InfoAreaType['type']) => {
    const { isOpen, type } = infoArea

    if (type === changeType && isOpen) {
      setInfoArea((prevState) => {
        return { ...prevState, isOpen: false }
      })
    } else {
      setInfoArea({ isOpen: true, type: changeType })
      updateInfoAreaContent(changeType)
    }
  }

  useEffect(() => {
    if (infoArea.type === 'filter') {
      updateInfoAreaContent(infoArea.type)
    }
  }, [infoArea, updateInfoAreaContent])

  useEffect(() => {
    if (map) {
      if (props.onBoundChange) {
        props.onBoundChange(setSerializableMapBounds(map.getBounds()))
      }
      props.getMapInstance && props.getMapInstance(map)
    }
  }, [map])

  const showBoundOrCenter: ShowBoundOrCenter = {
    center: props.location ? props.location : !props.bounds ? ([0, 0] as LatLngTuple) : undefined,
  }

  useEffect(() => {
    if (!props.location) {
      return
    }
    map?.panTo(props.location)
    if (props.noAddressCheck) {
      return
    }
    const locationString =
      'lat' in props.location
        ? `${props.location.lat},${props.location.lng}`
        : isArray(props.location) && `${props.location[0]},${props.location[1]}`
    locationString &&
      (async () => {
        const res = await mirrorFetch(
          `${GEOCODE_API}latlng=${locationString}&key=${process.env.REACT_APP_GOOGLE_SEARCH_API_KEY}`,
        )
        setCurrentAddress(res?.results?.[0]?.formatted_address ?? '')
        props.setAddress && props.setAddress(res?.results?.[0]?.formatted_address ?? '')
      })()
  }, [props.location])

  useEffect(() => {
    currentAddress && props.onAddressChange && props.onAddressChange(currentAddress)
  }, [currentAddress, props.onAddressChange])

  useEffect(() => {
    if (props.isEnabledBulkSelection) {
      setInfoArea({ isOpen: false })
      setInfoAreaContent(null)
    }
  }, [props.isEnabledBulkSelection])

  useEffect(() => {
    props.bounds && map?.flyToBounds(props.bounds)
  }, [props.bounds])

  const defaultMapProps = useMemo(() => {
    return {
      isLayerControlVisible:
        props.isLayerControlVisible === false ? props.isLayerControlVisible : true,
      isZoomControlVisible:
        props.isZoomControlVisible === false ? props.isZoomControlVisible : true,
      renderMapSearch: props.renderMapSearch === true ? props.renderMapSearch : false,
      isClusteringEnabled: props.isClusteringEnabled === false ? props.isClusteringEnabled : true,
      scrollWheelZoom: props.scrollWheelZoom === false ? props.scrollWheelZoom : true,
    }
  }, [
    props.isLayerControlVisible,
    props.isZoomControlVisible,
    props.renderMapSearch,
    props.isClusteringEnabled,
    props.scrollWheelZoom,
  ])

  const modifiedMapMarkers = useMemo(() => {
    if (!props.markers) return []
    if (!defaultMapProps.isClusteringEnabled) return props.markers

    let markersData: CustomMarkerProps[] = []
    markersData = props.markers.map((marker) => ({
      ...marker,
      isClusterable: marker.isClusterable === false ? marker.isClusterable : true,
    }))
    return markersData
  }, [props.markers, defaultMapProps.isClusteringEnabled])

  const renderMapMarkers = useCallback(() => {
    if (!props.markers) return null

    if (!defaultMapProps.isClusteringEnabled) {
      return <MapMarkers markers={modifiedMapMarkers} />
    }

    const clusterableMarkers = (modifiedMapMarkers as CustomMarkerProps[]).filter(
      (markerData) => markerData.isClusterable,
    )
    const unclusterableMarkers = (modifiedMapMarkers as CustomMarkerProps[]).filter(
      (markerData) => !markerData.isClusterable,
    )

    return (
      <Fragment>
        {clusterableMarkers.length ? (
          <MarkerClusterControl
            showCoverageOnHover={false}
            maxClusterRadius={40}
            removeOutsideVisibleBounds={false}
            // onClick={(e) => console.log('cluster clicked!', e)}
            // onContextMenu={(e) => console.log('onContextMenu!', e)}
          >
            <MapMarkers markers={clusterableMarkers} />
          </MarkerClusterControl>
        ) : null}
        {unclusterableMarkers.length ? <MapMarkers markers={unclusterableMarkers} /> : null}
      </Fragment>
    )
  }, [
    defaultMapProps.isClusteringEnabled,
    props.markers,
    props.popup,
    props.icon,
    props.selectedMarkers,
    props.isEnabledBulkSelection,
    props.onMarkerSelect,
  ])

  useEffect(() => {
    map?.setZoom(props?.zoom ?? 12)
  }, [props.zoom, map])

  //TODO: maxzoom prevents unexpecting internal error when mousedown with scroll happen
  return (
    <MapContextProvider {...props} icon={iconMarkup}>
      <MapStyled data-testid={props.dataTestId}>
        {hasHeader && <MapControl onInfoAreaChange={onInfoAreaChange} />}
        <div style={{ position: 'relative', height: hasHeader ? 'calc(100% - 60px)' : '100%' }}>
          {props.isEnabledBulkSelection && <SelectedMarkers />}
          <MapContainer
            fullscreenControl={!props.customFullScreen}
            scrollWheelZoom={defaultMapProps.scrollWheelZoom}
            zoomControl={false}
            maxZoom={20}
            zoom={props?.zoom ?? 12}
            bounds={props.bounds}
            ref={setMap}
            worldCopyJump
            {...showBoundOrCenter}
          >
            <MapEventsEmitter />
            {defaultMapProps.isZoomControlVisible && (
              <ZoomControl zoomInTitle={t('zoomin')} zoomOutTitle={t('zoomout')} />
            )}
            {!!props.customFullScreen && (
              <FitToMarkersButtonStyled
                style={{
                  zIndex: 500,
                }}
                variant="outline"
                size="medium"
                color="secondary"
                onlyIcon
                iconLeft={props.customFullScreen.isFullscreen ? UilCompress : UilFocus}
                top={84}
                onClick={props.customFullScreen.onFullscreenChange}
              />
            )}
            {props.onFitToMarkers && (
              <FitToMarkersButtonStyled
                size="medium"
                disabled={false}
                variant="outline"
                color="secondary"
                iconLeft={UilLocationArrow}
                onClick={props.onFitToMarkers}
                onlyIcon
              />
            )}
            <BoundControl />
            {defaultMapProps.isLayerControlVisible && <LayerControl />}
            {renderMapMarkers()}
            {!!props.legend && (
              <LegendComponent
                legendExtraSpace={props.legend.legendExtraSpace}
                legendItems={props.legend.legendItems}
                isRtl={props.isRtl}
              />
            )}
            {props.isFilter && renderFilterButton()}
            {infoArea.isOpen && infoAreaContent && <MapInfoArea content={infoAreaContent} />}
            <Polygons polygons={props.polygons} customKey={props.polygonCustomKey} />
            {renderPolyLines(props.polylines)}
            {renderCircles(props.circles)}
            {defaultMapProps.renderMapSearch && <MapSearchNew />}
            {props.onBulkSelection && <MarkerBulkSelection />}
            {props.children}
            <MapEvents />
          </MapContainer>
        </div>
      </MapStyled>
    </MapContextProvider>
  )
}
