import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  RegionEditDetailRequest,
  RegionEditDetailResponse,
  RegionListRequest,
  RegionListResponse,
  RegionUpsertRequest,
  RegionUpsertResponse,
  RegionDeleteResponse,
  RegionDeleteRequest,
  RegionsServicePointsResponse,
  RegionsServicePointsRequest,
  RegionsResponse,
  RegionsRequest,
  RegionItem,
} from './types'
import { sendNotification } from 'components'
import { servicePointApi } from './servicePoints'
import { GenericResponse } from 'services/types'
import { staticTourApi } from './staticTours'

export const regionApi = createApi({
  reducerPath: 'regionApi',
  tagTypes: ['Regions', 'RegionDetail', 'RegionSearch', 'MapListRegion'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getRegions: builder.query<RegionListResponse, RegionListRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/region/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Regions'],
    }),
    getEditRegionDetail: builder.query<RegionEditDetailResponse, RegionEditDetailRequest>({
      query: ({ clientId, id }) => ({
        url: `clients/${clientId}/ops_management/edit_region_detail/${id}`,
        method: 'GET',
      }),
      providesTags: (_result, _error, { id }) => [{ type: 'Regions', id }],
    }),
    getRegionsServicePoints: builder.query<
      RegionsServicePointsResponse,
      RegionsServicePointsRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/region/get_region_service_points/`,
        method: 'POST',
        data,
      }),
      providesTags: (_result, _error, { region_id }) => [{ type: 'Regions', region_id }],
    }),
    addRegion: builder.mutation<RegionUpsertResponse, RegionUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/region/add/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Regions', 'RegionSearch', 'MapListRegion'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editRegion: builder.mutation<RegionUpsertResponse, RegionUpsertRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/region/edit/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Regions', 'RegionDetail', 'MapListRegion'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          dispatch(servicePointApi.util.invalidateTags(['ServicePoints', 'ServicePointDetail']))
          dispatch(
            staticTourApi.util.invalidateTags([
              'StaticTourDetail',
              'SpItineraryList',
              'KmlList',
              'StaticTours',
            ]),
          )

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteRegion: builder.mutation<RegionDeleteResponse, RegionDeleteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/region/delete/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Regions', 'MapListRegion'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          dispatch(servicePointApi.util.invalidateTags(['ServicePoints']))

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getRegionOptions: builder.query<RegionsResponse, RegionsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/region/search/`,
        method: 'POST',
        data: {
          ...data,
          query_param: '',
        },
      }),
      providesTags: ['RegionSearch'],
      transformResponse(res: any) {
        return res.dump_location_list
      },
    }),
    getRegionMapList: builder.query<
      { map_list_region: Array<RegionItem> } & GenericResponse,
      { clientId: number }
    >({
      query: ({ clientId }) => ({
        url: `clients/${clientId}/ops_management/map_list_region/`,
        method: 'GET',
      }),
      providesTags: ['MapListRegion'],
    }),
  }),
})

export const {
  useAddRegionMutation,
  useDeleteRegionMutation,
  useGetRegionsQuery,
  useGetRegionsServicePointsQuery,
  useEditRegionMutation,
  useGetRegionOptionsQuery,
  useGetRegionMapListQuery,
} = regionApi
