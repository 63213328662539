import { useState } from 'react'
import { getActionMenu, ThemeColors } from '@evrekadev/evreka-ui-components'
import { useTranslation } from 'utils/useTranslation'
import { UilUser } from '@iconscout/react-unicons'

export const useCustomerPortalProfileModals = () => {
  const [isUpdateProfileModalOpen, setIsUpdateProfileModalOpen] = useState(false)
  const [isChangePasswordModalOpen, setIsPasswordModalOpen] = useState(false)
  const { t: tCustomerPortal } = useTranslation(
    'pages.engagement.entity_management.details.customer_portal',
  )

  const closeUpdateProfileModal = () => {
    setIsUpdateProfileModalOpen(false)
  }

  const closeChangePasswordModal = () => {
    setIsPasswordModalOpen(false)
  }

  const ActionMenu = getActionMenu(
    [
      {
        label: tCustomerPortal('update_profile'),
        onClick: () => setIsUpdateProfileModalOpen(true),
      },
      {
        label: tCustomerPortal('change_password'),
        onClick: () => setIsPasswordModalOpen(true),
      },
    ],
    {
      returnComponent: true,
      menuStyle: { backgroundColor: ThemeColors['darkblue900'], marginRight: 16 },
      icon: { icon: UilUser, color: 'white', size: 20 },
      dataTestId: 'customer-portal-action-menu',
    },
  )

  return {
    isUpdateProfileModalOpen,
    closeUpdateProfileModal,
    isChangePasswordModalOpen,
    closeChangePasswordModal,
    ActionMenu,
    setIsPasswordModalOpen,
    setIsUpdateProfileModalOpen,
  }
}
