import {
  ActionMenuItem,
  ActionSubMenu,
  ControlledMenu,
  Icon,
  MenuItem,
  Typography,
  color,
  padding,
  shadow,
  useMenuState,
} from '@evrekadev/evreka-ui-components'
import {
  UilBuilding,
  UilDirection,
  UilEnglishToChinese,
  UilKeySkeleton,
  UilLockOpenAlt,
  UilSignInAlt,
  UilUser,
  UilWebSectionAlt,
} from '@iconscout/react-unicons'
import { Avatar, Flex } from 'components'
import styled from 'styled-components'
import { semanticSidebarColors } from './Sidebar.style'
import { SidebarColorTheme } from './Sidebar'
import { useSelector } from 'react-redux'
import { selectClientId, selectCurrentLanguage, selectCurrentUser } from 'services/slices/auth'
import { useMemo, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useSetLanguageMutation } from 'services/common'
import { useLogoutMutation } from 'services/auth'
import { changeClientId, changeSidebarTheme, selectUi } from 'services/slices/ui'
import { useLocation, useNavigate } from 'react-router'
import moment from 'moment-timezone'
import { ProfileEnvironment } from 'types/common'
import { ChangePasswordModal, UpdateProfileModal } from 'containers/Profile'
import { useCustomerPortalProfileModals } from 'utils/useCustomerPortalProfileModals'
import { CustomerPortalUpdateProfileModal } from 'containers/Profile/UpdateCustomerProfileModal'

const LANGUAGES: Record<string, string> = {
  ar: 'العربية',
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  he: 'עברית',
  ms: 'Bahasa Melayu',
  nl: 'Nederlands',
  no: 'Norsk',
  pt: 'Português',
  ru: 'Русский',
  tr: 'Türkçe',
  vi: 'Tiếng Việt',
  zh: '中文',
}

export const ProfileButton: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loc = useLocation()
  const user = useSelector(selectCurrentUser)
  const clientId = useSelector(selectClientId)
  const { sidebarTheme, sidebarCollapsed } = useSelector(selectUi)
  const language = useSelector(selectCurrentLanguage)
  const buttonAnchor = useRef<HTMLButtonElement>(null)
  const menuRef = useRef<HTMLElement>(null)
  const [{ state }, open] = useMenuState({ unmountOnClose: true })

  const profileEnvironment: ProfileEnvironment = useMemo(() => {
    if (loc.pathname.includes('customer_portal')) {
      return 'customer-portal'
    }
    if (loc.pathname.includes('user_management')) {
      return 'user-management'
    }
  }, [loc])

  const toggleOpen = () => {
    if (state === 'open' || state === 'opening') {
      open(false)
    } else {
      open(true)
    }
  }

  const closeMenu = () => {
    open(false)
  }

  const [setLanguage] = useSetLanguageMutation()
  const [logout] = useLogoutMutation()

  const handleLogout = () => {
    logout(null)
      .unwrap()
      .then(() => {
        navigate(`/login`)
      })
  }

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang)
      .unwrap()
      .finally(() => window.location.reload())
  }

  const selectedClient = user?.clients.find(
    ({ value }) => value.toString() === clientId?.toString(),
  )

  const onChangeClient = (val: string) => {
    dispatch(changeClientId(val))
    // navigate to homepage when client changes
    navigate(`/`, { replace: true })
    window.location.reload()
  }

  const clientOptions =
    user?.clients.map(({ label, value, timezone }) => ({
      label,
      onClick: () => onChangeClient(value.toString()),
      description: `${timezone} (${moment.tz(timezone).format('Z')})`,
    })) ?? []

  const handleThemeChange = () => {
    dispatch(changeSidebarTheme(sidebarTheme === 'dark' ? 'light' : 'dark'))
  }

  const {
    isUpdateProfileModalOpen,
    closeUpdateProfileModal,
    isChangePasswordModalOpen,
    closeChangePasswordModal,
    ActionMenu,
    setIsPasswordModalOpen,
    setIsUpdateProfileModalOpen,
  } = useCustomerPortalProfileModals()

  return (
    <>
      <ProfileButtonStyled ref={buttonAnchor} onClick={toggleOpen} data-testid="profile-menu">
        <AvatarWrapper>
          <Avatar color="green" text={user?.username} size="sm" />
        </AvatarWrapper>
        <Flex
          directionColumn="zero"
          style={{
            flexGrow: 1,
            opacity: sidebarCollapsed ? 0 : 1,
            transition: 'opacity 0.3s ease-in-out',
          }}
        >
          <Typography
            fontSize="text_sm"
            fontWeight="medium"
            color={semanticSidebarColors['text'][sidebarTheme]}
          >
            {user?.username}
          </Typography>
          <Typography
            fontSize="text_xs"
            fontWeight="regular"
            style={{ margin: 0 }}
            color={semanticSidebarColors['text-muted'][sidebarTheme]}
          >
            {user?.email}
          </Typography>
        </Flex>
        <Icon
          icon={UilDirection}
          size={20}
          color={semanticSidebarColors['text-muted'][sidebarTheme]}
          style={{ opacity: sidebarCollapsed ? 0 : 1, transition: 'opacity 0.3s ease-in-out' }}
        />
      </ProfileButtonStyled>
      <MenuStyled
        direction={sidebarCollapsed ? 'right' : 'top'}
        menuStyle={{
          width: sidebarCollapsed ? '200px' : buttonAnchor.current?.offsetWidth,
        }}
        gap={8}
        anchorRef={buttonAnchor}
        onClose={closeMenu}
        state={state}
        portal
        ref={menuRef}
      >
        <ActionMenuItem
          label="Update Profile"
          icon={UilUser}
          onClick={() => setIsUpdateProfileModalOpen(true)}
          dataTestId="update-profile"
        />
        <ActionMenuItem
          label="Change Password"
          icon={UilLockOpenAlt}
          onClick={() => setIsPasswordModalOpen(true)}
          dataTestId="change-password"
        />
        {/* <ActionMenuItem
          label="Client"
          submenu={clientOptions}
          icon={UilBuilding}
          description={selectedClient?.label ?? 'Select Client...'}
        /> */}
        <ActionMenuItem
          label="Language"
          submenu={Object.entries(LANGUAGES).map(([key, value]) => ({
            label: value,
            onClick: () => handleLanguageChange(key),
          }))}
          icon={UilEnglishToChinese}
          description={LANGUAGES[language]}
          dataTestId="language"
        />
        <ActionMenuItem separator />
        <ActionMenuItem
          icon={UilWebSectionAlt}
          label="Dark Side Bar"
          toggle={{
            value: sidebarTheme === 'dark',
            onChange: handleThemeChange,
          }}
          onClick={handleThemeChange}
          dataTestId="dark-sidebar"
        />
        <ActionMenuItem separator />
        <ActionMenuItem
          dataTestId="logout"
          icon={UilSignInAlt}
          label="Log Out"
          color="red"
          onClick={handleLogout}
        />
      </MenuStyled>
      {profileEnvironment !== 'customer-portal' ? (
        <UpdateProfileModal
          closeModal={closeUpdateProfileModal}
          isOpen={isUpdateProfileModalOpen}
        />
      ) : (
        <CustomerPortalUpdateProfileModal
          isOpen={isUpdateProfileModalOpen}
          closeModal={closeUpdateProfileModal}
        />
      )}
      <ChangePasswordModal
        environment={profileEnvironment}
        closeModal={closeChangePasswordModal}
        isOpen={isChangePasswordModalOpen}
      />
    </>
  )
}

const ProfileButtonStyled = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
`

const AvatarWrapper = styled.div`
  ${padding([4])}
  ${shadow('xs')}
  background-color: ${color('white')};
  border-radius: 50%;
  border: 1px solid ${color('grey100')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const MenuStyled = styled(ControlledMenu)`
  [data-testid='submenu'] {
    max-height: 400px;
    overflow: auto;
  }
`
