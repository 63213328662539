import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  MutationResponse,
  TaskOrderDeleteRequest,
  TaskOrderDeleteResponse,
  TaskOrdersRequest,
  TaskOrdersResponse,
  TaskPlanDetailsRequest,
  TaskPlanDetailsResponse,
  CalendarDetailResponse,
  CalendarDetailTransformedResponse,
  CalendarResponse,
  TaskPlanCalendarDetailRequest,
  TaskPlanCalendarListRequest,
  ActionNoteRequest,
} from './types'
import { DATE_FORMAT, sendNotification } from '@evrekadev/evreka-ui-components'
import { HistoryResponse, HistoryRequest } from 'containers/HistoryV2/types'
import { ActionResponse } from 'services/types'

export const taskPlansApi = createApi({
  reducerPath: 'taskPlansApi',
  tagTypes: ['TaskPlans', 'TaskPlan', 'TaskPlanHistory', 'ServiceDays'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getTaskPlanList: builder.query<TaskOrdersResponse, TaskOrdersRequest>({
      query: ({ clientId, service_point, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_task_orders/${
          service_point ? '?service_point=' + service_point : ''
        }`,
        method: 'POST',
        data,
      }),
      providesTags: ['TaskPlans'],
    }),
    getTaskPlanDetails: builder.query<TaskPlanDetailsResponse, TaskPlanDetailsRequest>({
      query: ({ clientId, taskPlanId }) => ({
        url: `clients/${clientId}/ops_management/task_order/?task_order_id=${taskPlanId}`,
        method: 'GET',
      }),
      providesTags: ['TaskPlan'],
    }),
    addTaskPlan: builder.mutation<any, any>({
      // TODO types
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/task_order/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['TaskPlans'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editTaskPlan: builder.mutation<any, TaskPlanDetailsRequest & Record<any, any>>({
      //TODO: add types
      query: ({ clientId, taskPlanId, ...data }) => ({
        url: `clients/${clientId}/ops_management/task_order/${taskPlanId}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['TaskPlan', 'TaskPlans', 'TaskPlanHistory'],
    }),
    deleteTaskPlan: builder.mutation<TaskOrderDeleteResponse, TaskOrderDeleteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/delete_task_order/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['TaskPlans', 'TaskPlan'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    taskPlanHistory: builder.query<
      HistoryResponse & MutationResponse,
      HistoryRequest & { clientId: number; taskPlanId: number }
    >({
      query({ clientId, taskPlanId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/task_order/${taskPlanId}/history/`,
          method: 'POST',
          data,
        }
      },
      providesTags: ['TaskPlanHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTaskPlanCalendar: builder.query<CalendarResponse, TaskPlanCalendarListRequest>({
      query: ({ client_id, start_date, end_date, taskPlanId }) => ({
        url: `clients/${client_id}/ops_management/task_order/${taskPlanId}/calendar/list/?start_date=${start_date}&end_date=${end_date}`,
        method: 'GET',
      }),
      providesTags: ['ServiceDays'],
    }),
    upsertNoteForTaskPlan: builder.mutation<ActionResponse, ActionNoteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/task_plan/upsert_note/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['TaskPlans'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTaskPlanCalendarDetails: builder.query<
      CalendarDetailTransformedResponse,
      TaskPlanCalendarDetailRequest
    >({
      query: ({ client_id, taskPlanId, date }) => ({
        url: `clients/${client_id}/ops_management/task_order/${taskPlanId}/calendar/date_detail/?&date=${date}`,
        method: 'GET',
      }),
      transformResponse(res: CalendarDetailResponse) {
        return {
          data: res.data.map((item) => ({
            name: item.operation_name,
            type: item.operation_type.toString(),
          })),
        }
      },
    }),
  }),
})

export const {
  useGetTaskPlanDetailsQuery,
  useLazyGetTaskPlanDetailsQuery,
  useEditTaskPlanMutation,
  useGetTaskPlanListQuery,
  useAddTaskPlanMutation,
  useDeleteTaskPlanMutation,
  useTaskPlanHistoryQuery,
  useGetTaskPlanCalendarQuery,
  useGetTaskPlanCalendarDetailsQuery,
  useUpsertNoteForTaskPlanMutation,
} = taskPlansApi
