import { createApi } from '@reduxjs/toolkit/query/react'
import {
  AddEntityRequest,
  CustomFieldsRequest,
  EditEntityRequest,
  EntityDetailResponse,
  EntityDetailRequest,
  EntitySummaryResponse,
  MutationResponse,
  DeleteEntityRequest,
  EntityListResponse,
  EntityListRequest,
  ServiceDayDetailResponse,
  ServiceDayDetailRequest,
  ServiceDayListResponse,
  ServiceDayListTransformedResponse,
  ServiceDayListRequest,
  BulkAddOrderRequest,
  BulkAddServicePointRequest,
  BulkDeleteEntityRequest,
  BulkActionResponse,
  SearchEntityRequest,
  SearchEntityResponse,
  ExportRequest,
  CustomFieldEngResponse,
  AllServicePointsOfEntityRequest,
  UnlinkServicePointRequest,
  EntityContactResponse,
  UpdateStatusRequest,
  TableResponseWithTimezone,
} from './types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { sendNotification } from 'components'
import { getExportHandler, getImportHandler } from 'utils'
import i18n from 'utils/i18n'
import { TableRequestV2, TableResponse, TableResponseV2 } from 'services/types'
import { servicePointApi } from './servicePoint'
import { orderApi } from './order'

export const entityApi = createApi({
  reducerPath: 'entityApi',
  tagTypes: [
    'Entities',
    'EntityDetail',
    'Summary',
    'EntityHistory',
    'Contacts',
    'ContactDetail',
    'EntityContacts',
    'ListAllServicePointsOfEntity',
    'ServiceDays',
    'ServiceDayDetails',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    getEntitySummary: builder.query<EntitySummaryResponse, string>({
      query: (client_id) => ({
        url: `engagement/clients/${client_id}/entity/summary/`,
        method: 'GET',
      }),
      providesTags: ['Summary'],
    }),
    getEntityList: builder.query<EntityListResponse, EntityListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/entity/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['Entities'],
    }),
    unlinkServicePoint: builder.mutation<MutationResponse, UnlinkServicePointRequest>({
      query: ({ client_id, entity_id, body }) => ({
        url: `/engagement/clients/${client_id}/entity/${entity_id}/unlink/service_point/`,
        method: 'POST',
        data: body,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(
            servicePointApi.util.invalidateTags([
              'ServicePoints',
              'ServicePointLocations',
              'ServicePointDetail',
            ]),
          )
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteEntity: builder.mutation<MutationResponse, DeleteEntityRequest>({
      query: ({ client_id, entity_id, ...data }) => ({
        url: `engagement/clients/${client_id}/entity/${entity_id}/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['Entities', 'Summary'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchEntity: builder.mutation<SearchEntityResponse, SearchEntityRequest>({
      query: ({ client_id, external_client_id, ...data }) => ({
        url: client_id
          ? `engagement/clients/${client_id}/entity/search/`
          : `engagement/external/clients/${external_client_id}/entity/search/`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getEntityDetail: builder.query<EntityDetailResponse, EntityDetailRequest>({
      query: ({ client_id, entity_id }) => ({
        url: `engagement/clients/${client_id}/entity/?entity_id=${entity_id}`,
        method: 'GET',
      }),
      providesTags: ['EntityDetail'],
    }),
    getCustomFields: builder.query<CustomFieldEngResponse, CustomFieldsRequest>({
      query: ({ clientId, type_id, ...data }) => ({
        url: `engagement/clients/${clientId}/entity_dynamic_fields/?entity_type_id=${type_id}`,
        method: 'GET',
        data,
      }),
    }),
    addEntity: builder.mutation<
      MutationResponse & { entity_id: string; entity_type: string },
      AddEntityRequest
    >({
      query: ({ clientId, body }) => ({
        url: `engagement/clients/${clientId}/entity/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Entities', 'EntityHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/entities/detail/${data.entity_type}/${data.entity_id}`,
            },
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editEntity: builder.mutation<
      MutationResponse & { entity_id: string; entity_type: string },
      EditEntityRequest
    >({
      query: ({ clientId, entity_id, body }) => ({
        url: `engagement/clients/${clientId}/entity/${entity_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Entities', 'EntityDetail', 'EntityHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
            hyperLink: {
              type: 'newTab',
              label: i18n.t('response.see_more'),
              to: `/engagement/entities/detail/${data.entity_type}/${data.entity_id}`,
            },
          })
          dispatch(
            orderApi.util.invalidateTags(['OrderPlanDetails', 'OrderPlans', 'OrderSettings']),
          )
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getServiceDayList: builder.query<ServiceDayListTransformedResponse, ServiceDayListRequest>({
      query: ({ client_id, start_date, end_date, entity_id, service_point_id }) => ({
        url: `engagement/clients/${client_id}/service_day/list/?start_date=${start_date}&end_date=${end_date}${
          entity_id ? '&entity_id=' + entity_id : ''
        }${service_point_id ? '&service_point_id=' + service_point_id : ''}`,
        method: 'GET',
      }),
      transformResponse: ({ data }: ServiceDayListResponse) => {
        const parsedList = []
        for (const [key, value] of Object.entries(data))
          parsedList.push({ date: new Date(key).toUTCString(), types: value })
        return parsedList
      },
      providesTags: ['ServiceDays'],
    }),
    getServiceDayDetail: builder.query<ServiceDayDetailResponse, ServiceDayDetailRequest>({
      query: ({ client_id, date, entity_id, service_point_id }) => ({
        url: `engagement/clients/${client_id}/service_day/detail/?date=${date}${
          entity_id ? '&entity_id=' + entity_id : ''
        }${service_point_id ? '&service_point_id=' + service_point_id : ''}`,
        method: 'GET',
      }),
      providesTags: ['ServiceDayDetails'],
    }),
    bulkAddOrder: builder.mutation<BulkActionResponse, BulkAddOrderRequest>({
      query: ({ clientId, ...data }) => ({
        url: `engagement/clients/${clientId}/entity/bulk_edit/order/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['EntityDetail'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    bulkAddServicePoint: builder.mutation<BulkActionResponse, BulkAddServicePointRequest>({
      query: ({ clientId, ...data }) => ({
        url: `engagement/clients/${clientId}/entity/bulk_edit/service_point/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['ListAllServicePointsOfEntity'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
          dispatch(servicePointApi.util.invalidateTags(['ServicePoints', 'ServicePointLocations']))
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    bulkDeleteEntity: builder.mutation<BulkActionResponse, BulkDeleteEntityRequest>({
      query: ({ clientId, ...data }) => ({
        url: `engagement/clients/${clientId}/entity/bulk_delete/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['Entities', 'EntityDetail'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          if (data.failure_list.length === 0) {
            sendNotification({
              type: 'success',
              toastContent: data.detail.message,
            })
          } else if (data.successful_list.length === 0) {
            sendNotification({
              type: 'error',
              toastContent: data.detail.message,
            })
          } else {
            let failure_list_items = data.detail.message
            data.failure_list.forEach((item) => (failure_list_items += item.description))

            sendNotification({
              type: 'warning',
              toastContent: failure_list_items,
            })
          }

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportEntity: builder.mutation<MutationResponse, ExportRequest & { entity_type_id?: string }>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/entity/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    listAllServicePointsOfEntity: builder.query<
      MutationResponse & {
        service_point_list: Array<{ service_point_id: string; service_point_name: string }>
      },
      AllServicePointsOfEntityRequest
    >({
      query: ({ external_client_id, entity_id }) => ({
        url: `engagement/clients/${external_client_id}/entity/related_list/service_points?entity_id=${entity_id}`,
        method: 'GET',
      }),
      providesTags: ['ListAllServicePointsOfEntity'],
    }),
    getEntityOrdersExternal: builder.query<
      { order_list: Array<{ value: string; label: string }> },
      { client_id: string; entity_id: string }
    >({
      query: ({ client_id, entity_id }) => ({
        url: `engagement/external/clients/${client_id}/entity/${entity_id}/list/orders/`,
        method: 'GET',
      }),
    }),
    getEntityContacts: builder.query<
      EntityContactResponse,
      { client_id: string; entity_id: string }
    >({
      query: ({ client_id, entity_id }) => ({
        url: `engagement/clients/${client_id}/entity/related_list/entity_contact/?entity_id=${entity_id}`,
        method: 'GET',
      }),
      providesTags: ['EntityContacts'],
    }),
    importEntities: builder.mutation<MutationResponse, { clientId: string; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `engagement/clients/${clientId}/entity/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Entities'],
      onQueryStarted: getImportHandler,
    }),
    entityHistory: builder.query<
      TableResponseWithTimezone<{}>,
      TableRequestV2 & {
        entity_id: string
      }
    >({
      query: ({ client_id, entity_id, ...data }) => ({
        url: `engagement/clients/${client_id}/entity/${entity_id}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['EntityHistory'],
    }),
    updateEntityStatus: builder.mutation<MutationResponse, UpdateStatusRequest>({
      query: ({ client_id, ...data }) => ({
        url: `engagement/clients/${client_id}/status/update/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Entities', 'EntityDetail', 'EntityHistory'],
    }),
  }),
})

export const {
  useGetCustomFieldsQuery,
  useAddEntityMutation,
  useEditEntityMutation,
  useGetEntityListQuery,
  useDeleteEntityMutation,
  useGetEntityDetailQuery,
  useGetEntitySummaryQuery,
  useGetServiceDayListQuery,
  useGetServiceDayDetailQuery,
  useBulkAddServicePointMutation,
  useBulkDeleteEntityMutation,
  useSearchEntityMutation,
  useExportEntityMutation,
  useListAllServicePointsOfEntityQuery,
  useUnlinkServicePointMutation,
  useGetEntityOrdersExternalQuery,
  useGetEntityContactsQuery,
  useImportEntitiesMutation,
  useEntityHistoryQuery,
  useUpdateEntityStatusMutation,
} = entityApi
