import { useMemo } from 'react'
import { DatePicker } from 'components'
import { ColumnExtended } from '@evrekadev/evreka-ui-components'
import { useTranslation } from 'utils/useTranslation'

type DatepickerFilterProps = {
  column: ColumnExtended<any>
  filterValue: string
  onChange: (id: string, value: string | string[]) => void
  type: string
}

export function DatepickerColumnFilter({
  column: { id },
  onChange,
  filterValue,
}: DatepickerFilterProps) {
  const { t } = useTranslation()
  const date = useMemo(() => filterValue || null, [filterValue])

  return (
    <DatePicker
      onChange={(date) => {
        if (date !== null) {
          onChange(id, date)
        } else {
          onChange(id, '')
        }
      }}
      placeholder={t('placeholder.default.datepicker')}
      date={date}
      id={`datepicker-${id}`}
      componentSize="small"
    />
  )
}
