import React from 'react'
import { UilUserTimes } from '@iconscout/react-unicons'
import { useTranslation } from 'utils/useTranslation'
import { Box, Flex, FlexCell, Message } from 'components'
import { LayoutList } from 'containers/Layout'

export const InvalidClient: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation('pages.common.fallback.invalid_client')

  return (
    <LayoutList>
      <Box>
        <Flex style={{ height: 'calc(100vh - 160px)' }} center="sm" middle="sm">
          <FlexCell>
            <Message
              icon={{ icon: UilUserTimes, color: 'red500' }}
              heading={t('info_header_text')}
              subtitle={t('info_text')}
              fullHeight
            />
          </FlexCell>
        </Flex>
      </Box>
    </LayoutList>
  )
}
