import styled, { css } from 'styled-components'
import { Button, Search } from 'components'
import React from 'react'
import {
  SearchPropsV2 as SearchProps,
  color,
  fontSize,
  fontWeight,
  shadow,
  borderRadius,
  padding,
} from '@evrekadev/evreka-ui-components'
import { circle, rotate, move, polygon, edit, rectangle, mapLayers, erase } from './icons'
import { MapContextType } from './types'

export const MapStyled = styled.div`
  height: 100%;

  .leaflet-control-zoom {
    ${shadow('xs')}
    ${borderRadius(4)}
    ${padding([2])}
    border: none;
    background-color: ${color('white')};
  }
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    font-family: 'Open-Sans';
    ${fontSize('text_sm')};
    ${fontWeight('bold')}
    color: ${color('darkblue500')};
    &.leaflet-disabled {
      color: ${color('grey300')};
      :hover {
        color: ${color('grey300')};
      }
    }
    :hover {
      color: ${color('darkblue900')};
    }
  }

  .leaflet-pm-toolbar {
    ${fontSize('text_sm')};

    .leaflet-pm-icon-rectangle {
      background-image: url(${rectangle});
    }

    .leaflet-pm-icon-polygon {
      background-image: url(${polygon});
    }

    .leaflet-pm-icon-circle {
      background-image: url(${circle});
    }

    .leaflet-pm-icon-edit {
      background-image: url(${edit});
    }

    .leaflet-pm-icon-drag {
      background-image: url(${move});
    }

    .leaflet-pm-icon-delete {
      background-image: url(${erase});
    }

    .leaflet-pm-icon-rotate {
      background-image: url(${rotate});
    }
  }

  .leaflet-control-layers-toggle {
    background-image: url(${mapLayers});
  }

  .leaflet-tooltip {
    ${fontSize('text_xs')};
    padding: 3px;
    word-wrap: break-word;
  }

  .leaflet-popup-content-wrapper {
    padding: 1.5rem 2rem;
  }

  .leaflet-container a.leaflet-popup-close-button {
    padding: 4px 0 0 0;
    height: 2rem;
    width: 2rem;
  }

  // hide leaflet
  .leaflet-control-attribution {
    display: none;
  }
  .leaflet-control-layers {
    ${borderRadius(8)}
    ${fontSize('text_xs')}
    ${fontWeight('regular')}
    line-height: 1rem;
    font-style: normal;
    margin-bottom: 1.5rem;
  }

  .leaflet-control-layers-expanded {
    padding: 0.5rem;
  }
`

const ForwardRefSearch = React.forwardRef<any, SearchProps>((props, ref) => (
  <Search {...(props as any)} ref={ref} />
))

export const SearchStyled = styled(ForwardRefSearch)`
  ${borderRadius(4)}
  background-color: ${color('white')};
  position: absolute;
  z-index: 1000;
  left: 15%;
  top: 10px;
  max-width: 60%;
  display: block;

  .map_search__control {
    display: flex;
  }

  .map_search__value-container {
    display: flex;
    flex-grow: 1;
    align-items: center;
    width: 50%;
  }

  .map_search__input-container {
    width: auto;
  }

  .map_search__single-value:empty + .map_search__input-container {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .map_search__single-value:not(:empty) + .map_search__input-container {
    display: none;
  }
`

export const FilterButtonStyled = styled.div<{ isFilterVisible: boolean }>`
  ${borderRadius(4)}
  ${padding([8])}
  background-color: ${color('white')};
  cursor: default;
  left: unset;
  right: ${(props) => (props.isFilterVisible ? '49em' : '1em')};
  top: 10px;
  position: absolute;
  z-index: 401;
`

export const FilterAreaStyled = styled.div<{ isFilterVisible: boolean }>`
  display: ${(props) => (props.isFilterVisible ? 'show' : 'none')};
`

export const MapHeaderStyled = styled.div`
  overflow: hidden;
  border: 1px solid ${color('grey200')};
  border-bottom: none;
  border-radius: ${(props) => props.theme.borderRadiuses[12]}
    ${(props) => props.theme.borderRadiuses[12]} 0 0;
`
export const MapStaticImageStyled = styled.div<{ heading?: boolean }>`
  height: ${(props) => (props.heading ? 'calc(100% - 60px)' : '100%')};
  width: 100%;
  background-image: url('/images/static_map.jpeg');
  filter: blur(4px);
  object-fit: fill;
`
export const MapErrorStyled = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const MapErrorContainerStyled = styled.div<{ heading?: boolean }>`
  height: ${(props) => (props.heading ? 'calc(100% - 60px)' : '100%')};
  width: 100%;
  position: relative;
`

export const OverlayStyled = styled.div<{ heading?: boolean }>`
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: ${(props) => (props.heading ? 'calc(100% - 60px)' : '100%')};
  background-color: rgba(0, 0, 0, 0.185);
  z-index: 2;
  border: 1px solid ${color('grey300')};
  border-top: none;
  ${(props) =>
    props.heading
      ? css`
          border-bottom-right-radius: ${(props) => props.theme.borderRadiuses[4]};
          border-bottom-left-radius: ${(props) => props.theme.borderRadiuses[4]};
        `
      : css`
          border-radius: ${(props) => props.theme.borderRadiuses[4]};
        `}
`

export const FitToMarkersButtonStyled = styled(Button)<{ top?: number }>`
  position: absolute;
  z-index: 1000;
  /* width: 34px !important;
  height: 34px !important; */
  top: ${({ top }) => (top ? `${top}px` : '128px')};
  left: 10px;
  /* border-radius: 4px; */
  /* background-color: #fff; */
  /* justify-content: center; */
`
