import { MapContext } from 'context'
import React, { RefObject, useContext, useEffect, useRef } from 'react'
import { useMap } from 'react-leaflet'
import { LegendStyled } from './MapInfoArea.style'
import { MapContextType } from './types'

export type MapInfoAreaProps = {
  content?: React.ReactNode
  ref?: RefObject<HTMLDivElement>
}

export const MapInfoArea: React.FC<React.PropsWithChildren<MapInfoAreaProps>> = ({ content }) => {
  const infoAreaRef = useRef<HTMLDivElement>(null)
  const parentMap = useMap()

  const { isRtl } = useContext<MapContextType>(MapContext)

  useEffect(() => {
    if (infoAreaRef.current) {
      if (infoAreaRef.current) {
        infoAreaRef.current.addEventListener('mouseenter', () => {
          parentMap.dragging.disable()
          parentMap.doubleClickZoom.disable()
          parentMap.scrollWheelZoom.disable()
        })
        infoAreaRef.current.addEventListener('mouseleave', () => {
          parentMap.dragging.enable()
          parentMap.doubleClickZoom.enable()
          parentMap.scrollWheelZoom.enable()
        })
      }
    }
  }, [infoAreaRef])

  return (
    <LegendStyled isRtl={isRtl} ref={infoAreaRef}>
      {content}
    </LegendStyled>
  )
}
