import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const BinIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <path
        d="M27.1873 15.2859H23.9873V14.4859C23.9873 13.8494 23.7345 13.239 23.2844 12.7889C22.8343 12.3388 22.2239 12.0859 21.5873 12.0859H19.9873C19.3508 12.0859 18.7404 12.3388 18.2903 12.7889C17.8402 13.239 17.5873 13.8494 17.5873 14.4859V15.2859H14.3873C14.1752 15.2859 13.9717 15.3702 13.8217 15.5203C13.6716 15.6703 13.5873 15.8738 13.5873 16.0859C13.5873 16.2981 13.6716 16.5016 13.8217 16.6516C13.9717 16.8017 14.1752 16.8859 14.3873 16.8859H15.1873V25.6859C15.1873 26.3225 15.4402 26.9329 15.8903 27.383C16.3404 27.8331 16.9508 28.0859 17.5873 28.0859H23.9873C24.6239 28.0859 25.2343 27.8331 25.6844 27.383C26.1345 26.9329 26.3873 26.3225 26.3873 25.6859V16.8859H27.1873C27.3995 16.8859 27.603 16.8017 27.753 16.6516C27.9031 16.5016 27.9873 16.2981 27.9873 16.0859C27.9873 15.8738 27.9031 15.6703 27.753 15.5203C27.603 15.3702 27.3995 15.2859 27.1873 15.2859ZM19.1873 14.4859C19.1873 14.2738 19.2716 14.0703 19.4217 13.9203C19.5717 13.7702 19.7752 13.6859 19.9873 13.6859H21.5873C21.7995 13.6859 22.003 13.7702 22.153 13.9203C22.3031 14.0703 22.3873 14.2738 22.3873 14.4859V15.2859H19.1873V14.4859ZM18.7873 19.0693C19.2015 19.0693 19.5373 19.4051 19.5373 19.8193V23.8193C19.5373 24.2336 19.2015 24.5693 18.7873 24.5693C18.3731 24.5693 18.0373 24.2336 18.0373 23.8193V19.8193C18.0373 19.4051 18.3731 19.0693 18.7873 19.0693ZM23.5373 19.8193C23.5373 19.4051 23.2015 19.0693 22.7873 19.0693C22.3731 19.0693 22.0373 19.4051 22.0373 19.8193V23.8193C22.0373 24.2336 22.3731 24.5693 22.7873 24.5693C23.2015 24.5693 23.5373 24.2336 23.5373 23.8193V19.8193Z"
        fill={isSelected ? '#fff' : color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </AssetIconWrapperStyled>
  )
}

export default BinIcon
