import React, { useContext, useEffect, useState } from 'react'
import {
  Grid,
  GridCell,
  FlexCell,
  Button,
  Modal,
  Heading,
  InputText,
  Loading,
  Radio,
  RadioGroup,
  Dropdown,
  Spacing,
} from 'components'
import { TopSection, Typography } from '@evrekadev/evreka-ui-components'
import { useTranslation } from 'utils/useTranslation'
import { useMapSetting } from 'utils/useMapSetting'
import { Controller, useForm } from 'react-hook-form'
import { ToggleGroup } from './ToggleGroup'
import { MapSettingsOptions } from 'components/Map/MapSettingsModal/types'
import { MapContextType } from '../types'
import { MapContext } from 'context'

export type MapSettingsModalProps = {
  isOpen: boolean
  onClose: () => void
}

export const MapSettingsModal: React.FC<React.PropsWithChildren<MapSettingsModalProps>> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation('components.map.settings')
  const { t: tGlobal } = useTranslation()

  const { settings, onChangeSettings } = useContext<MapContextType>(MapContext)

  const [columnSettings, setColumnSettings] = useState<MapSettingsOptions>([])

  const {
    mapSettingProps: { onSettingChange, mapSetting: mapSettingsStorage },
  } = useMapSetting(settings?.key)

  const { handleSubmit, control, reset } = useForm()

  useEffect(() => {
    if (!columnSettings.length) {
      const data = mapSettingsStorage

      if (data && settings) {
        settings.onChange(data)
        setColumnSettings(data)
      }
    }
  }, [mapSettingsStorage, settings])

  const onSubmit = (data: any) => {
    settings?.onChange(data)

    if (onSettingChange) {
      onSettingChange(data)
    }

    onClose()
  }

  useEffect(() => {
    reset(mapSettingsStorage)
  }, [])

  const renderForms = () => {
    return settings ? (
      settings.forms?.map((form) => {
        if (form.type === 'HEADER') {
          return (
            <Spacing my={8}>
              <Typography key={form.id + form.title} fontWeight="semibold">
                {form.title}
              </Typography>
            </Spacing>
          )
        }
        if (form.type === 'DROPDOWN') {
          return (
            <GridCell key={form.id}>
              <Controller
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    isClearable
                    label={form.title}
                    labelRequired
                    options={form.options}
                  />
                )}
                control={control}
                name={form.id}
              />
            </GridCell>
          )
        }
        if (form.type === 'INPUT') {
          return (
            <GridCell key={form.id}>
              <Controller
                control={control}
                name={form.id}
                defaultValue={form.defaultValue}
                render={({ field: { ref, ...rest } }) => <InputText {...rest} label={form.title} />}
              />
            </GridCell>
          )
        }
        if (form.type === 'RADIO_GROUP') {
          return (
            <GridCell key={form.id}>
              <TopSection label={form.title}></TopSection>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    sideBySide={form.isInline}
                    value={typeof value === 'string' ? value : value?.value}
                    onChange={(e) => {
                      onChange(form.options?.find((o) => o.value === e.target.value))
                    }}
                  >
                    {form.options?.map((option) => (
                      <Radio key={option.value} value={option.value} label={option.label}></Radio>
                    ))}
                  </RadioGroup>
                )}
                control={control}
                name={form.id}
                defaultValue={form.defaultValue || form.options?.[0].value}
              />
            </GridCell>
          )
        }
        if (form.type === 'TOGGLE_GROUP') {
          return (
            <GridCell key={form.id}>
              <Controller
                control={control}
                name={form.id}
                defaultValue={form.defaultValue}
                render={({ field: { ref, ...rest } }) => (
                  <ToggleGroup {...rest} options={form.options} />
                )}
              />
            </GridCell>
          )
        }
      })
    ) : (
      <Loading componentSize={24} type="spinner" />
    )
  }

  return (
    <Modal
      componentSize="medium"
      isOpen={isOpen}
      onRequestClose={onClose}
      heading={t('title')}
      formProps={{ onSubmit: handleSubmit(onSubmit) }}
    >
      {renderForms()}
    </Modal>
  )
}
