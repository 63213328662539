import { FontSizeKeys, LineHeights } from '@evrekadev/evreka-ui-components'
import { useMemo } from 'react'
import ContentLoader, { IContentLoaderProps } from 'react-content-loader'

type TypographySkeletonProps = IContentLoaderProps & {
  height?: FontSizeKeys
  width?: string
  words?: number
  separate?: boolean
}

const AVG_WORD_LENGTH = 48
const PADDING = 4

export const TypographySkeleton: React.FC<TypographySkeletonProps> = ({
  height = 'text_xs',
  width,
  words,
  separate,
  ...props
}) => {
  const wordsArray = useMemo(
    () => (separate && words ? new Array(words).fill(1) : undefined),
    [separate],
  )

  return (
    <ContentLoader
      height={`calc(${LineHeights[height]} + 2px)`}
      width={'100%'}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      {words && separate ? (
        <>
          {wordsArray?.map((i, index) => (
            <rect
              key={index}
              width={AVG_WORD_LENGTH}
              height={LineHeights[height] || '12'}
              x={index * (AVG_WORD_LENGTH + PADDING)}
              rx={4}
              ry={4}
              y={1}
            />
          ))}
        </>
      ) : (
        <rect
          width={width || (words && `${words * AVG_WORD_LENGTH}px`) || '100%'}
          height={LineHeights[height] || '12'}
          rx={4}
          ry={4}
          y={1}
        />
      )}
    </ContentLoader>
  )
}
