import { useContext } from 'react'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import { LayersControl } from 'react-leaflet'
import { useTranslation } from 'utils/useTranslation'
import { MapContext } from 'context'
import { MapContextType } from './types'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from 'services/slices/auth'

type LayerType = {
  name: string
  key: 'satellite' | 'roadmap' | 'terrain' | 'hybrid'
  checked: boolean
}

export const LayerControl = () => {
  const user = useSelector(selectCurrentUser)
  const language = user?.default_language
  const { t } = useTranslation('components.map')
  const { isRtl } = useContext<MapContextType>(MapContext)

  const layers: Array<LayerType> = [
    { key: 'satellite', name: t('views.satellite'), checked: false },
    { key: 'roadmap', name: t('views.roadmap'), checked: true },
    { key: 'terrain', name: t('views.terrain'), checked: false },
    { key: 'hybrid', name: t('views.hybrid'), checked: false },
  ]

  return (
    <LayersControl position={isRtl ? 'bottomright' : 'bottomleft'}>
      {layers.map((layer) => (
        <LayersControl.BaseLayer checked={layer.checked} name={layer.name} key={layer.name}>
          <ReactLeafletGoogleLayer
            type={layer.key}
            styles={[
              {
                featureType: 'administrative',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'poi',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'road',
                elementType: 'labels.icon',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'transit',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
            ]}
            googleMapsLoaderConf={{
              apiKey: process.env.REACT_APP_LEAFLET_GOOGLE_API_KEY as string,
              language,
            }}
          />
        </LayersControl.BaseLayer>
      ))}
    </LayersControl>
  )
}
