import React, { useEffect, useRef } from 'react'
import { Command as $Command } from 'cmdk'
import './command.css'
import {
  Flex,
  Icon,
  Typography,
  borderRadius,
  color,
  padding,
} from '@evrekadev/evreka-ui-components'
import {
  UilArrowDown,
  UilArrowLeft,
  UilArrowUp,
  UilEnter,
  UilFileAlt,
  UilSearch,
} from '@iconscout/react-unicons'
import styled from 'styled-components'
import { useNavigate } from 'react-router'
import { CommandMessage } from './Components/CommandMessage'
import { getModuleInfo, updateRecents } from './util'
import { useMrfNavigationCommands } from './useMrfNavigationCommands'
import { useTranslation } from 'react-i18next'
import { assetRoutes, usersRoutes } from './Commands/routes'
import { engagementRoutes } from './Commands/routes'
import { eventRoutes } from './Commands/routes'
import { fleetRoutes } from './Commands/routes'
import { opsRoutes } from './Commands/routes'
import { reportsRoutes } from './Commands/routes'
import { documentsRoutes } from './Commands/routes'
import { useSelector } from 'react-redux'
import { selectClientModules } from 'services/slices/auth'

export type CommandProps = {
  open: boolean
  setOpen: (isOpen: boolean) => void
}

export const Command: React.FC<CommandProps> = ({ open, setOpen }) => {
  // const [page, setPage] = useState<Array<string>>([])
  const inputRef = useRef<HTMLInputElement | null>(null)
  const listRef = useRef(null)
  const { t } = useTranslation()
  const availableModules = useSelector(selectClientModules)

  const mrfNavigationCommands = useMrfNavigationCommands()

  const hasAsset = availableModules.asset
  const hasEngagement = availableModules.engagement
  const hasFleet = availableModules.fleet
  const hasOps = availableModules.ops

  const combinedNavigationCommands = [
    ...mrfNavigationCommands,
    ...(hasAsset ? assetRoutes : []),
    ...(hasEngagement ? engagementRoutes : []),
    ...(hasFleet ? fleetRoutes : []),
    ...(hasOps ? opsRoutes : []),
    ...eventRoutes,
    ...reportsRoutes,
    ...documentsRoutes,
    ...usersRoutes,
  ]

  const recentCommandIds: Array<string> = JSON.parse(
    localStorage.getItem('command_recents') || '[]',
  )

  const recentCommands = []
  for (const recentCommandId of recentCommandIds) {
    const foundCommand = combinedNavigationCommands.find(
      (command) =>
        `${command.moduleId}_${command.submoduleId}_${command.commandId}` === recentCommandId,
    )
    if (foundCommand) {
      recentCommands.push(foundCommand)
    }
  }

  // = combinedNavigationCommands.filter((command) =>
  //   recentCommandIds.includes(`${command.moduleId}_${command.submoduleId}_${command.commandId}`),
  // )

  const filteredCommands = combinedNavigationCommands.filter(
    (command) =>
      !recentCommandIds.includes(`${command.moduleId}_${command.submoduleId}_${command.commandId}`),
  )

  const favoriteCommands: Array<string> = JSON.parse(
    localStorage.getItem('command_favorites') || '[]',
  )

  const closeDialog = () => setOpen(false)

  return (
    <div className="cmd-class">
      <$Command.Dialog
        open={open}
        onOpenChange={setOpen}
        loop
        filter={(value, search) => {
          if (value.startsWith(search)) return 1
          if (value.includes(search)) return 0.5
          return 0
        }}
      >
        <Flex
          directionColumn="zero"
          gap={0.5}
          style={{
            padding: '12px',
            borderBottom: '1px solid #EAECF0',
          }}
        >
          {/* <BreadCrumbItem>
            <Typography fontSize="text_xs" fontWeight="regular" color="grey500">
              Change Inbound Status
            </Typography>
          </BreadCrumbItem> */}
          <Flex gap={0.5} middle="zero">
            {/* <KeyboardPlaceholder>
              <Icon icon={UilArrowLeft} size={20} color={'grey500'} />
            </KeyboardPlaceholder> */}
            <Icon icon={UilSearch} size={20} color={'grey500'} />
            <$Command.Input
              ref={inputRef}
              placeholder={t('components.command.placeholder')}
              autoFocus
            />
            <KeyboardPlaceholder hasText>
              <Typography fontSize="text_xs" fontWeight="medium" color="grey500">
                ⌘B
              </Typography>
            </KeyboardPlaceholder>
          </Flex>
        </Flex>

        <$Command.List ref={listRef}>
          {/* {false && <$Command.Loading>Hang on…</$Command.Loading>} */}

          <$Command.Empty>
            <CommandMessage variant="empty" />
          </$Command.Empty>
          {favoriteCommands.length > 0 && (
            <>
              <$Command.Group heading="Favorites">
                {favoriteCommands.map((commandId: string) => (
                  <CommandItem
                    commandId={commandId}
                    description="Engagement"
                    label={commandId}
                    to="#"
                    close={closeDialog}
                  />
                ))}
              </$Command.Group>
              <$Command.Separator />
            </>
          )}
          {recentCommands.length > 0 && (
            <>
              <$Command.Group heading={t('components.command.recents')}>
                {recentCommands.map((command) => (
                  <CommandItem
                    commandId={`${command.moduleId}_${command.submoduleId}_${command.commandId}`}
                    description={t(getModuleInfo(command.moduleId).moduleLabel)}
                    label={command.moduleId === 'mrf' ? command.label : t(command.label)}
                    to={getModuleInfo(command.moduleId).moduleUrlPrefix + command.to}
                    close={closeDialog}
                  />
                ))}
              </$Command.Group>
              <$Command.Separator />
            </>
          )}
          <$Command.Group heading={t('components.command.actions')}>
            {filteredCommands.map((command) => (
              <CommandItem
                commandId={`${command.moduleId}_${command.submoduleId}_${command.commandId}`}
                description={t(getModuleInfo(command.moduleId).moduleLabel)}
                label={command.moduleId === 'mrf' ? command.label : t(command.label)}
                to={getModuleInfo(command.moduleId).moduleUrlPrefix + command.to}
                close={closeDialog}
              />
            ))}
          </$Command.Group>
        </$Command.List>
        <CommandFooter>
          <Flex gap={0.25} middle="zero">
            <KeyboardShortcut>
              <Icon icon={UilArrowDown} color="grey700" size={16} />
            </KeyboardShortcut>
            <KeyboardShortcut>
              <Icon icon={UilArrowUp} color="grey700" size={16} />
            </KeyboardShortcut>
            <Typography fontSize="text_xs" fontWeight="medium" color="grey500">
              {t('components.command.footer.navigate')}
            </Typography>
          </Flex>
          <Flex gap={0.25} middle="zero">
            <KeyboardShortcut>
              <Icon icon={UilArrowLeft} color="grey700" size={16} />
            </KeyboardShortcut>
            <Typography fontSize="text_xs" fontWeight="medium" color="grey500">
              {t('components.command.footer.back')}
            </Typography>
          </Flex>
          <Flex gap={0.25} middle="zero">
            <KeyboardShortcut>
              <Icon icon={UilEnter} color="grey700" size={16} />
            </KeyboardShortcut>
            <Typography fontSize="text_xs" fontWeight="medium" color="grey500">
              {t('components.command.footer.select')}
            </Typography>
          </Flex>
          {/* <CommandSubmenu listRef={listRef} selectedValue={'Tarık'} inputRef={inputRef} /> */}
        </CommandFooter>
      </$Command.Dialog>
    </div>
  )
}

export const CommandItem: React.FC<{
  commandId: string
  label: string
  description: string
  to: string
  close: () => void
}> = ({ commandId, description, label, to, close }) => {
  const navigate = useNavigate()
  return (
    <$Command.Item
      onSelect={() => {
        updateRecents(commandId)
        navigate(to, { replace: true })
        close()
      }}
      value={`${label} ${description} ${commandId}`}
    >
      <Flex gap={0.5} middle="zero" fullWidth style={{ padding: '4px 8px', borderRadius: 6 }}>
        <Flex fullWidth middle="zero" gap={0.5}>
          <Icon icon={UilFileAlt} size={16} color={'grey500'} />
          <Typography fontSize="text_sm" fontWeight="regular" color="grey700">
            {label}
          </Typography>
          <Typography fontSize="text_xs" fontWeight="regular" color="grey500">
            {description ?? label}
          </Typography>
        </Flex>
      </Flex>
    </$Command.Item>
  )
}

export const KeyboardPlaceholder = styled.button<{ hasText?: boolean }>`
  border: none;
  cursor: pointer;
  display: flex;
  ${({ hasText }) => padding(hasText ? [2, 4] : [2])}
  background-color: ${color('white')};
  border: 1px solid ${color('grey100')};
  border-radius: 4px;
`

export const CommandFooter = styled.div`
  ${padding([4, 12])}
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-end;

  background-color: ${color('grey25')};
`
export const KeyboardShortcut = styled.div`
  ${padding([2])}
  background-color: ${color('white')};
  border: 1px solid ${color('grey100')};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const BreadCrumbItem = styled.div`
  ${borderRadius(4)}
  ${padding([2, 6])}
  background-color: ${color('grey100')};
  border: 1px solid ${color('grey200')};
  display: flex;
  align-items: center;
  width: fit-content;
`
