import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  MrfEnvironmentTransformedResponse,
  MrfEnvironmentResponse,
  EngagementEnvironmentResponse,
  MRFGoogleRequest,
  MRFGoogleResponse,
} from 'services/environment/types'

export const mrfEnvironmentApi = createApi({
  reducerPath: 'mrfEnvironmentApi',
  tagTypes: ['MrfEnvironment'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    getEnvironment: builder.query<MrfEnvironmentTransformedResponse, number | undefined>({
      query: (clientId) => ({
        url: `MRF/v3/general/environment/?client_external_id=${clientId}`,
        method: 'GET',
      }),
      // transform get environment response to retrieve only needed fields and transform the dropdown data
      transformResponse: (response: MrfEnvironmentResponse) => {
        const { materials, ...rest } = response
        return {
          materials: materials.map(({ id, name, ...rest }) => ({
            value: id,
            name,
            label: name,
            ...rest,
          })),
          ...rest,
        }
      },
      providesTags: [{ type: 'MrfEnvironment' }],
    }),
    mrfGoogle: builder.mutation<MRFGoogleResponse, MRFGoogleRequest>({
      query: ({ clientId, model_name, search_fields, search_query }) => {
        const searchFields = JSON.stringify(search_fields)
          .replace('true', 'True')
          .replace('false', 'False')
        return {
          url: `MRF/v1/general/clients/${clientId}/search/?model_name=${model_name}&search_fields=${searchFields}&search_query=${encodeURIComponent(
            search_query,
          )}`,
          method: 'GET',
        }
      },
    }),
  }),
})
export const engagementEnvironmentApi = createApi({
  reducerPath: 'engagementEnvironmentApi',
  tagTypes: ['EngagementEnvironment'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/'}`,
  }),
  endpoints: (builder) => ({
    getEnvironment: builder.query<EngagementEnvironmentResponse, number | undefined>({
      query: (clientId) => ({
        url: `engagement/environment/?client_external_id=${clientId}`,
        method: 'GET',
      }),
      providesTags: ['EngagementEnvironment'],
    }),
  }),
})
