export const updateRecents = (commandId: string) => {
  const recents: Array<string> = JSON.parse(localStorage.getItem('command_recents') || '[]')
  const newRecents = [commandId, ...recents.filter((r: string) => r !== commandId)].slice(0, 3)
  localStorage.setItem('command_recents', JSON.stringify(newRecents))
}

export const getModuleInfo = (
  moduleId: string,
): {
  moduleLabel: string
  moduleUrlPrefix: string
} => {
  switch (moduleId) {
    case 'mrf':
    default:
      return {
        moduleLabel: 'sidebar.modules.mrf',
        moduleUrlPrefix: '/mrf_management',
      }
    case 'asset':
      return {
        moduleLabel: 'sidebar.modules.asset',
        moduleUrlPrefix: '/asset_management',
      }
    case 'fleet':
      return {
        moduleLabel: 'sidebar.modules.fleet',
        moduleUrlPrefix: '/fleet_management',
      }
    case 'documents':
      return {
        moduleLabel: 'sidebar.modules.documents',
        moduleUrlPrefix: '/documents',
      }
    case 'event':
      return {
        moduleLabel: 'sidebar.modules.events',
        moduleUrlPrefix: '/event_management',
      }
    case 'reports':
      return {
        moduleLabel: 'sidebar.modules.reports',
        moduleUrlPrefix: '/reports',
      }
    case 'engagement':
      return {
        moduleLabel: 'sidebar.modules.engagement',
        moduleUrlPrefix: '/engagement',
      }
    case 'ops':
      return {
        moduleLabel: 'sidebar.modules.operations',
        moduleUrlPrefix: '/ops_management',
      }
    case 'users':
      return {
        moduleLabel: 'sidebar.modules.users',
        moduleUrlPrefix: '/user_management',
      }
  }
}
