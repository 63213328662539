import { Flex } from 'components'
import { TypographySkeleton } from 'components/Skeletons/TypographySkeleton'
import React from 'react'

export type BreadcrumbsSkeletonProps = {}

export const BreadcrumbsSkeleton: React.FC<BreadcrumbsSkeletonProps> = () => {
  return (
    <Flex gap={0.5}>
      <TypographySkeleton words={6} />
    </Flex>
  )
}
