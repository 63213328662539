import { useCallback, useContext, useEffect, useState } from 'react'
import * as L from 'leaflet'
import { useLeafletContext } from '@react-leaflet/core'
import * as turf from '@turf/turf'
import { CustomMarkerProps } from 'components/Map/MapMarkers'
import { getCircleDrawing } from 'components/Map/DrawControl/ControlledDrawings'
import { DrawControl } from 'components/Map/DrawControl'
import styled from 'styled-components'
import { ReactComponent as BulkSelectIcon } from './icons/bulk-select.svg'
import { borderRadius, color, fontSize, padding, shadow } from '@evrekadev/evreka-ui-components'
import { UilTimes } from '@iconscout/react-unicons'
import { Icon } from 'components'
import { useTranslation } from 'utils/useTranslation'
import { MapContextType } from '../types'
import { MapContext } from 'context'

export const MarkerBulkSelection = () => {
  const { t } = useTranslation('components.map')
  const [options] = useState<L.PM.ToolbarOptions>({
    drawMarker: false,
    drawCircle: false,
    drawCircleMarker: false,
    drawRectangle: false,
    drawPolygon: false,
    drawPolyline: false,
    drawText: false,
    cutPolygon: false,
    rotateMode: false,
    dragMode: false,
    editMode: false,
    removalMode: false,
  })
  const { map } = useLeafletContext()

  const {
    isEnabledBulkSelection: isEnabled,
    markers,
    onBulkSelection,
    onToggleBulkSelection,
  } = useContext<MapContextType>(MapContext)

  const onDrawEnd = useCallback(
    (e: any) => {
      const layers: any = map.pm.getGeomanLayers()

      if (!layers.length) {
        return
      }

      const lastDrawing = layers[layers.length - 1]

      const lastDrawingPolygon =
        e.shape === 'Circle'
          ? turf.feature({
              type: 'Polygon',
              coordinates: [
                getCircleDrawing(lastDrawing).positions.map(([lat, lon]) => [lon, lat]),
              ],
            })
          : lastDrawing.toGeoJSON()

      const foundMarkers: CustomMarkerProps[] = []

      lastDrawing.remove()

      markers
        ?.filter((m) => m.selectable)
        ?.forEach((marker: CustomMarkerProps) => {
          const position: any = marker.position
          const newPosition = [position[1], position[0]]

          const isPointInPolygon =
            lastDrawingPolygon.geometry.type !== 'Point'
              ? turf.booleanPointInPolygon(newPosition, lastDrawingPolygon)
              : false

          if (isPointInPolygon) {
            foundMarkers.push(marker)
          }
        })

      if (onBulkSelection) {
        onBulkSelection(foundMarkers)
      }
    },
    [onBulkSelection, markers],
  )

  useEffect(() => {
    map.off('pm:drawend', onDrawEnd)
    map.on('pm:drawend', onDrawEnd)

    return () => {
      map.off('pm:drawend', onDrawEnd)
    }
  }, [map, onDrawEnd])

  return (
    <>
      <BulkSelectionButton
        isEnabled={isEnabled}
        style={{ left: 10 }}
        title={t('bulk_select')}
        onClick={() => {
          map.pm.disableDraw()
          onToggleBulkSelection?.(!isEnabled)
        }}
      >
        {isEnabled ? <Icon icon={UilTimes} /> : <BulkSelectIcon />}
      </BulkSelectionButton>

      {isEnabled && (
        <BulkSelectionContainer
          className="leaflet-pm-toolbar leaflet-pm-draw leaflet-bar leaflet-control"
          style={{ left: 10 }}
        >
          <div className="button-container">
            <a
              className="leaflet-buttons-control-button"
              role="button"
              onClick={(e) => {
                e.preventDefault()
                map.pm.enableDraw('Rectangle')
              }}
            >
              <div className="control-icon leaflet-pm-icon-rectangle"></div>
            </a>
          </div>
          <div className="button-container">
            <a
              className="leaflet-buttons-control-button"
              role="button"
              onClick={(e) => {
                e.preventDefault()
                map.pm.enableDraw('Polygon')
              }}
            >
              <div className="control-icon leaflet-pm-icon-polygon"></div>
            </a>
          </div>
          <div className="button-container">
            <a
              className="leaflet-buttons-control-button"
              role="button"
              onClick={(e) => {
                e.preventDefault()
                map.pm.enableDraw('Circle')
              }}
            >
              <div className="control-icon leaflet-pm-icon-circle"></div>
            </a>
          </div>
        </BulkSelectionContainer>
      )}
      <DrawControl options={options}></DrawControl>
    </>
  )
}

const BulkSelectionContainer = styled.div`
  width: 36px;
  top: 162px;
  z-index: 1000;
  border-radius: 0 0 5px 5px !important;

  a {
    width: 33px !important;
  }
`

const BulkSelectionButton = styled.div<{ isEnabled?: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${padding([6])}

  width: 36px;
  height: 32px;

  background: ${color('grey50')};
  border: 1px solid ${color('grey300')};

  ${shadow('xs')}
  ${borderRadius(8)}
  ${fontSize('text_xs')};

  position: absolute;
  top: 130px;
  z-index: 1000;

  ${(props) =>
    props.isEnabled &&
    `
      background: ${color('grey100')};
      border-radius: 5px 5px 0 0 !important;
  `}
`
