import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['translation'],
    fallbackLng: 'en',
    load: 'currentOnly',
    returnEmptyString: false,
    react: {
      // wait: true,
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    backend: {
      loadPath: `/locales/{{lng}}/translation.json?v=${process.env.REACT_APP_BUILD_ID}`,
    },
  })
export default i18n
