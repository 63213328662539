// @ts-nocheck

export const initGoogleAnalytics = (id) => {
  ;(function (h, o, t, j, a, r) {
    a = o.getElementsByTagName('head')[0]
    r = o.createElement('script')
    r.async = 1
    r.src = 'https://www.googletagmanager.com/gtag/js?id=' + id
    a.appendChild(r)

    window.dataLayer = window.dataLayer || []

    function gtag() {
      dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', id)
  })(window, document)
}

export const initHotjar = () => {
  var ALBA_ID = 2882676
  var NEXT_360_ID = 2882666

  var hjid = window.location.host.includes('360-next')
    ? NEXT_360_ID
    : window.location.host.includes('alba-next')
      ? ALBA_ID
      : undefined

  if (hjid) {
    ;(function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          ;(h.hj.q = h.hj.q || []).push(arguments)
        }
      h._hjSettings = { hjid: hjid, hjsv: 6 }
      a = o.getElementsByTagName('head')[0]
      r = o.createElement('script')
      r.async = 1
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv
      a.appendChild(r)
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
  }
}

export const initFullStory = () => {
  window['_fs_debug'] = false
  window['_fs_host'] = 'fullstory.com'
  window['_fs_script'] = 'edge.fullstory.com/s/fs.js'
  window['_fs_org'] = 'W27ZY'
  window['_fs_namespace'] = 'FS'
  ;(function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].')
      }
      return
    }
    g = m[e] = function (a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s)
    }
    g.q = []
    o = n.createElement(t)
    o.async = 1
    o.crossOrigin = 'anonymous'
    o.src = 'https://' + _fs_script
    y = n.getElementsByTagName(t)[0]
    y.parentNode.insertBefore(o, y)
    g.identify = function (i, v, s) {
      g(l, { uid: i }, s)
      if (v) g(l, v, s)
    }
    g.setUserVars = function (v, s) {
      g(l, v, s)
    }
    g.event = function (i, v, s) {
      g('event', { n: i, p: v }, s)
    }
    g.anonymize = function () {
      g.identify(!!0)
    }
    g.shutdown = function () {
      g('rec', !1)
    }
    g.restart = function () {
      g('rec', !0)
    }
    g.log = function (a, b) {
      g('log', [a, b])
    }
    g.consent = function (a) {
      g('consent', !arguments.length || a)
    }
    g.identifyAccount = function (i, v) {
      o = 'account'
      v = v || {}
      v.acctId = i
      g(o, v)
    }
    g.clearUserCookie = function () {}
    g.setVars = function (n, p) {
      g('setVars', [n, p])
    }
    g._w = {}
    y = 'XMLHttpRequest'
    g._w[y] = m[y]
    y = 'fetch'
    g._w[y] = m[y]
    if (m[y])
      m[y] = function () {
        return g._w[y].apply(this, arguments)
      }
    g._v = '1.3.0'
  })(window, document, window['_fs_namespace'], 'script', 'user')
}

export const initPendo = (userName?: string, clientName?: string) => {
  ;(function (apiKey) {
    ;(function (p, e, n, d, o) {
      var v, w, x, y, z
      o = p[d] = p[d] || {}
      o._q = o._q || []
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)))
            }
        })(v[w])
      y = e.createElement(n)
      y.async = !0
      y.src = 'https://cdn.eu.pendo.io/agent/static/' + apiKey + '/pendo.js'
      z = e.getElementsByTagName(n)[0]
      z.parentNode.insertBefore(y, z)
    })(window, document, 'script', 'pendo')
    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function after users are authenticated in your app and your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    pendo.initialize({
      visitor: {
        id: userName ?? 'VISITOR-UNIQUE-ID', // Required if user is logged in, default creates anonymous ID
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: clientName ?? 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    })
  })('1d1e257a-1ac5-4df8-52ff-939e821c4116')
}

export const initTrackingScripts = () => {
  initGoogleAnalytics('G-CJJWGHC908')
  initGoogleAnalytics('G-J1SLGTRHY1')

  initHotjar()
  initFullStory()
  // initPendo(userId)
}
