import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography, Flex } from 'components'
import { borderRadius, color, padding, shadow } from '@evrekadev/evreka-ui-components'

export const LinkTypo = styled(Typography)`
  color: ${color('green500')};
  text-decoration: none;
  white-space: pre-wrap;
  :hover {
    text-decoration: underline;
    text-decoration-color: ${color('blue500')};
  }
`

export const LinkStyled = styled(Link)`
  text-decoration: none;
`

export const StyledBox = styled.div<{
  hasPadding?: boolean
  noHeight?: boolean
  scroll?: boolean
}>`
  ${borderRadius(12)}
  ${shadow('xs')}
  ${(props) => props.hasPadding && padding([16])}
  background: ${color('white')};
  border: 1px solid ${color('grey200')};
  overflow: ${({ scroll }) => (scroll ? 'auto' : 'hidden')};
  display: flex;
  flex-direction: column;

  ${({ noHeight }) =>
    !noHeight &&
    css`
      height: 400px;
    `}
`

export const InnerContainer = styled.div`
  ${padding([16])}
  scrollbar-gutter: stable;
  overflow: auto;
  flex-grow: 1;
`

export const PropertiesHeader = styled(Flex)`
  ${padding([12, 16])}
  background-color: ${color('grey25')};
  border-bottom: 1px solid ${color('grey200')};
`
export const Collapse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`
