import { FleetVehicleIconWrapperStyled, FleetVehicleIconProps } from './FleetIcon.style'

const EmployeeIcon = ({ color, isSelected }: FleetVehicleIconProps) => {
  return (
    <FleetVehicleIconWrapperStyled
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <circle
        cx="20"
        cy="20"
        r="19"
        fill={isSelected ? color : '#fff'}
        stroke={!isSelected ? color : '#fff'}
        strokeWidth="2"
      ></circle>
      <path
        d="M11 23V27.375C11 28.0712 11.2559 28.7389 11.7113 29.2312C12.1668 29.7234 12.7845 30 13.4286 30C14.0727 30 14.6904 29.7234 15.1458 29.2312C15.6013 28.7389 15.8571 28.0712 15.8571 27.375V26.5H23.9524C24.4551 26.5 24.9509 26.3735 25.4005 26.1305C25.8501 25.8875 26.2412 25.5347 26.5429 25.1L28 23"
        stroke={isSelected ? '#fff' : color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M23 15.6354C22.2865 14.5136 21.343 13.5984 20.2483 12.966C19.1535 12.3337 17.9392 12.0025 16.7065 12C14.7555 12.0065 12.8785 12.8338 11.45 14.3166C10.0216 15.7995 9.14668 17.829 9 20"
        stroke={isSelected ? '#fff' : color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M14 12.5077L17.4638 10.2987C17.7782 10.0947 18.1658 9.9895 18.561 10.0008C21.3438 10.0432 23.9961 11.0121 25.948 12.6993C27.8999 14.3865 28.9957 16.6574 29 19.0242V23"
        stroke={isSelected ? '#fff' : color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M20 19H11.2C10.6165 19 10.0569 19.2107 9.64437 19.5858C9.23179 19.9609 9 20.4696 9 21C9 21.5304 9.23179 22.0391 9.64437 22.4142C10.0569 22.7893 10.6165 23 11.2 23H31"
        stroke={isSelected ? '#fff' : color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </FleetVehicleIconWrapperStyled>
  )
}

export default EmployeeIcon
