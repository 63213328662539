import {
  FleetVehicleIconWrapperStyled,
  FleetVehicleIconBorderStyled,
  FleetVehicleIconProps,
} from './FleetIcon.style'

const CraneTruckIcon = ({ color, isSelected }: FleetVehicleIconProps) => {
  const colorOpposite = isSelected ? color : '#fff'
  return (
    <FleetVehicleIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <FleetVehicleIconBorderStyled
        cx="24"
        cy="24"
        r="23"
        color={color}
        colorOpposite={colorOpposite}
        isSelected={isSelected}
      />
      <path
        d="M33.7205 31.3586H11.3252V27.7281H36.7383V28.3453C36.7359 29.1445 36.417 29.9103 35.8514 30.475C35.2859 31.0397 34.5197 31.3574 33.7205 31.3586Z"
        fill={isSelected ? '#fff' : color}
        stroke={isSelected ? '#fff' : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.7373 22.7046V27.7237H29.5717V16.8369H33.1386C33.4222 16.8343 33.7007 16.9122 33.9417 17.0616C34.1827 17.211 34.3763 17.4258 34.5 17.681L35.0128 18.7202L36.5376 21.8378C36.6688 22.108 36.7371 22.4043 36.7373 22.7046Z"
        fill={isSelected ? '#fff' : color}
        stroke={isSelected ? '#fff' : color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8906 34C18.2713 34 19.3906 32.8807 19.3906 31.5C19.3906 30.1193 18.2713 29 16.8906 29C15.5099 29 14.3906 30.1193 14.3906 31.5C14.3906 32.8807 15.5099 34 16.8906 34Z"
        fill={isSelected ? color : '#fff'}
        stroke={isSelected ? '#fff' : color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.7666 34C32.1473 34 33.2666 32.8807 33.2666 31.5C33.2666 30.1193 32.1473 29 30.7666 29C29.3859 29 28.2666 30.1193 28.2666 31.5C28.2666 32.8807 29.3859 34 30.7666 34Z"
        fill={isSelected ? color : '#fff'}
        stroke={isSelected ? '#fff' : color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M35.7666 23H31.2662V18H33.7437" fill={isSelected ? color : '#fff'} />
      <path
        d="M35.7666 23H31.2662V18H33.7437C33.7437 18 34.8947 19.6232 35.4046 20.8141C35.5178 21.0783 35.7666 21.7126 35.7666 22C35.7666 22.5 35.7666 23 35.7666 23Z"
        stroke={isSelected ? color : '#fff'}
        strokeLinejoin="round"
      />
      <path
        d="M18.7441 27.7719C18.7441 27.2196 19.1919 26.7719 19.7441 26.7719H27.2004C27.7527 26.7719 28.2004 27.2196 28.2004 27.7719V31.5H18.7441V27.7719Z"
        fill={isSelected ? '#fff' : color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.576 14.2413C11.576 14.0259 11.6628 13.8197 11.8168 13.6692C11.9708 13.5186 12.179 13.4366 12.3943 13.4415L14.8489 13.4979C15.0141 13.5017 15.1741 13.5565 15.3069 13.6549L24.6155 20.5528C24.8191 20.7037 24.9392 20.9422 24.9392 21.1956L24.9391 23.2C24.9391 23.6419 24.5809 24 24.1391 24C23.6973 24 23.3391 23.6418 23.3391 23.2L23.3392 21.5985L14.5583 15.0916L13.176 15.0599V15.6349C13.3596 15.7003 13.5355 15.7884 13.6994 15.8979C14.0909 16.1596 14.3961 16.5314 14.5763 16.9665C14.7566 17.4016 14.8037 17.8804 14.7118 18.3423C14.62 18.8042 14.3932 19.2285 14.0602 19.5615C13.7272 19.8945 13.3029 20.1213 12.841 20.2131C12.3791 20.305 11.9003 20.2579 11.4652 20.0776C11.0302 19.8974 10.6583 19.5922 10.3966 19.2006C10.1512 18.8333 10.25 18.3365 10.6173 18.091C10.9847 17.8455 11.4815 17.9444 11.727 18.3117C11.8128 18.4402 11.9348 18.5403 12.0775 18.5994C12.2203 18.6585 12.3773 18.674 12.5289 18.6439C12.6804 18.6137 12.8196 18.5393 12.9288 18.4301C13.0381 18.3208 13.1124 18.1817 13.1426 18.0301C13.1727 17.8786 13.1573 17.7216 13.0981 17.5788C13.039 17.4361 12.9389 17.3141 12.8104 17.2283C12.682 17.1424 12.531 17.0966 12.3765 17.0966C11.9903 17.0966 11.6681 16.823 11.593 16.4591C11.5818 16.4059 11.576 16.3508 11.576 16.2943V14.2413Z"
        fill={isSelected ? '#fff' : color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4322 23.4158L26.8443 23.4181V25.0037H21.4312V25.0037H11.3252L11.3272 24.6563L21.4314 24.6568L21.4322 23.4158Z"
        fill={isSelected ? '#fff' : color}
      />
      <path
        d="M26.8443 23.4181H27.8443C27.8443 22.866 27.3968 22.4184 26.8447 22.4181L26.8443 23.4181ZM21.4322 23.4158L21.4327 22.4158C20.8805 22.4155 20.4326 22.8629 20.4322 23.4151L21.4322 23.4158ZM26.8443 25.0037V26.0037C27.3966 26.0037 27.8443 25.556 27.8443 25.0037H26.8443ZM21.4312 25.0037H20.4312C20.4312 25.556 20.8789 26.0037 21.4312 26.0037V25.0037ZM21.4312 25.0037H22.4312C22.4312 24.4514 21.9834 24.0037 21.4312 24.0037V25.0037ZM11.3252 25.0037L10.3252 24.9979C10.3237 25.2641 10.4284 25.52 10.6161 25.7088C10.8038 25.8975 11.059 26.0037 11.3252 26.0037V25.0037ZM11.3272 24.6563L11.3273 23.6563C10.7772 23.6562 10.3304 24.1005 10.3272 24.6505L11.3272 24.6563ZM21.4314 24.6568L21.4313 25.6568C21.9834 25.6568 22.431 25.2095 22.4314 24.6574L21.4314 24.6568ZM26.8447 22.4181L21.4327 22.4158L21.4318 24.4158L26.8438 24.4181L26.8447 22.4181ZM27.8443 25.0037V23.4181H25.8443V25.0037H27.8443ZM21.4312 26.0037H26.8443V24.0037H21.4312V26.0037ZM20.4312 25.0037V25.0037H22.4312V25.0037H20.4312ZM11.3252 26.0037H21.4312V24.0037H11.3252V26.0037ZM10.3272 24.6505L10.3252 24.9979L12.3252 25.0095L12.3272 24.662L10.3272 24.6505ZM21.4314 23.6568L11.3273 23.6563L11.3272 25.6563L21.4313 25.6568L21.4314 23.6568ZM20.4322 23.4151L20.4314 24.6561L22.4314 24.6574L22.4322 23.4164L20.4322 23.4151Z"
        fill={isSelected ? '#fff' : color}
      />
    </FleetVehicleIconWrapperStyled>
  )
}

export default CraneTruckIcon
