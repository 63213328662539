import {
  configureStore,
  ThunkAction,
  Action,
  MiddlewareAPI,
  isRejectedWithValue,
  isPending,
  isFulfilled,
  isRejected,
} from '@reduxjs/toolkit'
import { combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { authApi } from 'services/auth'
import { categoryApi } from 'services/EventManagement'
import { eventApi } from 'services/EventManagement'
import { operationApi } from 'services/EventManagement'
import { settingsApi } from 'services/EventManagement'
import { servicePointApi } from 'services/OpsManagement'
import ui, { setLoading } from './slices/ui'
import auth from './slices/auth'
import environment from './slices/environment'
import { sendNotification } from 'components'
import { routeApi } from 'services/OpsManagement'
import { staticTourApi } from 'services/OpsManagement'
import { assetApi } from 'services/AssetManagement/asset'
import { consignmentApi } from 'services/MrfManagement/consignment'
import { regionApi } from 'services/OpsManagement'
import { opsGeneralApi } from 'services/OpsManagement'
import { opsSettingsApi } from 'services/OpsManagement'
import { inboundApi } from 'services/MrfManagement/inbound'
import { inventoryApi } from 'services/MrfManagement/inventory'
import { parcelApi } from 'services/MrfManagement/parcel'
import { processApi } from 'services/MrfManagement/process'
import { commonApi } from 'services/common'
import { fleetApi } from 'services/FleetManagement'
import { entityApi } from 'services/Engagement/entity'
import { contactApi } from 'services/Engagement/contact'
import { servicePointApi as engagementServicePointApi } from 'services/Engagement/servicePoint'
import { orderApi as engagementOrderApi } from 'services/Engagement/order'
import { insightApi } from 'services/InsightManagement'
import i18n from 'utils/i18n'
import { materialApi } from 'services/MrfManagement/material'
import { financialDetailApi } from 'services/Engagement/financialDetail'
import { activityApi } from 'services/AssetManagement/activity'
import { geomapApi } from 'services/OpsManagement'
import { mrfEnvironmentApi, engagementEnvironmentApi } from './environment'
import { documentApi } from 'services/DocumentManagement'
import { reportsApi } from 'services/ReportManagement/reports'
import { caseApi } from 'services/Engagement/case'
import { externalApi as externalEngagementApi } from 'services/Engagement/external'
import { usersApi } from 'services/UserManagement/users'
import { taskPlansApi } from 'services/OpsManagement'

//combine rtk-query and redux/toolkit reducers
const rootReducer = combineReducers({
  auth,
  ui,
  environment,
  [authApi.reducerPath]: authApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [operationApi.reducerPath]: operationApi.reducer,
  [servicePointApi.reducerPath]: servicePointApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [routeApi.reducerPath]: routeApi.reducer,
  [assetApi.reducerPath]: assetApi.reducer,
  [consignmentApi.reducerPath]: consignmentApi.reducer,
  [parcelApi.reducerPath]: parcelApi.reducer,
  [processApi.reducerPath]: processApi.reducer,
  [inboundApi.reducerPath]: inboundApi.reducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [commonApi.reducerPath]: commonApi.reducer,
  [fleetApi.reducerPath]: fleetApi.reducer,
  [entityApi.reducerPath]: entityApi.reducer,
  [contactApi.reducerPath]: contactApi.reducer,
  [engagementServicePointApi.reducerPath]: engagementServicePointApi.reducer,
  [engagementOrderApi.reducerPath]: engagementOrderApi.reducer,
  [insightApi.reducerPath]: insightApi.reducer,
  [financialDetailApi.reducerPath]: financialDetailApi.reducer,
  [materialApi.reducerPath]: materialApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [staticTourApi.reducerPath]: staticTourApi.reducer,
  [regionApi.reducerPath]: regionApi.reducer,
  [opsGeneralApi.reducerPath]: opsGeneralApi.reducer,
  [opsSettingsApi.reducerPath]: opsSettingsApi.reducer,
  [geomapApi.reducerPath]: geomapApi.reducer,
  [mrfEnvironmentApi.reducerPath]: mrfEnvironmentApi.reducer,
  [engagementEnvironmentApi.reducerPath]: engagementEnvironmentApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [caseApi.reducerPath]: caseApi.reducer,
  [externalEngagementApi.reducerPath]: externalEngagementApi.reducer,
  [reportsApi.reducerPath]: reportsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [taskPlansApi.reducerPath]: taskPlansApi.reducer,
})

export const rtkQueryErrorLogger = (api: MiddlewareAPI) => (next: any) => (action: any) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (action.error) {
    if (
      (action.meta.arg.endpointName === 'getAuthWithUser' ||
        action.meta.arg.endpointName === 'getAuth') &&
      action.payload.code === 403
    ) {
      localStorage.clear()
      return window.location.assign(`${process.env.REACT_APP_BASE_URL}/login/`)
    }
    if (action.meta.arg.endpointName === 'getAuthWithUser') {
      return
    }

    if (action.error.message?.startsWith('Network'))
      sendNotification({
        type: 'error',
        toastContent: i18n.t('response.message.network.error') as string,
      })
    else if (action.error.message?.startsWith('Request timeout'))
      sendNotification({
        type: 'error',
        toastContent: i18n.t('response.message.timeout.error') as string,
      })
  }
  if (isRejectedWithValue(action)) {
    if (action.payload.errorOverride) {
      store.dispatch(setLoading(false))

      return next(action)
    } else if (action.payload.status === 403) {
      sendNotification({
        type: 'error',
        toastContent: JSON.stringify(action.payload.data.detail.message),
        header: 'Error',
        toastOptions: {},
      })
      //return window.location.assign(`${process.env.REACT_APP_BASE_URL}/login/`)
    } else if (action.payload.status >= 500) {
      store.dispatch(setLoading(false))
      sendNotification({
        type: 'error',
        toastContent: i18n.t('response.message.server.error') as string,
        header: 'Error',
        toastOptions: {},
      })
    } else {
      // Regular error scenario - print error message that backend sends
      sendNotification({
        type: 'error',
        toastContent: action.payload.message,
        header: 'Error',
      })
    }
  }

  return next(action)
}

export const progressBarVisibilitySetter = (api: MiddlewareAPI) => (next: any) => (action: any) => {
  if (isFulfilled(action) || isRejected(action)) {
    store.dispatch(setLoading(false))
  } else if (isPending(action)) store.dispatch(setLoading(true))
  return next(action)
}

//TODO add middlewares we should add all rtk reducers as middlaware to caching
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(rtkQueryErrorLogger)
      .concat(progressBarVisibilitySetter)
      .concat(authApi.middleware)
      .concat(categoryApi.middleware)
      .concat(eventApi.middleware)
      .concat(operationApi.middleware)
      .concat(settingsApi.middleware)
      .concat(servicePointApi.middleware)
      .concat(routeApi.middleware)
      .concat(staticTourApi.middleware)
      .concat(assetApi.middleware)
      .concat(consignmentApi.middleware)
      .concat(regionApi.middleware)
      .concat(geomapApi.middleware)
      .concat(parcelApi.middleware)
      .concat(commonApi.middleware)
      .concat(inboundApi.middleware)
      .concat(inventoryApi.middleware)
      .concat(processApi.middleware)
      .concat(fleetApi.middleware)
      .concat(entityApi.middleware)
      .concat(contactApi.middleware)
      .concat(engagementServicePointApi.middleware)
      .concat(engagementOrderApi.middleware)
      .concat(insightApi.middleware)
      .concat(materialApi.middleware)
      .concat(financialDetailApi.middleware)
      .concat(activityApi.middleware)
      .concat(mrfEnvironmentApi.middleware)
      .concat(engagementEnvironmentApi.middleware)
      .concat(documentApi.middleware)
      .concat(caseApi.middleware)
      .concat(externalEngagementApi.middleware)
      .concat(reportsApi.middleware)
      .concat(opsGeneralApi.middleware)
      .concat(opsSettingsApi.middleware)
      .concat(usersApi.middleware)
      .concat(taskPlansApi.middleware),
})

//to support rtk-query ontabfocus functionality.
setupListeners(store.dispatch)

// if (process.env.NODE_ENV === 'development' && (module as any).hot) {
//   ;(module as any).hot.accept('./rootReducer', () => {
//     const newRootReducer = require('./rootReducer').default
//     store.replaceReducer(newRootReducer)
//   })
// }

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
export type AppDispatch = typeof store.dispatch

export default store
