import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const RoroIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <g clipPath="url(#clip0_5563_108183)">
        <path
          d="M24.8464 22.4948C24.8291 22.4346 24.864 22.3717 24.9242 22.3545L26.0696 22.026C26.1721 21.9966 26.2787 22.0579 26.305 22.1613L27.1644 25.5502L25.765 25.6982L24.8464 22.4948Z"
          fill={isSelected ? '#fff' : color}
        />
        <path
          d="M28.9776 21.4588C29.4218 21.3468 29.7331 20.9473 29.7331 20.4892L29.7331 14.35C29.7331 13.6894 29.1039 13.2103 28.4671 13.386L27.9217 13.5365C27.6879 13.601 27.4855 13.7483 27.3522 13.9509L26.6547 15.0112C26.5222 15.2128 26.3212 15.3596 26.089 15.4246L11.8306 19.4174C11.3009 19.5658 10.9905 20.114 11.1357 20.6445L12.2378 24.67C12.3816 25.1951 12.919 25.5087 13.4469 25.3756L28.9776 21.4588ZM17.2397 19.8245C17.1395 19.4226 16.7325 19.178 16.3306 19.2782C15.9287 19.3784 15.6841 19.7855 15.7843 20.1874L16.3891 22.6131C16.4893 23.0151 16.8964 23.2596 17.2983 23.1594C17.7002 23.0592 17.9447 22.6522 17.8445 22.2503L17.2397 19.8245ZM20.2426 18.3019C20.6445 18.2017 21.0516 18.4463 21.1518 18.8482L21.7566 21.2739C21.8568 21.6758 21.6122 22.0829 21.2103 22.1831C20.8084 22.2833 20.4013 22.0387 20.3011 21.6368L19.6963 19.2111C19.5961 18.8092 19.8407 18.4021 20.2426 18.3019ZM25.0633 17.8732C24.9631 17.4713 24.556 17.2267 24.1541 17.327C23.7522 17.4272 23.5076 17.8342 23.6079 18.2361L24.2127 20.6619C24.3129 21.0638 24.7199 21.3083 25.1218 21.2081C25.5237 21.1079 25.7683 20.7009 25.6681 20.299L25.0633 17.8732Z"
          fill={isSelected ? '#fff' : color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M10.9681 25.3672H30.9681"
          stroke={isSelected ? '#fff' : color}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5563_108183">
          <rect
            width="24"
            height="24"
            fill={isSelected ? color : '#fff'}
            transform="translate(7.99988 8.12891)"
          />
        </clipPath>
      </defs>
    </AssetIconWrapperStyled>
  )
}

export default RoroIcon
