import { createApi } from '@reduxjs/toolkit/query/react'
import { sendNotification } from 'components'
import axiosBaseQuery from 'services/axiosBaseQuery'
import {
  AddInboundRequest,
  EditInboundRequest,
  MutationResponse,
  InboundDetailResponse,
  InboundDetailTransformedResponse,
  InboundDetailRequest,
  InboundHistoryRequest,
  InboundHistoryResponse,
  ExportRequest,
  ExportResponse,
  InboundListRequest,
  InboundListResponse,
  InboundSearchResponse,
  InboundSearchRequest,
  BulkImportResponse,
  BulkImportRequest,
  RequestInboundNoteResponse,
  GetRequestInboundNoteRequest,
  GetInboundNoteResponse,
  GetWeighingScalesResponse,
  ChangeInboundStatusRequest,
  BulkMutationResponse,
  BulkDeleteRequest,
  AttachableInboundResponse,
  AttachableInboundRequest,
  AttachObjectToInboundResponse,
  AttachObjectToInboundRequest,
  ParcelListResponse,
  ParcelListRequest,
  EditInboundAttachmentsRequest,
} from './types'
import { getExportHandler, getImportHandler } from 'utils'
import { TableRequestV2External, TableResponse } from 'services/types'
import { inventoryApi } from './inventory'

export const inboundApi = createApi({
  reducerPath: 'inboundApi',
  tagTypes: [
    'Inbounds',
    'InboundDetails',
    'InboundHistory',
    'InboundParcels',
    'WorkOrderListByOrderId',
  ],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/MRF/'}`,
  }),
  endpoints: (builder) => ({
    getInboundDetails: builder.query<InboundDetailResponse, InboundDetailRequest>({
      query: ({ client_id, inboundId, external_id }) => ({
        url: `v2/inbound/clients/${client_id}/`,
        method: 'GET',
        params: inboundId ? { inbound_id: inboundId } : { external_id },
      }),
      providesTags: ['InboundDetails'],
    }),
    getInboundHistory: builder.query<InboundHistoryResponse, InboundHistoryRequest>({
      query: ({ client_id, inboundId, ...data }) => ({
        url: `clients/${client_id}/inbound/${inboundId}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['InboundHistory'],
    }),
    addInbound: builder.mutation<MutationResponse, AddInboundRequest>({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/inbound/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['Inbounds'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editInbound: builder.mutation<MutationResponse, EditInboundRequest>({
      query: ({ client_id, inbound_id, body }) => ({
        url: `clients/${client_id}/inbound/${inbound_id}/`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['Inbounds', 'InboundDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          dispatch(
            inventoryApi.util.invalidateTags([
              'Inventory',
              'InventoryDetails',
              'Transactions',
              'TransactionChart',
            ]),
          )
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getInboundList: builder.query<InboundListResponse, InboundListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `v2/inbound/clients/${client_id}/list/${
          data.task_id ? '?' + (data.task_id ? 'task_id=' + data.task_id : '') : ''
        }`,
        method: 'POST',
        data,
      }),
      providesTags: ['Inbounds'],
    }),
    exportInboundList: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/inbound/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    bulkImportInbounds: builder.mutation<BulkImportResponse, BulkImportRequest>({
      query: ({ clientId, file }) => ({
        url: `clients/${clientId}/inbound/bulk_upload/`,
        method: 'POST',
        data: file,
      }),
      onQueryStarted: getImportHandler,
    }),
    searchInbound: builder.mutation<InboundSearchResponse, InboundSearchRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/inbound/search/`,
        method: 'POST',
        data,
      }),
    }),
    searchAttachableInbounds: builder.mutation<AttachableInboundResponse, AttachableInboundRequest>(
      {
        query: ({ client_id, material_id, query_param }) => ({
          //change type to smth like mrfGoogle if you want to search a field other than name
          url: `v1/inbound/clients/${client_id}/search/attachable_inbounds/`,
          method: 'GET',
          params: {
            search_fields: '[{"field_name": "name", "exact_match": False}]',
            search_query: query_param,
            material_id,
          },
        }),
      },
    ),
    attachObjectToInbound: builder.mutation<
      AttachObjectToInboundResponse,
      AttachObjectToInboundRequest
    >({
      query: ({ client_id, inboundId, ...data }) => ({
        //change type to smth like mrfGoogle if you want to search a field other than name
        url: `clients/${client_id}/inbound/${inboundId}/attach/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Inbounds', 'InboundParcels'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    UnlinkObjectFromInbound: builder.mutation<
      MutationResponse,
      Pick<AttachObjectToInboundRequest, 'client_id' | 'inboundId'>
    >({
      query: ({ client_id, inboundId }) => ({
        //change type to smth like mrfGoogle if you want to search a field other than name
        url: `clients/${client_id}/inbound/${inboundId}/detach/`,
        method: 'POST',
      }),
      invalidatesTags: ['InboundParcels', 'Inbounds'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    requestInboundNote: builder.mutation<RequestInboundNoteResponse, GetRequestInboundNoteRequest>({
      query: ({ client_id, inboundId }) => ({
        url: `clients/${client_id}/inbound/${inboundId}/request_note/`,
        method: 'GET',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getInboundNote: builder.query<GetInboundNoteResponse, GetRequestInboundNoteRequest>({
      query: ({ client_id, inboundId }) => ({
        url: `clients/${client_id}/inbound/${inboundId}/get_note/`,
        method: 'GET',
      }),
    }),
    getWeighingScales: builder.query<GetWeighingScalesResponse, { client_id: number }>({
      query: ({ client_id }) => ({
        url: `clients/${client_id}/weighingscale/list/`,
        method: 'POST',
        data: {},
      }),
    }),
    changeInboundStatus: builder.mutation<MutationResponse, ChangeInboundStatusRequest>({
      query: ({ clientId, inboundId, ...data }) => ({
        url: `clients/${clientId}/inbound/${inboundId}/status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['InboundDetails', 'Inbounds'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          // `onSuccess` side-effect
          dispatch(
            inventoryApi.util.invalidateTags([
              'Inventory',
              'InventoryDetails',
              'Transactions',
              'TransactionChart',
            ]),
          )
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteInbound: builder.mutation<BulkMutationResponse, BulkDeleteRequest<number, string>>({
      query: ({ clientId, deleteIdArray: inbound_id_list }) => ({
        url: `clients/${clientId}/inbound/bulk_delete/`,
        method: 'DELETE',
        data: { inbound_id_list },
        errorOverride: true,
      }),
      invalidatesTags: ['Inbounds'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
          sendNotification({
            type: 'error',
            toastContent: (err as any).error.data.failure_list?.[0]?.description,
          })
        }
      },
    }),
    getInboundParcelList: builder.query<ParcelListResponse, ParcelListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `v2/parcel/clients/${client_id}/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['InboundParcels'],
    }),
    getInboundListByOrderIdExternal: builder.query<
      TableResponse<{}>,
      TableRequestV2External & { order_id: string }
    >({
      query: ({ client_external_id, order_id, ...data }) => ({
        url: `v1/external/${client_external_id}/inbound/list/?order_id=${order_id}`,
        method: 'POST',
        data,
      }),
      providesTags: ['WorkOrderListByOrderId'],
    }),
    editInboundAttachments: builder.mutation<MutationResponse, EditInboundAttachmentsRequest>({
      query: ({ clientId, inboundId, body }) => ({
        url: `clients/${clientId}/inbound/${inboundId}/edit_attachments/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['InboundDetails'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
  }),
})

export const {
  useAddInboundMutation,
  useEditInboundMutation,
  useGetInboundDetailsQuery,
  useGetInboundHistoryQuery,
  useGetInboundListQuery,
  useExportInboundListMutation,
  useSearchInboundMutation,
  useBulkImportInboundsMutation,
  useGetInboundNoteQuery,
  useRequestInboundNoteMutation,
  useGetWeighingScalesQuery,
  useChangeInboundStatusMutation,
  useDeleteInboundMutation,
  useSearchAttachableInboundsMutation,
  useAttachObjectToInboundMutation,
  useUnlinkObjectFromInboundMutation,
  useGetInboundParcelListQuery,
  useGetInboundListByOrderIdExternalQuery,
  useEditInboundAttachmentsMutation,
} = inboundApi
