import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useChangePasswordMutation,
  useEditContactMutation,
  useGetContactDetailQuery,
  useValidateContactPhoneMutation,
} from 'services/Engagement/contact'
import { selectEnvironmentEngagement } from 'services/slices/environment'
import { useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/dist/query/react'
import { jsonToFormData } from './formDataFieldHandler'

type CheckPhoneValidityParams = {
  client_id?: string
  contact_id?: string
  watchPhoneValue?: string
}

export const useCheckCustomerPortalProfilePhoneValidity = ({
  client_id,
  contact_id,
  watchPhoneValue,
}: CheckPhoneValidityParams) => {
  const { data, isLoading: isEnvLoading } = useSelector(selectEnvironmentEngagement)
  const [isPhoneValid, setIsPhoneValid] = useState<boolean | undefined>(true)

  const { data: detail, isLoading: isDetailsLoading } = useGetContactDetailQuery(
    client_id && contact_id
      ? {
          client_id,
          contact_id: contact_id,
        }
      : skipToken,
  )

  const [validatePhone, { isLoading: isPhoneValidationLoading }] = useValidateContactPhoneMutation()
  const [editContact] = useEditContactMutation()

  useEffect(() => {
    if (detail?.phone.value === watchPhoneValue || !client_id) return
    if (watchPhoneValue) {
      validatePhone({
        client_id,
        body: jsonToFormData({
          object_type: 'phone',
          object_value: watchPhoneValue,
        }),
      })
        .unwrap()
        .then((res: { is_valid: boolean }) => {
          setIsPhoneValid(res.is_valid)
        })
    } else {
      setIsPhoneValid(true)
    }
  }, [watchPhoneValue])

  return {
    editContactService: editContact,
    contactDetails: {
      data: detail,
      isLoading: isDetailsLoading,
    },
    isPhoneValid,
    isPhoneValidationLoading,
    env: {
      data,
      isLoading: isEnvLoading,
    },
  }
}

export const useCheckCustomerPortalProfile = () => {
  const { data, isLoading: isEnvLoading } = useSelector(selectEnvironmentEngagement)

  const {
    client_id,
    user_info: { contact_id, value: user_id },
  } = data || { user_info: {} }

  const [changePassword, { isLoading: isChangingPasswordCP }] = useChangePasswordMutation()

  return {
    service: { changePasswordCP: changePassword, isChangingPasswordCP },
    client_id,
    user_id,
    contact_id,
    isEngEnvLoading: isEnvLoading,
  }
}
