import {
  GenericSuccessResponse,
  GenericExportListRequest,
  TasksResponse,
  TasksRequest,
  TasksByFilterResponse,
  TasksByFilterRequest,
  TaskDetailResponse,
  TaskDetailRequest,
  TaskDetailForParcelResponse,
  TaskDetailForParcelRequest,
  TaskAddResponse,
  TaskAddRequest,
  TaskTransitionDetailPageResponse,
  TaskTransitionDetailPageRequest,
  TaskMapPointsResponse,
  TaskMapPointsRequest,
  TaskEditDetailFieldsResponse,
  TaskEditDetailFieldsRequest,
  TaskChangeStatusRequest,
  TasksCountByFilterResponse,
  TaskOptimizeFilterRequest,
  RoutesByFilterResponse,
  TaskOptimizeServiceResponse,
  SearchTaskResponse,
  SearchTaskRequest,
  ValidateTaskUsabilityResponse,
  ValidateTaskUsabilityRequest,
  ActionNoteRequest,
  TaskMapPopupDetailsRequest,
  TaskMapPopupDetailsResponse,
  DeleteTaskRequest,
  DeleteTaskResponse,
  SearchSPByParamsResponse,
  SearchSPByParamsRequest,
  SearchTasksByParamsRequest,
  SearchTasksByParamsResponse,
  TaskTransitionDetailsResponse,
  TaskTransitionDetailsRequest,
  TaskTransitionRequest,
  MutationResponse,
  CockpitTaskListResponse,
} from './types'
import { sendNotification } from 'components'
import { ActionResponse, GenericResponse } from 'services/types'
import { routeApi } from './routes'
import { getExportHandler, getImportHandler } from 'utils'
import { opsGeneralApi } from './opsGeneral'
import {
  BaseColorType,
  DATE_TIME_FORMAT,
  dateFormat,
  getColorsFromBaseColor,
} from '@evrekadev/evreka-ui-components'
import { HistoryRequest, HistoryResponse } from 'containers/HistoryV2/types'
import { TaskCardItem } from './types'

export const taskApi = routeApi.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<TasksResponse, TasksRequest>({
      query: ({ clientId, order_id, ...data }) => ({
        url: `clients/${clientId}/ops_management/list_task/${
          data.route_id ||
          data.service_point_id ||
          order_id ||
          data.inbound_id ||
          data.task_plan_id ||
          data.consignment_id
            ? '?' +
              (data.route_id ? 'route_id=' + data.route_id : '') +
              (data.service_point_id ? 'service_point_id=' + data.service_point_id : '') +
              (order_id ? 'order_id=' + order_id : '') +
              (data.inbound_id ? 'inbound_id=' + data.inbound_id : '') +
              (data.task_plan_id ? 'task_plan=' + data.task_plan_id : '') +
              (data.consignment_id ? 'consignment_id=' + data.consignment_id : '')
            : ''
        }`,
        method: 'POST',
        data,
      }),
      providesTags: ['Tasks'],
    }),
    getTasksByFilter: builder.query<TasksByFilterResponse, TasksByFilterRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_tasks_by_filter/`,
        method: 'POST',
        data,
      }),
      providesTags: [],
    }),
    getTaskDetails: builder.query<TaskDetailResponse, TaskDetailRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/task_detail/`,
        method: 'POST',
        data,
      }),
      providesTags: ['TaskDetail'],
    }),
    getTaskDetailsForParcel: builder.query<TaskDetailForParcelResponse, TaskDetailForParcelRequest>(
      {
        query: ({ clientId, ...data }) => ({
          url: `clients/${clientId}/ops_management/tasks/get_task_detail/`,
          method: 'POST',
          data,
        }),
        providesTags: ['TaskDetail'],
      },
    ),
    addAdHocTask: builder.mutation<TaskAddResponse, TaskAddRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/create_task/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Tasks', 'TaskHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTaskTransitions: builder.query<
      TaskTransitionDetailPageResponse,
      TaskTransitionDetailPageRequest
    >({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/task_transitions/`,
        method: 'POST',
        data,
      }),
      providesTags: ['TaskTransitionDetailPage'],
    }),
    getTaskPointsForMap: builder.query<TaskMapPointsResponse, TaskMapPointsRequest>({
      query: ({ clientId, route_id, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_tasks_for_map/${
          route_id ? '?route_id=' + route_id : ''
        }`,
        method: 'GET',
      }),
      providesTags: ['TasksInMaps'],
    }),
    getEditTaskFields: builder.query<TaskEditDetailFieldsResponse, TaskEditDetailFieldsRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/get_edit_task_fields/?task=${data.task}`,
        method: 'GET',
      }),
      providesTags: ['EditTasksFields'],
    }),
    editTaskAssignment: builder.mutation<GenericSuccessResponse, any>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/edit_task_assignment/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        'Tasks',
        'TaskDetail',
        'EditTasksFields',
        'TaskItineraryList',
        'TaskHistory',
      ],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(routeApi.util.invalidateTags(['RouteHistory']))
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    exportTasks: builder.mutation<GenericSuccessResponse, GenericExportListRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/export/task/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    changeTaskStatus: builder.mutation<GenericResponse, TaskChangeStatusRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/change_task_status/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Tasks', 'TaskHistory', 'TaskDetail'],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTasksCountByFilter: builder.query<TasksCountByFilterResponse, TaskOptimizeFilterRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/tasks/get_tasks_count/`,
        method: 'POST',
        data,
      }),
    }),
    getRoutesByFilter: builder.query<RoutesByFilterResponse, TaskOptimizeFilterRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route/get_routes/`,
        method: 'POST',
        data,
      }),
    }),
    optimizeTaskService: builder.mutation<TaskOptimizeServiceResponse, TaskOptimizeFilterRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/tasks/optimize/`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    optimizeRoute: builder.mutation<TaskOptimizeServiceResponse, TaskOptimizeFilterRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/route/${data.id}/optimize_route/`,
        method: 'GET',
      }),
      invalidatesTags: ['TaskItineraryList'],
      async onQueryStarted(id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect

          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    searchTask: builder.mutation<SearchTaskResponse, SearchTaskRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/search/task/`,
        method: 'POST',
        data,
      }),
    }),
    validateTaskUsability: builder.mutation<
      ValidateTaskUsabilityResponse,
      ValidateTaskUsabilityRequest
    >({
      query: ({ external_client_id, ...data }) => ({
        url: `clients/${external_client_id}/validate/task/`,
        method: 'POST',
        data,
      }),
    }),

    upsertNoteForTask: builder.mutation<ActionResponse, ActionNoteRequest>({
      query({ clientId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/task/upsert_note/`,
          method: 'POST',
          data,
        }
      },
      invalidatesTags: ['Tasks', 'TaskHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteTask: builder.mutation<DeleteTaskResponse, DeleteTaskRequest>({
      query: ({ clientId, ...data }) => ({
        url: `clients/${clientId}/ops_management/task/`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['Tasks', 'EditTasksFields', 'TaskItineraryList', 'TaskHistory'],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),

    getTaskMapPopupDetails: builder.query<TaskMapPopupDetailsResponse, TaskMapPopupDetailsRequest>({
      query: ({ clientId, taskId }) => ({
        url: `clients/${clientId}/ops_management/task/${taskId}/map/detail/`,
        method: 'GET',
      }),
      providesTags: ['TaskDetail', 'EditTasksFields'],
    }),
    searchTasksByParams: builder.mutation<SearchTasksByParamsResponse, SearchTasksByParamsRequest>({
      queryFn: async (arg, api) => {
        try {
          const res = await api.dispatch(
            opsGeneralApi.endpoints.genericSearchByParams.initiate({
              clientId: arg.clientId,
              model_name: 'task',
              requested_values: [
                'id',
                'status_category__id',
                'status_category__name',
                'status_category__color',
                'status_category__state',
                'due_date',
                'create_time',
                'last_updated',
                'service_point__id',
                'service_point__name',
                'service_point__type',
                'info',
                'address',
                'latitude',
                'longitude',
                'task_template__name',
                'task_order_tasks__id',
                'task_order_tasks__name',
                'operation_date',
                'shift__start_time',
                'shift__end_time',
                'client__client_tz',
                'route_plan__name',
                'route_plan__id',
                'route__route_id',
                'route__route_name',
              ],
              search_fields: [
                'operation__id',
                'operation_date',
                ...(arg.status?.length ? ['status_category__id'] : []),
              ],
              search_queries: [
                arg.operation,
                arg.date,
                ...(arg.status?.length ? [arg.status] : []),
              ],
              filter_expressions: ['exact', 'exact', ...(arg.status?.length ? ['in'] : [])],

              order_by: 'id',
              requested_labels: ['id'],
            }),
          )

          return res
        } catch (err) {}

        return { data: [] }
      },
    }),
    getTaskTransitionDetails: builder.query<
      TaskTransitionDetailsResponse,
      TaskTransitionDetailsRequest
    >({
      query: ({ clientId, taskStatus, task_id }) => ({
        url: `clients/${clientId}/ops_management/tasks/${task_id}/task_transition_details/?task_status_category=${taskStatus}`,
        method: 'GET',
        errorOverride: true,
      }),
      providesTags: ['TaskTransitionDetails'],
    }),
    taskTransition: builder.mutation<MutationResponse, { clientId: number; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/ops_management/task_transition/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [
        'Tasks',
        'TaskDetail',
        'EditTasksFields',
        'TaskItineraryList',
        'TaskTransitionDetails',
        'TaskTransitionDetailPage',
        'TaskHistory',
      ],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    taskTransitionEdit: builder.mutation<MutationResponse, { clientId: number; body: FormData }>({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/ops_management/task_transition_edit/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: [
        'Tasks',
        'EditTasksFields',
        'TaskItineraryList',
        'TaskTransitionDetails',
        'TaskTransitionDetailPage',
        'TaskHistory',
      ],
      async onQueryStarted(_id, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    taskHistory: builder.query<
      HistoryResponse & MutationResponse,
      HistoryRequest & { clientId: number; taskId: number }
    >({
      query({ clientId, taskId, ...data }) {
        return {
          url: `clients/${clientId}/ops_management/task/${taskId}/history/`,
          method: 'POST',
          data,
        }
      },
      providesTags: ['TaskHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getTaskStatuses: builder.query<
      {
        data: Array<{
          name: string
          state: string
          color: Exclude<BaseColorType, 'transparent' | 'white' | 'black'>
        }>
      },
      { client_id: number; operation_id: number }
    >({
      query: ({ client_id, operation_id }) => ({
        url: `clients/${client_id}/ops_management/task_status_categories/list/?operation_id=${operation_id}`,
        method: 'GET',
      }),
    }),
    getCockpitTaskList: builder.query<
      Array<TaskCardItem>,
      {
        client_id: number
        date: string
        operation_id: number
        route_id?: number
        timezone?: string
      }
    >({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/ops_management/cockpit/tasks/`,
        method: 'POST',
        data,
      }),
      transformResponse(res: CockpitTaskListResponse, undefined, { timezone }) {
        const mappedData = res.map((data) => {
          return {
            id: data.task_id,
            create_time: dateFormat(data.creation_time, DATE_TIME_FORMAT, timezone),
            last_updated: dateFormat(data.last_update_time, DATE_TIME_FORMAT, timezone),
            shift: data.task_shift
              ? data.task_shift.start_time.substring(0, data.task_shift.start_time.length - 3) +
                '-' +
                data?.task_shift.end_time?.substring(0, data?.task_shift.end_time.length - 3)
              : '-',
            operation_date: data.operation.operation_date,
            status: {
              id: data.status.id,
              name: data.status.name,
              color: data.status.color,
              hexColor: getColorsFromBaseColor(data.status.color).text,
              state: data.status.value,
            },
            task_template: data?.task_template_name,
            due_date: data.due_date,
            note: data?.note,
            address: data?.address,
            latitude: data.latitude,
            longitude: data.longitude,
            servicePoint: data.service_point?.id ? data.service_point : null,
            route: data.route ? { id: data.route.id, name: data.route.name } : null,
            routePlan: data.route_plan,
            taskPlan: data.task_plan,
            sequence: data.sequence,
          }
        })

        return mappedData
      },
    }),
  }),

  overrideExisting: false,
})

export const {
  useGetTasksQuery,
  useGetTasksByFilterQuery,
  useGetTaskTransitionsQuery,
  useGetTaskDetailsQuery,
  useGetTaskDetailsForParcelQuery,
  useGetTaskPointsForMapQuery,
  useExportTasksMutation,
  useAddAdHocTaskMutation,
  useEditTaskAssignmentMutation,
  useGetEditTaskFieldsQuery,
  useChangeTaskStatusMutation,
  useOptimizeTaskServiceMutation,
  useGetTasksCountByFilterQuery,
  useGetRoutesByFilterQuery,
  useSearchTaskMutation,
  useValidateTaskUsabilityMutation,
  useUpsertNoteForTaskMutation,
  useDeleteTaskMutation,
  useGetTaskMapPopupDetailsQuery,
  useOptimizeRouteMutation,
  useSearchTasksByParamsMutation,
  useGetTaskTransitionDetailsQuery,
  useTaskTransitionMutation,
  useTaskTransitionEditMutation,
  useTaskHistoryQuery,
  useGetTaskStatusesQuery,
  useGetCockpitTaskListQuery,
} = taskApi
