import { Grid, GridCell, Spacing } from 'components'
import { InputSkeleton } from 'components/Skeletons/InputSkeleton'
import React from 'react'

type FormSkeletonProps = {
  count: number
}
export const ModalSkeleton: React.FC<React.PropsWithChildren<FormSkeletonProps>> = ({ count }) => {
  const arr = new Array(count)
  arr.fill(1)
  return (
    <>
      <InputSkeleton width={200} />
      <Spacing mt={12} />
      <Grid gap={3} columns={2}>
        {arr.map((_, idx) => (
          <GridCell key={idx}>
            <InputSkeleton />
          </GridCell>
        ))}
      </Grid>
    </>
  )
}
