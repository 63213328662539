import { createPathComponent, LeafletContextInterface } from '@react-leaflet/core'
import L from 'leaflet'
import 'leaflet.markercluster'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'

//TODO better event types
type AwfulType = {
  [key in string]: any
}

const MarkerClusterControl = (props: any, context: LeafletContextInterface) => {
  const { children, ...rest } = props

  ;(L as any).MarkerCluster = (L as any).MarkerCluster.extend({
    options: { pmIgnore: true, ...(L as any).MarkerCluster.prototype.options },
  })

  let clusterProps: AwfulType = {}
  let clusterEvents: AwfulType = {}
  // Splitting props and events to different objects
  Object.entries(rest).forEach(([propName, prop]) => {
    if (propName.startsWith('on')) {
      clusterEvents = { ...clusterEvents, [propName]: prop }
    } else {
      clusterProps = { ...clusterProps, pmIgnore: true, [propName]: prop }
    }
  })

  const markerClusterGroup = new (L as any).MarkerClusterGroup(clusterProps)

  // Initializing event listeners for clusters
  Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
    const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`
    markerClusterGroup.on(clusterEvent, callback)
  })

  return {
    instance: markerClusterGroup,
    context: { ...context, layerContainer: markerClusterGroup },
  }
}

//TODO
// const MarkerClusterUpdate = (
//   instance: L.MarkerClusterGroup,
//   props: MarkerClusterControlProps,
//   prevProps: MarkerClusterControlProps,
// ) => {}

export default createPathComponent<any, any>(
  MarkerClusterControl,
  // MarkerClusterUpdate,
)
