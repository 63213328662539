import { Icon, borderRadius, color, margin, padding, shadow } from '@evrekadev/evreka-ui-components'
import { Typography } from 'components'
import styled, { css } from 'styled-components'
import { themedColor } from '../Sidebar.style'
import { SidebarBaseProps } from '../Sidebar'
import { Link, LinkProps } from 'react-router-dom'

export const MenuItemStyled = styled.button<SidebarBaseProps>`
  border: none;
  background-color: ${color('transparent')};
  text-decoration: none;
  ${({ sidebarCollapsed }) => padding([10, sidebarCollapsed ? 10 : 8])}
  ${borderRadius(8)}
  cursor: pointer;
  user-select: none;
  width: 100%;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  path {
    /* fill: transparent; */
  }

  &:hover {
    background-color: ${themedColor('background-muted')};
  }
`
export const MenuDivider = styled.div<Pick<SidebarBaseProps, 'sidebarTheme'>>`
  ${margin([8, 0])}
  height: 1px;
  width: 100%;
  background-color: ${color('grey200')};
`

export const ModuleIcon = styled(Icon)<{ isSOS: boolean } & Pick<SidebarBaseProps, 'sidebarTheme'>>`
  flex-shrink: 0;

  @keyframes pulse {
    0% {
      stroke: ${color('red500')};
    }
    50% {
      stroke: ${themedColor('text-muted')};
    }
    100% {
      stroke: ${color('red500')};
    }
  }

  ${({ isSOS }) =>
    isSOS &&
    css`
      path {
        stroke: ${color('red500')};
        animation: pulse 0.5s infinite;
      }
    `}
`

export const MenuItemChevron = styled(Icon)<Pick<SidebarBaseProps, 'sidebarCollapsed'>>`
  flex-shrink: 0;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};
`

export const MenuItemLabel = styled(Typography)<Pick<SidebarBaseProps, 'sidebarCollapsed'>>`
  flex-grow: 1;
  opacity: ${({ sidebarCollapsed }) => (sidebarCollapsed ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
`

export const SubItemsLine = styled.div`
  width: 1px;
  background-color: ${color('grey200')};
  margin-left: 1.125rem;
`
export const SubItemStyled = styled(Link)<Pick<SidebarBaseProps, 'sidebarTheme'>>`
  ${padding([4, 8])}
  ${borderRadius(8)}
  cursor: pointer;
  user-select: none;
  transition: all 0.3s ease-in-out;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  text-decoration: none;

  &:hover {
    background-color: ${themedColor('background-muted')};
  }
`
export const SubItemsWrapperStyled = styled.div`
  transition: height 0.2s ease-in-out;
  overflow: hidden;
  display: flex;
  gap: 0.75rem;
`

export const SubItemsFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const PopoverSubItems = styled.div`
  ${borderRadius(8)}
  ${padding([4])}
  ${margin({ l: 8 })}
  ${shadow('lg')}
  border: 1px solid ${color('grey100')};
  background-color: ${color('white')};
`

export const MenuItemBadge = styled.div<
  { isSOS: boolean } & Pick<SidebarBaseProps, 'sidebarCollapsed'>
>`
  position: relative;
  ${({ sidebarCollapsed }) =>
    sidebarCollapsed
      ? css`
          bottom: 14px;
          right: 36px;
        `
      : css`
          bottom: 0px;
          right: 0px;
        `}
  p {
    padding: 0;
  }
  transition: all 0.3s ease-in-out;

  @keyframes wiggle {
    0% {
      transform: rotate(0deg);
    }
    2% {
      transform: rotate(-8deg);
    }
    4% {
      transform: rotate(8deg);
    }
    6% {
      transform: rotate(-5deg);
    }
    8% {
      transform: rotate(3deg);
    }
    10% {
      transform: rotate(0deg);
    }
    60% {
      transform: rotate(0deg);
    }
    62% {
      transform: rotate(-8deg);
    }
    64% {
      transform: rotate(8deg);
    }
    66% {
      transform: rotate(-5deg);
    }
    68% {
      transform: rotate(3deg);
    }
    70% {
      transform: rotate(0deg);
    }
  }
  ${({ isSOS }) =>
    isSOS &&
    css`
      animation: wiggle 5s infinite;
    `}
`
