import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const SkipContainerIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <path
        d="M27.1368 25.0859C27.5199 25.0859 27.8693 24.8671 28.0365 24.5225L30.5993 19.2407C30.7629 18.9035 30.7243 18.5032 30.4992 18.2035L28.4573 15.4853C28.2684 15.2339 27.9722 15.0859 27.6577 15.0859H13.5698C13.2553 15.0859 12.9591 15.2339 12.7702 15.4853L10.736 18.1933C10.5069 18.4983 10.4712 18.9071 10.6441 19.2471L13.3347 24.5391C13.5053 24.8746 13.8498 25.0859 14.2261 25.0859H27.1368ZM16.3638 18.3525C16.3638 17.9383 16.0281 17.6025 15.6138 17.6025C15.1996 17.6025 14.8638 17.9383 14.8638 18.3525V22.3525C14.8638 22.7668 15.1996 23.1025 15.6138 23.1025C16.0281 23.1025 16.3638 22.7668 16.3638 22.3525V18.3525ZM20.6138 17.6025C21.0281 17.6025 21.3638 17.9383 21.3638 18.3525V22.3525C21.3638 22.7668 21.0281 23.1025 20.6138 23.1025C20.1996 23.1025 19.8638 22.7668 19.8638 22.3525V18.3525C19.8638 17.9383 20.1996 17.6025 20.6138 17.6025ZM26.3638 18.3525C26.3638 17.9383 26.0281 17.6025 25.6138 17.6025C25.1996 17.6025 24.8638 17.9383 24.8638 18.3525V22.3525C24.8638 22.7668 25.1996 23.1025 25.6138 23.1025C26.0281 23.1025 26.3638 22.7668 26.3638 22.3525V18.3525Z"
        fill={isSelected ? '#fff' : color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </AssetIconWrapperStyled>
  )
}

export default SkipContainerIcon
