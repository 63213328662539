import { MapSettingsStorage } from 'components/Map/MapSettingsModal/types'
import { useLocalStorage } from '@evrekadev/evreka-ui-components'

export type UseMapSetting = {
  mapSettingProps: {
    mapSetting?: any
    onSettingChange: (setting: any) => void
    resetMapSettings: () => void
  }
}

//Make sure your key value is unique!
export const useMapSetting = (settingKey?: string): UseMapSetting => {
  const [mapSettings, setSettings] = useLocalStorage<MapSettingsStorage | undefined>(
    'map_settings',
    undefined,
  )

  const setMapSettings = (key: string, setting: any) => {
    const newSettings = {
      [key]: { ...setting },
    }
    setSettings((prevSettings) => {
      return prevSettings
        ? {
            ...prevSettings,
            ...newSettings,
          }
        : newSettings
    })
  }

  const onSettingChange = (setting: any) => {
    settingKey && setMapSettings(settingKey, setting)
  }

  const resetMapSettings = () => {
    mapSettings &&
      settingKey &&
      setSettings((prevSettings) => {
        prevSettings && delete prevSettings[settingKey]
        return { ...prevSettings }
      })
  }

  const mapSetting = settingKey && mapSettings ? mapSettings[settingKey] : undefined

  return {
    mapSettingProps: { mapSetting, onSettingChange, resetMapSettings },
  }
}
