import React from 'react'
import { Typography, Flex } from 'components'
import { StyledDiv, WrapperFlex } from './ButtonGroup.style'
import { SquareSkeleton } from 'components/Skeletons/SquareSkeleton'

export type ButtonGroupItem = {
  value: number | string
  title: string
  dataTestId?: string
}

type ButtonGroupProps = {
  value?: number | string
  items: Array<ButtonGroupItem>
  onChange: (item: ButtonGroupItem) => void
  isLoading?: boolean
  dataTestId?: string
}

export const ButtonGroup: React.FC<ButtonGroupProps> = ({
  items,
  value,
  onChange,
  isLoading,
  dataTestId,
}) => {
  if (isLoading) {
    return (
      <Flex gap={0.5}>
        <SquareSkeleton height={30} />
        <SquareSkeleton height={30} />
      </Flex>
    )
  }
  return (
    <WrapperFlex data-testid={dataTestId ?? 'button_group'}>
      {items.map((item, i) => (
        <StyledDiv
          key={i}
          onClick={() => onChange(item)}
          selected={item.value === value}
          data-testid={item.dataTestId || `data_testid_${item.title}`}
        >
          <Typography
            color={item.value === value ? 'green500' : 'grey800'}
            fontSize="text_sm"
            fontWeight="medium"
          >
            {item.title}
          </Typography>
        </StyledDiv>
      ))}
    </WrapperFlex>
  )
}
