import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { UilEye, UilEyeSlash } from '@iconscout/react-unicons'
import { Button, Icon } from 'components'
import { InputPropsV2 } from '@evrekadev/evreka-ui-components'

const ToggleVisibilityButton = styled(Button)`
  padding: 0;
  line-height: 0;

  &:hover,
  &:focus {
    background-color: transparent;
  }
`

type PasswordFieldType = 'current' | 'new' | 'confirm'

type Params = {
  compare: {
    base: string
    confirm: string
  }
  helperText: {
    at_least_one_symbol: string
    at_least_one_uppercase: string
    at_least_one_number: string
    min_length: string
  }
}

export const useCheckPasswordValidityAndMatch = ({ compare, helperText }: Params) => {
  const [passwordInputsVisible, setPasswordInputsVisible] = useState({
    isVisibleCurrent: false,
    isVisibleNew: false,
    isVisibleConfirm: false,
  })

  const [passwordRules, setPasswordRules] = useState({
    at_least_one_symbol: false,
    at_least_one_uppercase: false,
    at_least_one_number: false,
    min_length: false,
  })

  const [doPasswordsMatch, setDoPasswordsMatch] = useState(false)

  useEffect(() => {
    let { at_least_one_symbol, at_least_one_number, at_least_one_uppercase, min_length } =
      passwordRules
    if (
      at_least_one_symbol &&
      at_least_one_number &&
      at_least_one_uppercase &&
      min_length &&
      compare.base === compare.confirm
    ) {
      setDoPasswordsMatch(true)
    } else {
      setDoPasswordsMatch(false)
    }
  }, [compare.base, compare.confirm, passwordRules])

  useEffect(() => {
    setPasswordRules((prevState) => {
      return {
        ...prevState,
        at_least_one_symbol: /[!-\/:-@[-`{-~]/.test(compare.base),
        at_least_one_number: /\d/.test(compare.base),
        at_least_one_uppercase: /[A-Z]/.test(compare.base),
        min_length: compare.base?.length >= 8 ? true : false,
      }
    })
  }, [compare.base])

  const passwordHelperText = useMemo(
    () => [
      {
        id: '1',
        rule: passwordRules.at_least_one_uppercase,
        text: helperText.at_least_one_uppercase,
      },
      {
        id: '2',
        rule: passwordRules.at_least_one_symbol,
        text: helperText.at_least_one_symbol,
      },
      {
        id: '3',
        rule: passwordRules.at_least_one_number,
        text: helperText.at_least_one_number,
      },
      {
        id: '4',
        rule: passwordRules.min_length,
        text: helperText.min_length,
      },
    ],
    [passwordRules],
  )

  const passwordInputIconHandler = useMemo(
    () =>
      (input: PasswordFieldType): InputPropsV2['extraButtons'] => {
        let { isVisibleNew, isVisibleCurrent, isVisibleConfirm } = passwordInputsVisible
        switch (input) {
          case 'current':
            return [
              {
                onClick: () =>
                  setPasswordInputsVisible((prevState) => {
                    return { ...prevState, isVisibleCurrent: !prevState.isVisibleCurrent }
                  }),
                iconRight: isVisibleCurrent ? UilEyeSlash : UilEye,
                onlyIcon: true,
              },
            ]

          case 'new':
            return [
              {
                onClick: () =>
                  setPasswordInputsVisible((prevState) => {
                    return { ...prevState, isVisibleNew: !prevState.isVisibleNew }
                  }),
                iconRight: isVisibleNew ? UilEyeSlash : UilEye,
                onlyIcon: true,
              },
            ]

          case 'confirm':
            return [
              {
                onClick: () =>
                  setPasswordInputsVisible((prevState) => {
                    return { ...prevState, isVisibleConfirm: !prevState.isVisibleConfirm }
                  }),
                iconRight: isVisibleConfirm ? UilEyeSlash : UilEye,
                onlyIcon: true,
              },
            ]
        }
      },
    [passwordInputsVisible],
  )

  return {
    doPasswordsMatch,
    passwordInputsVisible,
    toggleVisibilityIcon: passwordInputIconHandler,
    passwordHelperText,
  }
}
