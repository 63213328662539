import React from 'react'
import { ModuleSlider } from 'containers/ModuleSlider/ModuleSlider'
import { WelcomeBanner } from 'containers/WelcomeBanner/WelcomeBanner'
import { Flex, FlexCell } from 'components'
import { LayoutList } from 'containers/Layout'

export const Home: React.FC<React.PropsWithChildren<unknown>> = () => {
  // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  //   return <>Visible in development mode only 🚀</>
  // }
  return (
    <LayoutList>
      <Flex directionColumn="zero" fullHeight gap={2}>
        <FlexCell>
          <WelcomeBanner />
        </FlexCell>
        <FlexCell>
          <ModuleSlider />
        </FlexCell>
      </Flex>
    </LayoutList>
  )
}

export default Home
