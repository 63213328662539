import { AssetIconWrapperStyled, AssetIconBorderStyled, AssetIconProps } from './AssetIcon.style'

const ContainerIcon = ({ color, isSelected }: AssetIconProps) => {
  return (
    <AssetIconWrapperStyled
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
      isSelected={isSelected}
    >
      <AssetIconBorderStyled cx="21" cy="21" r="20" color={color} isSelected={isSelected} />
      <path
        d="M10 23.5585V16.5371C10 16.1148 10.2652 15.7381 10.6627 15.5957L11.8347 15.1758C11.9557 15.1325 12.0838 15.1128 12.2122 15.118L29.0402 15.7948C29.5764 15.8163 30 16.2573 30 16.794L30 23.2716C30 23.8058 29.5801 24.2456 29.0465 24.2705L12.2413 25.0534C12.0941 25.0602 11.9473 25.0345 11.8113 24.978L10.6166 24.482C10.2433 24.3271 10 23.9626 10 23.5585ZM16.1415 17.1838C16.1415 16.7696 15.8058 16.4338 15.3915 16.4338C14.9773 16.4338 14.6415 16.7696 14.6415 17.1838V22.9901C14.6415 23.4043 14.9773 23.7401 15.3915 23.7401C15.8058 23.7401 16.1415 23.4043 16.1415 22.9901V17.1838ZM18.2947 16.6412C18.7089 16.6412 19.0447 16.977 19.0447 17.3912V22.7828C19.0447 23.197 18.7089 23.5328 18.2947 23.5328C17.8805 23.5328 17.5447 23.197 17.5447 22.7828V17.3912C17.5447 16.977 17.8805 16.6412 18.2947 16.6412ZM21.9478 17.5986C21.9478 17.1844 21.612 16.8486 21.1978 16.8486C20.7836 16.8486 20.4478 17.1844 20.4478 17.5986V22.5754C20.4478 22.9896 20.7836 23.3254 21.1978 23.3254C21.612 23.3254 21.9478 22.9896 21.9478 22.5754V17.5986ZM24.101 17.056C24.5152 17.056 24.851 17.3917 24.851 17.806V22.368C24.851 22.7822 24.5152 23.118 24.101 23.118C23.6867 23.118 23.351 22.7822 23.351 22.368V17.806C23.351 17.3917 23.6867 17.056 24.101 17.056ZM27.7541 18.0133C27.7541 17.5991 27.4183 17.2633 27.0041 17.2633C26.5899 17.2633 26.2541 17.5991 26.2541 18.0133V22.1607C26.2541 22.5749 26.5899 22.9107 27.0041 22.9107C27.4183 22.9107 27.7541 22.5749 27.7541 22.1607V18.0133Z"
        fill={isSelected ? '#fff' : color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </AssetIconWrapperStyled>
  )
}

export default ContainerIcon
