import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'utils/useTranslation'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import {
  DateRangeSelector,
  Dropdown,
  Radio,
  RadioGroup,
  Flex,
  GridCell,
  FormInfo,
  Modal,
  Typography,
} from 'components'
import { useGetCategoriesQuery, useUpdateEventsReportMutation } from 'services/EventManagement'
import { selectCurrentUser } from 'services/slices/auth'
import { skipToken } from '@reduxjs/toolkit/dist/query'

type ReportProps = {
  closeModal: () => void
  isOpen: boolean
}

//TODO fix type inference
const schema = yup.object().shape({
  date: yup.array().required('required.date'),
  categories: yup.array().required('required.categories'),
  fileType: yup.mixed().oneOf(['pdf', 'xls']).required('required.filetype').default('pdf'),
})

type FormInputs = {
  date: [string, string]
  categories: Array<{ value: string; label: string }>
  fileType: 'xls' | 'pdf'
}

export const Report: React.FC<React.PropsWithChildren<ReportProps>> = ({ closeModal, isOpen }) => {
  const { t } = useTranslation('pages.event_management.modals.report')
  const user = useSelector(selectCurrentUser)
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })

  const { data: categories } = useGetCategoriesQuery(user?.as_client_id ?? skipToken)
  const [updateReport] = useUpdateEventsReportMutation()

  const categoriesDropdown =
    categories?.map((c) => ({
      value: c.id,
      label: c.label,
    })) || []

  const onSubmit = (data: FormInputs) => {
    if (user?.as_client_id) {
      updateReport({
        clientId: user.as_client_id,
        categories: data.categories?.map((c) => c.value) ?? [],
        startTime: data.date !== null ? data.date[0] : '',
        endTime: data.date !== null ? data.date[1] : '',
        fileType: data.fileType,
      })
        .unwrap()
        .then((_res) => {
          closeModal()
        })
    }
  }

  useEffect(() => {
    !isOpen && reset()
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      heading={t('header')}
      onRequestClose={closeModal}
      formProps={{ onSubmit: handleSubmit(onSubmit) }}
    >
      <Flex directionColumn="zero" gap={1}>
        <GridCell>
          <Controller
            render={({ field: { name, value, onBlur, ...controllerField } }) => (
              <DateRangeSelector {...controllerField} label={t('date_label')} labelRequired />
            )}
            control={control}
            name="date"
          />
        </GridCell>
        <GridCell>
          <Controller
            render={({ field }) => (
              <Dropdown
                {...field}
                labelRequired
                label={t('category_label')}
                fullWidth
                isMulti
                options={categoriesDropdown}
                onChange={(v, s) => {
                  if (Array.isArray(v) && (v as any).length === 0) {
                    field.onChange(undefined)
                  } else {
                    field.onChange(v)
                  }
                  // v && (v as any).length === 0 ? field.onChange(null) : field.onChange(v)
                }}
                portal
              />
            )}
            control={control}
            name="categories"
          />
        </GridCell>
        <Flex fullWidth directionColumn="zero">
          <Typography fontWeight="bold" fontSize="text_md">
            {t('filetype')}
          </Typography>

          <Controller
            render={({ field: { onChange, value } }) => (
              <RadioGroup sideBySide value={value} onChange={(e) => onChange(e.target.value)}>
                <Radio value="pdf" label="pdf"></Radio>
                <Radio value="xls" label="excel"></Radio>
              </RadioGroup>
            )}
            control={control}
            name="fileType"
          />
        </Flex>
        <Flex fullWidth directionColumn="zero">
          {Object.keys(errors).length > 0 && (
            <FormInfo>
              {errors.date && <p>{t((errors.date as any).message ?? '')}</p>}
              {errors.categories && <p>{t((errors.categories as any).message ?? '')}</p>}
              {errors.fileType && <p>{t(errors.fileType?.message ?? '')}</p>}
            </FormInfo>
          )}
        </Flex>
      </Flex>
    </Modal>
  )
}
