import { useCallback, useEffect } from 'react'
import { UilCheckCircle } from '@iconscout/react-unicons'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import {
  Flex,
  FlexCell,
  Grid,
  GridCell,
  Icon,
  Modal,
  sendNotification,
  Spacing,
  Typography,
  InputText,
} from 'components'
import { useTranslation } from 'utils/useTranslation'
import { useCheckPasswordValidityAndMatch } from 'utils/useCheckPasswordValidityAndMatch'
import { useCheckCustomerPortalProfile } from 'utils/useCheckCustomerPortalInfo'
import { useChangePasswordMutation } from 'services/UserManagement'
import { ModalSkeleton } from 'components/Skeletons'
import { ProfileEnvironment } from 'types/common'
import { useParams } from 'react-router-dom'

type ChangePasswordModalProps = {
  closeModal: () => void
  isOpen: boolean
  environment?: ProfileEnvironment
}

type FormInputs = {
  current_password: string
  new_password: string
  confirm_password: string
}

const schema = yup.object().shape({
  current_password: yup.string().required('required.current_password'),
  new_password: yup.string().required('required.new_password'),
  confirm_password: yup.string().required('required.confirm_password'),
})

const TRANSLATION_KEY = 'pages.common.profile'

export const ChangePasswordModal: React.FC<React.PropsWithChildren<ChangePasswordModalProps>> = ({
  closeModal,
  isOpen,
  environment,
}) => {
  const { t: tGlobal } = useTranslation()
  const { t: tProfile } = useTranslation(TRANSLATION_KEY)
  const { t: tChangePasswordModal } = useTranslation(`${TRANSLATION_KEY}.change_password_modal`)

  const methods = useForm<FormInputs>({
    resolver: yupResolver(schema),
  })

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
    reset,
    setFocus,
  } = methods

  const watchConfirmPasswordValue = watch('confirm_password')
  const watchNewPasswordValue = watch('new_password')

  const {
    service: { changePasswordCP, isChangingPasswordCP },
    client_id,
    contact_id,
    user_id,
    isEngEnvLoading,
  } = useCheckCustomerPortalProfile()
  const [changePassword, { isLoading: isChangePasswordLoading }] = useChangePasswordMutation()

  const { doPasswordsMatch, passwordInputsVisible, passwordHelperText, toggleVisibilityIcon } =
    useCheckPasswordValidityAndMatch({
      compare: {
        base: watchNewPasswordValue,
        confirm: watchConfirmPasswordValue,
      },
      helperText: {
        at_least_one_symbol: tChangePasswordModal('at_least_one_symbol'),
        at_least_one_number: tChangePasswordModal('at_least_one_number'),
        at_least_one_uppercase: tChangePasswordModal('at_least_one_uppercase'),
        min_length: tChangePasswordModal('min_length'),
      },
    })

  //TODO cato - fix this
  const params = useParams()
  const entity_id =
    environment === 'customer-portal' ? Object.values(params)[0]?.split('/')[1] : undefined

  const onSubmit = useCallback(
    (data: FormInputs) => {
      if (environment === 'customer-portal') {
        if (!contact_id || !user_id || !client_id || !entity_id) return

        let body = {
          next_password: data.new_password,
          current_password: data.current_password,
          entity_id,
          contact_id,
        }

        return changePasswordCP({
          client_id,
          body,
          user_id,
        })
          .unwrap()
          .then((res) => {
            if (res.is_invalid_current_password) {
              setValue('current_password', '')
              setFocus('current_password', { shouldSelect: true })
              return
            }

            closeModal()
            sendNotification({
              type: 'success',
              toastContent: res.detail.message,
            })
          })
      }

      changePassword({
        current_password: data.current_password,
        password: data.new_password,
        password_again: data.confirm_password,
      })
        .unwrap()
        .then(closeModal)
    },
    [environment, contact_id, entity_id, user_id, client_id],
  )

  useEffect(() => {
    !isOpen && reset()
  }, [isOpen])

  return (
    <Modal
      isOpen={isOpen}
      heading={tProfile('change_password')}
      formProps={{ onSubmit: handleSubmit(onSubmit) }}
      loading={isChangePasswordLoading || isChangingPasswordCP}
      isPrimaryActionDisabled={!doPasswordsMatch}
      onRequestClose={closeModal}
    >
      {isEngEnvLoading && environment === 'customer-portal' ? (
        <ModalSkeleton count={3} />
      ) : (
        <Grid rowGap={2.4}>
          <GridCell>
            <Typography>{tChangePasswordModal('security_info')}</Typography>
            <Spacing mb={8} />
            <Flex directionColumn="zero" gap={0.125}>
              <FlexCell>
                <Typography variant="span">{tChangePasswordModal('rules_info')}</Typography>
              </FlexCell>
              {passwordHelperText.map((helperText, idx) => (
                <FlexCell key={helperText.id || idx}>
                  <Flex middle="zero" gap={0.5}>
                    <Icon
                      color={helperText.rule ? 'green500' : 'darkblue900'}
                      icon={UilCheckCircle}
                      size={20}
                    />
                    <Typography color={helperText.rule ? 'green500' : 'darkblue900'} variant="span">
                      {helperText.text}
                    </Typography>
                  </Flex>
                </FlexCell>
              ))}
            </Flex>
          </GridCell>
          <GridCell>
            <Grid rowGap={1.6} columns={1}>
              <GridCell width={1}>
                <Controller
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type={!passwordInputsVisible.isVisibleCurrent ? 'password' : 'text'}
                      label={tChangePasswordModal('current_password')}
                      labelRequired
                      error={!!errors.current_password}
                      extraButtons={toggleVisibilityIcon('current')}
                    />
                  )}
                  control={control}
                  name="current_password"
                />
              </GridCell>
              <GridCell width={1}>
                <Controller
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type={!passwordInputsVisible.isVisibleNew ? 'password' : 'text'}
                      label={tChangePasswordModal('new_password')}
                      labelRequired
                      error={!!errors.new_password}
                      extraButtons={toggleVisibilityIcon('new')}
                    />
                  )}
                  control={control}
                  name="new_password"
                />
              </GridCell>
              <GridCell width={1}>
                <Controller
                  render={({ field }) => (
                    <InputText
                      {...field}
                      type={!passwordInputsVisible.isVisibleConfirm ? 'password' : 'text'}
                      label={tChangePasswordModal('confirm_password')}
                      labelRequired
                      error={!!errors.confirm_password}
                      extraButtons={toggleVisibilityIcon('confirm')}
                    />
                  )}
                  control={control}
                  name="confirm_password"
                />
              </GridCell>
            </Grid>
          </GridCell>
        </Grid>
      )}
    </Modal>
  )
}
