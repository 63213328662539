import React, { useRef, useState } from 'react'
import { Spacing, Typography } from 'components'
import { ModuleCard } from 'containers/ModuleSlider/ModuleCard'
import {
  ModuleSlideContainerStyled,
  ModuleSliderStyled,
} from 'containers/ModuleSlider/ModuleSlider.styled'
import { useTranslation } from 'utils/useTranslation'
import { ModuleSliderArrow } from 'containers/ModuleSlider/ModuleSliderArrow'
import { useRtl } from 'utils/useRtl'

type ModuleSliderProps = {}

export const ModuleSlider: React.FC<React.PropsWithChildren<ModuleSliderProps>> = () => {
  const { t } = useTranslation('pages.home')
  const [arrowVisible, setArrowVisible] = useState<{ left: boolean; right: boolean }>({
    left: false,
    right: true,
  })

  const slidesRef = useRef<HTMLDivElement | null>(null)

  const isRtl = useRtl()

  const handleLeft = () => {
    slidesRef.current &&
      slidesRef.current.scrollTo({ left: isRtl ? -slidesRef.current.scrollWidth : 0 })
  }
  const handleRight = () => {
    slidesRef.current &&
      slidesRef.current.scrollTo({ left: isRtl ? 0 : slidesRef.current.scrollWidth })
  }
  const onScroll = (e: React.UIEvent<HTMLDivElement>) => {
    let diff = isRtl
      ? e.currentTarget.clientWidth - e.currentTarget.scrollWidth
      : e.currentTarget.scrollWidth - e.currentTarget.clientWidth

    if (e.currentTarget.scrollLeft === 0) {
      setArrowVisible((v) => ({ ...v, right: isRtl ? false : true, left: isRtl ? true : false }))
    } else if (diff === e.currentTarget.scrollLeft) {
      setArrowVisible((v) => ({ ...v, right: isRtl ? true : false, left: isRtl ? false : true }))
    } else {
      setArrowVisible((v) => ({ ...v, right: true, left: true }))
    }
  }

  return (
    <>
      <Spacing pl={16} mb={4}>
        <Typography fontSize="text_xl" fontWeight="bold" color="darkblue600">
          {t('solutions')}
        </Typography>
      </Spacing>

      <ModuleSliderStyled>
        <ModuleSlideContainerStyled ref={slidesRef} onScroll={onScroll}>
          <ModuleSliderArrow direction="left" visible={arrowVisible.left} onClick={handleLeft} />
          <ModuleSliderArrow direction="right" visible={arrowVisible.right} onClick={handleRight} />
          <ModuleCard module="ops" />
          <ModuleCard module="eng" />
          <ModuleCard module="fleet" />
          <ModuleCard module="mrf" />
          <ModuleCard module="asset" />
          <ModuleCard module="route" />
          <ModuleCard module="workforce" />
        </ModuleSlideContainerStyled>
      </ModuleSliderStyled>
    </>
  )
}
