import { servicePointApi } from './servicePoints'
import { routeApi } from './routes'
import { taskApi } from './tasks'
import { geomapApi } from './geomaps'
import { regionApi } from './regions'
import { opsGeneralApi } from './opsGeneral'
import { taskPlansApi } from './taskPlans'
import { staticTourApi } from './staticTours'
import { opsSettingsApi } from './opsSettings'

import {
  AssetTypeRequest,
  AssetTypeResponse,
  AssetsRequest,
  AssetsResponse,
  AssigneesRequest,
  AssigneesResponse,
  FIELD_TYPE,
  FormType,
  OperationItemType,
  OperationItemTypeRequest,
  OperationItemTypeResponse,
  OperationRegionRequest,
  OperationResponse,
  OperationsRegionResponse,
  RegionsRequest,
  RegionsResponse,
  SearchAssetRequest,
  SearchAssetResponse,
  ServicePointUpsertRequest,
  ServicePointDetailRequest,
  ServicePointDetailResponse,
  ServicePointEditResponse,
  ServicePointViewResponse,
  ShiftsResponse,
  TaskDetailRequest,
  TaskDetailResponse,
  TaskOrderDeleteRequest,
  TaskOrderDeleteResponse,
  TaskOrdersRequest,
  TaskOrdersResponse,
  TaskStep,
  TaskTemplateStepsRequest,
  TaskTemplateStepsResponse,
  TaskTemplatesRequest,
  TaskTemplatesResponse,
  TaskTransitionDetailPageRequest,
  TaskTransitionDetailPageResponse,
  TransitionType,
  ServicePointLocationRequest,
  ServicePointLocationResponse,
  TaskTransitionRequest,
} from './types'

export const {
  useAddServicePointMutation,
  useEditServicePointMutation,
  useGetContentFieldsQuery,
  useGetOperationsQuery,
  useGetOperationsBySpIdQuery,
  useGetAssetsQuery,
  useGetServicePointQuery,
  useGetTaskTemplatesQuery,
  useGetShiftsQuery,
  useGetServicePointsQuery,
  useGetMapPointsQuery,
  useDetachAssetFromSPMutation,
  useGetAssetTypesQuery,
  useSearchAssetMutation,
  useGetServicePointOperationsQuery,
  useGetServicePointViewQuery,
  useGetAssigneesQuery,
  useGetGeomapsQuery,
  useGetTaskTemplateStepsQuery,
  useGetOperationItemTypesQuery,
  useDeleteServicePointMutation,
  useGetServicePointLocationsQuery,
  useExportServicePointMutation,
  useImportServicePointMutation,
  useGetDumpLocationsQuery,
  useGetServicePointCalendarQuery,
  useGetServicePointCalendarDetailsQuery,
} = servicePointApi

export const {
  useGetRoutesQuery,
  useAddAdHocRouteMutation,
  useGetRouteDetailsQuery,
  useGetQhseResponsesQuery,
  useExportRoutesMutation,
  useGetRouteHistoryQuery,
  useSearchRoutesByNameMutation,
  useGetRoutesByOperationQuery,
  useGetRoutePlansQuery,
  useSearchRoutePlansMutation,
  useGetStaticTourOptionsQuery,
  useAddRoutePlanMutation,
  useEditRoutePlanMutation,
  useGetRoutePlanEditValuesQuery,
  useGetItineraryListQuery,
  useEditTaskItineraryItemMutation,
  useImportKmlMutation,
  useImportTaskItineraryListMutation,
  useDrawPathServiceMutation,
  useCalculateEtaMutation,
  useOptimizeRouteServiceMutation,
  useGetMonthlyRoutesQuery,
  useGetDailyRoutesQuery,
  useRouteBulkCancelMutation,
  useRouteBulkDuplicateMutation,
  useRouteBulkEditMutation,
  useDeleteRouteMutation,
  useGetAssigneesByRoutesQuery,
  useChangeRoutesAssigneesMutation,
  useGetRoutesForTasksQuery,
  useDeactivateRoutePlansMutation,
  useGetAssetAndItemsQuery,
  useSearchRoutesByParamsMutation,
  useGetDisposalListQuery,
  useAddDisposalAmountMutation,
  useEditDisposalAmountMutation,
  useGetNavigationRecordByRouteQuery,
  useGetRouteActivitiesQuery,
  useGetCockpitRouteDetailsQuery,
  useGetCockpitRouteListQuery,
  useLazyGetCockpitRouteListQuery,
} = routeApi

export const {
  useGetTasksQuery,
  useGetTasksByFilterQuery,
  useGetTaskTransitionsQuery,
  useGetTaskDetailsQuery,
  useGetTaskDetailsForParcelQuery,
  useGetTaskPointsForMapQuery,
  useExportTasksMutation,
  useAddAdHocTaskMutation,
  useEditTaskAssignmentMutation,
  useGetEditTaskFieldsQuery,
  useChangeTaskStatusMutation,
  useOptimizeTaskServiceMutation,
  useGetTasksCountByFilterQuery,
  useGetRoutesByFilterQuery,
  useSearchTaskMutation,
  useValidateTaskUsabilityMutation,
  useUpsertNoteForTaskMutation,
  useDeleteTaskMutation,
  useGetTaskMapPopupDetailsQuery,
  useOptimizeRouteMutation,
  useSearchTasksByParamsMutation,
  useGetTaskTransitionDetailsQuery,
  useTaskTransitionMutation,
  useTaskTransitionEditMutation,
  useTaskHistoryQuery,
  useGetTaskStatusesQuery,
  useGetCockpitTaskListQuery,
  useLazyGetCockpitTaskListQuery,
} = taskApi

export const {
  useGetStaticToursQuery,
  useGetEditStaticTourDetailQuery,
  useAddStaticTourMutation,
  useEditStaticTourMutation,
  useDeleteStaticTourMutation,
  useGetStaticTourQuery,
} = staticTourApi

export const {
  useListShiftsQuery,
  useGetShiftDetailsQuery,
  useAddShiftMutation,
  useDeleteShiftMutation,
  useEditShiftMutation,
} = opsSettingsApi

export const {
  useGetTaskPlanDetailsQuery,
  useLazyGetTaskPlanDetailsQuery,
  useEditTaskPlanMutation,
  useGetTaskPlanListQuery,
  useAddTaskPlanMutation,
  useDeleteTaskPlanMutation,
  useTaskPlanHistoryQuery,
  useGetTaskPlanCalendarQuery,
  useGetTaskPlanCalendarDetailsQuery,
  useUpsertNoteForTaskPlanMutation,
} = taskPlansApi

export { FIELD_TYPE }
export type {
  AssetTypeRequest,
  AssetTypeResponse,
  AssetsRequest,
  AssetsResponse,
  AssigneesRequest,
  AssigneesResponse,
  FormType,
  OperationItemType,
  OperationItemTypeRequest,
  OperationItemTypeResponse,
  OperationRegionRequest,
  OperationResponse,
  OperationsRegionResponse,
  RegionsRequest,
  RegionsResponse,
  SearchAssetRequest,
  SearchAssetResponse,
  ServicePointUpsertRequest,
  ServicePointDetailRequest,
  ServicePointDetailResponse,
  ServicePointEditResponse,
  ServicePointViewResponse,
  ServicePointLocationRequest,
  ServicePointLocationResponse,
  ShiftsResponse,
  TaskDetailRequest,
  TaskDetailResponse,
  TaskOrderDeleteRequest,
  TaskOrderDeleteResponse,
  TaskOrdersRequest,
  TaskOrdersResponse,
  TaskStep,
  TaskTemplateStepsRequest,
  TaskTemplateStepsResponse,
  TaskTemplatesRequest,
  TaskTemplatesResponse,
  TaskTransitionDetailPageRequest as TaskTransitionRequest,
  TaskTransitionDetailPageResponse as TaskTransitionResponse,
  TransitionType,
}

export {
  taskApi,
  servicePointApi,
  routeApi,
  staticTourApi,
  opsSettingsApi,
  taskPlansApi,
  geomapApi,
  regionApi,
  opsGeneralApi,
}
