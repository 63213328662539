import React, { useEffect } from 'react'
import { Flex, Grid } from 'components'
import { BreadCrumbType } from 'utils/useBreadcrumb'
import { get } from 'lodash-es'
import { useTranslation } from 'utils/useTranslation'
import { usePrompt } from 'utils/usePrompt'
import { FormHeader } from 'containers/Header'
import { PropertiesHeader, StyledBox } from '../Detail/Detail.styled'
import { InputGridSkeleton } from 'components/Skeletons/InputGridSkeleton'
import { useFormContext } from 'react-hook-form'

type FormProps = {
  promptMessage?: string
  isDirty?: boolean
  onSubmit?: React.FormEventHandler<HTMLFormElement>
  breadcrumbs?: BreadCrumbType[]
  children?: JSX.Element[]
}

type FormComponent = React.FC<React.PropsWithChildren<FormProps>> & {
  LeftColumn: React.FC<React.PropsWithChildren>
  RightColumn: React.FC<React.PropsWithChildren>
  ButtonArea: React.FC<React.PropsWithChildren<{ buttonPlacement?: ButtonPlacementType }>>
  Map: React.FC<React.PropsWithChildren<{ title?: React.ReactNode }>>
}

type ButtonPlacementType = 'left' | 'center' | 'right'

const styles = {
  column: { overflow: 'auto', height: '100%' },
  container: { height: 'calc(100vh - 260px)' },
}

export const Form: FormComponent = ({
  promptMessage,
  isDirty,
  breadcrumbs,
  onSubmit,
  children,
}) => {
  const leftColumn = children?.find((child) => get(child, 'type.displayName', '') === 'LeftColumn')
  const rightColumn = children?.find(
    (child) => get(child, 'type.displayName', '') === 'RightColumn',
  )
  const buttonArea = children?.find((child) => get(child, 'type.displayName', '') === 'ButtonArea')
  const map = children?.find((child) => get(child, 'type.displayName', '') === 'Map')
  const { t } = useTranslation()

  usePrompt(promptMessage ?? t('leave_page_confirmation'), isDirty)

  const {
    formState: { errors },
    setFocus,
  } = useFormContext()

  useEffect(() => {
    const keysWithError = Object.keys(errors)
    if (keysWithError?.length > 0) {
      const dropdownElement = document.querySelector(
        `div[data-testid="${keysWithError[0]}"] input.Select__input`,
      ) as HTMLInputElement

      if (dropdownElement) {
        dropdownElement.focus()
      } else {
        setFocus(keysWithError[0])
      }
    }
  }, [errors])

  return (
    <Flex directionColumn="zero" style={{ height: '100vh' }}>
      <FormHeader breadcrumbs={breadcrumbs} />
      <form onSubmit={onSubmit} style={{ padding: '1rem', flexGrow: 1, overflow: 'hidden' }}>
        <Flex directionColumn="zero" gap={1} fullHeight style={{ maxHeight: '100%' }}>
          <Grid columns={2} gap={1} style={{ flexGrow: 1, overflow: 'hidden' }}>
            <StyledBox scroll noHeight hasPadding>
              {leftColumn?.props.children}
            </StyledBox>
            <StyledBox scroll noHeight hasPadding={!map}>
              {map && map.props.title && <PropertiesHeader>{map.props.title}</PropertiesHeader>}
              {map ? (
                <div style={{ flexGrow: 1 }}>{map?.props.children}</div>
              ) : (
                rightColumn?.props.children
              )}
            </StyledBox>
          </Grid>
          <Flex gap={0.75}>{buttonArea?.props.children}</Flex>
        </Flex>
      </form>
    </Flex>
  )
}

const LeftColumn: React.FC<React.PropsWithChildren> = () => <></>
const RightColumn: React.FC<React.PropsWithChildren> = () => <></>
const ButtonArea: React.FC<React.PropsWithChildren> = () => <></>
const Map: React.FC<React.PropsWithChildren<{ title?: React.ReactNode }>> = () => <></>

LeftColumn.displayName = 'LeftColumn'
RightColumn.displayName = 'RightColumn'
ButtonArea.displayName = 'ButtonArea'
Map.displayName = 'Map'

Form.LeftColumn = LeftColumn
Form.RightColumn = RightColumn
Form.ButtonArea = ButtonArea
Form.Map = Map

export const FormSkeletonNew: React.FC = () => {
  return (
    <Flex directionColumn="zero" style={{ height: '100vh' }}>
      <FormHeader loading />
      <form style={{ padding: '1rem', flexGrow: 1, overflow: 'hidden' }}>
        <Flex directionColumn="zero" gap={1} fullHeight style={{ maxHeight: '100%' }}>
          <Grid columns={2} gap={1} style={{ flexGrow: 1, overflow: 'hidden' }}>
            <StyledBox scroll noHeight hasPadding>
              <InputGridSkeleton
                gridProps={{
                  columns: 2,
                  gap: 1,
                }}
                count={17}
              />
            </StyledBox>
            <StyledBox scroll noHeight hasPadding>
              <InputGridSkeleton
                gridProps={{
                  columns: 2,
                  gap: 1,
                }}
                count={17}
              />
            </StyledBox>
          </Grid>
          <Flex gap={0.75}></Flex>
        </Flex>
      </form>
    </Flex>
  )
}
