import { Box, Button, Flex, Typography } from 'components'
import React from 'react'
import { UilRefresh } from '@iconscout/react-unicons'
import i18n from 'utils/i18n'
import { LayoutList } from 'containers/Layout'

export const Fallback: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <LayoutList>
      <Box fullHeight>
        <Flex
          fullHeight
          directionColumn="zero"
          middle="zero"
          center="zero"
          gap={2}
          style={{
            position: 'relative',
            zIndex: 2,
          }}
        >
          <img src="/images/error_guy.svg" height="200" />
          <img
            src="/images/checker.svg"
            height="700"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: -1,
            }}
          />
          <Flex directionColumn="zero" middle="zero" center="zero" gap={1.5}>
            <Flex directionColumn="zero" middle="zero" center="zero" gap={0.5}>
              <Flex directionColumn="zero" middle="zero" center="zero">
                <Typography color="grey900" fontSize="header_sm" fontWeight="bold">
                  {i18n.t('pages.common.fallback.trouble_text')}
                </Typography>
              </Flex>
              <Typography color="grey600" fontSize="text_xl">
                {i18n.t('pages.common.fallback.notified_text')}
              </Typography>
            </Flex>
            <Flex gap={0.5} middle="zero" center="zero">
              <Button
                onClick={() => window.location.reload()}
                variant="outline"
                color="secondary"
                iconLeft={UilRefresh}
              >
                {i18n.t('refresh')}
              </Button>
              <Button onClick={() => window.location.replace('/')}>
                {i18n.t('pages.common.error.go_to_homepage')}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </LayoutList>
  )
}
