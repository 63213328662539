import styled from 'styled-components'

export type AssetIconProps = {
  // TODO: color type
  color: string
  isSelected: boolean
}

export const AssetIconWrapperStyled = styled.svg<AssetIconProps>`
  width: ${({ isSelected }) => (isSelected ? '56px' : '40px')};
  height: ${({ isSelected }) => (isSelected ? '56px' : '40px')};
  fill: none;
`

export const AssetIconBorderStyled = styled.circle<AssetIconProps>`
  fill: ${({ isSelected, color }) => (isSelected ? color : '#fff')};
  stroke: ${({ isSelected, color }) => (!isSelected ? color : '#fff')};
  stroke-width: 2;
`
