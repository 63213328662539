import React, { useState } from 'react'
import { Button, Flex, InputPassword, InputText, Typography } from 'components'
import ReCAPTCHA from 'react-google-recaptcha'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useLazyGetAuthQuery, useLoginMutation } from 'services/auth'
import i18n from 'utils/i18n'
import { useNavigate } from 'react-router-dom'
import { changeClientId } from 'services/slices/ui'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'utils/useTranslation'
import { useSetLanguageMutation } from 'services/common'
import LoginLayout from './LoginLayout'

export const isCaptchaRequired =
  !!process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY &&
  process.env.REACT_APP_ENV !== 'int01' &&
  process.env.NODE_ENV !== 'development'

type LoginProps = {}

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
})

type FormInputs = {
  username: string
  password: string
}

const Login: React.FC<LoginProps> = () => {
  const { t } = useTranslation('pages.authentication')
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const captchaRef = React.useRef<ReCAPTCHA>(null)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      username: JSON.parse(localStorage.getItem('remember_user_login_credentials') || '{}')
        .username,
      password: JSON.parse(localStorage.getItem('remember_user_login_credentials') || '{}')
        .password,
    },
  })
  const [reCAPTCHA, setReCAPTCHA] = useState<string>()

  const [getAuth] = useLazyGetAuthQuery()
  const [login] = useLoginMutation()
  const [setLanguage] = useSetLanguageMutation()

  const onSubmit = async (data: FormInputs) => {
    const { username, password } = data

    login({
      username,
      password,
      recaptchaToken: reCAPTCHA,
    })
      .unwrap()
      .then(async (res) => {
        if (res.success) {
          const response = await getAuth()
          if (response.isSuccess) {
            const isCustomerPortalUser =
              response.data?.user?.customer_portal_credentials?.client_id &&
              response.data?.user?.customer_portal_credentials?.entity_id
            const customerPortalUrl = `/engagement/customer_portal/${response.data?.user?.customer_portal_credentials?.entity_id}`
            response.data?.user?.as_client_id &&
              dispatch(changeClientId(String(response.data?.user?.as_client_id)))
            setLanguage(i18n.language)
            navigate(isCustomerPortalUser ? customerPortalUrl : '/')
          }
        }
      })
      .finally(() => {
        if (captchaRef.current) {
          captchaRef.current.reset()
        }
      })
  }

  return (
    <LoginLayout title={t('welcome')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex gap={1} directionColumn="zero">
          <Controller
            render={({ field }) => (
              <InputText {...field} label={t('username')} error={!!errors.username} />
            )}
            control={control}
            name="username"
          />
          <Controller
            render={({ field }) => (
              <InputPassword {...field} label={t('password')} error={!!errors.password} />
            )}
            control={control}
            name="password"
          />

          {isCaptchaRequired && (
            <ReCAPTCHA
              onChange={(e) => e && setReCAPTCHA(e)}
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
              lang={i18n.language}
              ref={captchaRef}
            />
          )}

          <Flex middle="zero" between="zero">
            <Button
              onClick={() => navigate('/forgot_password')}
              size="small"
              type="button"
              variant="text"
              color="primary"
            >
              {t('forgot_password')}
            </Button>
          </Flex>
          <Button type="submit" variant="filled" color="primary">
            {t('log_in')}
          </Button>
        </Flex>
      </form>
      <Flex gap={0.125} directionRow="sm" directionColumn="zero" center="zero" middle="zero">
        <Typography fontSize="text_sm" color="grey600" fontWeight="regular">
          {t('get_info')}
        </Typography>
        <Button href="mailto:info@evreka.co" variant="link" color="primary">
          {t('contact_us')}
        </Button>
      </Flex>
    </LoginLayout>
  )
}

export default Login
