import { createApi } from '@reduxjs/toolkit/query/react'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { providesList } from 'services/util'

type OperationsResponse = Array<{
  id: number
  name: string
  assignee_type: number
}>

type ActiveShiftResponse = {
  shifts: Array<{
    id: number
    name: string
  }>
  success: boolean
}

type VehicleResponse = Array<{
  vehicle_id: number
  plate: string
}>

export const operationApi = createApi({
  reducerPath: 'operationApi',
  tagTypes: ['Operations', 'Tasks'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/api/'}`,
  }),
  endpoints: (builder) => ({
    getOperations: builder.query<OperationsResponse, number>({
      query: (clientId) => ({
        url: `clients/${clientId}/operations/?fields=id,name,assignee_type`,
        method: 'GET',
      }),
      providesTags: (result) => providesList(result, 'Operations'),
    }),
    getTasks: builder.query<OperationsResponse, number | undefined>({
      query: (operationId) => ({ url: `operations/${operationId}/operation_types`, method: 'GET' }),
      providesTags: (result) => providesList(result, 'Tasks'),
    }),
    getVehicles: builder.query<
      VehicleResponse,
      { clientId: number; operationId: number | undefined }
    >({
      query: ({ clientId, operationId }) => ({
        url: `clients/${clientId}/vehicles/?fields=vehicle_id,plate&?operation=${operationId}`,
        method: 'GET',
      }),
    }),
    //not active on averda
    getEmployees: builder.query<
      OperationsResponse,
      { clientId: number; operationId: number | undefined }
    >({
      query: ({ clientId, operationId }) => ({
        url: `clients/${clientId}/employee/?operation=${operationId}`,
        method: 'GET',
      }),
    }),
    getActiveShifts: builder.query<
      ActiveShiftResponse,
      {
        clientId: number
        vehicleId: number | undefined
        operationId: number | undefined
        date: string
      }
    >({
      query: ({ clientId, vehicleId, operationId, date }) => ({
        url: `clients/${clientId}/event_management/get_active_shifts/`,
        method: 'POST',
        data: {
          operationId,
          vehicleId,
          date,
        },
      }),
    }),
  }),
})

export const {
  useGetOperationsQuery,
  useGetEmployeesQuery,
  useGetActiveShiftsQuery,
  useGetTasksQuery,
  useGetVehiclesQuery,
} = operationApi
