import { Typography, Flex } from 'components'
import { Logo, Sidebar } from '../Sidebar'
import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'
import { selectClientModules, selectMenu } from 'services/slices/auth'
import { selectUi } from 'services/slices/ui'
import { useTranslation } from 'utils/useTranslation'
import { selectEnvironmentEngagement } from 'services/slices/environment'
import { CommandTrigger } from 'containers/Sidebar/CommandTrigger'
import { ProfileButton } from 'containers/Sidebar/ProfileButton'
import { MenuItem } from 'containers/Sidebar/SidebarMenu/MenuItem'
import { SidebarContentStyled } from 'containers/Sidebar/Sidebar.style'
import { MenuDivider } from 'containers/Sidebar/SidebarMenu/SidebarMenu.style'
import { ProfileEnvironment } from 'types/common'
import { useLocation } from 'react-router'

export const MenuContainer: React.FC = () => {
  const { isSOS, notificationCount, sidebarTheme } = useSelector(selectUi)
  const menu = useSelector(selectMenu)
  const env = useSelector(selectEnvironmentEngagement)
  const { t } = useTranslation('sidebar')
  const loc = useLocation()

  const availableModules = useSelector(selectClientModules)
  const hasOps = availableModules.ops
  const hasAsset = availableModules.asset
  const hasMrf = availableModules.mrf
  const hasEngagement = availableModules.engagement
  const hasFleet = availableModules.fleet

  const profileEnvironment: ProfileEnvironment = useMemo(() => {
    if (loc.pathname.includes('customer_portal')) {
      return 'customer-portal'
    }
    if (loc.pathname.includes('user_management')) {
      return 'user-management'
    }
  }, [loc])

  const CUSTOMER_PORTAL_URL = '/engagement/customer_portal'

  const operationLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'operation')
  }, [menu])

  const hasOpsSubItems = useMemo(() => {
    return operationLinks?.subMenus && operationLinks.subMenus.length > 0
  }, [operationLinks])

  const fleetLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'fleet')
  }, [menu])

  const hasFleetSubItems = useMemo(() => {
    return fleetLinks?.subMenus && fleetLinks.subMenus.length > 0
  }, [fleetLinks])

  const assetLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'asset')
  }, [menu])

  const hasAssetSubItems = useMemo(() => {
    return assetLinks?.subMenus && assetLinks.subMenus.length > 0
  }, [assetLinks])

  const engagementLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'engagement')
  }, [menu])

  const hasEngagementSubItems = useMemo(() => {
    return engagementLinks?.subMenus && engagementLinks.subMenus.length > 0
  }, [engagementLinks])

  const mrfLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'mrf')
  }, [menu])

  const hasMrfSubItems = useMemo(() => {
    return mrfLinks?.subMenus && mrfLinks.subMenus.length > 0
  }, [mrfLinks])

  const userLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'user')
  }, [menu])

  const eventLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'event')
  }, [menu])

  const reportsLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'report')
  }, [menu])
  const documentLinks = useMemo(() => {
    return menu?.find((item) => item.id === 'document')
  }, [menu])

  return (
    <Sidebar>
      <Logo
        src="https://media.evreka.co/360-Symbol.png"
        to={
          env.data && env.data.is_customer_portal_user
            ? `${CUSTOMER_PORTAL_URL}/${env.data.customer_portal_entity_id}`
            : undefined
        }
      />
      <SidebarContentStyled directionColumn="zero" gap={1}>
        {profileEnvironment !== 'customer-portal' && <CommandTrigger />}
        <Flex directionColumn="zero">
          {hasOps && operationLinks && hasOpsSubItems && (
            <MenuItem icon={'ops'} label={t('modules.operations')} links={operationLinks} />
          )}
          {hasFleet && fleetLinks && hasFleetSubItems && (
            <MenuItem icon={'fleet'} label={t('modules.fleet')} links={fleetLinks} />
          )}
          {hasAsset && assetLinks && hasAssetSubItems && (
            <MenuItem links={assetLinks} icon={'asset'} label={t('modules.asset')} />
          )}
          {hasEngagement && engagementLinks && hasEngagementSubItems && (
            <MenuItem links={engagementLinks} icon={'engagement'} label={t('modules.engagement')} />
          )}
          {hasMrf && mrfLinks && hasMrfSubItems && (
            <MenuItem icon={'mrf'} links={mrfLinks} label={t('modules.mrf')} />
          )}
          <MenuDivider sidebarTheme={sidebarTheme} />
          {userLinks && <MenuItem links={userLinks} icon={'user'} label={t('modules.users')} />}
          {eventLinks && (
            <MenuItem
              links={eventLinks}
              icon={'event'}
              label={t('modules.events')}
              isSOS={isSOS}
              badge={
                notificationCount
                  ? {
                      label: notificationCount > 99 ? '99' : notificationCount.toString(),
                      color: 'red',
                    }
                  : undefined
              }
            />
          )}
          {reportsLinks && (
            <MenuItem links={reportsLinks} icon={'report'} label={t('modules.reports')} />
          )}
          {documentLinks && (
            <MenuItem links={documentLinks} icon={'document'} label={t('modules.documents')} />
          )}
        </Flex>
      </SidebarContentStyled>
      <Flex directionColumn="zero" gap={0.25}>
        <Typography variant="span" fontSize="text_xxs" color="grey400" align="center">
          v{process.env.REACT_APP_VERSION}
        </Typography>
        <ProfileButton />
      </Flex>
    </Sidebar>
  )
}
