import 'utils/wdyr'
import moment from 'moment'
import React, { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { Provider } from 'react-redux'
import { StyleSheetManager } from 'styled-components'
import stylisRTLPlugin from 'stylis-plugin-rtl'
import reportWebVitals from './utils/reportWebVitals'
import {
  GlobalStyles,
  ThemeContextProvider,
  AppContextProvider,
} from '@evrekadev/evreka-ui-components'
import { Loading, Flex } from 'components'
import Routing from 'routes/Routing'
import store from 'services/store'
import { useRtl } from 'utils/useRtl'
import { useTranslation } from 'utils/useTranslation'
import { Helmet } from 'react-helmet'
import '@evrekadev/evreka-ui-components/dist/common.css'
import { createRoot } from 'react-dom/client'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'

/*
We're overriding en-US as en-GB
https://github.com/moment/moment/blob/e96809208c9d1b1bbe22d605e76985770024de42/dist/locale/en-gb.js#L17
*/
moment.updateLocale('en', {
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd, D MMMM YYYY HH:mm',
  },
})

let dialogShownTimeout: ReturnType<typeof setTimeout>

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
} else {
  const replayIntegrationRequiredEnvironments = new Set(['alba', 'bell', '360'])

  const isReplayIntegrationRequired = replayIntegrationRequiredEnvironments.has(
    process.env.REACT_APP_ENV || '',
  )

  Sentry.init({
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'UnhandledRejection',
      '_controlCorners',
      'site_id',
      'CustomEvent',
      '_leaflet_pos',
    ],
    dsn: 'https://6063b3df46a24022b8c30f7110923a10@o54982.ingest.sentry.io/5729045',
    integrations: isReplayIntegrationRequired ? [Sentry.replayIntegration()] : undefined,
    environment: process.env.REACT_APP_SENTRY_ENV,
    release: 'dashboard-next@' + process.env.REACT_APP_VERSION,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}

function AppWithStyledWrapper() {
  const isRtl = useRtl()
  return (
    <StyleSheetManager
      key={isRtl ? 'rtl' : 'ltr'}
      stylisPlugins={isRtl ? [stylisRTLPlugin] : undefined}
    >
      <Routing />
    </StyleSheetManager>
  )
}

function ErrorFallback({ error }: FallbackProps) {
  React.useEffect(() => {
    if (error.message.includes("SyntaxError: Unexpected token '‹'")) {
      window.location.reload()
    }
  }, [error])
  return null
}

function AppWithStore() {
  const { i18n, t } = useTranslation()
  return (
    <>
      <Helmet>
        <title>{t('dashboard.title')}</title>
        <html lang={i18n.language} />
      </Helmet>
      <GlobalStyles />
      <Provider store={store}>
        <AppWithStyledWrapper />
      </Provider>
    </>
  )
}

const suspenseStyle: React.CSSProperties = { height: '100vh' }

function App() {
  const { i18n, t } = useTranslation()

  return (
    <AppContextProvider currentLanguage={i18n.language}>
      <ThemeContextProvider>
        <Suspense
          fallback={
            <Flex style={suspenseStyle} center="zero" middle="zero">
              <Loading />
            </Flex>
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AppWithStore />
          </ErrorBoundary>
        </Suspense>
      </ThemeContextProvider>
    </AppContextProvider>
  )
}

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
