import { createApi } from '@reduxjs/toolkit/query/react'
import {
  MutationResponse,
  FinancialDetailListResponse,
  FinancialDetailListRequest,
  AddFinancialDetailRequest,
  DeleteFinancialDetailRequest,
  FinancialDetailResponse,
  FinancialDetailRequest,
  EditFinancialDetailRequest,
  ExportRequest,
} from './types'
import axiosBaseQuery from 'services/axiosBaseQuery'
import { sendNotification } from 'components'
import { ExportResponse } from 'services/MrfManagement/types'
import { getExportHandler, getImportHandler } from 'utils'
import { TableRequestV2, TableResponse, TableResponseV2 } from 'services/types'

export const financialDetailApi = createApi({
  reducerPath: 'financialDetailApi',
  tagTypes: ['FinancialDetails', 'FinancialDetail', 'FinancialDetailHistory'],
  baseQuery: axiosBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL + '/engagement/'}`,
  }),
  endpoints: (builder) => ({
    getFinancialDetailList: builder.query<FinancialDetailListResponse, FinancialDetailListRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/financial_detail/list/`,
        method: 'POST',
        data,
      }),
      providesTags: ['FinancialDetails'],
    }),
    addFinancialDetail: builder.mutation<MutationResponse, AddFinancialDetailRequest>({
      query: ({ client_id, body }) => ({
        url: `clients/${client_id}/financial_detail/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['FinancialDetails', 'FinancialDetail'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    editFinancialDetail: builder.mutation<
      MutationResponse & { service_point_id: string },
      EditFinancialDetailRequest
    >({
      query: ({ client_id, financial_detail_id, body }) => ({
        url: `clients/${client_id}/financial_detail/${financial_detail_id}/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['FinancialDetails', 'FinancialDetail', 'FinancialDetailHistory'],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    deleteFinancialDetail: builder.mutation<MutationResponse, DeleteFinancialDetailRequest>({
      query: ({ client_id, financial_detail_id }) => ({
        url: `clients/${client_id}/financial_detail/${financial_detail_id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FinancialDetails'],
      async onQueryStarted(_id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          // `onSuccess` side-effect
          sendNotification({
            type: 'success',
            toastContent: data.detail.message,
          })
        } catch (err) {
          // `onError` side-effect
        }
      },
    }),
    getFinancialDetail: builder.query<FinancialDetailResponse, FinancialDetailRequest>({
      query: ({ client_id, financial_detail_id }) => ({
        url: `clients/${client_id}/financial_detail/?financial_detail_id=${financial_detail_id}`,
        method: 'GET',
      }),
      providesTags: ['FinancialDetail'],
    }),
    exportFinancialDetailList: builder.mutation<ExportResponse, ExportRequest>({
      query: ({ client_id, ...data }) => ({
        url: `clients/${client_id}/financial_detail/export/`,
        method: 'POST',
        data,
      }),
      onQueryStarted: getExportHandler,
    }),
    importFinancialDetailList: builder.mutation<
      MutationResponse,
      { clientId: string; body: FormData }
    >({
      query: ({ clientId, body }) => ({
        url: `clients/${clientId}/financial_detail/import/`,
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['FinancialDetails'],
      onQueryStarted: getImportHandler,
    }),
    financialDetailHistory: builder.query<
      TableResponse<{}>,
      TableRequestV2 & {
        financial_detail_id: string
      }
    >({
      query: ({ client_id, financial_detail_id, ...data }) => ({
        url: `clients/${client_id}/financial_detail/${financial_detail_id}/history/`,
        method: 'POST',
        data,
      }),
      providesTags: ['FinancialDetailHistory'],
    }),
  }),
})

export const {
  useGetFinancialDetailListQuery,
  useAddFinancialDetailMutation,
  useDeleteFinancialDetailMutation,
  useGetFinancialDetailQuery,
  useEditFinancialDetailMutation,
  useExportFinancialDetailListMutation,
  useImportFinancialDetailListMutation,
  useFinancialDetailHistoryQuery,
} = financialDetailApi
